/*
 * @Author: BaiXin 
 * @Date: 2021-10-10 16:08:26 
 * @Last Modified by: HouQi
 * @Last Modified time: 2022-02-24 21:11:11
 */

// 打印申请书页面
import {
  Card,
  List,
  Button,
  Table,
} from "antd";
import Password from "antd/lib/input/Password";
import React, { useEffect, useMemo, useState } from "react";
import CustomCard from "../../../../components/common/card/card";
import { post } from "../../../../network/request";
import "./outboundApplication.css";

export default function PrintApplication(props){
  console.log('@',props)
  // console.log(props.location.state.studentSid)
  console.log(parseInt(localStorage.studentSid))
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll", height: "calc(115vh - 78px - 130px)" },
  };
  // console.log(props)
  console.log(localStorage)
  let studentName = localStorage.studentName
  // let studentName = props.location.state.studentName;
  // console.log(props.location.state)
  const [apply,setApply]=useState([])
  // const [projectColumns,setProjectColumns] = useState([]);
  // 获取要求与项目数据
  async function getProjectInfo() {
    await post(
      {
        url: "/ec/station/getAllProjectZByStudentSid",
        data: {
          sessionId: localStorage.sessionId,
          // studentSid:  props.location.state.studentSid,
          studentSid:  parseInt(localStorage.studentSid),
          // degreeType:  props.location.state.degreeType,
          degreeType:  parseInt(localStorage.degreeType),
          // entryGrade:  props.location.state.entryGrade,
          entryGrade:  parseInt(localStorage.entryGrade),
        },
      },
      (data) => {
        handleSubmitedData(data.contents)
      },
      props.history
    );
  }
  useEffect(()=>{
    getProjectInfo()
  },[])
  //选择子table要显示的信息
  const childTableColumn = (type) => {
    switch (type) {
      case 1:
        return [
          {
            title: "项目名称",
            dataIndex: "itemName",
            align: "center",
          },
          {
            title: "主持者",
            dataIndex: "managerName",
            align: "center",
          },
          {
            title: "参与者",
            dataIndex: "participants",
            align: "center",
          },
          {
            title: "开始时间",
            dataIndex: "insertTimestamp",
            align: "center",
            render: (text, record) => (
              <div>
                {new Date(record.insertTimestamp * 1000).toLocaleDateString()}
              </div>
            ),
          },
          {
            title: "结束时间",
            dataIndex: "endTime",
            align: "center",
            render: (text, record) => (
              <div>{new Date(record.endTime * 1000).toLocaleDateString()}</div>
            ),
          },
        ];
      case 2:
        return;
      default:
        break;
    }
  };
const handleSubmitedData = (content) => {
  console.log(content)
  if (!content) return;
  let request = content.request;
  let project = content.project;
  if (typeof request === "undefined" && typeof project === "undefined")
    return;
  let templet = [];

  for (let i = 0; i < request.length; i++) {
    let item = request[i];
    let childColumns = childTableColumn(1);
    let data = [];
    let count=0
    for (let j = 0; j < project.length; j++) {
      if (
        project[j].outboundConditionSid === item.outboundConditionSid 
        // project[j].outboundConditionSid === 0
      ) {
        data.push(project[j]);
        if( project[j].ifChange===1) {
          count++
          console.log(count)}
      } else if (
        project[j].outboundConditionSid === item.outboundConditionSid &&
        project[j].managerName === localStorage.studentName
      ) {
        data.push(project[j]);
        if( project[j].ifChange===1) {
          count++
          console.log(count)}
      } else if (
        project[j].outboundConditionSid === item.outboundConditionSid &&
        typeof project[j].changeSid !== "undefined"
      ) {
        data.push(project[j]);
        if( project[j].ifChange===1) {
          count++
          console.log(count)
     }
      }
    }
    let title =
       item.conditionName+
      "要求: " +
      item.amount +
      "项" +
      " 已完成：" +
      (data.length-count) +
      "/" +
      item.amount;
    templet.push({
      title,
      data,
      childColumns,
    });
  }
  setApply(templet)
};
  // 处理表格内容的数据
  // const projectData = useMemo(()=>{
  //   if(content === {}){
  //     return;
  //   }
  //   let request0 = content.request
  //   let project0 = content.project
  //   let applyContentInfo = [];
  //   for(let i in request0){
  //     applyContentInfo[request0[i].outboundConditionSid] = {
  //       title:{},
  //       content0:[],
  //       columns:[]
  //     };
  //     applyContentInfo[request0[i].outboundConditionSid].title.amount = request0[i].amount;//要求数量
  //     applyContentInfo[request0[i].outboundConditionSid].title.conditionName = request0[i].conditionName;//条件名称
  //     applyContentInfo[request0[i].outboundConditionSid].title.itemType = request0[i].itemType;
  //   }
  //   for(let i in project0){
  //     if(project0){
  //       if(project0[i].participants == ''){
  //         project0[i].participants = '无';
  //       }
  //       //数据分类
  //       if(project0[i].role === 1){   //主持
  //         applyContentInfo[project0[i].itemType].content0.push(((project0[i])));
  //         applyContentInfo[project0[i].itemType].content0[applyContentInfo[project0[i].itemType].content0.length-1].num = applyContentInfo[project0[i].itemType].content0.length;
  //       }else if(project0[i].role === 2){  //参与
  //         for(let j in request0){
  //           if(request0[j].itemType == project0[i].itemType && request0[j].itemType != request0[j].outboundConditionSid){
  //             applyContentInfo[request0[j].outboundConditionSid].content0.push(((project0[i])));
  //             applyContentInfo[request0[j].outboundConditionSid].content0[applyContentInfo[request0[j].outboundConditionSid].content0.length-1].num = applyContentInfo[request0[j].outboundConditionSid].content0.length;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   applyContentInfo.splice(0,1);
  //     let tmpArr = [];
  //     for(let i in applyContentInfo){
  //       tmpArr.push(applyContentInfo[i]);
  //       for(let j in applyContentInfo[i].content0){
  //         applyContentInfo[i].content0[j].insertTimestamp = !applyContentInfo[i].content0[j].insertTimestamp
  //         ? "暂无"
  //         : new Date(applyContentInfo[i].content0[j].insertTimestamp * 1000).toLocaleDateString();
  //         applyContentInfo[i].content0[j].endTime = !applyContentInfo[i].content0[j].endTime
  //         ? "暂无"
  //         : new Date(applyContentInfo[i].content0[j].endTime * 1000).toLocaleDateString();
  //       }
  //     }
  //   applyContentInfo = tmpArr;
  //   // console.log(applyContentInfo);
  //   for(let i in applyContentInfo){
  //     if(applyContentInfo[i]){
  //       applyContentInfo[i].title.completed = applyContentInfo[i].content0.length;
  //       let cnt = 0;
  //       for(var j in applyContentInfo[i].content0){
  //         let begin = new Date(applyContentInfo[i].content0[j].insertTimestamp).getTime();
  //         let end = new Date(applyContentInfo[i].content0[j].endTime).getTime();
  //         if(begin == end){
  //           cnt++;
  //         }
  //       }
  //       if(cnt == applyContentInfo[i].title.completed){
  //         applyContentInfo[i].title.showTwoTime = false;
  //         applyContentInfo[i].columns.push(
  //           {
  //             title:'项目名称',
  //             dataIndex:'itemName',
  //             key:'itemName',
  //             align:'center',
  //           },
  //           {
  //             title:'主持者',
  //             dataIndex:'managerName',
  //             key:'managerName',
  //             align:'center',
  //           },
  //           {
  //             title:'参与者',
  //             dataIndex:'participants',
  //             key:'participants',
  //             align:'center',
  //           },
  //           {
  //             title:'申请时间',
  //             dataIndex:'endTime',
  //             key:'endTime',
  //             align:'center',
  //           }
  //         );
  //       }else{
  //         applyContentInfo[i].columns.push(
  //           {
  //             title:'项目名称',
  //             dataIndex:'itemName',
  //             key:'itemName',
  //             align:'center',
  //           },
  //           {
  //             title:'主持者',
  //             dataIndex:'managerName',
  //             key:'managerName',
  //             align:'center',
  //           },
  //           {
  //             title:'参与者',
  //             dataIndex:'participants',
  //             key:'participants',
  //             align:'center',
  //           },
  //           {
  //             title:'开始时间',
  //             dataIndex:'insertTimestamp',
  //             key:'insertTimestamp',
  //             align:'center',
  //           },
  //           {
  //             title:'结束时间',
  //             dataIndex:'endTime',
  //             key:'endTime',
  //             align:'center',
  //           }
  //         );
  //       }
  //     }
  //   }
  //   return applyContentInfo;
  // },[content])
  // 初始化加载项目要求
  useEffect(() => {
    // getProjectInfo();
  }, []);
  // 返回上一级
  function goback(){
    props.history.push('/home/verticalExitManage');
  }
  return(
    <>
    <CustomCard options={tableCardOptions}>
      <div>
        <div style={{height:"30px",lineHeight:"30px"}}>
          <h2 className="leftBorder" style={{borderLeft: "4px #5f9ea0 solid",paddingLeft:"10px !important", padding: "0 10px",margin:"10px 0 0 20px", fontSize:"18px",color: "#222"}}>二阶段出站  <span style={{fontWeight:600}}>{studentName}</span> - 出站申请</h2>
          <div style={{marginLeft:"20px",marginTop:"30px"}}>
            <Button  type="primary" style={{marginRight:"20px"}} onClick={goback}>返回上一级</Button >
          </div>
        </div>
        <div className="printContainer" id="print">
          <div className="printContent" style={{marginTop:"60px"}}>
            <div className="lookApply">
              <div>
              <List
                dataSource={apply}
                renderItem={item => (
                  <List.Item >
                  <Card title={item.title}>
                    <Table
                    rowKey= "itemSid"
                    className="listData"
                    style={item.data.length!=0 ? {display:'block'}:{display:'none'}}
                    columns={item.childColumns}
                    dataSource={item.data}
                    pagination={false}
                    />
                    <div className="noDataTip" style={item.data.length!=0 ? {display:'none'}:{display:'block'}}>暂无内容</div>
                  </Card>
                  </List.Item>
                )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomCard>
    </>
  )
}
