import React, { useEffect, useState, useMemo } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    Select,
    Space,
    notification,
    Modal,
    DatePicker,
    Popconfirm,
    message,
    Tag
} from "antd";
import { post, request } from "../../../../network/request";
import moment from 'moment'
import "../paperManage.css"
export default function AddDialog(props) {
    /******************普通数据****************/
    // console.log(props)
    const { Option } = Select
    const [form] = Form.useForm();
    const layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 20 },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    }
    const todayDate = new Date().getFullYear()
    const grades = [todayDate + 1, todayDate, todayDate - 1, todayDate - 2]
    const dateFormat = 'YYYY/MM/DD'
    const mentorName = JSON.parse(localStorage.userInfo).mentorName
    const managerSid = parseInt(localStorage.studentSid)
    const validateMessages = {
        required: "请填写${label}!",
        types: {
            email: "${label} is not validate email!",
            number: "${label} is not a validate number!",
            array: "请填写${label}!",
        },
        number: {
            range: "${label} must be between ${min} and ${max}",
        },
    };
    //useState
    //当前所选年纪学生
    const [selectedStudents, setSelectedStudents] = useState('')
    //当前所选学生
    const [selectedStudent, setSelectStudent] = useState('')
    //已添加的学生
    const [currentStudent, SetCurStu] = useState([])
    /********************useEffect*************/
    useEffect(() => {
        setSelectStudent('')
        setSelectedStudents('')
        SetCurStu([])
        if (props.type === '添加') {
            // console.log('??')
            form.resetFields()
            form.setFieldsValue({ managerName: mentorName })

        }
        else {
            // console.log(props.text)
            let text = { ...props.text }
            let time = moment(new Date(text.insertTimestamp * 1000).toLocaleDateString(), dateFormat)
            console.log("asqqqdf", time)
            form.setFieldsValue({ itemName: text.itemName, managerName: mentorName, statusDescription: text.statusDescription, insertTimestamp: time })
            let participants = []
            if (text.participants)
                participants = text.participants.split(" ")

            let students = []
            // console.log(participants.length)
            for (let i = 0; i < participants.length; i++) {
                // console.log(i)
                for (let j = 0; j < props.allStudents.length; j++) {
                    // console.log(props.allStudents.length)
                    if (participants[i] == props.allStudents[j].studentName) {
                        students.push(props.allStudents[j])
                    }

                }
            }
            // console.log(students)
            SetCurStu(students)
        }

    }, [props.visible])

    /******************方法********************/
    //重置关闭
    const handleClearAddInfo = () => {
        setSelectStudent('')
        setSelectedStudents('')
        SetCurStu([])
        props.closeAddForm()
        form.resetFields()
    }
    //提交
    const onFinish = (value) => {
        // console.log(value)
        // console.log(currentStudent.toString())
        let participants = currentStudent.map(item => {
            // console.log(item)
            return item.studentSid
        })
        let data = {
            itemType: 4,
            roleSid: parseInt(localStorage.roleSid),
            itemName: value.itemName,
            chargeTeacher: '',
            insertTimestamp: parseInt(new Date(value.insertTimestamp).getTime() / 1000),
            endTime: parseInt(new Date(value.insertTimestamp).getTime() / 1000),
            participantSid: participants.toString(),
            managerSid: parseInt(localStorage.studentSid),
            statusDescription: value.statusDescription
        }
        // console.log(participants.toString())
        if (props.type === '添加') {

            // console.log(data)
            addPaper(data)
        } else {
            // console.log(data)
            updatePaper(data)
        }
    }
    //选择年纪
    const handleSelectGrade = (value) => {
        let studens = props.allStudents.filter((item) => {
            // console.log(item)
            if (item.entryGrade === value)
                return item
        })
        setSelectedStudents(studens)
        //    console.log(props.allStudents)
        //    console.log(studens)
    }

    //选择参与学生
    const handleSelectedStudent = (value) => {

        const student = props.allStudents.find((item) => {
            if (item.studentSid === value)
                return item
        })

        setSelectStudent(student)
    }
    //添加参与
    const handleClickAddPartStudent = () => {
        //  console.log(selectedStudent.studentSid)
        if (typeof (selectedStudent.studentSid) === 'undefined') {
            message.error('请选择正确的参与者');
            return
        }
        let isHave = currentStudent.find(item => {
            if (item.studentSid === selectedStudent.studentSid)
                return true
        })
        if (isHave) {
            message.error('此同学已经参与此项目');
        } else {
            // console.log(currentStudent)
            // let curStu = currentStudent
            // curStu.push(selectedStudent)
            // console.log(curStu)
            SetCurStu([...currentStudent, selectedStudent])

        }
    }
    // //添加信息
    // const handleAdd = () => { }
    //渲染要参加学生
    const handleRenderStu = () => {
        let res = []
        // console.log
        if (selectedStudents !== '')
            res = selectedStudents.map(item => {
                //  console.log(item)
                return <Option value={item.studentSid} key={item.studentSid}>{item.studentName}</Option>
            })
        return res
    }
    //删除已添加
    const handleClickDeletePartStudent = (index) => {
        let data = [...currentStudent];
        data.splice(index, 1);
        SetCurStu(data);
    }
    //处理时间
    const handleTime = () => {
        if (props.type === '修改')
            return new Date(props.text.insertTimestamp * 1000).toLocaleDateString()
        else
            return new Date().toLocaleDateString()
    }
    /****************************************接口 *************************/
    //添加信息
    const addPaper = async (data) => {
        await post({
            url: "/ec/project/addProjectWriteInfo",
            data: {
                sessionId: localStorage.sessionId,
                ...data
            },
        }, (data) => {
            if (data.code === "N01") {
                message.success('添加论文成功');
                setSelectStudent('')
                setSelectedStudents('')
                SetCurStu([])
                props.closeAddForm()
                props.getInfo()

            } else {
                message.error('添加论文失败');
            }
        })
    }
    //更新信息
    const updatePaper = async (data) => {
        await post({
            url: "/ec/project/updateProjectWriteInfoByItemSid",
            data: {
                sessionId: localStorage.sessionId,
                ...data,
                itemSid: props.text.itemSid,
                itemNum: props.text.itemNum
            },
        }, (data) => {
            if (data.code === "N01") {
                message.success('更新论文成功');
                setSelectStudent('')
                setSelectedStudents('')
                SetCurStu([])
                props.closeAddForm()
                props.getInfo()

            } else {
                message.error('更新论文失败');
            }
        })
    }
    return (<>
        <Modal
            title={props.type + "论文"}
            visible={props.visible}
            onCancel={() => {
                handleClearAddInfo()
            }}
            // destroyOnClose={true}
            footer={[

            ]}
        >
            <Form
                {...layout}
                onFinish={onFinish}
                // autoComplete="off"
                form={form}
                validateMessages={validateMessages}
                validateTrigger="onBlur"
                preserve={false}
                {...layout}
                style={{ flexDirection: "column" }}
            >
                <Form.Item name="itemName" label="论文名称" rules={[{ required: true, message: "请输入名称", }]}>
                    <Input placeholder="请输入论文名称" />
                </Form.Item>
                <Form.Item name="managerName" label="撰写者" rules={[{ required: true, message: "请输入撰写者", }]}>
                    <Input name="mentorName" disabled />
                </Form.Item>
                {/* <Form.Item label="参与者" name="participantSid"  >
                    <Select placeholder="年级" onChange={handleSelectGrade.bind(null)} style={{ width: '20%', marginRight: '5%' }} allowClear>
                        {grades.map((item) => {
                            return <Option value={item} key={item}>{item}</Option>
                        })}
                    </Select>
                    <Select placeholder="姓名" onChange={handleSelectedStudent.bind(null)} style={{ width: '50%', marginRight: '5%' }} allowClear>
                        {handleRenderStu()}
                    </Select>
                    <Button type="primary" onClick={handleClickAddPartStudent}>添加</Button>
                    <div className="tagsDiv">  {currentStudent.map((item, index) => {
                        // console.log(currentStudent)
                        return (<Tag closable
                            key={item.studentSid}
                            onClose={handleClickDeletePartStudent.bind(null, index)}
                            style={{ marginTop: '5px' }}
                        >{item.studentName} </Tag>)
                    })}</div>
                </Form.Item> */}
                {/* <Form.Item name="insertTimestamp" label="申请时间" rules={[{ required: true, message: "请选择日期", }]}>
                    <Space direction="vertical">
                    <DatePicker  placeholder={handleTime()}
                        // defaultValue={props.rowstuInfo.length === 0 ? moment(todayDate, dateFormat) : moment(new Date(addInfo.insertTimestamp * 1000).toLocaleDateString(), dateFormat)}
                        format={dateFormat} style={{ width: '100%' }} name="insertTimestamp" />
                    </Space>
                </Form.Item> */}


                <Form.Item 
                    label = '申请时间'
                    name="insertTimestamp"
                    rules={[{ type:'date',required:true,message:'请选择日期！' }]}
                    >
                <DatePicker></DatePicker>
                </Form.Item>
                <Form.Item name="statusDescription" label="论文状态" rules={[{ required: true, message: "请输入状态", }]}>
                    <Input placeholder="请输入当前专利状态" name="statusDescription" />
                </Form.Item >
                <Form.Item labelAlign={"right"}   {...tailFormItemLayout}>
                    {/* <div className="Btn"> */}
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }} >
                            <Button style={{ margin: '0 8px' }} onClick={handleClearAddInfo.bind(null)}>取消</Button>
                            <Button type="primary" htmlType="submit" >提交</Button>
                        </Col>
                    </Row>
                    {/* </div> */}
                </Form.Item>
            </Form>
        </Modal>
    </>)
}