/*
 * @Author: Baixin
 * @Date: 2021-11-22 15:56:04
 * @Last Modified by: HouQi
 * @Last Modified time: 2021-12-28 10:21:03
 */
// 打印申请书页面
import React, { useEffect, useState, useMemo } from "react";
import {
  Card,
  List,
  Button,
  Table,
} from "antd";
import { post, request } from "../../../network/request";
import "./printApplication.css";

export default function PrintApplication(props){
  // const [content,setContent] = useState({});
  const [apply,setApply]=useState([])
  // const [projectColumns,setProjectColumns] = useState([]);
  // 获取要求与项目数据
  async function getProjectInfo() {
    await post(
      {
        url: "/ec/station/getAllProjectZByStudentSid",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: localStorage.studentSid,
          degreeType: JSON.parse(localStorage.userInfo).degreeType,
          entryGrade: JSON.parse(localStorage.userInfo).entryGrade,
          // applicationStatus:0
        },
      },
      (data) => {
        // setContent(data.contents)
        handleSubmitedData(data.contents)
      },
      props.history
    );
  }
    //选择子table要显示的信息
    const childTableColumn = (type) => {
      switch (type) {
        case 1:
          return [
            {
              title: "项目名称",
              dataIndex: "itemName",
              align: "center",
            },
            {
              title: "主持者",
              dataIndex: "managerName",
              align: "center",
            },
            {
              title: "参与者",
              dataIndex: "participants",
              align: "center",
            },
            {
              title: "开始时间",
              dataIndex: "insertTimestamp",
              align: "center",
              render: (text, record) => (
                <div>
                  {new Date(record.insertTimestamp * 1000).toLocaleDateString()}
                </div>
              ),
            },
            {
              title: "结束时间",
              dataIndex: "endTime",
              align: "center",
              render: (text, record) => (
                <div>{new Date(record.endTime * 1000).toLocaleDateString()}</div>
              ),
            },
          ];
        case 2:
          return;
        default:
          break;
      }
    };
  const handleSubmitedData = (content) => {
    if (!content) return;
    let request = content.request;
    let project = content.project;
    if (typeof request === "undefined" && typeof project === "undefined")
      return;
    let templet = [];

    for (let i = 0; i < request.length; i++) {
      let item = request[i];
      let childColumns = childTableColumn(1);
      let data = [];
      let count=0
      for (let j = 0; j < project.length; j++) {
        if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          project[j].outboundConditionSid === 0
        ) {
          data.push(project[j]);
          if( project[j].ifChange===1) {
            count++
            console.log(count)}
        } else if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          project[j].managerName === localStorage.studentName
        ) {
          data.push(project[j]);
          if( project[j].ifChange===1) {
            count++
            console.log(count)}
        } else if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          typeof project[j].changeSid !== "undefined"
        ) {
          data.push(project[j]);
          if( project[j].ifChange===1) {
            count++
            console.log(count)
       }
        }
      }
      let title =
        item.conditionName+
        "要求: " +
        item.amount +
        "项" +
        " 已完成：" +
        (data.length-count) +
        "/" +
        item.amount;
      templet.push({
        title,
        data,
        childColumns,
      });
    }
    setApply(templet)
  };
  // 初始化加载项目要求
  useEffect(() => {
    getProjectInfo();
  }, []);
  // 关闭
  function close(){
    props.history.push('/home/studyOutbound');
  }
  // 打印申请书
  function print(){
    window.document.body.innerHTML = window.document.getElementById('print').innerHTML;
    window.print();
    window.location.reload();
  }
  return(
    <>
      <div>
        <div style={{height:"30px",lineHeight:"30px"}}>
          <h2 className="leftBorder" style={{borderLeft: "4px #5f9ea0 solid",paddingLeft:"10px !important", padding: "0 10px",margin:"10px 0 0 20px", fontSize:"18px",color: "#222"}}>下载出站申请书</h2>
          <div style={{marginLeft:"20px",marginTop:"10px"}}>
            <Button  type="primary" style={{marginRight:"20px"}} onClick={print}>下载申请书</Button >
            <Button  type="danger" onClick={close}>关闭</Button >
          </div>
        </div>
        <div className="printContainer" id="print">
          <div className="printContent">
            <h2>附件一：二阶段出站工作量详情</h2>
            <div className="lookApply">
              <div>
                <List
                dataSource={apply}
                renderItem={item => (
                  <List.Item >
                  <Card title={item.title}>
                    <Table
                    rowKey= "itemSid"
                    className="listData"
                    style={item.data.length!=0 ? {display:'block'}:{display:'none'}}
                    columns={item.childColumns}
                    dataSource={item.data}
                    pagination={false}
                    />
                    <div className="noDataTip" style={item.data.length!=0 ? {display:'none'}:{display:'block'}}>暂无内容</div>
                  </Card>
                  </List.Item>
                )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
