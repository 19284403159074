/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import "../../../components/common/card/card.css";
import { Card, Table, Button, Modal, Form, Select, notification } from "antd";
import { post } from "../../../network/request";
import moment from "moment";
//import { getRenderPropValue } from "antd/lib/_util/getRenderPropValue";
function projectHandover(props) {
  //#region useState区域
  const [state, setState] = useState({
    key: "getProject",
    noTitleKey: "getProject",
  });

  const [handFilterInfo, setHandFileInfo] = useState({
    currIndex: 1,
    pageSize: 10,
    studentSid: localStorage.studentSid,
  });
  const [receiveFilterInfo, setReceiveFileInfo] = useState({
    currIndex: 1,
    pageSize: 10,
    heirSid: localStorage.studentSid,
  });
  const [handOverData, setHandoverData] = useState([]);
  const [receiveData, setReceiveData] = useState([]);
  const [handoverTotal, setHandoverTotal] = useState(0);
  const [receiveTotal, setReceiveTotal] = useState(0);
  const [handoverLoading, setHandoverLoading] = useState(false);
  const [receiveLoading, setReceiveLoading] = useState(false);
  const [showHandFormState, setHandState] = useState(false);
  const [handoverTitle, setHandTitle] = useState();
  const [allStudentInfo, setStudentInfo] = useState([]);
  const [selectStudent, setSelectStu] = useState([]);
  const [handForm] = Form.useForm();
  const { Option } = Select;
  const { curGrade, setCurGrade } = useState("2017");
  const [entryGrade, setEntryGrade] = useState([]);
  const [heirForm, setHeirForm] = useState({
    heir: "",
    heirSid: null,
    maintainSid: "",
  });
  const [upateInfo, setUpdateInof] = useState({
    handoverSid: null,
    ifConfirm: 1,
    maintainSid: null,
    studentSid: "",
  });
  const dateRule = "YYYY-MM-DD";
  //#endregion
  //#region  模板区
  //对应的key用来选页面，tab是相应标题名字

  const tabListNoTitle = [
    {
      key: "getProject",

      tab: "接受项目",
    },
    {
      key: "giveProject",
      tab: "交接项目",
    },
  ];

  //table 模板
  const ReceiveColumns = [
    {
      title: "交接内容",
      dataIndex: "itemName",
   //   key: "itemSid",
      align: "center",
    },
    {
      title: "内容来源",
      dataIndex: "studentName",
    //  key: "itemSid",
      align: "center",
    },
    {
      title: "类型",
      dataIndex: "conditionName",
    //  key: "itemSid",
      align: "center",
    },
    {
      title: "确认接收时间",
    //  key: "itemSid",
      align: "center",
      render: (text) => {
        if (text.ifConfirm === 2) {
          return <span style={{ color: "#CC0000" }}>暂未交接</span>;
        } else if (text.ifConfirm === 1) {
          return (
            <span>{moment(text.updateTimestamp * 1000).format(dateRule)} </span>
          );
        }
      },
    },
    {
      title: "操作",
      key: "itemSid",
      align: "center",
      render: (text) => {
        return (
          <div>
            <Button
              type="primary"
              disabled={text.ifConfirm === 1 ? true : false}
              onClick={handleUpdateIfConfirm.bind(null, text)}
            >
              确认接收
            </Button>
          </div>
        );
      },
    },
  ];
  //交接table
  const handoverColunms = [
    {
      title: "交接内容",
      dataIndex: "itemName",
      key: "maintainSid",
      align: "center",
    },
    {
      title: "内容来源",
      dataIndex: "roleName",
      key: "maintainSid",
      align: "center",
    },
    {
      title: "类型",
      dataIndex: "conditionName",
      key: "maintainSid",
      align: "center",
    },
    {
      title: "是否需要交接",
      key: "maintainSid",
      align: "center",
      render: (text, record) => {
        if (text.ifConfirm === 3) {
          return <span className="ec-text-color-red">否</span>;
        } else {
          return <span className="ec-text-color-green">是</span>;
        }
      },
    },
    {
      title: "交接人",
      key: "maintainSid",
      align: "center",
      render: (text, record) => {
        if (text.ifConfirm === 0) {
          return <span>未指定</span>;
        } else if (text.ifConfirm === 3) {
          return <span>不需要交接</span>;
        } else {
          return <span>{text.heir}</span>;
        }
      },
    },
    {
      title: "交接人是否确认",
      key: "maintainSid",
      align: "center",
      render: (text, record) => {
        switch (text.ifConfirm) {
          case 0:
            return <span>无</span>;
          case 1:
            return <span className="ec-text-color-green">确认</span>;
          case 2:
            return <span className="ec-text-color-red">未确认</span>;
          case 3:
            return <span>不需要确认</span>;
          default:
            break;
        }
      },
    },
    {
      title: "操作",
      align: "center",
      key: "maintainSid",
      render: (text) => {
        return (
          <div>
            <Button
              type="primary"
              disabled={text.ifConfirm === 3 || text.ifConfirm === 1}
              onClick={HandleOpenHandOverForm.bind(null, true, text)}
            >
              指定交接人
            </Button>
          </div>
        );
      },
    },
  ];
  //#region 数据请求
  const getStudentHandoverProject = async () => {
    setHandoverLoading(true);
    await post(
      {
        url: "ec/station/getStudentHandoverProject",
        data: {
          sessionId: localStorage.sessionId,
          ...handFilterInfo,
        },
      },
      (data) => {
        //   console.log(data.contents.list)
        setHandoverData(data.contents.list);
        setHandoverTotal(data.contents.size);
        setHandoverLoading(false);
      },
      props.history
    );
  };
  const updateIfConfirm = async (updateInfo) => {
    await post(
      {
        url: "ec/station/updateIfConfirm",
        data: {
          sessionId: localStorage.sessionId,
          ...updateInfo,
        },
      },
      (data) => {
        console.log(data.message);
        getStudentReceiveProject();
      },
      props.history
    );
  };
  const getStudentReceiveProject = async () => {
    setReceiveLoading(true);
    await post(
      {
        url: "ec/station/getStudentReceiveProject",
        data: {
          sessionId: localStorage.sessionId,
          ...receiveFilterInfo,
        },
      },
      (data) => {
        setReceiveData(data.contents.list);
        setReceiveTotal(data.contents.size);
        setReceiveLoading(false);
      },
      props.history
    );
  };
  const getAllStudentInfo = async () => {
    await post(
      {
        url: "/ec/project/getAllStudentInfoOfInBound",
        data: {
          sessionId: localStorage.sessionId,
        },
      },
      (data) => {
        // console.log(data.message)
        setStudentInfo(data.contents);
      },
      props.history
    );
  };
  const updateStudentHandovr = async () => {
    setHandoverLoading(false);
    await post(
      {
        url: "ec/station/updateStudentHandover",
        data: {
          sessionId: localStorage.sessionId,
          // maintainSid:134,
          ...heirForm,
        },
      },
      (data) => {
        console.log(data.message);
        getStudentHandoverProject();
        setHandoverLoading(false);
        setHeirForm({
          heir: "",
          heirSid: null,
          maintainSid: "",
        });
      },
      props.history
    );
  };
  //#region 方法区
  //方法
  //确认接受
  const handleUpdateIfConfirm = (text) => {
    const updateInfo = {
      handoverSid: null,
      ifConfirm: 1,
      maintainSid: null,
      studentSid: "",
    };
    for (var i = 0; i < allStudentInfo.length; i++) {
      if (text.studentName === allStudentInfo[i].studentName) {
     //   console.log(allStudentInfo[i].studentSid);
        updateInfo.handoverSid = allStudentInfo[i].studentSid;
      }
    }
    updateInfo.studentSid = localStorage.studentSid;
    updateInfo.maintainSid = text.maintainSid;
    // console.log(text.maintainSid)
    //console.log(updateInfo);
    updateIfConfirm(updateInfo);
  };
  const updataHandOverData = () => {
    console.log(heirForm);
    if (heirForm.heirSid === null) {
      notification.error({
        description: "表单信息尚未完善，无法提交！",
        message: "警告",
      });
    } else {
      updateStudentHandovr();
      setHandState(false);
    }
  };
  const closeHandForm = () => {
    setHandState(false);
    setHeirForm({
      heir: "",
      heirSid: null,
      maintainSid: "",
    });
  };
  //切换页面 参数key：要切换的页面，tyle:noTitleKey
  const handleTabChange = (key, type) => {
    console.log(key);
    if (key === "getProject") {
     // console.log(receiveData);
      setHandoverData([]);
      getStudentReceiveProject();
    } else if (key === "giveProject") {
    //  console.log(handOverData);
      setReceiveData([]);
      getStudentHandoverProject();
    }

    setState({ [type]: key });
  };
  //翻页
  const handleHandoverPageChange = (event, pageSize) => {
    const index = event;
    setHandFileInfo({
      ...handFilterInfo,
      pageSize: pageSize,
      currIndex: index,
    });
    // getStudentHandoverProject()
  };
  const handleRecPageChange = (event, pageSize) => {
    const index = event;
    setReceiveFileInfo({
      ...receiveFilterInfo,
      pageSize: pageSize,
      currIndex: index,
    });
    //getStudentReceiveProject()
  };
  //handover表单开启
  const HandleOpenHandOverForm = (state, text) => {
    getAllStudentInfo();
    setHandState(state);
    setHandTitle(text.itemName);
    setHeirForm({ ...heirForm, maintainSid: text.maintainSid });
    handForm.resetFields();
  };
  //选择年纪
  const handleGradeChange = (value) => {
    const res = [];
    console.log(value);
    for (let i = 0; i < allStudentInfo.length; i++) {
      if (allStudentInfo[i].entryGrade === Number(value)) {
        res.push(allStudentInfo[i]);
        // console.log(allStudentInfo[i].studentName)
      }
    }
    setSelectStu(res);
  };
  //渲染学生列表
  const handleStudentRender = () => {
    return selectStudent.map((item) => (
      <Option value={item.studentSid} key={item.studentSid}>
        {item.studentName}
      </Option>
    ));
  };
  //选择交付人
  const handleSelectHeir = (text) => {
    for (var i = 0; i < allStudentInfo.length; i++) {
      if (allStudentInfo[i].studentSid == text) {
        setHeirForm({
          ...heirForm,
          heirSid: text,
          heir: allStudentInfo[i].studentName,
        });
      }
    }
  };

  //  //#endregion
  //#region

  //#endregion 生命周期

  useEffect(() => {
    getStudentHandoverProject();
  }, [handFilterInfo.currIndex, handFilterInfo.pageSize]);
  useEffect(() => {
    getStudentReceiveProject();
  }, [receiveFilterInfo.pageSize, receiveFilterInfo.currIndex]);
  useEffect(() => {
    async function genEntryGrade() {
      await post(
        {
          url: "/ec/project/getOutStationGrade",
          data: {
            sessionId: localStorage.sessionId,
            inboundState: 2
          },
        },
        (data) => {
          if (data.code == 'N01') {
            let Outing = data.contents.Outing
            console.log(data.contents)
            for(let i = 0;i < Outing.length;i++){
              entryGrade.push(parseInt(Outing[i]))
            }
          }else {
            console.log('error')
          }
        },
        props.history
      );
        console.log(entryGrade)
      setEntryGrade(entryGrade);
    }
    genEntryGrade();
    getAllStudentInfo();
  }, []);
  //#endregion
  // 页面分类模板
  const contentListNoTitle = {
    getProject: (
      <Table
        loading={receiveLoading}
        dataSource={receiveData}
        columns={ReceiveColumns}
        className="table"
        rowKey={(r,i)=>{i.toString()}}
        pagination={{
          total: receiveTotal,
          current: receiveFilterInfo.currIndex,
          pageSize: receiveFilterInfo.pageSize,
          pageSizeOptions: [10, 20,30],
          onChange: handleRecPageChange,
          showSizeChanger: true,
        }}
      />
    ),
    giveProject: (
      <Table
        dataSource={handOverData}
        columns={handoverColunms}
        className="table"
        rowKey="maintainSid"
        loading={handoverLoading}
        pagination={{
          total: handoverTotal,
          current: handFilterInfo.currIndex,
          pageSize: handFilterInfo.pageSize,
          pageSizeOptions: [10, 20,30],
          onChange: handleHandoverPageChange,
          showSizeChanger: true,
        }}
      />
    ),
  };

  //#endregion

  return (
    <div>
      <Card
        style={{ overflowY: "scroll", height: "calc(100vh - 78px)" }}
        tabList={tabListNoTitle}
        activeTabKey={state.key}
        onTabChange={(key) => {
          handleTabChange(key, "noTitleKey");
        }}
        className="custom-card"
      >
        {contentListNoTitle[state.noTitleKey]}
      </Card>
      <Modal
        title={`为<${handoverTitle}>项目指定交接人`}
        visible={showHandFormState}
        //  onOk={closeHandForm}
        getContainer={false}
        onCancel={closeHandForm}
        destroyOnClose={true}
        footer={[
          <div>
            <Button key="back" onClick={setHandState.bind(null, false)}>
              关闭
            </Button>
            <Button
              key="submitAdd"
              type="primary"
              onClick={updataHandOverData.bind(null)}
            >
              提交
            </Button>
          </div>,
        ]}
      >
        <Form form={handForm} labelCol={{ span: 5 }} wrapperCol={{ span: 14 }}>
          <Form.Item
            label="项目指定人"
            tooltip={{
              title: "若项目无需交接，请指定交接人为自己，并进行确认接收",
            }}
          >
            {/* <Select
              placeholder="年级"
              defaultValue={curGrade}
              style={{ width: "40%" }}
              onChange={handleGradeChange.bind(null)}
            >
              <Option value="2017">2017</Option>
              <Option value="2018">2018</Option>
              <Option value="2019">2019</Option>
              <Option value="2020">2020</Option>
            </Select> */}

            <Select
              placeholder="年级"
              defaultValue={curGrade}
              style={{ width: "40%" }}
              onChange={handleGradeChange.bind(null)}
            >
              {entryGrade.map((item, index) => (
                <Option value={item} key={index}>
                  {item}
                </Option>
              ))}
            </Select>

            <Select
              placeholder="姓名"
              defaultValue={receiveData.heir}
              style={{ width: "40%", marginLeft: "5%" }}
              onChange={handleSelectHeir.bind(null)}
            >
              {handleStudentRender()}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default projectHandover;
