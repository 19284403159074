/*
 * @Author: HouQi
 * @Date: 2021-09-27 16:53:46
 * @Last Modified by: HouQi
 * @Last Modified time: 2022-03-24 17:35:30
 */

import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";

import { Card, List, Button, Table, notification } from "antd";
import { post, request } from "../../../network/request";
import CustomCard from "../../../components/common/card/card";
import "./stageOutbound.css";
import ExchangeDialog from './exchange/exchange'
export default function StageOutbound(props) {
  /***************************状态变量UseState*********************************************/
  // const [reviewDataSource, setReviewDataSource] = useState([]);
  //状态table
  const [table, setTable] = useState([]);
  const [loading, setLoading] = useState(null);
  const [content, setContent] = useState();
  const [apply, setApply] = useState([]);
  const [projectData, setProData] = useState([]);
  //记录已选
  const [Keys, setKeys] = useState([]);
  // const [selectedprojiect, setPreject] = useState({});
  const [applicationStatus, setApplicationStatus] = useState("");
  const [applyForFirst, setApplyForFirst] = useState("");
  const [exchangeModal, setExhangeModal] = useState(false);
  const selecedData = useRef({});
  // const [showData, setShowData] = useState();
  const [papareShow, setPapare] = useState({
    title: "",
    papare: [],
  });
  const [netShow, setNet] = useState({
    title: "",
    net: [],
  });
  /* 构造可置换的表数据结构 */
  const [mproject, setMproject] = useState({
    title: "",
    mproject: []
  })
  const [pproject, setPproject] = useState({
    title: "",
    pproject: []
  })
  const [mconstruct, setMconstruct] = useState({
    title: "",
    mconstruct: []
  })
  const [pconstruct, setPconstruct] = useState({
    title: "",
    pconstruct: []
  })

  const [exchangePro, setExchangePro] = useState([])
  const [exchangeCon, setExchangeCon] = useState([])

  const [exchangeData, setExchange] = useState([]);
  /**************************组件变量******************************** */
  const headerCardOptions = { title: null, bordered: false };
  const columns = [
    {
      title: "是否提交出站申请",
      key: "applyForFirst",
      align: "center",
      render: (text) => {
        // console.log(text)
        if (text.applyForFirst === 1) {
          return <span className="ec-text-color-green">{"是"}</span>;
        } else if (text.applyForFirst === 0) {
          return <span className="ec-text-color-red">{"否"}</span>;
        }
      },
    },
    {
      title: "审核状态",
      key: "applicationStatus",
      align: "center",
      render: (text) => {
        if (text.applicationStatus == 3) {
          return <span className="ec-text-color-green">{"同意"}</span>;
        } else if (text.applicationStatus == 2) {
          return <span className="ec-text-color-red">{"拒绝"}</span>;
        } else if (text.applicationStatus == 1) {
          return <span className="ec-text-color-orange">{"待审核"}</span>;
        } else if (text.applicationStatus == 0) {
          return <span style={{ color: "black" }}>{"暂无"}</span>;
        }
      },
    },
  ];
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll" },
  };

  /************************接口******************************/
  // 二阶段出栈状态
  async function getApplicationInfo() {
    // console.log(11)
    setLoading(true);
    await post(
      {
        url: "/ec/station/getApplicationInfoByStudentSid",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: localStorage.studentSid,
          // entryGrade: localStorage.entryGrade,
          // degreeType: localStorage.degreeType,
        },
      },
      (data) => {
        console.log(data)
        setLoading(false);

        let arr = [];
        // 将对象传入数组
        arr.push(data.contents);
        setTable(arr);
        setApplicationStatus(data.contents.applicationStatus);
        setApplyForFirst(data.contents.applyForFirst);
      },
      props.history
    );
  }
  // 获取要求与项目数据
  async function getProjectInfo() {
    await post(
      {
        url: "/ec/station/getAllProjectByStudentSid",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: localStorage.studentSid,
          degreeType: JSON.parse(localStorage.userInfo).degreeType,
          entryGrade: JSON.parse(localStorage.userInfo).entryGrade,
        },
      },
      (data) => {
        console.log(data)
        setContent(data.contents);
      },
      props.history
    );
  }

  /************************************function***************************** */
  //是否显示子table信息
  const childTable = (len, childColumns, data) => {
    if (len === 0) return <div>暂无数据</div>;
    else
      return (
        <Table
          columns={childColumns}
          dataSource={data}
          rowKey="itemSid"
          pagination={false}
        // rowSelection={rowSelection}
        />
      );
    // else
    //    return(<div></div>),
  };
  //选择子table要显示的信息
  const childTableColumn = (type) => {
    switch (type) {
      case 1:
        return [
          {
            title: "项目名称",
            dataIndex: "itemName",
            align: "center",
          },
          {
            title: "主持者",
            dataIndex: "managerName",
            align: "center",
          },
          {
            title: "参与者",
            dataIndex: "participants",
            align: "center",
          },
          {
            title: "开始时间",
            dataIndex: "insertTimestamp",
            align: "center",
            render: (text, record) => (
              <div>
                {new Date(record.insertTimestamp * 1000).toLocaleDateString()}
              </div>
            ),
          },
          {
            title: "结束时间",
            dataIndex: "endTime",
            align: "center",
            render: (text, record) => (
              <div>{new Date(record.endTime * 1000).toLocaleDateString()}</div>
            ),
          },
        ];
      case 2:
        return;
      default:
        break;
    }
  };
  //主页面数据预处理
  const handleUpstateData = () => {
    if (!content) return;
    let request = content.request;
    let project = content.project;
    console.log(project)
    if (typeof request === "undefined" && typeof project === "undefined")
      return;
    let templet = [];
    let tmp = []
    for (let k = 0; k < project.length; k++) {
      tmp.push(project[k].itemSid)
    }
    setKeys(tmp)
    for (let i = 0; i < request.length; i++) {
      let item = request[i];
      let childColumns = childTableColumn(1);
      let data = [];
      let count = 0
      console.log(project)
      for (let j = 0; j < project.length; j++) {
        if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          item.outboundConditionSid === 1
        ) {
          data.push(project[j]);
          if (project[j].ifChange === 1) {
            count++
            console.log(count)
          }
        } else if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          item.outboundConditionSid === 2
        ) {
          data.push(project[j]);
          if (project[j].ifChange === 1) {
            count++
            console.log(count)
          }
        } else if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          item.outboundConditionSid === 3
        ) {
          data.push(project[j]);
          if (project[j].ifChange === 1) {
            count++
            console.log(count)
          }
        } else if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          item.outboundConditionSid === 4
        ) {
          data.push(project[j]);
          if (project[j].ifChange === 1) {
            count++
            console.log(count)
          }
        } else if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          item.outboundConditionSid === 5
        ) {
          data.push(project[j]);
          if (project[j].ifChange === 1) {
            count++
            console.log(count)
          }
        } else if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          item.outboundConditionSid === 6
        ) {
          data.push(project[j]);
          if (project[j].ifChange === 1) {
            count++
            console.log(count)
          }
        }
      }
      console.log(data)
      console.log(count)

      let temp = {
        requestCount: item.amount,
        outboundConditionSid: item.outboundConditionSid,
        columns: [
          {
            title: item.conditionName,
            align: "center",
            dataIndex: "describe",
            width: "25%",
          },
          {
            title: item.conditionName,
            align: "center",
            render: () => childTable(data.length, childColumns, data),
          },
        ],
        dataSource: [],
      };
      let dataSource = [];

      dataSource = [
        {
          key: "1",
          describe:
            "要求: " +
            item.amount +
            "项" +
            " 已完成：" +
            (data.length - count) +
            "/" +
            item.amount,
        },
      ];
      temp.dataSource = dataSource;

      templet.push(temp);
    }
    for (let i = 0; i < templet.length; i++) {
      console.log(templet[i].dataSource[0].describe)
    }
    // setMproject(mprojectData)
    // console.log(mprojectData)
    console.log(mproject)
    console.log(templet)
    setProData(templet);
    return templet;
  };
  //开启置换
  const handleExchange = () => {
    setExhangeModal(true);
  };
  //处理置换Modal的显示数据
  const handleShowData = () => {
    // console.log(content)
    if (typeof content === "undefined") return;
    if (content.request.length === 0) return;
    let mproject = [];
    let pproject = [];
    let mconstruct = [];
    let pconstruct = [];
    let net = [];
    let papare = [];
    let emproject = 0
    let epproject = 0
    let emconstruct = 0
    let epconstruct = 0

    // console.log(content)
    if (!content) {
      return;
    }
    console.log(content.project)
    for (let i = 0; i < content.project.length; i++) {
      // count = 0
      let item = content.project[i];
      if ( // 项目主持
        item.outboundConditionSid === 1
        &&
        item.managerName === localStorage.studentName
      ) {
        mproject.push(item);
        if (item.ifChange === 1) {
          emproject++
        }
      } else if ( // 项目参与
        item.outboundConditionSid === 2
        &&
        item.participants.indexOf(localStorage.studentName) !== -1
      ) {
        pproject.push(item);
        if (item.ifChange === 1) {
          epproject++
        }
      }
      else if ( // 方案主持
        item.outboundConditionSid === 3
        &&
        item.managerName === localStorage.studentName
      ) {
        mconstruct.push(item);
        if(item.ifChange === 1){
          emconstruct++
        }
      }
      else if ( // 方案参与
        item.outboundConditionSid === 4
        &&
        item.participants.indexOf(localStorage.studentName) !== -1
      ) {
        pconstruct.push(item);
        if(item.ifChange === 1){
          epconstruct++
        }
      }
      else if (
        // 筛选出置换后的主持项目
        item.outboundConditionSid === 1 && item.changeSid
      ) {
        mproject.push(item);
      } else if (
        // 筛选出置换后的参与项目
        item.outboundConditionSid === 2 && item.changeSid
      ) {
        pproject.push(item);
      }
      else if (
        // 筛选出置换后的主持建设
        item.outboundConditionSid === 3 && item.changeSid
      ) {
        mconstruct.push(item);
      }
      else if (
        // 筛选出置换后的参与建设
        item.outboundConditionSid === 4 && item.changeSid
      ) {
        pconstruct.push(item);
      }
    }
    
    // 置换-主持项目
    let mprojectData = {
      mproject,
      title:
        content.request[0].conditionName +
        " 要求" +
        content.request[0].amount +
        " 已完成：" +
        (mproject.length - emproject) +
        "/" +
        content.request[0].amount,
    }
    console.log(projectData)

    setMproject(mprojectData)
    // 置换-参与项目
    let pprojectData = {
      pproject,
      title:
        content.request[1].conditionName +
        " 要求" +
        content.request[1].amount +
        " 已完成：" +
        (pproject.length - epproject) +
        "/" +
        content.request[1].amount,
    }
    // console.log(pprojectData)
    setPproject(pprojectData)
    // 置换-主持建设
    let mconstructData = {
      mconstruct,
      title:
        content.request[2].conditionName +
        " 要求" +
        content.request[2].amount +
        " 已完成：" +
        (mconstruct.length - emconstruct) +
        "/" +
        content.request[2].amount,
    }
    setMconstruct(mconstructData)
    // 置换-参与建设
    let pconstructData = {
      pconstruct,
      title:
        content.request[3].conditionName +
        " 要求" +
        content.request[3].amount +
        " 已完成：" +
        (pconstruct.length - epconstruct) +
        "/" +
        content.request[3].amount,
    }
    setPconstruct(pconstructData)

    let papareData = {
      papare,
      title:
        content.request[3].conditionName +
        " 要求" +
        content.request[3].amount +
        " 已完成：" +
        papare.length +
        "/" +
        content.request[3].amount,
    };
    setPapare(papareData);
    let netData = {
      net,
      title:
        content.request[5].conditionName +
        " 要求" +
        content.request[5].amount +
        " 已完成：" +
        papare.length +
        "/" +
        content.request[5].amount,
    };
    setNet(netData);

    console.log(content.project)

    // 构造置换以后的表单数据
    let exchangeData = [];
    let exchangeProTmp = [];
    let exchangeConTmp = [];

    for (let i = 0; i < mproject.length; i++) {
      let item = mproject[i];
      let tmp = {};
      if (item.changeSid) {
        console.log('####')
        tmp.changeSid = item.changeSid;
        tmp.first = item.itemName;
        tmp.second = "";
        for (let j = 0; j < pproject.length; j++) {
          if (pproject[j].changeSid === tmp.changeSid) {
            tmp.second += pproject[j].itemName;
            if (j !== pproject.length - 1) {
              tmp.second += " ";
            }
          }
        }
        exchangeProTmp.push(tmp);
      }
    }

    for (let i = 0; i < mconstruct.length; i++) {
      let item = mconstruct[i];
      let tmp = {};
      if (item.changeSid) {
        console.log('####')
        tmp.changeSid = item.changeSid;
        tmp.first = item.itemName;
        tmp.second = "";
        for (let j = 0; j < pconstruct.length; j++) {
          if (pconstruct[j].changeSid === tmp.changeSid) {
            tmp.second += pconstruct[j].itemName;
            if (j !== pconstruct.length - 1) {
              tmp.second += " ";
            }
          }
        }
        exchangeConTmp.push(tmp);
      }
    }

    setExchangePro(exchangeProTmp);
    setExchangeCon(exchangeConTmp);
  };
  //处理提交后的数据显示
  const handleSubmitedData = () => {
    if (!content) return;
    let request = content.request;
    let project = content.project;
    if (typeof request === "undefined" && typeof project === "undefined")
      return;
    let templet = [];
    console.log(project)
    for (let i = 0; i < request.length; i++) {
      let item = request[i];
      let childColumns = childTableColumn(1);
      let data = [];
      let count = 0
      for (let j = 0; j < project.length; j++) {
        if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          project[j].outboundConditionSid === 0

        ) {
          if (project[j].ifChange === 1) {
            count++
            console.log(count)
          }
          data.push(project[j]);
        } else if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          project[j].managerName === localStorage.studentName
        ) {
          data.push(project[j]);
          if (project[j].ifChange === 1) {
            count++
            console.log(count)
          }
        } else if (
          project[j].outboundConditionSid === item.outboundConditionSid
          // typeof project[j].changeSid !== "undefined"
        ) {
          data.push(project[j]);
          if (project[j].ifChange === 1) {
            count++
          }
        }
      }
      console.log(data)
      let title =
        item.conditionName +
        "要求: " +
        item.amount +
        "项" +
        " 已完成：" +
        (data.length - count) +
        "/" +
        item.amount;
      templet.push({
        title,
        data,
        childColumns,
      });
    }

    console.log(templet)
    setApply(templet)
  };
  // 打印申请书
  function submitApply() {
    props.history.push('/printApplication');
  }
  /*****************useCallBack*********************** */
  //关闭置换
  const handleConcelModal = useCallback(() => {
    setExhangeModal(false);
  }, [exchangeModal]);
  const handleUpdata = useCallback(async () => {
    await post(
      {
        url: "/ec/station/getAllProjectByStudentSid",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: localStorage.studentSid,
          degreeType: JSON.parse(localStorage.userInfo).degreeType,
          entryGrade: JSON.parse(localStorage.userInfo).entryGrade,
        },
      },
      (data) => {
        console.log(data.contents)
        setContent(data.contents);
        console.log(content)
      },
      props.history
    );
  }, [exchangeModal]);

  //深拷贝
  function extend(source) {
    var target;
    if (typeof source === "object") {
      target = Array.isArray(source) ? [] : {};
      for (var key in source) {
        if (source.hasOwnProperty(key)) {
          if (typeof source[key] !== "object") {
            target[key] = source[key];
          } else {
            target[key] = extend(source[key]);
          }
        }
      }
    } else {
      target = source;
    }
    return target;
  }

  // 提交出站申请
  const submit = async () => {
    console.log(Array.from(new Set(Keys)).toString())
    await post(
      {
        url: "/ec/station/addApplicationInfo",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: localStorage.studentSid,
          project: Array.from(new Set(Keys)).toString()
          // projectZ: "166",
        },
      },
      (data) => {
        console.log(data)
        if (data.code === "N01") {
          notification.success({
            description: "提交申请书成功！",
            message: "通知",
            duration: 1,
            onClose: () => {
              getApplicationInfo().then(() => {
                // return getApplyProjectInfo();
              });
            },
          });
        }
      },
      props.history
    );
    setApplyForFirst(1);
  };

  //置换Modal
  const modalChange = useMemo(() => {
    return (
      <ExchangeDialog
        // style={{ width:'300px' }}
        cRef={exchangeModal}
        concelForModal={handleConcelModal}
        mproject={mproject}
        pproject={pproject}
        mconstruct={mconstruct}
        pconstruct={pconstruct}
        exchangePro={exchangePro}
        exchangeCon={exchangeCon}
        updata={handleUpdata}
        fatherChange={projectData}
      />
    );
  }, [
    exchangeModal,
    papareShow,
    netShow,
    exchangeData,
    handleUpdata,
    handleConcelModal,
    mproject,
    pproject
  ]);

  /*****************************useEffect*************************************** */
  useEffect(() => {
    console.log(111)
    getApplicationInfo().then(() => {
      getProjectInfo().then(() => {
      });
    });
  }, []);
  useEffect(() => {
    if (applyForFirst === 0) {
      handleUpstateData();
      handleShowData();
    } else {
      handleSubmitedData()
    }
  }, [content]);
  //置换数据
  /**********************************useBack*************************************/
  return (
    <>
      <CustomCard options={headerCardOptions} className="outbound">
        {/* {console.log(table)} */}
        <Table
          className="table"
          dataSource={table}
          columns={columns}
          // loading={loading}
          pagination={false}
          style={{ margin: "0 auto", width: "55%" }}
        />
      </CustomCard>
      <CustomCard options={tableCardOptions}>
        {/* 还未提交申请横向出站表单 */}
        <div
          style={
            applyForFirst === 0 ? { display: "block" } : { display: "none" }
          }
        >
          <div className="section">
            <h3 className="headTitleh">待提交出站申请</h3>
          </div>
          <div className="btnChangeh">
            <Button type="primary" onClick={handleExchange}>
              置换
            </Button>
          </div>
          {/* {console.log('1111', projectData)} */}
          <List
            dataSource={projectData}
            renderItem={(item) => {
              return (
                <List.Item className="listItemh">
                  <Table
                    rowKey="key"
                    className="contentTableh"
                    columns={item.columns}
                    dataSource={item.dataSource}
                    loading={loading}
                    pagination={false}
                  />
                </List.Item>
              );
            }}
          />
          <div className="btn">
            <Button type="primary" style={{ width: "100px" }} onClick={submit}>
              提交
            </Button>
          </div>
        </div>

        {/* 已经提交申请横向出站表单 */}
        <div
          className="sectionContainerh"
          style={
            applyForFirst === 1 ? { display: "block" } : { display: "none" }
          }
        >
          <Button
            type="primary"
            style={{ width: "100px" }}
            onClick={submitApply}
          >
            下载申请书
          </Button>
          <div className="section">
            <h3 className="headTitleh">已提交出站申请</h3>
          </div>
          <div className="lookApplyBookModal">
            <div>
              <List
                dataSource={apply}
                renderItem={(item) => {
                  return (
                    <List.Item>
                      <Card
                        title={
                          item.title
                        }
                      >
                        <Table
                          rowKey="itemSid"
                          className="listData"
                          style={
                            item.data.length != 0
                              ? { display: "block" }
                              : { display: "none" }
                          }
                          columns={item.childColumns}
                          dataSource={item.data}
                          pagination={false}
                        />
                        <div
                          className="noData"
                          style={
                            item.data.length != 0
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          暂无内容
                        </div>
                      </Card>
                    </List.Item>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </CustomCard>
      {/* 置换表单 */}
      {modalChange}

    </>
  );
}
