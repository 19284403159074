
import React, { useEffect, useState, useMemo , useCallback , useRef } from "react";
import {Image,Row,Col,Form,Input,Button,Select,Table,Space,notification,Modal,DatePicker,message,} from "antd";
// import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { post, request } from "../../../network/request";
// import moment from "moment";
import CustomCard from "../../../components/common/card/card";
import "./requirementManagement.css";
// import userEvent from '@testing-library/user-event';
// import { render } from "@testing-library/react";
import AddDialog from "./addDialog/addDialog";
import SettingDialog from "./settingDialog/settingDialog";
import { useHistory } from "react-router-dom";
// Option需要单独引入
const { Option } = Select;
const { TextArea } = Input;

export default function RequirementManagement(props) {

   //测试
   let history =useHistory()


  //header筛选框传值
  const headerCardOptions = { title: null, bordered: false };
  //表格传值
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll", height: "calc(100vh - 78px - 130px)" },
  };
  const [demands, setDemands] = useState([]);
  const [conditions, setConditions] = useState([]);
  // 表格列名
  const [columns,setColumns] = useState([]);
  const [rawTable, setRawTable] = useState([]);
  // const [header, setHeader] = useState([]);
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 20 },
  };
  //date
  const dateFormat = "YYYY/MM/DD";
  const todayDate = new Date().toLocaleDateString();
  const todayYear = new Date().getFullYear();
  const [filterInfo,setFilterInfo] = useState({
    itemStatus:null,
    itemStatus1:null
  });
  // const [nearFilterInfo,setNearFilterInfo] = useState({
  //   yearLeft:null,
  //   yearRight:null
  // });
  const [filterForm] = Form.useForm();
  const [loading, setLoading] = useState(null);
  // 筛选下拉框
  function handleSelectChange(tag, value) {
    setFilterInfo({ ...filterInfo, [tag]: value });
  }
  //useEffect初始化并监听表格数据的变化
  useEffect(() => {
    getTableDataTest(filterInfo);
    setLoading(false);
  },[]);

  async function getTableDataTest(filterInfo) {
    setLoading(true);
    let header = [];
    await post(
      {
        url: "/ec/station/getOutBoundConditions",
        data: {
          sessionId: localStorage.sessionId,
        },
      },
      (data) => {
        console.log(data.contents)
        setLoading(false);
        setConditions(data.contents.conditions)
        setDemands(data.contents.demands)
        if(filterInfo.itemStatus && filterInfo.itemStatus1){
          header = reformatData(data,filterInfo.itemStatus,filterInfo.itemStatus1).colData;
        }
        else{
          let arr = reduceSort(data.contents.demands)
          if(arr.length < 3){
            header = reformatData(data,parseInt(arr[arr.length-1]),parseInt(arr[0])).colData;
          }
          else{
            header = reformatData(data, parseInt(arr[arr.length-1]),parseInt(arr[arr.length-3])).colData;
          }
          // header = reformatData(data,nearFilterInfo.yearLeft,nearFilterInfo.yearRight).colData;
          // header = reformatData(data,todayYear+2,todayYear-2).colData;
        }
      },
      props.history
    );
    return header;
  }

  // 去重排序
  let reduceSort = (data) => {
    let arr = [];
    for (let i = 0 ; i < data.length ; i++){
      arr.push(data[i].grade)
    }
    var result = arr.reduce(function(prev,next,index,array){
      prev[next] = prev[next]+1 || 1;//
      return prev;
    },{});
    var newarr = [];
    for(var i in result){
      newarr.push(i);
    }
    console.log("arrnew",newarr)
    return newarr
    // if(newarr.length-3){
    //   const initNearState = {
    //     yearLeft:newarr[newarr.length-1],
    //     yearRight:newarr[newarr.length-3]
    //   }
    //   setNearFilterInfo({
    //     ...initNearState,
    //   });
    // }
    // else{
    //   const initNearState = {
    //     yearLeft:newarr[newarr.length-1],
    //     yearRight:newarr[0]
    //   }
    //   setNearFilterInfo({
    //     ...initNearState,
    //   });
    // }
  }

  // async function getTableData() {
  //   await post(
  //     {
  //       url: "/ec/station/getOutBoundConditions",
  //       data: {
  //         sessionId: localStorage.sessionId,
  //       },
  //     },
  //     (data) => {
  //       setLoading(false);
  //       setConditions(data.contents.conditions)
  //       setDemands(data.contents.demands)
  //     },
  //     props.history
  //   );
  // }
  // 筛选按钮
  function handleFilter() {
    getTableDataTest(filterInfo);
  }
  // 清除
  function handleClear() {
    filterForm.resetFields();
    const initState = {
      itemStatus:null,
      itemStatus1:null,
    }
    setFilterInfo({
      ...initState,
    });
    getTableDataTest(initState);
  }
  //关闭详情模态框
  function handleCancel(target, type) {
    console.log(target)
    console.log(type)
    if (target === "addStudent") {
      setAddStudent({
        visible: false,
      });
    }else {
      setSettingStudent({
        visible: false,
      });
    }
  }
  /*************新增*************/
  // ref
  const addRef = useRef();
  const settingRef = useRef();
  // state
  const [addStudent, setAddStudent] = useState({ visible: false });
  const cancelForAdd = useCallback((type) => {
    handleCancel("addStudent", type);
  }, []); 
  //addDialog组件记忆
  const addDialogMemo = useMemo(() => {
    return (
      <AddDialog
        cRef={addRef}
        addStudent={addStudent}
        handleCancel={cancelForAdd}
        handleClear={handleClear}
        rawTable = {rawTable}
        demands = {demands}
      />
    );
  }, [addRef, addStudent, cancelForAdd]);

  function handleClickAddStudent() {
    addRef.current.addForm.resetFields();
    setAddStudent({
      visible: true,
    });
  }
  /*************修改*************/
  // state
  const [settingStudent, setSettingStudent] = useState({ visible: false });
  const [gradeType,setGradeType] = useState("");
  const cancelForSetting = useCallback((type) => {
    handleCancel(null , type);
  }, []);
  //settingDialog组件记忆
  const settingDialogMemo = useMemo(() => {
    return (
      <SettingDialog
        cRef={settingRef}
        settingStudent={settingStudent}
        handleCancel={cancelForSetting}
        handleClear={handleClear}
        gradeType={gradeType}
        demands = {demands}
        conditions = {conditions}
      />
    );
  }, [settingRef, demands , settingStudent, cancelForSetting]);

  function handleClickSettingStudent(gradeType) {
    console.log(gradeType)
    setGradeType(gradeType);
    // settingRef.current.settingForm.resetFields();
    setSettingStudent({
      visible: true,
    });
  }

  //needSid记录，是一个二维数组，用于与处理后的数据作标定，向后台传参时用
  let needSid = [];
  let reformatData = (data,leftYear,rightYear) => {
    let rawData = data.contents;
    let result = {};
    let json = {};
    result.header = [];
    result.header.push('序号');
    result.header.push('内容');
    result.content = [];
    for (let i=0;i<7;i++)
    {
      needSid[i] = [];
      result.content[i] = [];
    }
    for (let i = 0;i < rawData.conditions.length;i++) {
      needSid[i] = [];
      needSid[i].push('null');
      needSid[i].push('null');
      result.content[i] = [];
      result.content[i].push(rawData.conditions[i].outboundConditionSid);
      result.content[i].push(rawData.conditions[i].conditionName);
    }
    result.content[6] = [];
    result.content[6].push(7);
    result.content[6].push('生效时间');
    for (let val of rawData.demands) {
        let flag = val.grade + (val.studentType === 1 ? '学硕' : '专硕');
        if (!json[flag]) {
          result.header.push(flag);
          result.content[6].push(new Date(val.effectiveTimestamp * 1000).toLocaleDateString());
          json[flag] = 1;
        }
        switch (val.outboundConditionSid) {
          case 1:
            result.content[0].push(val.amount);
            needSid[0].push(val.needSid);
            break;
          case 2:
            result.content[1].push(val.amount);
            needSid[1].push(val.needSid);
            break;
          case 3:
            result.content[2].push(val.amount);
            needSid[2].push(val.needSid);
            break;
          case 4:
            result.content[3].push(val.amount);
            needSid[3].push(val.needSid);
            break;
          case 5:
            result.content[4].push(val.amount);
            needSid[4].push(val.needSid);
            break;
          case 6:
            result.content[5].push(val.amount);
            needSid[5].push(val.needSid);
            break;
          // case 7:
          //   result.content[6].push(val.amount);
          //   needSid[6].push(val.needSid);
          //   break;
        }
    }
    let headerLen = result.header.length;
    let contentLen = result.content.length;
    var column = [];
    for (let coli = 0;coli<headerLen;coli++){
      let coltemp = {
        title:result.header[coli],
        dataIndex:"col"+coli,
        key:coli,
        align: "center",
        className: coli>1 && (parseInt(result.header[coli])>leftYear || parseInt(result.header[coli]) < rightYear)? "notshow" :"",
      }
      column.push(coltemp)

      //新增部分
      //**************************************************************************** */
      // if (coli <= 1) {
      //   let coltemp = {
      //     title: result.header[coli],
      //     dataIndex: "col" + coli,
      //     key: coli,
      //     align: "center",
      //     fixed: "left"
      //   }
      //   column.push(coltemp)
      // }else{
      //       let coltemp = {
      //         title: result.header[coli],
      //         dataIndex: "col" + coli,
      //         key: coli,
      //         align: "center",
              
      //       }
      //   column.push(coltemp)
        
      // }
      //**************************************************************************** */
    }
    console.log(column)
    setColumns(column);
    // console.log(result)
    var row = [];
    for(let rowi = 0 ; rowi < contentLen ; rowi++){
      let rowtemp = {
        index:rowi,
      }
      for(let rowj = 0 ;rowj < headerLen ; rowj++){
        rowtemp["col"+rowj] = result.content[rowi][rowj]
      }
      row.push(rowtemp);
    }
    // console.log(row)
    let rowtemp1 = {
      index:contentLen,
    }
    for(let rowj = 0 ;rowj < headerLen ; rowj++){
      // 列名
      let gradeType = result.header[rowj]
  
      if(rowj === 0 || rowj === 1)
        rowtemp1["col"+rowj] = '';
      else{
        let require = [];
        // 列值
        for (let req = 0; req < contentLen ;req++){
          require.push(result.content[req][rowj]);
        }
        rowtemp1["col"+rowj] = (<Button type="primary" htmlType="submit" onClick={handleClickSettingStudent.bind(null,gradeType)}>修改</Button>);
      }
    }
    row.push(rowtemp1);
    setRawTable(row);
    console.log(row)
    return {rowData:row,colData:column};
  };

  function goBack() {
    window.history.go(-1);
  }
    function jumpToNew(){
     console.log("即将跳转页面！")
     
     history.push({ pathname: "/home/requirementManagement2022"});
    }



  return (
    <>
      <CustomCard options={headerCardOptions}>
        {/* Form表单相当于插槽的内容 */}
        <Form layout="inline" {...layout} style={{ flexDirection: "column" }} form={filterForm}>
          <Row>
            <Col span={10} offset={1}>
              <Form.Item label="查看指定年级区域" name="filterInfo">
                <Select style={{ width: "30%" }} value={filterInfo.itemStatus} placeholder="请选择"
                  name="itemStatus" onChange={handleSelectChange.bind(null,"itemStatus")} allowClear>
                  <Option value={todayYear+2} key="1">
                    {todayYear+2}
                  </Option>
                  <Option value={todayYear+1} key="2">
                    {todayYear+1}
                  </Option>
                  <Option value={todayYear} key="3">
                    {todayYear}
                  </Option>
                  <Option value={todayYear-1} key="4">
                    {todayYear-1}
                  </Option>
                  <Option value={todayYear-2} key="5">
                    {todayYear-2}
                  </Option>
                  <Option value={todayYear-3} key="6">
                    {todayYear-3}
                  </Option>
                  <Option value={todayYear-4} key="7">
                    {todayYear-4}
                  </Option>
                </Select>
                <span style={{width:'5%',margin:'0 10px'}}>--</span>
                <Select style={{ width: "30%" }} value={filterInfo.itemStatus1} placeholder="请选择"
                  name="itemStatus1" onChange={handleSelectChange.bind(null,"itemStatus1")} allowClear>
                  <Option value={todayYear+2} key="1">
                    {todayYear+2}
                  </Option>
                  <Option value={todayYear+1} key="2">
                    {todayYear+1}
                  </Option>
                  <Option value={todayYear} key="3">
                    {todayYear}
                  </Option>
                  <Option value={todayYear-1} key="4">
                    {todayYear-1}
                  </Option>
                  <Option value={todayYear-2} key="5">
                    {todayYear-2}
                  </Option>
                  <Option value={todayYear-3} key="6">
                    {todayYear-3}
                  </Option>
                  <Option value={todayYear-4} key="7">
                    {todayYear-4}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Button type="primary" htmlType="submit" onClick={handleFilter} disabled={filterInfo.itemStatus < filterInfo.itemStatus1?true:false}>
                筛选
              </Button>
            </Col>
            <Col span={2}>
              <Button type="plain" htmlType="submit" onClick={handleClear}>
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomCard>
      {/* 表格 */}
      <CustomCard options={tableCardOptions}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 10,
          }}
        >
          {/* <Button type="primary" onClick={jumpToNew}
          style={{
          marginRight:"10px"
          }} >
          切换新版出站条件
          </Button> */}
          <Button type="primary" htmlType="submit" onClick={handleClickAddStudent}>
            新增
          </Button>
        </div>
        <div>
          <Table
            className="addDialogTable"
            rowKey="index"
            columns={columns}
            dataSource={rawTable}
            loading={loading}
            pagination={false}
            // scroll={{
            //   x: 2382,
            // }}
          />
        </div>
      </CustomCard>
      {/* 新增 */}
      {addDialogMemo}
      {/* 修改 */}
      {settingDialogMemo}
    </>
  );
}
