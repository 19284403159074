/*
 * @Author: LongJuanjuan
 * @Date: 2020-12-06 15:56:04
 * @Last Modified by:   LongJuanjuan
 * @Last Modified time: 2020-12-06 15:56:04
 */
// 打印申请书页面
import React, { useEffect, useState, useMemo } from "react";
import {
  Card,
  List,
  Button,
  Table,
} from "antd";
import { post, request } from "../../../network/request";
import "./printApplication.css";

export default function PrintApplication(props){
  const [content,setContent] = useState({});
  // const [projectColumns,setProjectColumns] = useState([]);
  // 获取要求与项目数据
  async function getProjectInfo() {
    await post(
      {
        url: "/ec/station/getApplicationForFirstByStudentSid",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: localStorage.studentSid,
          degreeType: JSON.parse(localStorage.userInfo).degreeType,
          entryGrade: JSON.parse(localStorage.userInfo).entryGrade,
          applicationStatus:0
        },
      },
      (data) => {
        setContent(data.contents)
      },
      props.history
    );
  }
  // 处理表格内容的数据
  const projectData = useMemo(()=>{
    if(content === {}){
      return;
    }
    let request0 = content.request
    let project0 = content.project
    let applyContentInfo = [];
    for(let i in request0){
      applyContentInfo[request0[i].outboundConditionSid] = {
        title:{},
        content0:[],
        columns:[]
      };
      applyContentInfo[request0[i].outboundConditionSid].title.amount = request0[i].amount;//要求数量
      applyContentInfo[request0[i].outboundConditionSid].title.conditionName = request0[i].conditionName;//条件名称
      applyContentInfo[request0[i].outboundConditionSid].title.itemType = request0[i].itemType;
    }
    for(let i in project0){
      if(project0){
        if(project0[i].participants == ''){
          project0[i].participants = '无';
        }
        //数据分类
        if(project0[i].role === 1){   //主持
          applyContentInfo[project0[i].itemType].content0.push(((project0[i])));
          applyContentInfo[project0[i].itemType].content0[applyContentInfo[project0[i].itemType].content0.length-1].num = applyContentInfo[project0[i].itemType].content0.length;
        }else if(project0[i].role === 2){  //参与
          for(let j in request0){
            if(request0[j].itemType == project0[i].itemType && request0[j].itemType != request0[j].outboundConditionSid){
              applyContentInfo[request0[j].outboundConditionSid].content0.push(((project0[i])));
              applyContentInfo[request0[j].outboundConditionSid].content0[applyContentInfo[request0[j].outboundConditionSid].content0.length-1].num = applyContentInfo[request0[j].outboundConditionSid].content0.length;
            }
          }
        }
      }
    }
    applyContentInfo.splice(0,1);
      let tmpArr = [];
      for(let i in applyContentInfo){
        tmpArr.push(applyContentInfo[i]);
        for(let j in applyContentInfo[i].content0){
          applyContentInfo[i].content0[j].insertTimestamp = !applyContentInfo[i].content0[j].insertTimestamp
          ? "暂无"
          : new Date(applyContentInfo[i].content0[j].insertTimestamp * 1000).toLocaleDateString();
          applyContentInfo[i].content0[j].endTime = !applyContentInfo[i].content0[j].endTime
          ? "暂无"
          : new Date(applyContentInfo[i].content0[j].endTime * 1000).toLocaleDateString();
        }
      }
    applyContentInfo = tmpArr;
    console.log(applyContentInfo);
    for(let i in applyContentInfo){
      if(applyContentInfo[i]){
        applyContentInfo[i].title.completed = applyContentInfo[i].content0.length;
        let cnt = 0;
        for(var j in applyContentInfo[i].content0){
          let begin = new Date(applyContentInfo[i].content0[j].insertTimestamp).getTime();
          let end = new Date(applyContentInfo[i].content0[j].endTime).getTime();
          if(begin == end){
            cnt++;
          }
        }
        if(cnt == applyContentInfo[i].title.completed){
          applyContentInfo[i].title.showTwoTime = false;
          applyContentInfo[i].columns.push(
            {
              title:'项目名称',
              dataIndex:'itemName',
              key:'itemName',
              align:'center',
            },
            {
              title:'主持者',
              dataIndex:'managerName',
              key:'managerName',
              align:'center',
            },
            {
              title:'参与者',
              dataIndex:'participants',
              key:'participants',
              align:'center',
            },
            {
              title:'申请时间',
              dataIndex:'endTime',
              key:'endTime',
              align:'center',
            }
          );
        }else{
          applyContentInfo[i].columns.push(
            {
              title:'项目名称',
              dataIndex:'itemName',
              key:'itemName',
              align:'center',
            },
            {
              title:'主持者',
              dataIndex:'managerName',
              key:'managerName',
              align:'center',
            },
            {
              title:'参与者',
              dataIndex:'participants',
              key:'participants',
              align:'center',
            },
            {
              title:'开始时间',
              dataIndex:'insertTimestamp',
              key:'insertTimestamp',
              align:'center',
            },
            {
              title:'结束时间',
              dataIndex:'endTime',
              key:'endTime',
              align:'center',
            }
          );
        }
      }
    }
    return applyContentInfo;
  },[content])
  // 初始化加载项目要求
  useEffect(() => {
    getProjectInfo();
  }, []);
  // 关闭
  function close(){
    props.history.push('/home/stageOutbound');
  }
  // 打印申请书
  function print(){
    window.document.body.innerHTML = window.document.getElementById('print').innerHTML;
    window.print();
    window.location.reload();
  }
  return(
    <>
      <div>
        <div style={{height:"30px",lineHeight:"30px"}}>
          <h2 className="leftBorder" style={{borderLeft: "4px #5f9ea0 solid",paddingLeft:"10px !important", padding: "0 10px",margin:"10px 0 0 20px", fontSize:"18px",color: "#222"}}>下载出站申请书</h2>
          <div style={{marginLeft:"20px",marginTop:"10px"}}>
            <Button  type="primary" style={{marginRight:"20px"}} onClick={print}>下载PDF文件</Button >
            <Button  type="danger" onClick={close}>关闭</Button >
          </div>
        </div>
        <div className="printContainer" id="print">
          <div className="printContent">
            <h2>附件一：一阶段出站工作量详情</h2>
            <div className="lookApply">
              <div>
                <List
                dataSource={projectData}
                renderItem={item => (
                  <List.Item >
                  <Card title={item.title.conditionName + "要求："+item.title.amount+"    已完成："+item.title.completed+"/"+item.title.amount}>
                    <Table
                    rowKey= "itemSid"
                    className="listData"
                    style={item.content0.length!=0 ? {display:'block'}:{display:'none'}}
                    columns={item.columns}
                    dataSource={item.content0}
                    pagination={false}
                    />
                    <div className="noDataTip" style={item.content0.length!=0 ? {display:'none'}:{display:'block'}}>暂无内容</div>
                  </Card>
                  </List.Item>
                )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
