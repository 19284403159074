import React, { useEffect, useState, useMemo } from "react";

import {DatePicker,Form,InputNumber,Button,Select,Modal,Table,Row,Col, notification,} from "antd";
// ;
import { post } from "../../../../network/request";
export default function AddInfo(props) {
  // console.log(props)
  //普通参数
  const todayYear = new Date().getFullYear();
  const dateFormat = "YYYY/MM/DD";
  const years = [todayYear + 2, todayYear + 1, todayYear, todayYear - 1, todayYear - 2]
  const { Option } = Select;
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      align: 'center',

    },
    {
      title: '考察项目',
      dataIndex: 'requestTitle',
      key: 'index',
      align: 'center',

    },
    {
      title: '达标要求',
      dataIndex: 'inputMount',
      key: 'index',
      align: 'center',
    },
  ];
  //状态
  const [form] = Form.useForm();
  const [gradeType, setGradeType] = useState({
    grade: null,
    type: null
  })
  //tabel数据
  const [tableData,setTableData]=useState([])
  //普通函数
  //筛选年分
  const handleYearShow = (item) => {
    let {gradeTypes}=props
    // console.log(gradeTypes)
    let j=0;
    for (let i=0;i<gradeTypes.length;i++) {
      if (parseInt(gradeTypes[i]) == item)
              j++
    }
    if(j==2)
       return true
    return false
  }
  //筛选专业
  let handleGradeType = (grade, type) => {
    // console.log(grade)
        if(grade==null){
          return true
        }
        let{gradeTypes}=props
        for( let i=0;i<gradeTypes.length;i++){
          let res= gradeTypes[i].split('.')
          // console.log(res)
           if(res[0]==grade&&type==res[1]){
                return true
           }
        }
        return false
  }
  //下拉改变
  let handleSelectChange = (type,value) => {
      setGradeType({ ...gradeType, [type]: value})
  }

  //生成table内容
 const handleRowData=()=>{
       let {condition}=props
      //  console.log(props)
      //  console.log(condition)
       let result=[]
       if(condition.length==0)
       return
       for(var i=0 ;i<condition.length;i++){
          let temp={
            requestTitle:condition[i].conditionName,
            index:condition[i].outboundConditionSid,
            inputMount : (<Form.Item name={condition[i].outboundConditionSid} style={{margin:'0' , padding:'0'}}
            rules={[{required: true,pattern:new RegExp(/^[0-9]\d*$/,"g"), message: "请输入整数",},]}>
            <InputNumber
              placeholder="请输入整数"
              style={{ width: "70%" }}
              min={0}
            />
          </Form.Item>)
          }
          result.push(temp)
       }
       let temp={
       index:condition[condition.length-1].outboundConditionSid+1,
       requestTitle : "生效时间",
       inputMount : (<Form.Item  name="effectiveTimestamp" style={{margin:'0' , padding:'0'}}
                    rules={[{required: true,message: "请输入日期",}]}>
                     <DatePicker
                       format={dateFormat}
                       placeholder="年/月/日"
                       style={{ width: '70%' }}
                       name="effectiveTimestamp"
                     />
                   </Form.Item>)
       }
       result.push(temp)

      //  console.log(result)
     setTableData(result)
 }
 //取消
 const handleCancel=()=>{
  props.cancel('add')
  // gradeType.grade==null
  setGradeType({
    type:null,
    grade:null
  })
  form.resetFields()
 }
 //提交
 const handleOnFish=(value)=>{
     let time=  parseInt(new Date(value.effectiveTimestamp).getTime() / 1000)
      value.effectiveTimestamp=time  
      addData(value)
 }

 //接口
 const addData=(addDatas)=>{
   post({
    url: "/ec/station/addOutBoundZConditions",
    data: {
      ...addDatas,
      sessionId: localStorage.sessionId,
    },
   },(data)=>{
      if(data.code=="N01"){
        notification.success({
          description: "添加成功！",
          message: "通知",
          duration: 1,
          onClose: () => {
           handleCancel()
           props.getTableInfo()
          },
        });
      }else{
        notification.error({
          description: "添加失败！",
          message: "通知",
          duration: 1,
          onClose: () => {
           handleCancel()
          },
        });
      }
   })
 }
 //添加数据
 //useEffect
 useEffect(()=>{
   handleRowData()
 },[props])
  return (
    <div>
      <Modal
        title="出站条件"
        visible={props.visible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form  form={form} name="addForm" labelCol={{ span: 5 }} onFinish={handleOnFish} >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="grade" rules={[{ required: true, message: "请选择年级", },]}>
                <Select placeholder="请选择年级"  onChange={handleSelectChange.bind(null,'grade')} allowClear>
                  {years.map((item, index) => (
                    <Option key={item} value={item} disabled={handleYearShow(item)}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="studentType" rules={[{ required: true, message: "请选择学硕/专硕", },]}>
                <Select placeholder="请选择学硕/专硕" allowClear onChange={handleSelectChange.bind(null, 'type')}>
                  <Option value={1} disabled={handleGradeType(gradeType.grade, 1)}>学硕</Option>
                  <Option value={2} disabled={handleGradeType(gradeType.grade, 2)}>专硕</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Table
            rowKey="index"
            rowClassName = "addDialogTable"
            pagination={false}
            columns={columns}
            dataSource={tableData} 
            />
             <Form.Item>
            <Button type="primary" htmlType="submit" block style = {{margin:"20px 0 0 0"}}>
              添加
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}