/*
 * @Author: ZhouShan
 * @Date: 2020-11-05 11:04:56
 * @Last Modified by: HouQi
 * @Last Modified time: 2021-11-16 09:49:30
 */
import React, { useEffect, useState, useMemo } from 'react'
import { Row, Col, Form, Input, Button, Select, Table, Space, notification, Modal, Popconfirm } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { post, request } from '../../../network/request';
//import component
import CustomCard from '../../../components/common/card/card';
//import css
import "./studentResume.css"

const { Option } = Select;

export default function StudentResume(props) {
  const headerCardOptions = { title: null, bordered: false }
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: 'scroll', height: 'calc(100vh - 78px - 130px)' },
  }
  const [filterForm] = Form.useForm()
  //table column
  const columns = [
    {
      title: '姓名',
      dataIndex: 'studentName',
      key: 'studentName',
      align: 'center'
    },
    {
      title: '学硕/专硕',
      dataIndex: 'degreeType',
      key: 'degreeType',
      align: 'center'
    },
    {
      title: '本科院校',
      dataIndex: 'undergraduateSchool',
      key: 'undergraduateSchool',
      align: 'center'
    },
    {
      title: '应届/往届',
      dataIndex: 'graduate',
      key: 'graduate',
      align: 'center'
    },
    {
      title: '联系邮箱',
      dataIndex: 'studentEmail',
      key: 'studentEmail',
      align: 'center'
    },
    {
      title: '联系电话',
      dataIndex: 'studentPhone',
      key: 'studentPhone',
      align: 'center'
    },
    {
      title: '意向导师',
      dataIndex: 'mentor',
      key: 'mentor',
      align: 'center'
    },
    {
      title: '意向科研方向',
      dataIndex: 'ecResearchDirectionName',
      key: 'ecResearchDirectionName',
      align: 'center'
    },
    {
      title: '状态',
      key: 'acceptState',
      align: 'center',
      render: text => {
        if (text.acceptState === '已接受') {
          return <span className="ec-text-color-green">{text.acceptState}</span>
        }
        else if (text.acceptState === '已拒绝') {
          return <span className="ec-text-color-red">{text.acceptState}</span>
        }
        else {
          return <span>{text.acceptState}</span>
        }
      }
    },
    {
      title: '处理人',
      dataIndex: 'refuseTeacher',
      key: 'refuseTeacher',
      align: 'center'
    },
    {
      title: '处理时间',
      dataIndex: 'updateTimestamp',
      key: 'updateTimestamp',
      align: 'center'
    },
    {
      title: '审核申请',
      key: 'acceptState',
      align: 'center',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Popconfirm
          title="确定接受该学生吗？"
          okText="确定"
          cancelText="取消"
          disabled={ text.acceptState === '已接受' }
          onConfirm={ handleStatusChange.bind(null, 'accept', text) }
          >
            <Button
            type="primary"
            size="small"
            disabled={ text.acceptState === '已接受' }
            icon={ <CheckOutlined /> }
            ></Button>
          </Popconfirm>
          <Popconfirm
          title="确定拒绝该学生吗？"
          okText="拒绝"
          cancelText="取消"
          disabled={ text.acceptState === '已拒绝' }
          onConfirm={ handleStatusChange.bind(null, 'reject', text) }
          >
            <Button
            type="primary"
            danger
            size="small"
            disabled={ text.acceptState === '已拒绝' }
            icon={ <CloseOutlined /> }
            ></Button>
          </Popconfirm>
        </Space>
      )
    },
    {
      title: '操作',
      key: 'userName',
      align: 'center',
      fixed: 'right',
      render: (text, record) => (
        <Button type="primary" size="small" onClick={ handleClickDetail.bind(null, text) }>查看详情</Button>
      )
    }
  ]
  const detailColumns = [
    {
      dataIndex: 'title',
      key: 'title',
      align: 'center'
    },
    {
      dataIndex: 'content',
      key: 'content',
      align: 'center'
    }
  ]
  //state
  const [mentorList, setmentorList] = useState([]);
  const [filterInfo, setFilterInfo] = useState({
    inbountState: '',
    acceptState: '',
    senior: '',
    studentName: '',
    undergraduateSchool: '',
    graduate: '',
    degreeType: '',
    mentor: '',
    lastMentor: '',
    entryGrade: '',
    currIndex: 1,
    pageSize: 10
  })
  const [rawTable, setRawTable] = useState([]);
  const [total, setTotal] = useState(0);
  const [detailDialog, setDetailDialog] = useState({ visible: false });
  const [detailContent, setDetailContent] = useState({});
  const [loading, setLoading] = useState(false);
  //memo
  const table = useMemo(() => {
    rawTable.forEach(item => {
      item.degreeType = item.degreeType === 1 ? '学硕' : '专硕';
      item.graduate = item.graduate === 1 ? '应届' : '往届';
      switch(item.acceptState) {
        case 1:
          item.acceptState = '已接受';
          break;
        case 0:
          item.acceptState = '未处理';
          break;
        case 2:
          item.acceptState = '已拒绝';
          break;
        default:
          item.acceptState = '错误';
      }
      item.updateTimestamp = !item.updateTimestamp ? '暂无' : new Date(item.updateTimestamp * 1000).toLocaleDateString();
    })
    return rawTable;
  }, [rawTable])
  const detailTable = useMemo(() => {
    // console.log('@',detailContent)
    if (detailContent === {}) return;
    const table = [];
    let scores = '';
    const detail = detailContent;
    // console.log('!!',detail);
    for (let item in detail) {
      switch(item) {
        case 'studentName':
          table.push({ title: '姓名', content: detail[item] });
          break;
        case 'studentSex':
          table.push({ title: '性别', content: detail[item] });
          break;
        case 'studentBirthDate':
          table.push({ title: '出生年月', content: new Date(detail[item] * 1000).toLocaleDateString() });
          break;
        case 'natives':
          table.push({ title: '籍贯', content: detail[item] });
          break;
        case 'studentEmail':
          table.push({ title: '常用邮箱', content: detail[item] });
          break;
        case 'studentPhone':
          table.push({ title: '联系电话', content: detail[item] });
          break;
        case 'undergraduateSchool':
          table.push({ title: '本科院校', content: detail[item] });
          break;
        case 'undergraduateMajor':
          table.push({ title: '本科专业', content: detail[item] });
          break;
        case 'firstChoice':
          table.push({ title: '第一志愿院校', content: detail[item] });
          break;
        case 'direction':
          table.push({ title: '报考专业', content: detail[item] });
          break;
        case 'degreeType':
          table.push({ title: '学硕/专硕', content: detail[item] });
          break;
        case 'graduate':
          table.push({ title: '应届/往届', content: detail[item] });
          break;
        case 'preliminaryGrade':
          table.push({ title: '初始总分', content: detail[item] });
          break;
        case 'math':
          scores += detail[item] + '(数学)';
          break;
        case 'english':
          scores += detail[item] + '(英语)';
          break;
        case 'politics':
          scores += detail[item] + '(政治)';
          break;
        case 'professionalCourse':
          scores += detail[item] + '(专业课)';
          break;
        case 'ecResearchDirectionName2':
          table.push({ title: '意向应用方向', content: detail[item] });
          break;
        case 'ecResearchDirectionName':
          table.push({ title: '意向科研方向', content: detail[item] });
          break;
        default:
          break;
      }
    }
    table.push({ title: '初始各科成绩', content: scores });
    // console.log(table)
    return table;
  }, [detailContent])
  //get mentor list
  //you should get sessionId from redux
  useEffect(() => {
    async function getMentorList() {
      await post({
        url: '/ec/student/getMentorNameList',
        data: {
          sessionId: localStorage.sessionId,
        }
      }, data => {
        setmentorList(data.contents);
      }, props.history)
    }
    getMentorList();
  }, [])
  useEffect(() => {
    getTableData(filterInfo);
  }, [filterInfo.pageSize, filterInfo.currIndex]);
  //operate not auto
  async function getTableData(filterData) {
    setLoading(true);
    await post({
      url: '/ec/student/getStudentAndGradeByMultipleConditions',
      data: {
        sessionId: localStorage.sessionId,
        ...filterData
      }
    }, data => {
      setLoading(false);
      setRawTable(data.contents.list);
      setTotal(data.contents.size);
    }, props.history)
  }

  function handleFilter() {
    // console.log(filterInfo)
    setFilterInfo({...filterInfo, currIndex: 1, pageSize: 10});
    getTableData(filterInfo);
  }

  function handleClear() {
    filterForm.resetFields();
    const initState = {
      inbountState: '',
      acceptState: '',
      senior: '',
      studentName: '',
      undergraduateSchool: '',
      graduate: '',
      degreeType: '',
      mentor: '',
      lastMentor: '',
      entryGrade: '',
      currIndex: 1,
      pageSize: 10
    }
    setFilterInfo({
      ...initState
    });
    getTableData(initState);
  }

  function handlePageChange(event, pageSize) {
    setFilterInfo({...filterInfo, pageSize, currIndex: event});
  }

  async function handleStatusChange(type, data) {
    console.log(data);
    await post({
      url: '/ec/student/updateAcceptStateByStudentNo',
      data: {
        sessionId: localStorage.sessionId,
        studentNo: data.studentNo,
        acceptState: type === 'accept' ? 1 : 2,
        refuseTeacher: data.refuseTeacher,
        refuseTime: parseInt(new Date().getTime() / 1000)
      }
    }, () => {
      notification.success({ description: '修改状态成功!', message: '通知' });
      getTableData(filterInfo);
    }, props.history) 
  }

  function handleClickDetail(data) {
    setDetailDialog({
      visible: true
    });
    // console.log('test',data)
    setDetailContent(data);
  }

  function handleCancel() {
    setDetailDialog({
      visible: false
    })
  }

  //handleValueChange
  function handleValueChange(changedValues, allValues) {
    for (let key in changedValues) {
      if (!changedValues[key]) {
        changedValues[key] = '';
      }
    }
    setFilterInfo({...filterInfo, ...changedValues});
  }

  async function handleClickExport() {
    const filter = Object.assign({}, filterInfo);
    delete filter.currIndex;
    delete filter.pageSize;
    filter.number = 1;
    const rawData = await request({
      url: '/ec/student/exportStudentInfo',
      method: 'post',
      data: {
        sessionId: localStorage.sessionId,
        ...filter
      },
      responseType: 'arraybuffer'
    })
    console.log(rawData);
    if (!rawData.data?.code) {
      let blob = new Blob([rawData.data], {type: "application/msexcel"});
      let objectUrl = URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display:none');
      a.setAttribute('href', objectUrl);
      //下载的文件名称
      let filename = "学生简历管理.xls";
      a.setAttribute('download', filename);
      a.click();
      URL.revokeObjectURL(objectUrl);
      if (window.navigator.msSaveBlob)
        window.navigator.msSaveBlob(blob, '学生简历管理.xls');
    }
  }

  return (
    <>
      <CustomCard options={ headerCardOptions }>
        <Form layout="inline"
        style={{ flexDirection: 'column' }}
        form={filterForm}
        onValuesChange={ handleValueChange }
        >
          <Row className="student-resume-row">
            <Col span={6} offset={1}>
              <Form.Item label="姓名" name="studentName">
                <Input
                placeholder="请输入姓名"
                name="studentName"
                />
              </Form.Item></Col>
            <Col span={6}>
              <Form.Item label="应届/往届" name="graduate">
                <Select
                style={{ width: '80%' }}
                allowClear
                >
                  <Option value="1" key="1">应届</Option>
                  <Option value="2" key="2">往届</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="申请状态" name="acceptState">
                <Select
                style={{ width: '80%' }}
                allowClear
                >
                  <Option value="0" key="0">未处理</Option>
                  <Option value="1" key="1">已接受</Option>
                  <Option value="2" key="2">已拒绝</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={ handleFilter }
                >筛选</Button>
            </Col>
          </Row>
          <Row>
            <Col span={6} offset={1}>
              <Form.Item label="本科学校" name="undergraduateSchool">
                <Input
                placeholder="请输入学校名"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="学硕/专硕" name="degreeType">
                <Select
                style={{ width: '80%' }}
                allowClear
                >
                  <Option value="1" key="1">学硕</Option>
                  <Option value="2" key="2">专硕</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="意向导师" name="mentor">
                <Select
                style={{ width: '80%' }}
                allowClear
                >
                  { mentorList.map((item, index) => <Option value={ item } key={ index }>{item}</Option>) }
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Button
                type="plain"
                htmlType="submit"
                onClick={ handleClear }
                >重置</Button>
            </Col>
          </Row>
        </Form>
      </CustomCard>
      <CustomCard options={ tableCardOptions }>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={ handleClickExport }
            >导出为Excel</Button>
        </div>
        <div>
          <Table
          className="table"
          rowKey="studentNo"
          columns={columns}
          dataSource={table}
          loading={ loading }
          scroll={{ x: '1450px' }}
          pagination={{
            total: total,
            current: filterInfo.currIndex,
            pageSize: filterInfo.pageSize,
            pageSizeOptions: [10, 20, 30],
            onChange: handlePageChange,
            showSizeChanger: true
          }}
          />
        </div>
      </CustomCard>
      <Modal
        title="详细信息"
        visible={ detailDialog.visible }
        onCancel={ handleCancel }
        footer={[
          <Button key="back" onClick={ handleCancel }>
            关闭
          </Button>
        ]}
      >
        <Table
        className="detailTable"
        showHeader={ false }
        columns={ detailColumns }
        dataSource={ detailTable }
        pagination={ false }
        ></Table>
        <div className="studentResume-award">
          <p style={{ marginLeft: '15px', fontSize: '15px', fontWeight: 600, marginBottom: 0 }}>所获奖项：</p>
          <div dangerouslySetInnerHTML={{ __html: detailContent.awards }}></div>
          <p style={{ marginLeft: '15px', fontSize: '15px', fontWeight: 600, marginBottom: 0 }}>个人简介</p>
          <div dangerouslySetInnerHTML={{ __html: detailContent.selfIntroduction }}></div>
        </div>
      </Modal>
    </>
  )
}
