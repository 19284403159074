/*
 * @Author: 廖立婷
 * @Date: 2020-11-05 11:14:54
 * @Last Modified by: HouQi
 * @Last Modified time: 2022-02-24 19:20:11
 */
import React, { useEffect, useState, useMemo } from 'react'
import { Row, Col, Form, Input, Button, Select, Table, } from 'antd';
// import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { post, request } from '../../../network/request';
// import moment from 'moment';
import CustomCard from '../../../components/common/card/card';
import "./projectManage.css"
// Option需要单独引入
const { Option } = Select;
export default function ProjectManage(props){
  //header筛选框传值
  const headerCardOptions = { title: null, bordered: false }
  //表格传值
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: 'scroll', height: 'calc(100vh - 78px - 88px)' },
  }
  //表格列名
  const columns = [
    {
      title: '项目编号',
      dataIndex: 'itemNumber',
      key: 'itemNumber',
      align: 'center'
    },
    {
      title: '项目名称',
      dataIndex: 'itemName',
      key: 'itemName',
      align: 'center'
    },
    {
      title: '角色',
      dataIndex: 'roleName',
      key: 'roleName',
      align: 'center'
    },
    {
      title: '主持者',
      dataIndex: 'managerName',
      key: 'managerName',
      align: 'center'
    },
    {
      title: '参与者',
      dataIndex: 'participants',
      key: 'participants',
      align: 'center'
    },
    {
      title: '负责老师',
      dataIndex: 'chargeTeacher',
      key: 'chargeTeacher',
      align: 'center'
    },
    {
      title: '开始时间',
      dataIndex: 'insertTimestamp',
      key: 'insertTimestamp',
      align: 'center'
    },
    {
      title: '截至时间',
      dataIndex: 'endTime',
      key: 'endTime',
      align: 'center'
    },
    {
      title: '项目状态',
      // dataIndex: 'itemStatus',
      key: 'itemStatus',
      align: 'center',
      render:text=>{
        if(text.itemStatus == '已完成'){
          return <span className="ec-text-color-green">{text.itemStatus}</span>
        }else if(text.itemStatus == '进行中'){
          return <span className="ec-text-color-orange">{text.itemStatus}</span>
        }else if(text.itemStatus == '已终止'){
          return <span className="ec-text-color-grey">{text.itemStatus}</span>
        }
      }
    }
  ]
  //使用Form.userForm创建表单数据域进行控制
  const [filterForm] = Form.useForm();
  // 初始化筛选框值
  const [filterInfo, setFilterInfo] = useState({
    roleSid: 3,
    itemType:1,
    itemStatus:'',
    index: 1,
    managerName:"",
    pageSize: 10
  })
  //加载初始化表格请求数据
  const [loading, setLoading] = useState(null);
  //初始化行数据
  const [rawTable, setRawTable] = useState([]);
  //初始化total为零
  const [total, setTotal] = useState(0);
  //useMemo处理表格数据
  const table = useMemo(() => {
    rawTable.forEach(item => {
      switch(item.itemStatus) {
        case 1:
          item.itemStatus = '进行中';
          break;
        case 2:
          item.itemStatus = '已完成';
          break;
        case 3:
          item.itemStatus = '已终止';
          break;
        default:
          item.itemStatus = '暂无';
      }
      item.endTime = !item.endTime ? '暂无' : new Date(item.endTime * 1000).toLocaleDateString();
      item.insertTimestamp = !item.insertTimestamp ? '暂无' : new Date(item.insertTimestamp * 1000).toLocaleDateString();
    })
    return rawTable;
  }, [rawTable])
  //useEffect初始化并监听表格数据的变化
  useEffect(() => {
    getTableData(filterInfo);
  }, [filterInfo.pageSize, filterInfo.currIndex]);
  //获取表格数据
  async function getTableData(filterData) {
    setLoading(true);
    console.log(localStorage)
    await post({
      url: '/ec/project/getProjectInfoByItemType',
      data: {
        sessionId: localStorage.sessionId,
        studentSid:parseInt(localStorage.studentSid),
        ...filterData
      }
    }, data => {
      setLoading(false);
      setRawTable(data.contents.list);
      setTotal(data.contents.size);
    }, props.history)
  };
  // 获取下拉框改变后的值，实现双向绑定
  function handleSelectChange(tag, value, options) {
    setFilterInfo({ ...filterInfo, [tag]: value });
  }
  //分页的改变，实现双向绑定
  function handlePageChange(event, pageSize) {
    // setFilterInfo({...filterInfo, pageSize, currIndex: event});
      const index = event
      setFilterInfo({...filterInfo, pageSize, currIndex: index, index: index});
  }
  //筛选数据
  function handleFilter() {
    setFilterInfo({...filterInfo});
    getTableData(filterInfo);
  }
  //清除数据
  function handleClear() {
    //重置为初始值
    filterForm.resetFields();
    const initState = {
      roleSid: 3,
      itemType:1,
      itemStatus:'',
      index: 1,
      managerName:"",
      pageSize: 10
    }
    setFilterInfo({
      ...initState
    });
    getTableData(initState);
  }
  return(
    <>
    <CustomCard options={ headerCardOptions }>
      {/* Form表单相当于插槽的内容 */}
      <Form layout="inline"
        style={{ flexDirection: 'column' }}
        form={filterForm}
        >
        <Row>
          <Col span={7} offset={1}>
            <Form.Item label="项目状态" name="itemStatus">
              <Select
              style={{ width: '70%' }}
              value={ filterInfo.itemStatus }
              placeholder="--请选择--"
              name="itemStatus"
              onChange={ handleSelectChange.bind(null, 'itemStatus') }
              allowClear
              >
                <Option value="1" key="1">进行中</Option>
                <Option value="2" key="2">已完成</Option>
                <Option value="3" key="3">已终止</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="角色" name="managerName">
              <Select
              style={{ width: '70%' }}
              placeholder="--请选择--"
              value={ filterInfo.managerName }
              name="managerName"
              onChange={ handleSelectChange.bind(null, 'managerName') }
              allowClear
              >
                <Option value="1" key="1">主持者</Option>
                <Option value="2" key="2">参与者</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={ handleFilter }
                >筛选</Button>
            </Col>
            <Col offset={1}>
              <Button
                type="plain"
                htmlType="submit"
                onClick={ handleClear }
                >重置</Button>
            </Col>
        </Row>
      </Form>
    </CustomCard>
    <CustomCard options={ tableCardOptions }>
    <div>
      <Table
      className="table"
      rowKey="itemSid"
      columns={columns}
      dataSource={table}
      loading={loading}
      pagination={{
        total: total,
        current: filterInfo.currIndex,
        pageSize: filterInfo.pageSize,
        pageSizeOptions: [10,20,30],
        onChange: handlePageChange,
        showSizeChanger: true
      }}
      />
    </div>
  </CustomCard>
  </>
  )
}
