/*
 * @Author: HouQi 
 * @Date: 2021-09-29 21:53:47 
 * @Last Modified by: HouQi
 * @Last Modified time: 2021-11-16 09:48:52
 */
import React, { useEffect, useState, useMemo } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Table,
  Space,
  notification,
  Modal,
  Upload,
  DatePicker,
  message,
} from "antd";
// import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { post, request } from "../../../network/request";
import moment from "moment";
//import component
import CustomCard from "../../../components/common/card/card";
//import css
import "./publishedPapers.css";

const { Option } = Select;
const { TextArea } = Input;

export default function TechnologyManage(props) {
  const headerCardOptions = { title: null, bordered: false };
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll", height: "calc(100vh - 78px - 98px)" },
  };
  const [filterForm] = Form.useForm();
  const [refuseForm] = Form.useForm();

  //table column
  const columns = [
    {
      title: "编号",
      dataIndex: "itemNumber",
      key: "itemNumber",
      align: "center",
    },
    {
      title: "论文综述名称",
      dataIndex: "itemName",
      key: "itemName",
      align: "center",
    },
    {
      title: "汇报人",
      dataIndex: "managerName",
      key: "managerName",
      align: "center",
    },
    {
      title: "提交时间",
      dataIndex: "insertTimestamp",
      key: "insertTimestamp",
      align: "center",
    },
    {
      title: "目前状态",
      // dataIndex: 'itemStatus',
      key: "itemStatus",
      align: "center",
      render: (text, record) => {
        switch (text.itemStatus) {
          case "已通过":
            return <span className="ec-text-color-green">{text.itemStatus}</span>;
          case "待审核":
            return <span className="ec-text-color-orange">{text.itemStatus}</span>;
          case "已拒绝":
            return <span className="ec-text-color-red">{text.itemStatus}</span>;
          default:
            return <span>{text.itemStatus}</span>;
        }
      },
    },
    {
      title: "处理理由",
      dataIndex: "note",
      key: "note",
      align: "center",
    },
    {
      title: "操作",
      key: "userName",
      align: "center",
      render: (text, record) => (
        <div>
          <Button
            className="operate-button ec-button-green"
            // type="primary"
            size="small"
            onClick={handleStatusChange.bind(null, "通过", text)}
            // style={{ backgroundColor: "#5CB85C", border: "1px solid #4CAE4C" }}
          >
            通过
          </Button>
          <Button
            className="operate-button"
            type="danger"
            size="small"
            onClick={handleClickRefuseBtn.bind(null, "拒绝", text)}
            // style={{ backgroundColor: "#FF4D4F" }}
          >
            拒绝
          </Button>
          {/* <Button
            className="operate-button ec-button-lightBlue"
            size="small"
            onClick={handleClickDownloadBtn.bind(null, "下载文件", text)}
          >
            下载文件
          </Button> */}
        </div>
      ),
    },
  ];
  const detailColumns = [
    {
      title: "文件名称",
      dataIndex: "filename",
      key: "title",
      align: "center",
    },
    {
      title: "操作",
      key: "content",
      align: "center",
      render: (text, record) => (
        <div>
          <Button
            type="primary"
            style={{ marginRight: "5%" }}
            onClick={downloadfile.bind(null, record)}
          >
            下载文件
          </Button>
          <Button type="danger" onClick={deleteFile.bind(null, record)}>
            删除文件
          </Button>
        </div>
      ),
    },
  ];

  const singleDetailColumns = [
    {
      title: "文件名称",
      dataIndex: "filename",
      key: "title",
      align: "center",
    },
    {
      title: "操作",
      key: "content",
      align: "center",
      render: (text, record) => (
        <div>
          <Button
            type="primary"
            style={{ marginRight: "5%" }}
            onClick={downloadfile.bind(null, record)}
          >
            下载文件
          </Button>
          <Button type="danger" onClick={deleteSingleFile.bind(null, record)}>
            删除文件
          </Button>
        </div>
      ),
    },
  ];

  //state
  const [filterInfo, setFilterInfo] = useState({
    itemType: 4,
    roleSid: 1,
    pageSize: 10,
    index: 1,
    itemStatus: "",
    managerName: "",
    participants: "",
    itemName: "",
  });
  const [addInfo, setAddInfo] = useState({
    itemType: 4,
    roleSid: 1,
    itemName: "",
    itemNumber: "",
    managerSid: 0,
    itemStatus: "",
    insertTimestamp: "",
    endTimestamp: "",
    statusDescription: "",
  });
  const [rawTable, setRawTable] = useState([]);
  const [fileInfo, setFileInfo] = useState([]);
  const [detailTable, setDetailTable] = useState([]);
  const [singleDetailTable, setSingleDetailTable] = useState([]);
  const [total, setTotal] = useState(0);
  const [templateDialog, setTemplateDialog] = useState({ visible: false });
  const [refuseDialog, setRefuseDialog] = useState({ visible: false });
  const [dowmloadDialog, setDownloadDialog] = useState({ visible: false });
  const [uploadDialog, setUploadDialog] = useState({ visible: false });
  const [uploadFileList, setuploadFileList] = useState([]);
  const [loading, setLoading] = useState(null);

  //memo
  const table = useMemo(() => {
    rawTable.forEach((item) => {
      switch (item.itemStatus) {
        case 1:
          item.itemStatus = "待审核";
          break;
        case 2:
          item.itemStatus = "已通过";
          break;
        case 3:
          item.itemStatus = "已拒绝";
          break;
        default:
          item.itemStatus = "错误";
      }
      item.updateTimestamp = !item.updateTimestamp
        ? "暂无"
        : new Date(item.updateTimestamp * 1000).toLocaleDateString();
      item.insertTimestamp = !item.insertTimestamp
        ? "暂无"
        : new Date(item.insertTimestamp * 1000).toLocaleDateString();
      item.endTime = !item.endTime
        ? "暂无"
        : new Date(item.endTime * 1000).toLocaleDateString();
    });
    return rawTable;
  }, [rawTable]);

  //date
  const dateFormat = "YYYY/MM/DD";
  const todayDate = new Date().toLocaleDateString();

  //form
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 20 },
  };
  const refuseLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  const uploadprops = {
    name: "file",
    action: "/ec/project/upLoadFile",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: stopUpload,
    fileList: uploadFileList,
    onChange(info) {
      console.log("info", info);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      setuploadFileList(info.fileList);
    },
  };

  useEffect(() => {
    getFileList();
  }, []);
  useEffect(() => {
    getTableData(filterInfo);
  }, [filterInfo.pageSize, filterInfo.currIndex]);
  useEffect(() => {
    refuseForm.setFieldsValue({
      note: addInfo.note,
    });
  }, [refuseDialog]);

  //operate not auto
  async function getTableData(filterData) {
    setLoading(true);
    console.log(filterData)
    await post(
      {
        url: "/ec/project/getProjectInfoByWriteItemType",
        data: {
          sessionId: localStorage.sessionId,
          ...filterData,
        },
      },
      (data) => {
        setLoading(false);
        setRawTable(data.contents.list);
        setTotal(data.contents.size);
      },
      props.history
    );
  }

  async function getFileList() {
    setLoading(true);
    await post(
      {
        url: "/ec/project/getFileInfo",
        data: {
          sessionId: localStorage.sessionId,
          flag: 0,
          itemSid: 1,
        },
      },
      (data) => {
        setLoading(false);
        setFileInfo(data.contents);
        if (data.code == "N01") setDetailTable(data.contents);
        else setDetailTable(data.contents.list);
      },
      props.history
    );
  }

  async function getSingleFileList(id) {
    setLoading(true);
    await post(
      {
        url: "/ec/project/getFileInfo",
        data: {
          sessionId: localStorage.sessionId,
          flag: 0,
          itemSid: id,
        },
      },
      (data) => {
        setLoading(false);
        if (data.code == "N01") setSingleDetailTable(data.contents);
        else setSingleDetailTable(data.contents.list);
      },
      props.history
    );
  }

  function handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    setFilterInfo({ ...filterInfo, [name]: value });
    
  }

  function handleRefuseInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    setAddInfo({ ...addInfo, [name]: value });
  }

  function handleTimeChange(tag, date, dateString) {
    let value = "";
    if (date) {
      value = parseInt(moment(dateString, dateFormat).valueOf() / 1000);
      console.log(date);
      setFilterInfo({ ...filterInfo, [tag]: value, endTimestamp: parseInt(new Date().getTime() / 1000) });
      console.log(filterInfo);
  }
    setFilterInfo({ ...filterInfo, [tag]: value, endTimestamp: parseInt(new Date().getTime() / 1000) });
  }

  function handleSelectChange(tag, value, options) {
    if (tag == "itemStatus") value = parseInt(value);
    setFilterInfo({ ...filterInfo, [tag]: value });
    console.log(filterInfo);
  }

  function handleRefuseInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    setAddInfo({ ...addInfo, [name]: value });
  }

  function handleFilter() {
    filterInfo.index = 1
    getTableData(filterInfo);
  }

  function handleClear() {
    filterForm.resetFields();
    const initState = {
      itemType: 4,
      roleSid: 1,
      pageSize: 10,
      index: 1,
      itemStatus: "",
      itemNumber: "",
      managerName: "",
      participants: "",
      itemName: "",
    };
    setFilterInfo({
      ...initState,
    });
    getTableData(initState);
  }

  function handlePageChange(event, pageSize) {
    const index = event;
    setFilterInfo({ ...filterInfo, pageSize, currIndex: index, index: index });
  }

  async function downloadfile(record) {
    await post(
      {
        url: "/ec/project/downloadFile",
        data: {
          sessionId: localStorage.sessionId,
          fileSid: record.fileSid,
          itemSid: record.itemSid,
          flag: 0,
        },
      },
      (data) => {
        if ((data.code = "N01")) {
          let url = data.contents;
          let a = document.createElement("a");
          let event = new MouseEvent("click");
          // a.download = "file";
          a.target = "_blank";
          a.href = url;
          a.dispatchEvent(event);
        }
      },
      props.history
    );
  }

  async function deleteFile(record) {
    await post(
      {
        url: "/ec/project/deleteFile",
        data: {
          sessionId: localStorage.sessionId,
          fileSid: record.fileSid,
          itemSid: record.itemSid,
          flag: 0,
        },
      },
      () => {
        notification.success({ description: "通知", message: "删除文件成功!" });
        getFileList();
      },
      props.history
    );
  }

  function clickUploadFile() {
    setUploadDialog({
      visible: true,
    });
  }

  function stopUpload(file, filelist) {
    let oldList = [...uploadFileList];
    oldList.push(file);
    setuploadFileList(oldList);
    return new Promise((resolve, reject) => {
      return reject(false);
    });
  }

  async function handleConfirmUpload() {
    let list = [...uploadFileList];
    if (list != []) {
      list.forEach((item) => {
        console.log(item);
        upLoad(item);
      });
    }
  }

  async function upLoad(item) {
    let data = {
      sessionId: localStorage.sessionId,
      itemSid: 1,
      flag: 0,
      file: item,
      updateTime: parseInt(new Date().getTime() / 1000),
    };
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    await post(
      {
        url: "/ec/project/upLoadFile",
        data: formData,
      },
      () => {
        notification.success({ description: "通知", message: "上传文件成功!" });
        getFileList();
        setuploadFileList([]);
        handleCancelUpload();
      },
      props.history
    );
  }

  async function deleteSingleFile(record) {
    await post(
      {
        url: "/ec/project/deleteFile",
        data: {
          sessionId: localStorage.sessionId,
          fileSid: record.fileSid,
          itemSid: record.itemSid,
          flag: 0,
        },
      },
      () => {
        notification.success({ description: "通知", message: "删除文件成功!" });
        getSingleFileList(record.itemSid);
      },
      props.history
    );
  }

  async function handleStatusChange(type, data) {
    console.log("data", data);
    switch (type) {
      case "待审核":
        data.itemStatus = 1;
        break;
      case "通过":
        data.itemStatus = 2;
        break;
      case "拒绝":
        data.itemStatus = 3;
        setRefuseDialog({
          visible: false,
        });
        break;
    }
    await post(
      {
        url: "/ec/project/updateProjectStatus",
        data: {
          sessionId: localStorage.sessionId,
          itemSid: data.itemSid,
          itemStatus: data.itemStatus,
          roleSid: 1,
          isHandover: 0,
          note: data.note,
        },
      },
      () => {
        notification.success({ description: "通知", message: "修改状态成功!" });
        getTableData(filterInfo);
      },
      props.history
    );
  }

  // async function handleClickTemplateBtn() {
  //   await post(
  //     {
  //       url: "/ec/project/getFileInfo",
  //       data: {
  //         sessionId: localStorage.sessionId,
  //         flag: 0,
  //         itemSid: 1,
  //       },
  //     },
  //     (data) => {
  //       if (data.code == "N01") setDetailTable(data.contents);
  //       else setDetailTable(data.contents.list);
  //     }
  //   );
  //   setTemplateDialog({
  //     visible: true,
  //   });
  // }

  function handleClickRefuseBtn(type, data) {
    refuseForm.resetFields();
    setRefuseDialog({
      visible: true,
    });
    setAddInfo({ ...data });
  }

  // async function handleClickDownloadBtn(type, data) {
  //   await post(
  //     {
  //       url: "/ec/project/getFileInfo",
  //       data: {
  //         sessionId: localStorage.sessionId,
  //         flag: 0,
  //         itemSid: data.itemSid,
  //       },
  //     },
  //     (data) => {
  //       if (data.code == "N01") setSingleDetailTable(data.contents);
  //       else setSingleDetailTable(data.contents.list);
  //     }
  //   );
  //   setDownloadDialog({
  //     visible: true,
  //   });
  // }

  function handleCancel() {
    setTemplateDialog({
      visible: false,
    });
  }

  function handleCancelRefuse() {
    setRefuseDialog({
      visible: false,
    });
  }

  function handleCancelDownload() {
    setDownloadDialog({
      visible: false,
    });
  }

  function handleCancelUpload() {
    setUploadDialog({
      visible: false,
    });
  }

  function handleQuickFilter(type) {
    filterForm.setFieldsValue({itemStatus: type.toString()});
    setFilterInfo({...filterInfo, itemStatus: type, index: 1})
    getTableData({...filterInfo, itemStatus: type, index: 1});
  }

  return (
    <>
      <CustomCard options={headerCardOptions}>
        <Form
          layout="inline"
          {...layout}
          style={{ flexDirection: "column" }}
          form={filterForm}
        >
          <Row className="project-manage-row">
            <Col span={4} offset={1}>
              <Form.Item label="论文综述" name="itemName">
                <Input
                  placeholder="请输入论文综述名"
                  value={filterInfo.itemName}
                  style={{ width: "80%" }}
                  onChange={handleInputChange}
                  name="itemName"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="主持者" name="managerName">
                <Input
                  placeholder="请输入主持者"
                  value={filterInfo.managerName}
                  style={{ width: "80%" }}
                  onChange={handleInputChange}
                  name="managerName"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="提交时间" name="startTimestamp">
                <Space direction="vertical" size={12} style={{ width: "100%" }}>
                  <DatePicker
                    // defaultValue={moment('2015/01/01', dateFormat)}
                    format={dateFormat}
                    // value={ filterInfo.startTimestamp }
                    style={{ width: "80%" }}
                    onChange={handleTimeChange.bind(null, "startTimestamp")}
                    name="startTimestamp"
                    placeholder="请选择时间"
                  />
                </Space>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="管理员审核状态" name="itemStatus">
                <Select
                  placeholder="请选择"
                  style={{ width: "60%" }}
                  value={filterInfo.itemStatus}
                  name="itemStatus"
                  onChange={handleSelectChange.bind(null, "itemStatus")}
                  allowClear
                >
                  <Option value="1" key="1">
                    待审核
                  </Option>
                  <Option value="2" key="2">
                    已通过
                  </Option>
                  <Option value="3" key="3">
                    已拒绝
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={2}>
              <Button type="primary" htmlType="submit" onClick={handleFilter}>
                筛选
              </Button>
            </Col>
            <Col span={2}>
              <Button type="plain" htmlType="submit" onClick={handleClear}>
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomCard>
      <CustomCard options={tableCardOptions}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <div>
            <Button className="quickFilter ec-button-orange" onClick={handleQuickFilter.bind(null, 1)}>
                待审核
            </Button>
            <Button className="quickFilter ec-button-green" onClick={handleQuickFilter.bind(null, 2)}>
                已通过
            </Button>
            <Button className="quickFilter" type="danger" onClick={handleQuickFilter.bind(null, 3)}>
                已拒绝
            </Button>
          </div>
          {/* <Button type="primary" onClick={handleClickTemplateBtn.bind(null)}>
            查看模板
          </Button> */}
        </div>
        <div>
          <Table
            className="table"
            rowKey="itemSid"
            columns={columns}
            dataSource={table}
            loading={loading}
            pagination={{
              total: total,
              current: filterInfo.index,
              pageSize: filterInfo.pageSize,
              pageSizeOptions: [10,20,30],
              onChange: handlePageChange,
              showSizeChanger: true,
            }}
          />
        </div>
      </CustomCard>
      {/* <Modal
        title="查看模板"
        visible={templateDialog.visible}
        onCancel={handleCancel}
        footer={[
          <div>
            <Button key="upload" type="primary" onClick={clickUploadFile}>
              上传模板
            </Button>
            <Button key="backupload" onClick={handleCancel}>
              关闭
            </Button>
          </div>,
        ]}
        width={800}
      >
        <Table
          className="table"
          // showHeader={ false }
          columns={detailColumns}
          dataSource={detailTable}
          pagination={false}
        ></Table>
      </Modal> */}
      <Modal
        title="拒绝理由"
        visible={refuseDialog.visible}
        onCancel={handleCancelRefuse}
        footer={[
          <div>
            <Button key="back" onClick={handleCancelRefuse}>
              关闭
            </Button>
            <Button
              key="submitAdd"
              type="primary"
              onClick={handleStatusChange.bind(null, "拒绝", addInfo)}
            >
              提交
            </Button>
          </div>,
        ]}
      >
        <Form {...refuseLayout} name="control-ref" form={refuseForm}>
          <Form.Item name="note" label="理由">
            <TextArea
              rows={4}
              value={addInfo.note}
              onChange={handleRefuseInputChange}
              name="note"
            />
          </Form.Item>
        </Form>
      </Modal>
      {/* <Modal
        title="文件列表"
        visible={dowmloadDialog.visible}
        onCancel={handleCancelDownload}
        footer={[
          <Button key="back" onClick={handleCancelDownload}>
            关闭
          </Button>,
        ]}
        width={800}
      >
        <Table
          className="table"
          // showHeader={ false }
          columns={singleDetailColumns}
          dataSource={singleDetailTable}
          pagination={false}
        ></Table>
      </Modal>
      <Modal
        title="上传文件"
        visible={uploadDialog.visible}
        onCancel={handleCancelUpload}
        footer={[
          <div>
            <Button type="primary" onClick={handleConfirmUpload}>
              确认上传
            </Button>
            <Button onClick={handleCancelUpload}>关闭</Button>
          </div>,
        ]}
      >
        <div>
          <Upload {...uploadprops}>
            <Button type="primary">选择文件</Button>
          </Upload>
        </div>
      </Modal> */}
    </>
  );
}
