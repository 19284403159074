/*
 * @Author: 廖立婷
 * @Date: 2020-11-16 10:07:33
 * @Last Modified by: HouQi
 * @Last Modified time: 2021-11-28 15:39:00
 */

import React, { useState } from 'react'
import { Menu, Dropdown, notification, Modal, Button, Form, Input } from 'antd';
import { MenuFoldOutlined, AppstoreOutlined, MailOutlined, UserOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { post } from '../../network/request';
import md5 from 'js-md5';

import './home.css';
const { SubMenu } = Menu;


export default function Home(props) {
  const [changePasswordForm] = Form.useForm();
  // console.log('props',props)
  const [changePasswordDialog, setChangePasswordDialog] = useState({ visible: false })
  const [passwordInfo, setPasswordInfo] = useState({
    oldPassword: '',
    newPassword: '',
    repeatPassword: ''
  })

  //管理员的菜单（key值对应路由名称）
  const ManagerMenuList = [
    {
      title: '学生简历管理',
      icon: <MailOutlined />,
      key: 'sub1',
      children: [
        {
          title: '学生简历管理',
          key: 'studentResume'
        }
      ]
    },
    {
      title: '出入站管理',
      icon: <AppstoreOutlined />,
      key: 'sub2',
      children: [
        {
          title: '入站管理',
          key: 'enterManagement'
        },
        {
          title: '横向出站管理',
          key: 'transveralExitManage'
        },
        {
          title: '纵向出站管理',
          key: 'verticalExitManage'
        },
      ]
    },
    {
      title: '横向出站条件管理',
      icon: <MenuFoldOutlined />,
      key: 'sub3',
      children: [
        {
          title: '横向出站条件',
          key: 'requirementManagement',
        },
        {
          title: '出站情况统计',
          key: 'horizonStatistics',
        },
        {
          title: '应用项目管理',
          key: 'projectManage',
        },
        {
          title: '建设方案管理',
          key: 'projectConstruction'
        },
        {
          title: '新技术框架管理',
          key: 'technologyManage'
        },
        {
          title: '软件著作权管理',
          key: 'technologySoftware'
        },
        
      ]
    },
    {
      title: '纵向出站条件管理',
      icon: <MenuFoldOutlined />,
      key: 'sub4',
      children: [
        {
          title: '纵向出站条件',
          // key: 'requirementManagement',
          key: 'studyRequirementManagement',
        },
        
        {
          title: '出站情况统计',
          key: 'verticalStatistics',
        },
        {
          title: '研究生项目/竞赛管理',
          // key: 'requirementManagement',
          key: 'studentProjectManage',
        },
        {
          title: '项目申请书撰写管理',
          key: 'erectapplication'
        },
        
        {
          title: '论文综述报告管理',
          key: 'reviewReport'
        },
        {
          title: '科研论文管理',
          key: 'paperManage'
        },
        {
          title: '发明专利管理',
          key: 'PatentManage'
        },
        {

          title: '纵向技术框架报告管理',
          key: 'verticalTechnicalFramework'
        }
        
      ]
    }
  ]
  //学生菜单
  const StudentMenuList = [
    {
      title: '横向出站条件管理',
      icon: <MenuFoldOutlined />,
      key: 'sub1',
      children: [
        {
          title: '应用项目管理',
          key: 'StuProjectManage'
        },
        {
          title: '建设方案管理',
          key: 'StuProjectContruction'
        },
        {
          title: '新技术框架管理',
          key: 'StuTechnologyManage'
        },
        {
          title: '软件著作权管理',
          key: 'StuTechnologySoftware'
        }
      ]
    },
    {
      title: '纵向出站条件管理',
      icon: <MenuFoldOutlined />,
      key: 'sub2',
      children: [
        
        {
          title: '研究生项目/竞赛管理',
          key: 'stuProComManage'
        },
        {
          title: '项目申请书撰写管理',
          key: 'projectApplication'
        },
        {
          title: '论文综述报告管理',
          key: 'stuReviewReport'
        },
        {
          title: '科研论文管理',
          key: 'studentPaperManage'
        },
        {
          title: '发明专利管理',
          key: 'stuPatentManage'
        },
        {
          title: '纵向技术框架报告管理',
          key: 'verticalTechnology'
        },
      ]
    },
    {
      title: '提交出站申请',
      icon: <MenuUnfoldOutlined />,
      key: 'sub3',
      children: [
        {
          title: '项目交接',
          key: 'projectHandOver'
        },
        {
          title: '横向出站申请',
          key: 'stageOutbound'
        },
        {
          title: '纵向出站申请',
          key: 'studyOutbound'
        },
      ]
    }
  ]
  //教师菜单
  const TeacherMenuList = [
    {
      title: '学生培养管理',
      icon: <MenuFoldOutlined />,
      key: 'sub1',
      children: [
        {
          title: '学生简历管理',
          key: 'TehStudentResume'
        }
      ]
    },
    {
      title: '纵向出站条件管理',
      icon: <MenuFoldOutlined />,
      key: 'sub4',
      children: [
        {
          title: '纵向出站条件',
          // key: 'requirementManagement',
          key: 'studyRequirementManagement',
        },
        
        {
          title: '出站情况统计',
          key: 'verticalStatistics',
        },
        {
          title: '研究生项目/竞赛管理',
          // key: 'requirementManagement',
          key: 'studentProjectManage',
        },
        {
          title: '项目申请书撰写管理',
          key: 'erectapplication'
        },
        
        {
          title: '论文综述报告管理',
          key: 'reviewReport'
        },
        {
          title: '科研论文管理',
          key: 'paperManage'
        },
        {
          title: '发明专利管理',
          key: 'PatentManage'
        },
        {

          title: '纵向技术框架报告管理',
          key: 'verticalTechnicalFramework'
        }
        
      ]
    }
  ]
  let mentorName = JSON.parse(localStorage.userInfo).mentorName;
  // const [roleName, setRoleName] = useState(mentorName);
  //判断角色，返回对应数组
  function getMenuList() {
    //roleSid的值标识了用户的类型，1为管理员，2为教师，3为学生
    const roleSid = JSON.parse(localStorage.userInfo).roleSid;
    switch (roleSid) {
      case 1:
        return ManagerMenuList;
      case 2:
        return TeacherMenuList;
      case 3:
        return StudentMenuList;
      default:
        notification.error({
          description: '您的会话已经过期，请重新登录',
          message: '警告',
          duration: 1,
          onClose: () => {
            props.history.push('/login');
          }
        });
    }
  }
  //生成菜单元素
  function returnMenu() {
    //获得菜单列表
    const list = getMenuList();
    // console.log('list',list)
    return (
      list.map(item =>
        <SubMenu
          key={item.key}
          title={<span>
            {item.icon}
            <span>{item.title}</span>
          </span>}>
          {item.children.map(item => <Menu.Item key={item.key}>{item.title}</Menu.Item>)}
        </SubMenu>)
    )
  }

  function handleMenuClick(event) {
    props.history.push(`/home/${event.key}`);
  }
  //打开修改密码模态框
  function handleOpenChangePasswordDialog() {
    setChangePasswordDialog({ visible: true });
  }
  //关闭模态框
  function handleClose() {
    setChangePasswordDialog({ visible: false });
    changePasswordForm.resetFields();
  }
  //input绑定
  function handleUserInfoChange(event) {
    const value = event.target.value;
    setPasswordInfo({ ...passwordInfo, [event.target.name]: value });
  }
  //点击提交修改密码
  async function handleClickChangePassword() {
    try {
      await changePasswordForm.validateFields();
      await modifyPassword();
    } catch (error) {
      console.log(error.message);
    }
  }
  //提交修改密码
  async function modifyPassword() {
    await post({
      url: '/ec/accountNumber/modifyPassword',
      data: {
        sessionId: localStorage.sessionId,
        account: localStorage.account,
        oldPassword: md5(passwordInfo.oldPassword),
        newPassword: md5(passwordInfo.newPassword)
      }
    }, () => {
      notification.success({
        description: '密码修改成功！',
        message: '通知',
        duration: 1,
        onClose: () => {
          props.history.push('/login');
        }
      })
    }, props.history);
  }
  //登出
  function handleLoginOut() {
    loginOut();
  }
  //调用登出接口
  async function loginOut() {
    await post({
      url: '/ec/accountNumber/loginOut',
      data: {
        sessionId: localStorage.sessionId
      }
    }, () => {
      notification.success({
        description: '已退出，等待跳转',
        message: '通知',
        duration: 0.5,
        onClose: () => {
          props.history.push('/login');
        }
      })
    }, props.history);
  }
  const menu = (
    <Menu>
      <Menu.Item>
        <Button onClick={handleOpenChangePasswordDialog} type='text'>修改密码</Button>
      </Menu.Item>
      <Menu.Item>
        <Button onClick={handleLoginOut} type='text'>退出登录</Button>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="home">
      <div className="home-header">
        <div className="home-header-left">
          <img src="/assets/images/EClogo.png" alt="EClogo" className="home-logo" />
          <div className="title">
            <a href="https://www.znglzx.com/">工程中心学生管理系统</a>
          </div>
        </div>
        <div className="home-dropmenu">
          <Dropdown.Button overlay={menu} placement="bottomCenter" icon={<UserOutlined />}>
            {/* {roleName} */}
            {JSON.parse(localStorage.userInfo).mentorName}
          </Dropdown.Button>
        </div>
      </div>
      <div className="home-content">
        <div className="menu">
          <Menu
            mode="inline"
            style={{ width: 250,overflowY: 'auto' }}
            onClick={handleMenuClick}
            defaultOpenKeys={getMenuList().map(item => item.key)}
            defaultSelectedKeys={[props.history.location.pathname.split('/')[props.history.location.pathname.split('/').length - 1]]}
          >
            {returnMenu()}
          </Menu>
        </div>
        <div className="home-content-main">{props.children}</div>
      </div>
      {/* 修改密码 */}
      <Modal
        title="修改密码"
        visible={changePasswordDialog.visible}
        onCancel={handleClose}
        footer={[
          <Button key="back" onClick={handleClose}>
            关闭
          </Button>,
          <Button key="submit" type="primary" onClick={handleClickChangePassword}>
            提交
          </Button>
        ]}
      >
        <Form
          form={changePasswordForm}
          name="changePasswordForm"
          labelAlign={{ span: 1 }}
        >
          <Form.Item name="oldPassword" label="旧密码" rules={[
            {
              required: true,
              message: '请输入旧密码'
            }
          ]}>
            <Input
              placeholder="请输入旧密码"
              value={passwordInfo.oldPassword}
              name="oldPassword"
              type="password"
              onChange={handleUserInfoChange}></Input>
          </Form.Item>

          <Form.Item name="newPassword" label="新密码" rules={[
            {
              required: true,
              message: '请输入新密码'
            }
          ]}>
            <Input
              placeholder="请输入新密码"
              value={passwordInfo.newPassword}
              name="newPassword"
              type="password"
              onChange={handleUserInfoChange}></Input>
          </Form.Item>

          <Form.Item name="repeatPassword" label="再次输入密码" rules={[
            {
              required: true,
              message: '请再次输入新密码'
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('密码不匹配');
              },
            }),
          ]}>
            <Input
              placeholder="请再次输入新密码"
              value={passwordInfo.repeatPassword}
              name="repeatPassword"
              type="password"
              onChange={handleUserInfoChange}></Input>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
