/*
 * @Author: HouQi 
 * @Date: 2021-09-27 16:53:53 
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-06-09 16:30:55
 */
import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Table,
  Space,
  notification,
  Modal,
  Upload,
  Radio,
  DatePicker,
  Popconfirm,
  Image,
  message,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { post, request } from "../../../network/request";
import { UploadOutlined } from '@ant-design/icons';
import moment from "moment";
//import component
import CustomCard from "../../../components/common/card/card";
//import css
import "./stuProComManage.css";

const { Option } = Select;
const { TextArea } = Input;

export default function TechnologyManage(props) {
  const headerCardOptions = { title: null, bordered: false };
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll", height: "calc(100vh - 78px - 98px)" },
  };
  const [filterForm] = Form.useForm();
  const [addForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const columns = [
    {
      title: "编号",
      dataIndex: "itemNumber",
      key: "itemNumber",
      align: "center",
    },
    {
      title: "新项目/竞赛名称",
      dataIndex: "itemName",
      key: "itemName",
      align: "center",
    },
    {
      title: "主持者",
      dataIndex: "managerName",
      key: "managerName",
      align: "center",
    },
    {
      title: "类型",
      dataIndex: "firstLetter",
      key: "firstLetter",
      align: "center",
    },
    {
      title: "申请时间",
      dataIndex: "insertTimestamp",
      key: "insertTimestamp",
      align: "center",
    },
    {
      title: "管理员审核状态",
      key: "itemStatus",
      align: "center",
      render: (text, record) => {
        switch (text.itemStatus) {
          case "已通过":
            return <span className="ec-text-color-green">{text.itemStatus}</span>;
          case "待审核":
            return <span className="ec-text-color-orange">{text.itemStatus}</span>;
          case "已拒绝":
            return <span className="ec-text-color-red">{text.itemStatus}</span>;
          default:
            return <span>{text.itemStatus}</span>;
        }
      },
    },
    {
      title: "处理理由",
      dataIndex: "note",
      key: "note",
      align: "center",
    },
    {
      title: "操作",
      /* 不要 */
      key: "operate",
      align: "center",
      render: (text, record) => (
        <div>
          <Button
            className="operate-button ec-button-lightBlue"
            size="small"
            onClick={clickUploadFile.bind(null, text, record)}
          >
            上传文件
          </Button>
          <Button
            className="operate-button ec-button-lightBlue"
            size="small"
            onClick={handleClickDownloadBtn.bind(null, text, record)}
          >
            查看文件
          </Button>
          <Button
            className="operate-button ec-button-green"
            size="small"
            onClick={handleClickEditBtn.bind(null, text)}
          >
            修改信息
          </Button>
          <Popconfirm
            className="operate-button"
            type="danger"
            placement="bottomRight"
            title="确认删除吗？"
            onConfirm={handleDeleteItem.bind(null, text)}
            okText="确定"
            cancelText="取消"
          >
            <Button className="operate-button" type="danger" size="small">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  const detailColumns = [
    {
      title: "文件名称",
      dataIndex: "filename",
      key: "title",
      align: "center",
    },
    {
      title: "操作",
      key: "content",
      align: "center",
      render: (text, record) => (
        <div>
          <Button
            type="primary"
            style={{ marginRight: "5%" }}
            onClick={downloadfile.bind(null, record)}
          >
            下载文件
          </Button>
        </div>
      ),
    },
  ];
  // 时间验证
  const config = {
    rules: [{ type: 'date', required: true, message: '请选择提交时间!' }],
  };

  const refForm = useRef();

  //state
  const [filterInfo, setFilterInfo] = useState({
    itemType: 1,
    roleSid: 3,
    pageSize: 10,
    index: 1,
    managerName: "",
    itemStatus: '',
  });
  const [addInfo, setAddInfo] = useState({
    itemType: 1,
    roleSid: 3,
    index: 1,
    itemName: "",
    itemNumber: "",
    itemStatus: "",
    pageSize: 10,
    insertTimestamp: parseInt(new Date().getTime() / 1000),
    endTime: parseInt(new Date().getTime() / 1000),
    statusDescription: "",
  });
  const [editInfo, setEditInfo] = useState({
    itemType: 1,
    roleSid: 3,
    index: 1,
    itemName: "",
    itemNumber: "",
    itemStatus: "",
    pageSize: 10,
    insertTimestamp: '',
    endTime: parseInt(new Date().getTime() / 1000),
    statusDescription: "",
  });
  // const [editInfo, setEditInfo] = useState({});
  const [rawTable, setRawTable] = useState([]);
  const [addDialog, setAddDialog] = useState({ visible: false });
  const [editDialog, setEditDialog] = useState({ visible: false, tmpType: '' });
  const [fileInfo, setFileInfo] = useState([]);
  // load
  const [urlLoading, setUrlLoading] = useState(null);
  const [detailTable, setDetailTable] = useState([]);
  const [fileTable, setFileTable] = useState([]);
  const [total, setTotal] = useState(0);
  const [competitionsName, setCompetitionsName] = useState([])
  const [templateDialog, setTemplateDialog] = useState({ visible: false });
  const [dowmloadDialog, setDownloadDialog] = useState({ visible: false });
  const [uploadDialog, setUploadDialog] = useState({ visible: false });
  // 已经上传到弹框文件
  const [uploadFileList, setuploadFileList] = useState([]);
  //控制查看图片
  const [fileDialog, setFileDialog] = useState({ visible: false });
  // 查看要上传的文件
  const [previewInfo, setPreviewInfo] = useState({
    priviewVisible: false,
    priviewImage: '',
  });
  // 查看固定的图片
  const [pictureDialog, setPictureDialog] = useState({ visible: false });
  const [pictureContent, setPictureContent] = useState({});

  const [title, setTitle] = useState({ type: 0 });
  const [selectItemId, setSelectItemId] = useState(0);
  const [validateState, setValidateState] = useState({
    time: "success",
    timehelp: undefined,
  });
  const [test, setTest] = useState(undefined)
  const [loading, setLoading] = useState(null);

  //memo
  const table = useMemo(() => {
    rawTable.forEach((item) => {
      switch (item.itemStatus) {
        case 1:
          item.itemStatus = "待审核";
          break;
        case 2:
          item.itemStatus = "已通过";
          break;
        case 3:
          item.itemStatus = "已拒绝";
          break;
        default:
          item.itemStatus = "错误";
      }
      item.updateTimestamp = !item.updateTimestamp
        ? "暂无"
        : new Date(item.updateTimestamp * 1000).toLocaleDateString();
      item.insertTimestamp = !item.insertTimestamp
        ? "暂无"
        : new Date(item.insertTimestamp * 1000).toLocaleDateString();
      item.endTime = !item.endTime
        ? "暂无"
        : new Date(item.endTime * 1000).toLocaleDateString();

      switch (item.firstLetter) {
        case "C":
          item.firstLetter = "竞赛"
          break;
        case "G":
          item.firstLetter = "项目"
          break;
        default:
          break;
      }
    });
    return rawTable;
  }, [rawTable]);


  //date
  const dateFormat = "YYYY/MM/DD";
  const todayDate = new Date().toLocaleDateString();

  //查看文件Table
  const fileColumns = [
    {
      title: "文件名称",
      dataIndex: "filename",
      align: "center",
    },
    {
      title: "操作",
      key: "operate",
      align: "center",
      render: (text, record) => (
        <>
          <Button
            type="primary"
            size="small"
            // onClick={handleClickPicture.bind(null, text, record)}
            onClick={downloadfile.bind(null, record)}
          >
            获取文件
          </Button>

          <Button
            type="danger"
            size="small"
            style={{ marginLeft: '10px' }}
            onClick={deleteFile.bind(null, text)}
          >
            删除文件
          </Button>
        </>
      ),
    },
  ];

  //form
  const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 20 },
  };
  const layout2 = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  // const uploadprops = {
  //   listType: "picture",// 指定则显示照片列表，不指定不影响使用
  //   beforeUpload: stopUpload,
  //   fileList: uploadFileList,
  // };
  const uploadprops = {
    name: "file",
    action: "/ec/project/upLoadFile",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: stopUpload,
    fileList: uploadFileList,
    onChange(info) {
      console.log("info", info);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      setuploadFileList(info.fileList);
    },
  };

  //validate
  const validateMessages = {
    required: "",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
      array: "请填写${label}!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  // 单选框
  const [typeValue, setTypeValue] = useState(1);

  const onChange = e => {
    console.log('radio checked', e.target.value);
    console.log(e)
    setTypeValue(e.target.value);
  };

  useEffect(() => {
    getFileList();
  }, []);
  useEffect(() => {
    getCompetitions();
  }, [])

  useEffect(() => {
    getTableData(filterInfo);
  }, [filterInfo.pageSize, filterInfo.currIndex]);

  useEffect(() => {
    addForm.setFieldsValue({
      itemName: addInfo.itemName,
      itemNumber: addInfo.itemNumber,
    });
  }, [addDialog]);

  async function getTableData(filterData) {
    setLoading(true);
    await post(
      {
        url: "/ec/project/getProjectInfoByWriteItemType",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: localStorage.studentSid,
          ...filterData,
        },
      },
      (data) => {
        console.log(data)
        setLoading(false);
        setRawTable(data.contents.list);
        setTotal(data.contents.size);
      },
      props.history
    );
  }

  function handleFilter() {
    filterInfo.index = 1
    getTableData(filterInfo);
  }

  async function getFileList() {
    setLoading(true);
    await post(
      {
        url: "/ec/outResearchProject/getResearchFileInfo",
        data: {
          sessionId: localStorage.sessionId,
          flag: 0,
          itemSid: 1,
        },
      },
      (data) => {
        setLoading(false);
        setFileInfo(data.contents);
        if (data.code == "N01") setDetailTable(data.contents);
        else setDetailTable(data.contents.list);
      },
      props.history
    );
  }

  // 上传文件按钮
  function clickUploadFile(data, record) {
    console.log(data)
    setSelectItemId(data.itemSid);
    setuploadFileList([]);
    setUploadDialog({
      visible: true,
    });
  }

  // async function downloadfile(record, data) {
  //   setPictureDialog({
  //     visible: true,
  //   });
  //   getPictureData(data.itemSid, data.fileSid);
  // }

  async function downloadfile(record) {
    console.log(record)
    await post(
      {
        url: "/ec/outResearchProject/downloadResearchFile",
        data: {
          sessionId: localStorage.sessionId,
          fileSid: record.fileSid,
          itemSid: record.itemSid,
          flag: 0,
        },
      },
      (data) => {
        console.log(data)
        if ((data.code = "N01")) {
          let url = data.contents;
          let a = document.createElement("a");
          let event = new MouseEvent("click");
          // a.download = "file";
          a.target = "_blank";
          a.href = url;
          a.dispatchEvent(event);
        }
      },
      props.history
    );
  }

  // //查看固定图片
  // function handleClickPicture(record, data) {
  //   setPictureDialog({
  //     visible: true,
  //   });
  //   getPictureData(data.itemSid, data.fileSid);
  // }

  // //获取固定图片
  // async function getPictureData(itemSid, fileSid) {
  //   await post(
  //     {
  //       url: "/ec/outResearchProject/downloadResearchFile",
  //       data: {
  //         sessionId: localStorage.sessionId,
  //         flag: 1,
  //         itemSid: itemSid,
  //         fileSid: fileSid,
  //       },
  //     },
  //     (data) => {
  //       setPictureContent(data.contents);
  //     },
  //     props.history
  //   );
  // }

  async function deleteFile(record) {
    await post(
      {
        url: "/ec/outResearchProject/deleteResearchFile",
        data: {
          sessionId: localStorage.sessionId,
          fileSid: record.fileSid,
          itemSid: record.itemSid,
          flag: 0,
        },
      },
      () => {
        notification.success({ description: "通知", message: "删除文件成功!" });
        getFileData(record.itemSid);
      },
      props.history
    );
  }

  function stopUpload(file, filelist) {
    let oldList = [...uploadFileList];
    oldList.push(file);
    setuploadFileList(oldList);
    return new Promise((resolve, reject) => {
      return reject(false);
    });
  }

  function handleSelectChange(tag, value, options) {
    if (tag == "itemStatus") value = parseInt(value);
    setFilterInfo({ ...filterInfo, [tag]: value });
  }

  /* 竞赛相关 */
  function handleSelectChangeCom(tag, value) {
    setAddInfo({ ...addInfo, [tag]: value });
  }

  function handleClear() {
    filterForm.resetFields();
    const initState = {
      itemType: 1,
      roleSid: 3,
      pageSize: 10,
      index: 1,
      itemStatus: "",
      managerName: "",
      pageSize: 10
    };
    setFilterInfo({
      ...initState,
    });
    getTableData(initState);
  }

  async function handleClickTemplateBtn() {
    await post(
      {
        url: "/ec/outResearchProject/getResearchFileInfo",
        data: {
          sessionId: localStorage.sessionId,
          flag: 0,
          itemSid: 1,
        },
      },
      (data) => {
        console.log(data)
        if (data.code == "N01") setDetailTable(data.contents);
        else setDetailTable(data.contents.list);
      }
    );
    setTemplateDialog({
      visible: true,
    });
  }

  function handlePageChange(event, pageSize) {
    const index = event;
    setFilterInfo({ ...filterInfo, pageSize, currIndex: index, index: index });
  }

  function handleCancel() {
    setTemplateDialog({
      visible: false,
    });
  }

  function handleCancelAdd() {
    addForm.resetFields();
    setAddDialog({
      visible: false,
    });
  }
  function handleCancelEdit() {
    setEditDialog({
      visible: false,
      tmpType: ''
    });
  }

  function handleClearAddInfo() {
    addForm.resetFields();
    const initAdd = {
      itemType: 1,
      roleSid: 3,
      itemName: "",
      itemNumber: "",
      itemStatus: "",
      pageSize: 10,
      insertTimestamp: parseInt(new Date().getTime() / 1000),
      endTime: parseInt(new Date().getTime() / 1000),
      statusDescription: "",
    };
    const initValidate = {
      time: "success",
      timehelp: undefined,
    };
    setAddInfo({ ...initAdd });
    setValidateState({ ...initValidate });
  }

  function handleTimeChange(tag, date, dateString) {
    let value = "";
    let newAdd = { ...addInfo };
    if (date) {
      value = parseInt(moment(dateString, dateFormat).valueOf() / 1000);
    }
    newAdd[tag] = value;
    newAdd["endTime"] = value;
    setAddInfo(newAdd);
    handleMyValidate(tag, newAdd);
  }

  function myValidate(name, data) {
    console.log(name);
    console.log(data);
    if (!data) {
      data = { ...addInfo };
    }
    switch (name) {
      case "insertTimestamp":
      case "endTime": {
        if (data[name] == undefined || data[name] == "") {
          console.log("here end");
          setValidateState({
            ...validateState,
            time: "error",
            timehelp: "请填写申请时间!",
          });
        } else {
          console.log("here success");
          setValidateState({
            ...validateState,
            time: "success",
            timehelp: undefined,
          });
        }
        break;
      }
      default:
        break;
    }
  }

  function handleMyValidate(name, data) {
    myValidate(name, data);
  }

  function handleBeforeAjax() {
    if (title.type != 0) {
      if (addInfo.itemNumber == "") return false;
    }
    if (addInfo.insertTimestamp == "") return false;
    return true;
  }

  function handleClickAddItem() {
    let flag = handleBeforeAjax();
    if (flag) {
      console.log(addInfo)
      let formdata = { ...addInfo };
      formdata.sessionId = localStorage.sessionId;
      formdata.managerSid = parseInt(localStorage.studentSid);
      formdata.participantSid = "";
      if (typeValue == 1) {
        formdata.note = "G"
      } else {
        formdata.note = "C"
      }
      addProjectInfo(formdata);
    } else {
      notification.error({
        description: "表单信息尚未完善，无法提交！",
        message: "警告",
      });
    }
  }

  function handleAddInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    setAddInfo({ ...addInfo, [name]: value });
  }

  /* 获取竞赛名单 */
  async function getCompetitions() {
    await post(
      {
        url: "/ec/project/getCompetition",
        data: {
          sessionId: localStorage.sessionId
        }
      },
      (data) => {
        console.log(data)
        if (data.code == 'N01') {
          console.log(data.contents.list)
          let tmp = []
          data.contents.list.forEach((item) => {
            tmp.push(item)
          })
          setCompetitionsName(tmp)
          console.log(tmp)
          console.log(competitionsName)
        } else {
          console.log('Error!')
        }
      },
      props.history
    )
  }


  async function addProjectInfo(data) {
    setLoading(true);
    console.log(data);
    await post(
      {
        url: "/ec/project/addProjectWriteInfo",
        data,
      },
      (data) => {
        if (data.code == 'N01') {
          notification.success({
            description: "新增成功！",
            message: "通知",
          });
          console.log(data)
          setLoading(false);
          handleCancelAdd();
          addForm.resetFields();
          getTableData(filterInfo);
        } else {
          notification.error({
            description: "新增失败！",
            message: "警告",
          });
        }
      },
      props.history
    );
  }

  function handleClickAddBtn() {
    handleClearAddInfo();
    setAddDialog({
      visible: true,
    });
    setTitle({
      type: 0,
    });
  }

  // 点击修改信息
  function handleClickEditBtn(text) {
    console.log(text)
    editForm.setFieldsValue({
      itemSid: text.itemSid,
      itemName: text.itemName,
      itemNumber: text.itemNumber,
      insertTimestamp: moment(text.insertTimestamp, dateFormat)
    });
    setEditDialog({
      visible: true,
      tmpType: text.firstLetter == '竞赛' ? 'c' : 'g'
    });
    console.log(editDialog)
  }
  // 处理渲染的竞赛名单
  function handleCompetitionsName() {
    return competitionsName.map((item) => (
      <Option value={item.competitionName} key={item.id}>
        {item.competitionName}
      </Option>
    ));
  }

  function handleClickEditItem(value) {
    let trans = refForm.current.getFieldValue()
    // console.log(trans)
    if (trans.insertTimestamp !== '' &&
      trans.itemName !== '' &&
      trans.itemNumber !== '') {
      let formdata = { ...editInfo };
      formdata.sessionId = localStorage.sessionId;
      formdata.roleSid = 3;
      formdata.itemName = trans.itemName
      formdata.itemNumber = trans.itemNumber
      formdata.itemSid = trans.itemSid;
      formdata.insertTimestamp = parseInt(moment(trans.insertTimestamp, dateFormat).valueOf() / 1000);
      // console.log(formdata)
      updateProjectInfo(formdata);
    } else {
      console.log("验证不符合");
      notification.error({
        description: "表单信息尚未完善，无法提交！",
        message: "警告",
      });
    }
  }

  async function updateProjectInfo(data) {
    setLoading(true);
    await post(
      {
        url: "/ec/project/updateProjectWriteInfoByItemSid",
        data,
      },
      (data) => {
        if (data.code == 'N01') {
          notification.success({
            description: "修改成功！",
            message: "通知",
          });
          setLoading(false);
          handleCancelAdd();
          addForm.resetFields();
          getTableData(filterInfo);
          setEditDialog({
            visible: false,
            tmpType: ''
          });
        } else {
          notification.error({
            description: "修改失败！",
            message: "警告",
          });
        }

      },
      props.history
    );
  }

  async function handleDeleteItem(data) {
    console.log(data)
    await post(
      {
        url: "/ec/project/deleteProjectWriteInfo",
        data: {
          sessionId: localStorage.sessionId,
          itemSid: data.itemSid,
          roleSid: localStorage.roleSid,
        },
      },
      (data) => {
        console.log(data)
        if (data.code == 'N01') {
          notification.success({ description: "通知", message: "删除成功!" });
        } else {
          notification.error({ description: "通知", message: "删除成功!" });
        }
        getTableData(filterInfo);
      },
      props.history
    );
  }

  function handleCancelUpload() {
    setUploadDialog({
      visible: false,
    });
  }

  async function handleConfirmUpload() {
    deletePreview()
    let list = [...uploadFileList];
    if (list != []) {
      list.forEach((item) => {
        console.log(item);
        upLoad(item);
      });
    } else {
      console.log('空')
    }
  }
  // 删除预览？
  function deletePreview() {
    setPreviewInfo({
      priviewVisible: false,
      priviewImage: '',
    });
  }

  async function upLoad(item) {
    let data = {
      sessionId: localStorage.sessionId,
      itemSid: selectItemId,
      flag: 0,// 1为图片，0为文件
      file: item,
      updateTime: parseInt(new Date().getTime() / 1000),
    };
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    await post(
      {
        url: "/ec/outResearchProject/upLoadResearchFile",
        data: formData,
      },
      () => {
        notification.success({ description: "通知", message: "上传文件成功!" });
        handleCancelUpload();
      },
      props.history
    );
  }

  async function handleClickDownloadBtn(type, data) {
    setFileDialog({
      visible: true,
    });
    getFileData(data.itemSid);

  }

  //获取图片info
  async function getFileData(itemSid) {
    setUrlLoading(true);
    await post(
      {
        url: "/ec/outResearchProject/getResearchFileInfo",
        data: {
          sessionId: localStorage.sessionId,
          flag: 0,
          itemSid: itemSid,
        },
      },
      (data) => {
        if (data.code == "E02") {
          setFileTable([]);
          setUrlLoading(false);
        } else {
          setUrlLoading(false);
          setFileTable(data.contents);
        }
      },
      props.history
    );
  }

  return (
    <>
      {/* 筛选框 */}
      <CustomCard options={headerCardOptions}>
        <Form
          layout="inline"
          // {...layout}
          style={{ flexDirection: "column" }}
          form={filterForm}
        >
          <Row >
            <Col span={6} offset={1}>
              <Form.Item label="管理员审核状态" name="itemStatus">
                <Select
                  style={{ width: "80%" }}
                  value={filterInfo.itemStatus}
                  name="degreeType"
                  placeholder="--请选择--"
                  onChange={handleSelectChange.bind(null, "itemStatus")}
                  allowClear
                >
                  <Option value="1" key="1">
                    待审核
                  </Option>
                  <Option value="2" key="2">
                    已通过
                  </Option>
                  <Option value="3" key="3">
                    已拒绝
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col>
              <Button type="primary" htmlType="submit" onClick={handleFilter}>
                筛选
              </Button>
            </Col>
            <Col offset={1}>
              <Button type="plain" htmlType="submit" onClick={handleClear}>
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomCard>

      {/* 数据列表框 */}
      <CustomCard options={tableCardOptions}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 10,
          }}
        >
          <Button type="primary" onClick={handleClickTemplateBtn.bind(null)}>
            查看模板
          </Button>
          <Button
            type="primary"
            style={{ marginLeft: "30px" }}
            onClick={handleClickAddBtn.bind(null)}
          >
            新增项目/竞赛
          </Button>
        </div>
        <div>
          <Table
            className="table"
            rowKey="itemSid"
            columns={columns}
            dataSource={table}
            loading={loading}
            pagination={{
              total: total,
              current: filterInfo.currIndex,
              pageSize: filterInfo.pageSize,
              pageSizeOptions: [10, 20, 30],
              onChange: handlePageChange,
              showSizeChanger: true,
            }}
          />
        </div>
      </CustomCard>

      {/* 查看模板弹框 */}
      <Modal
        title="查看模板"
        visible={templateDialog.visible}
        onCancel={handleCancel}
        footer={[
          <div key='1'>
            <Button key="backupload" onClick={handleCancel}>
              关闭
            </Button>
          </div>,
        ]}
        width={800}
      >
        <Table
          className="table"
          columns={detailColumns}
          dataSource={detailTable}
          pagination={false}
        ></Table>
      </Modal>

      {/* 新增弹框 */}
      <Modal
        title={"新增项目/竞赛"}
        visible={addDialog.visible}
        onCancel={handleCancelAdd}
        forceRender
        footer={[
          <div key='1'>
            <Button key="back" onClick={handleClearAddInfo}>
              清空
            </Button>
            <Button
              key="submitAdd"
              type="primary"
              onClick={handleClickAddItem}
            >
              提交
            </Button>
          </div>,
        ]}
      >
        <Form
          {...layout2}
          name="control-ref"
          form={addForm}
          validateMessages={validateMessages}
          validateTrigger="onBlur"
        >
          <Form.Item>
            <Radio.Group onChange={onChange} value={typeValue}>
              <Radio value={1}>项目</Radio>
              <Radio value={2}>竞赛</Radio>
            </Radio.Group>
          </Form.Item>
          {/* 项目新增框 */}
          <Form.Item
            hidden={typeValue == 1 ? false : true}
          >
            <Form.Item
              name="itemName"
              label="项目名称"
              rules={[
                {
                  required: true,
                  message: "请输入项目名称!",
                },
              ]}
            >
              <Input
                placeholder="请输入项目名称"
                value={addInfo.itemName}
                onChange={handleAddInputChange}
                name="itemName"
              />
            </Form.Item>
            {/* <Form.Item
              label="汇报人"
              name="managerName"
              rules={[{ required: true, message: '请输入汇报人!' }]}
            >
              <Input 
                
                disabled />
            </Form.Item> */}
            <Form.Item
              name="itemNumber"
              label="项目编号"
              style={{ display: "none" }}
              rules={[
                {
                  required: true,
                  message: "请输入项目编号!",
                },
              ]}
            >
              <Input
                placeholder="请输入项目编号"
                value={addInfo.itemNumber}
                onChange={handleAddInputChange}
                disabled
                name="itemNumber"
              />
            </Form.Item>
            <Form.Item
              name="insertTimestamp"
              label="申请时间"
              rules={[{ required: true }]}
              validateStatus={validateState.time}
              help={validateState.timehelp}
            >
              <Space direction="vertical" size={12} style={{ width: "100%" }}>
                <DatePicker
                  defaultValue={
                    title.type == 0
                      ? moment(todayDate, dateFormat)
                      : moment(
                        new Date(
                          addInfo.insertTimestamp * 1000
                        ).toLocaleDateString(),
                        dateFormat
                      )
                  }
                  format={dateFormat}
                  style={{ width: "100%" }}
                  onChange={handleTimeChange.bind(null, "insertTimestamp")}
                  onBlur={handleMyValidate.bind(null, "insertTimestamp", addInfo)}
                  name="insertTimestamp"
                />
              </Space>
            </Form.Item>
          </Form.Item>
          {/* 竞赛新增框 */}
          <Form.Item
            hidden={typeValue == 2 ? false : true}
          >
            <Form.Item
              name="anyway"
              label="竞赛名称"
              rules={[
                {
                  required: true,
                  message: "请选择竞赛名称!",
                },
              ]}
            >
              <Select
                placeholder="请选择竞赛名称"
                value={test}
                onChange={handleSelectChangeCom.bind(null, "itemName")}
                showSearch
                allowClear
              >
                {handleCompetitionsName()}
              </Select>
            </Form.Item>

            <Form.Item
              name="itemNumber"
              label="竞赛编号"
              style={title.type == 0 ? { display: "none" } : null}
              rules={[
                {
                  required: true,
                  message: "请输入竞赛编号!",
                },
              ]}
            >
              <Input
                placeholder="请输入竞赛编号"
                value={addInfo.itemNumber}
                onChange={handleAddInputChange}
                name="itemNumber"
                disabled
              />
            </Form.Item>
            <Form.Item
              name="insertTimestamp"
              label="申请时间"
              rules={[{ required: true }]}
              validateStatus={validateState.time}
              help={validateState.timehelp}
            >
              <Space direction="vertical" size={12} style={{ width: "100%" }}>
                <DatePicker
                  defaultValue={
                    title.type == 0
                      ? moment(todayDate, dateFormat)
                      : moment(
                        new Date(
                          addInfo.insertTimestamp * 1000
                        ).toLocaleDateString(),
                        dateFormat
                      )
                  }
                  format={dateFormat}
                  style={{ width: "100%" }}
                  onChange={handleTimeChange.bind(null, "insertTimestamp")}
                  onBlur={handleMyValidate.bind(null, "insertTimestamp", addInfo)}
                  name="insertTimestamp"
                />
              </Space>
            </Form.Item>
          </Form.Item>


        </Form>
      </Modal>

      {/* 修改弹框 */}
      <Modal
        title={editDialog.tmpType == 'g' ? '修改项目' : '修改竞赛'}
        visible={editDialog.visible}
        forceRender
        onCancel={handleCancelEdit}
        onOk={handleClickEditItem}
        okText="确认"
        cancelText="取消"
      >
        <Form
          {...layout2}
          name="control-ref"
          form={editForm}
          validateMessages={validateMessages}
          validateTrigger="onBlur"
          ref={refForm}
        >
          <Form.Item
            name="itemName"
            label="项目名称"
            style={editDialog.tmpType == 'g' ? null : { display: "none" }}
            rules={[
              {
                required: true,
                message: "请输入项目名称!",
              },
            ]}
          >
            <Input
              placeholder="请输入项目名称"
            />
          </Form.Item>

          <Form.Item
            name="itemName"
            label="竞赛名称"
            style={editDialog.tmpType == 'c' ? null : { display: "none" }}
            rules={[
              {
                required: true,
                message: "请选择竞赛名称!",
              },
            ]}
          >
            <Select
              placeholder="请选择竞赛名称"
              value={test}
              onChange={handleSelectChangeCom.bind(null, "itemName")}
              showSearch
              allowClear
            >
              {handleCompetitionsName()}
            </Select>
          </Form.Item>

          <Form.Item
            name="itemNumber"
            label="项目编号"
            rules={[
              {
                required: true,
                message: "请输入项目编号!",
              },
            ]}
          >
            <Input
              placeholder="请输入项目编号"
              disabled
            />
          </Form.Item>
          <Form.Item
            name="insertTimestamp"
            label="申请时间"
            {...config}
          >
            <DatePicker />
          </Form.Item>
        </Form>
      </Modal>

      {/* 上传图片 */}
      <Modal
        title="上传文件"
        visible={uploadDialog.visible}
        onCancel={handleCancelUpload}
        footer={[
          <div key={Math.floor(Math.random() * 100)}>
            <Button type="primary" onClick={handleConfirmUpload}>
              确认上传
            </Button>
            <Button onClick={handleCancelUpload}>关闭</Button>
          </div>,
        ]}
      >
        <div className="clearfix">
          {/* <Upload {...uploadprops}>
            <Button icon={<UploadOutlined />}>选择图片</Button>
          </Upload> */}
          <Upload {...uploadprops}>
            <Button type="primary">选择文件</Button>
          </Upload>
          <Modal
            visible={previewInfo.priviewVisible}
            footer={null}
            onCancel={() => { setPreviewInfo({ priviewVisible: false }) }}
          >
            <img alt="example" src={previewInfo.priviewImage} />
          </Modal>
        </div>
      </Modal>

      {/* 查看文件列表 */}
      <Modal
        title="文件列表"
        visible={fileDialog.visible}
        onCancel={() => {
          setFileDialog({
            visible: false,
          });
        }}
        footer={[
          <Button key="back" onClick={() => {
            setFileDialog({
              visible: false,
            });
          }}>
            关闭
          </Button>,
        ]}
      >
        <Table
          className="pictureTable"
          rowKey="fileSid"
          columns={fileColumns}
          dataSource={fileTable}
          // loading={urlLoading}
          pagination={false}
        ></Table>
      </Modal>

      {/* 查看具体图片 */}
      <Modal
        title="查看文件"
        className="pictureList"
        visible={pictureDialog.visible}
        onCancel={() => {
          setPictureDialog({ visible: false })
        }}
        footer={[
          <Button key="back" onClick={() => {
            setPictureDialog({ visible: false })
          }}>
            关闭
          </Button>,
        ]}
      >
        <div style={{ width: "100%", margin: "0 auto", textAlign: "center" }}>
          <Image
            width={200}
            height={200}
            src={pictureContent}
          />
        </div>
      </Modal>
    </>
  );
}
