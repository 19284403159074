/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { Tag, Col, Form, Input, Button, Select, Row, Space, notification, DatePicker } from 'antd'
import { post } from '../../../network/request';
// import { useParams } from 'react-router-dom';
import moment from 'moment'
import FormItem from 'antd/lib/form/FormItem';
import './stuPatentManage.css'
const { Option } = Select

function addPatent(props) {
  console.log(props)
  const todayDate = new Date().toLocaleDateString()
  const dateFormat = 'YYYY/MM/DD'
  const mentorName = JSON.parse(localStorage.userInfo).mentorName
  const managerSid = parseInt(localStorage.studentSid)
  const [requiredflag, setRequiredflag] = useState(true)
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  const [addInfo, setAddInfo] = useState({
    itemType: 5,
    roleSid: parseInt(localStorage.roleSid),
    itemName: '',
    // itemNumber:'',
    chargeTeacher: '',
    managerSid: managerSid,
    itemStatus: '',
    insertTimestamp: parseInt(new Date().getTime() / 1000),
    endTime: parseInt(new Date().getTime() / 1000),
    selectParticipantSid: 0,
    participantSid: [],
    statusDescription: '',
  })
  // const [selectManagInfo,setManageInfo]=useState([])
  const [selectPartInfo, setSelectPartInfo] = useState([])
  const [validateState, setValidateState] = useState({
    managerName: 'success',
    managerNamehelp: undefined,
    time: 'success',
    timehelp: undefined,
  })
  const validateMessages = {
    required: '请填写${label}!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!',
      array: '请填写${label}!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }
  const [updataForm] = Form.useForm()
  const [loading, setLoading] = useState(true)

  //方法
  const itemNameChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    setAddInfo({ ...addInfo, [name]: value })
    console.log(addInfo)
  }
  const statusDescriptionChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    setAddInfo({ ...addInfo, [name]: value })
    console.log(addInfo)
  }
  //管理学生数据映射处理
  // const handleSelectGradeChange=(value)=>{
  //   let res = []
  //   props.allStuList.forEach((item)=>{
  //     if(item.entryGrade === Number(value)){
  //         res.push(item)

  //     }
  //   })
  //   setManageInfo(res)
  //   let data = {...addInfo}
  //   delete(data.managerName)
  //   data.managerSid=managerSid
  //   setAddInfo(data)
  // }
  //渲染管理学生数据下拉列表
  // const renderMagStuList=()=>{
  //   return(selectManagInfo.map((item)=>
  //     <Option value={item.studentSid} key={item.studentSid}>{item.studentName}</Option>
  //   )
  // )}

  //参加学生数据映射处理
  const handleSelectPartGradeChange = (value) => {
    console.log(value)
    let res = []
    props.allStuList.forEach((item) => {
      if (item.entryGrade === Number(value)) {
        res.push(item)
      }
    })
    setSelectPartInfo(res);
  }

  //渲染参加学生列表
  const renderPartList = () => {
    return (
      selectPartInfo.map((item) =>
        <Option value={item.studentSid} key={item.studentSid}>{item.studentName}</Option>
      )
    )
  }

  //渲染参加学生
  const handleGetPartStudentList = () => {
    let list = []
    addInfo.participantSid.forEach((item) => {
      props.allStuList.forEach((stu) => {
        if (item === stu.studentSid) {
          let student = {
            studentSid: item,
            studentName: stu.studentName
          }
          list.push(student)
        }
      })
    })
    return (
      list.map((item, index) =>
        <Tag closable
          key={item.studentSid}
          onClose={handleClickDeletePartStudent.bind(null, index)}
          style={{ marginTop: '5px' }}
        >{item.studentName} </Tag>
      )
    )
  }
  const handleClickDeletePartStudent = (index) => {
    let data = { ...addInfo };
    data.participantSid.splice(index, 1);
    setAddInfo(data);
  };


  //修改页面数据赋值
  const ediDataInit = () => {
    console.log(addInfo)
    let data = { ...props.rowstuInfo }
    let allInfo = [...props.allStuList]
    if (data.participants) {
      data.participants = data.participants.split(' ');
    }
    else {
      data.participants = [];
    }
    let partList = []
    let partGrade = 2017
    // let managerSid=managerSid
    // let managerGrade=2017
    console.log(data.participants.length)
    console.log("data.participants.length")
    for (let i = 0; i < data.participants.length; i++) {
      for (let j = 0; j <= allInfo.length; j++) {
        if (data.participants[i] === allInfo[j].studentName) {
          console.log(data.participants[i])
          partList.push(allInfo[j].studentSid)
          partGrade = allInfo[j].entryGrade
          break;
        }
      }
    }
    // for(let i=0;i<props.allStuList.length;i++)
    // {
    //     if(data.managerName===allInfo[i].studentName){
    //         // managerSid=allInfo[i].studentSid
    //         managerGrade=allInfo[i].entryGrade
    //         break;
    //     }
    // }
    data.participantSid = partList
    data.partGrade = partGrade
    // data.managerGrade = managerGrade
    handleSelectPartGradeChange(partGrade)

    data.insertTimestamp = parseInt(new Date(data.insertTimestamp).valueOf() / 1000)
    data.endTime = parseInt(new Date(data.endTime).valueOf() / 1000)
    data.updateTimestamp = parseInt(new Date(data.updateTimestamp).valueOf() / 1000)
    setAddInfo({ ...data })
    console.log(addInfo)
    updataForm.resetFields()
  }
  //规则验证
  const handleMyValidate = (state, info) => {
    myValidate(state, info)
  }

  function myValidate(name, data) {
    if (!data) {
      data = { ...addInfo }
    }
    switch (name) {
      case 'insertTimestamp':
        {
          if (data[name] === undefined || data[name] === '') {
            setValidateState({ ...validateState, time: 'error', timehelp: '请选择时间!' })
          }
          else {
            setValidateState({ ...validateState, time: 'success', timehelp: undefined })
          }
          break;
        }
      default:
        break;
    }
  }
  //修改参加学生
  function handleSelectPartChange(tag, value, options) {
    let stus = { ...addInfo }
    stus[tag] = value
    setAddInfo(stus)
  }
  //添加学生
  const handleClickAddPartStudent = () => {
    let list = [...addInfo.participantSid]
    if (addInfo.selectParticipantSid === addInfo.managerSid) {
      notification.error({
        description: '该学生是主持者！',
        message: '警告'
      });
    }
    else if (list.indexOf(addInfo.selectParticipantSid) === -1) {
      list.push(addInfo.selectParticipantSid)
      setAddInfo({ ...addInfo, participantSid: list })
    }
    else {
      notification.error({
        description: '该学生已经添加！',
        message: '警告'
      });
    }
  }
  const handleAddTimeChange = (tag, date, dateString) => {
    let value = ''
    let newAdd = { ...addInfo }
    if (date) {
      value = parseInt(moment(dateString, dateFormat).valueOf() / 1000)
    }
    newAdd[tag] = value

    setAddInfo(newAdd)
    handleMyValidate(tag, newAdd)
  }
  //初始化
  const handleClearAddInfo = () => {
    const initAdd = {
      itemType: 5,
      roleSid: parseInt(localStorage.roleSid),
      itemName: '',
      chargeTeacher: '',
      managerSid: managerSid,
      itemStatus: '',
      insertTimestamp: parseInt(new Date().getTime() / 1000),
      endTime: parseInt(new Date().getTime() / 1000),
      selectParticipantSid: 0,
      participantSid: [],
      statusDescription: '',
    }
    const initValidate = {
      managerName: 'success',
      managerNamehelp: undefined,
      time: 'success',
      timehelp: undefined,
    }
    setAddInfo({ ...initAdd })
    setValidateState({ ...initValidate })
    updataForm.resetFields()
  }

  const handleAddInputChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setAddInfo({ ...addInfo, [name]: value })
  }
  const addOrUpdata = () => {
    if (props.rowstuInfo.length === 0) handleClickAddItem()
    else handleClickEditItem()
  }
  const handleBeforeAjax = () => {
    if (addInfo.insertTimestamp === '' || addInfo.itemName === '') return false
    if (validateState.managerName != 'success' || validateState.time != 'success') return false
    return true
  }
  //点击添加
  const handleClickAddItem = () => {
    let flag = handleBeforeAjax()
    if (flag) {
      let formdata = { ...addInfo }
      formdata.participantSid = formdata.participantSid.join(",")
      formdata.sessionId = localStorage.sessionId
      addProjectInfo(formdata)
    }
    else {
      notification.error({
        description: '表单信息尚未完善，无法提交！',
        message: '警告',
      })
    }
  }
  //添加数据
  async function addProjectInfo(data) {
    await post({
      url: '/ec/project/addProjectWriteInfo',
      data
    }, data => {
      console.log(data)
      updataForm.resetFields();
      props.upDateTable()
      props.closeAddForm()
      props.setLoading(false);
    }, props.history)
  }

  //修改数据
  const handleClickEditItem = () => {
    let flag = handleBeforeAjax()
    if (flag) {
      let formdata = { ...addInfo }
      console.log(formdata)
      console.log(managerSid)
      formdata.participantSid = formdata.participantSid.join(",")
      formdata.sessionId = localStorage.sessionId
      formdata.roleSid = 3
      formdata.statusDescription = formdata.statusDescription
      formdata.managerSid = managerSid
      delete (formdata.participants)
      updateProjectInfo(formdata)
    }
    else {
      notification.error({
        description: '表单信息尚未完善，无法提交！',
        message: '警告',
      })
    }
  }
  async function updateProjectInfo(data) {
    console.log(data)
    props.setLoading(true);
    await post({
      url: '/ec/project/updateProjectWriteInfoByItemSid',
      data
    }, data => {
      props.closeAddForm(0)
      updataForm.resetFields();
      props.upDateTable()
      props.setLoading(false);
    }, props.history)
  }
  //生命周期函数
  useEffect(() => {
    handleClearAddInfo()
    if (props.rowstuInfo.length === 0) {
      updataForm.resetFields();
    }
    else
      ediDataInit()
    console.log(addInfo)
  }, [props.rowstuInfo])

  return (
    <div>
      <Form form={updataForm} validateMessages={validateMessages} validateTrigger='onBlur' preserve={false} {...layout}>
        <Form.Item name="itemName" label="专利名称" rules={[{ required: true, message: "请输入专利名称", }]}>
          <Input placeholder="请输入专利名称" value={addInfo.itemName} defaultValue={addInfo.itemName} onChange={itemNameChange} name="itemName" />
        </Form.Item >
        <Form.Item name="managerName" label="主持者" rules={[{ required: true, message: "请输入主持者", }]}>
          <Input defaultValue={mentorName} name="mentorName" disabled />
        </Form.Item>
        {/* <Form.Item label="参与者" name="participantSid"  initialValue={ addInfo.itemName }>
          <Select placeholder="年级" style={{ width: '20%',marginRight:'5%'}} defaultValue={addInfo.partGrade} onChange={handleSelectPartGradeChange.bind(null)} allowClear>
            <Option value="2017" key="2017">2017</Option>
            <Option value="2018" key="2018">2018</Option>
            <Option value="2019" key="2019">2019</Option>
            <Option value="2020" key="2020">2020</Option>
          </Select>
          <Select placeholder="姓名" style={{ width: '50%',marginRight:'5%'}} onChange={handleSelectPartChange.bind(null,'selectParticipantSid') } allowClear>
            {renderPartList()}
          </Select>
          <Button type="primary" onClick={handleClickAddPartStudent}>添加</Button>
          <div className="tagsDiv">{handleGetPartStudentList()}</div>
        </Form.Item> */}
        <Form.Item name="insertTimestamp" label="申请时间" rules={[{ required: true, message: "请选择申请时间" }]}>
          <Space direction="vertical">
            <DatePicker 
            defaultValue={props.rowstuInfo.length === 0 ? moment(todayDate, dateFormat) : moment(new Date(addInfo.insertTimestamp * 1000).toLocaleDateString(), dateFormat)}
              format={dateFormat} style={{ width: '100%' }} onChange={handleAddTimeChange.bind(null, 'insertTimestamp')} name="insertTimestamp" />
          </Space>
        </Form.Item>
        <Form.Item name="statusDescription" label="专利状态" rules={[{ required: true, message: "请输入专利状态" }]}>
          <Input placeholder="请输入当前专利状态" value={addInfo.statusDescription} defaultValue={addInfo.statusDescription} onChange={statusDescriptionChange} name="statusDescription" />
        </Form.Item >
        <FormItem labelAlign={"right"} labelCol={{ span: 3, offset: 12 }}>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button style={{ margin: '0 8px' }} onClick={handleClearAddInfo.bind(null)}>清空</Button>
              <Button type="primary" htmlType="submit" onClick={() => { addOrUpdata() }}>提交</Button>
            </Col>
          </Row>
        </FormItem>
      </Form>
    </div>
  )
}
export default addPatent;
