/*
 * @Author: HouQi
 * @Date: 2021-09-27 16:53:46
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-06-14 14:42:50
 */

import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";

import { Card, List, Button, Table, notification } from "antd";
import { post, request } from "../../../network/request";
import CustomCard from "../../../components/common/card/card";
import "./studyOutbound.css";
import ExchangeDialog from "./changeModal/exchangeModal";
export default function StageOutbound(props) {
  /***************************状态变量UseState*********************************************/
  // const [reviewDataSource, setReviewDataSource] = useState([]);
  //状态table
  const [table, setTable] = useState([]);
  const [loading, setLoading] = useState(null);
  const [content, setContent] = useState();
  const [apply, setApply] = useState([]);
  const [projectData, setProData] = useState([]);
  //记录已选
  // const [selectedprojiect, setPreject] = useState({});
  const [applicationStatus, setApplicationStatus] = useState("");
  const [applyForFirst, setApplyForFirst] = useState("");
  const [exchangeModal, setExhangeModal] = useState(false);
  const selecedData = useRef({});
  // const [showData, setShowData] = useState();
  const [papareShow, setPapare] = useState({
    title: "",
    papare: [],
  });
  const [hostShow, setHostShow] = useState({
    title: "",
    hostInfo: [],
  });
  const [partShow, setPartShow] = useState({
    title: "",
    partInfo: [],
  });
  const [tmpConstruct, setTmpConstruct] = useState()
  const [construct, setConstruct] = useState()
  const [exchangeData, setExchange] = useState([]);
  /**************************组件变量******************************** */
  const headerCardOptions = { title: null, bordered: false };
  const columns = [
    {
      title: "是否提交出站申请",
      key: "1",
      align: "center",
      render: (text) => {
        if (text.applyForResearchFirst === 1) {
          return <span className="ec-text-color-green">{"是"}</span>;
        } else if (text.applyForResearchFirst === 0) {
          return <span className="ec-text-color-red">{"否"}</span>;
        }
      },
    },
    {
      title: "审核状态",
      // dataIndex: "applicationStatus",
      key: "2",
      align: "center",
      render: (text) => {
        if (text.applicationStatus == 3) {
          return <span className="ec-text-color-green">{"同意"}</span>;
        } else if (text.applicationStatus == 2) {
          return <span className="ec-text-color-red">{"拒绝"}</span>;
        } else if (text.applicationStatus == 1) {
          return <span className="ec-text-color-orange">{"待审核"}</span>;
        } else {
          return <span style={{ color: "black" }}>{"暂无"}</span>;
        }
      },
    },
  ];
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll" },
  };

  /************************接口******************************/
  // 二阶段出栈状态
  async function getApplicationInfo() {
    setLoading(true);
    await post(
      {
        url: "/ec/station/getApplicationZInfoByStudentSid",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: localStorage.studentSid,
        },
      },
      (data) => {
        console.log(data)
        setLoading(false);
        let arr = [];
        // 将对象传入数组
        arr.push(data.contents);
        console.log(arr)
        setTable(arr);
        setApplicationStatus(data.contents.applicationStatus);
        setApplyForFirst(data.contents.applyForResearchFirst);
      },
      props.history
    );
  }
  // 获取要求与项目数据（纵向）
  async function getProjectInfo() {
    console.log('获取要求与项目数据')
    await post(
      {
        url: "/ec/station/getAllProjectZByStudentSid",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: localStorage.studentSid,
          degreeType: JSON.parse(localStorage.userInfo).degreeType,
          entryGrade: JSON.parse(localStorage.userInfo).entryGrade,
        },
      },
      (data) => {
        console.log(data)
        setContent(data.contents);
      },
      props.history
    );
  }
  // 建设方案获取要求与项目数据（横向接口）
  async function getConstructInfo() {
    await post(
      {
        url: "/ec/station/getAllProjectByStudentSid",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: localStorage.studentSid,
          degreeType: JSON.parse(localStorage.userInfo).degreeType,
          entryGrade: JSON.parse(localStorage.userInfo).entryGrade,
        },
      },
      (data) => {
        // setTmpConstruct(data)
        console.log(data)
        let zConstruct = {
          project: [],
          request: []
        }
        // 提取横向建设方案项目信息
        for (let i = 0; i < data.contents.project.length; i++) {
          if (data.contents.project[i].outboundConditionSid == 3 ||
            data.contents.project[i].outboundConditionSid == 4) {
            zConstruct.project.push(data.contents.project[i]);
          }
        }
        // 提取横向项目建设方案出站要求信息
        for (let i = 0; i < data.contents.request.length; i++) {
          if (data.contents.request[i].outboundConditionSid == 3 ||
            data.contents.request[i].outboundConditionSid == 4) {
            zConstruct.request.push(data.contents.request[i]);
          }
        }
        setConstruct(zConstruct)
        console.log(construct)
      },
      props.history
    );
  }

  /************************************function***************************** */
  //是否显示子table信息
  const childTable = (len, childColumns, data) => {
    if (len === 0) return <div>暂无数据</div>;
    else
      return (
        <Table
          columns={childColumns}
          dataSource={data}
          rowKey="itemSid"
          pagination={false}
        />
      );
  };
  //选择子table要显示的信息
  const childTableColumn = (type) => {
    switch (type) {
      case 1:
        return [
          {
            title: "项目名称",
            dataIndex: "itemName",
            align: "center",
          },
          {
            title: "主持者",
            dataIndex: "managerName",
            align: "center",
          },
          {
            title: "参与者",
            dataIndex: "participants",
            align: "center",
          },
          {
            title: "开始时间",
            dataIndex: "insertTimestamp",
            align: "center",
            render: (text, record) => (
              <div>
                {new Date(record.insertTimestamp * 1000).toLocaleDateString()}
              </div>
            ),
          },
          {
            title: "结束时间",
            dataIndex: "endTime",
            align: "center",
            render: (text, record) => (
              <div>{new Date(record.endTime * 1000).toLocaleDateString()}</div>
            ),
          },
        ];
      case 2:
        return;
      default:
        break;
    }
  };
  //主页面数据预处理
  const handleUpstateData = () => {
    //  console.log(11)
    if (!content) return;
    let request = content.request;
    let project = content.project;
    console.log(project)
    console.log(request)
    if (typeof request === "undefined" && typeof project === "undefined")
      return;
    let templet = [];
    for (let i = 0; i < request.length; i++) {
      let item = request[i];
      let childColumns = childTableColumn(1);
      let data = [];
      let count = 0

      // request[i].conditionName.replace(/' '/g,"1")

      let arr = request[i].conditionName.split("\\n");
      console.log(arr)
      arr.map((item) => {
        return item === "\n" ? "<br>" : item
      }).join("")
      console.log(arr)
      if(arr[1]){
        request[i].conditionName = arr[0] + " " + arr[1] 
      }
      // request[i].conditionName.innerHTML = request[i].conditionName

      // var parser = new DOMParser();
      // var doc = parser.parseFromString(request[i].conditionName,"text/xml")
      
      // console.log(doc)

      // console.log(tmp)/*  */
      // console.log(first)
      for (let j = 0; j < project.length; j++) {
        if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          project[j].outboundConditionSid === 0
        ) {
          data.push(project[j]);
          if (project[j].ifChange === 1) {
            count++
          }
        } else if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          project[j].managerName === localStorage.studentName
        ) {
          data.push(project[j]);
          if (project[j].ifChange === 1) {
            count++
          }
        } else if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          typeof project[j].changeSid !== "undefined"
        ) {
          data.push(project[j]);
          if (project[j].ifChange === 1) {
            count++
          }
        }
      } 
      let temp = {
        requestCount: item.amount,
        outboundConditionSid: item.outboundConditionSid,
        columns: [
          {
            title: item.conditionName,
            align: "center",
            dataIndex: "describe",
            width: "40%",
          },
          {
            title: item.conditionName,
            align: "center",
            render: () => childTable(data.length, childColumns, data),
          },
        ],
        dataSource: [],
      };
      let dataSource = [];
      dataSource = [
        {
          key: "1",
          describe:
            "要求: " +
            item.amount +
            "项" +
            " 已完成：" +
            (data.length - count) +
            "/" +
            item.amount,
        },
      ];
      temp.dataSource = dataSource;
      templet.push(temp);
    }
    console.log(templet)
    setProData(templet);
    return templet;
  };
  //开启置换
  const handleExchange = () => {
    setExhangeModal(true);
    handleConstData()
  };
  //处理置换Modal的专利部分显示数据
  const handleShowData = () => {
    if (typeof content === "undefined") return;
    if (content.request.length === 0) return;

    let papare = [];
    let net = [];

    if (!content) {
      return;
    }
    for (let i = 0; i < content.project.length; i++) {
      let item = content.project[i];
      if (
        item.outboundConditionSid === 5 &&
        item.managerName === localStorage.studentName
      ) {
        papare.push(item);
      } else if (
        item.outboundConditionSid === 6 &&
        item.managerName === localStorage.studentName
      ) {
        net.push(item);
      } else if (
        item.outboundConditionSid === 5 &&
        typeof item.changeSid !== "undefined"
      ) {
        papare.push(item);
      } else if (
        item.outboundConditionSid === 6 &&
        typeof item.changeSid !== "undefined"
      ) {
        net.push(item);
      }
    }
    // 处理置换页面专利信息
    let papareCount = 0
    for (let i = 0; i < papare.length; i++) {
      if (papare[i].ifChange == 0) {
        papareCount++
      }
    }
    let papareData = {
      papare,
      title:
        content.request[4].conditionName +
        " 要求" +
        content.request[4].amount +
        " 已完成：" +
        papareCount +
        "/" +
        content.request[4].amount,
    };
    setPapare(papareData);
    // 处理置换页面建设方案信息
    if (typeof tmpConstruct === "undefined") return;
    let zConstruct = {
      project: [],
      request: []
    }
    // 提取横向建设方案项目信息
    for (let i = 0; i < tmpConstruct.contents.project.length; i++) {
      if (tmpConstruct.contents.project[i].outboundConditionSid == 3 ||
        tmpConstruct.contents.project[i].outboundConditionSid == 4) {
        zConstruct.project.push(tmpConstruct.contents.project[i]);
      }
    }
    // 提取横向项目建设方案出站要求信息
    for (let i = 0; i < tmpConstruct.contents.request.length; i++) {
      if (tmpConstruct.contents.request[i].outboundConditionSid == 3 ||
        tmpConstruct.contents.request[i].outboundConditionSid == 4) {
        zConstruct.request.push(tmpConstruct.contents.request[i]);
      }
    }
    // console.log(zConstruct) // 数据更新
    setConstruct(zConstruct)
    handleConstData()
    // console.log(construct) // 数据未更新
  };
  // 
  const handleConstData = () => {
    let hostInfo = []
    let partInfo = []
    if (typeof construct == "undefined") return
    for (let i = 0; i < construct.project.length; i++) {
        if (construct.project[i].outboundConditionSid == 3) {
          hostInfo.push(construct.project[i])
        } else {
          partInfo.push(construct.project[i])
        }
      // }
    }
    
    let hostData = {
      hostInfo,
      title:
        construct.request[0].conditionName +
        " 要求" +
        construct.request[0].amount +
        " 已完成：" +
        hostInfo.length +
        "/" +
        construct.request[0].amount,
    }
    let partData = {
      partInfo,
      title:
        construct.request[1].conditionName +
        " 要求" +
        construct.request[1].amount +
        " 已完成：" +
        partInfo.length +
        "/" +
        construct.request[1].amount,
    }
    setHostShow(hostData)
    setPartShow(partData)
    let exchangeData = [];
    for (let i = 0; i < construct.project.length; i++) {
      let item = construct.project[i];
      let temp = {};
      if (typeof item.changeSid !== "undefined") {
        temp.itemSid = item.itemSid
        temp.changeSid = item.changeSid;
        temp.constructName = item.itemName;
        if (!content) {
          return;
        }
        for (let i = 0; i < content.project.length; i++) {
          if (content.project[i].ifChange == 1) {
            if(content.project[i].changeSid == item.changeSid){
              temp.papareName = content.project[i].itemName
            }
          }
        }
        if(temp.papareName){
          exchangeData.push(temp);
        }
      }
    }
    setExchange(exchangeData);
  }
  //处理提交后的数据显示
  const handleSubmitedData = () => {
    if (!content) return;
    let request = content.request;
    let project = content.project;
    if (typeof request === "undefined" && typeof project === "undefined")
      return;
    let templet = [];

    for (let i = 0; i < request.length; i++) {
      let item = request[i];
      let childColumns = childTableColumn(1);
      let data = [];
      let count = 0
      for (let j = 0; j < project.length; j++) {
        if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          project[j].outboundConditionSid === 0

        ) {
          if (project[j].ifChange === 1) {
            count++
          }
          data.push(project[j]);
        } else if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          project[j].managerName === localStorage.studentName

        ) {
          data.push(project[j]);
          if (project[j].ifChange === 1) {
            count++
          }
        } else if (
          project[j].outboundConditionSid === item.outboundConditionSid &&
          typeof project[j].changeSid !== "undefined"
        ) {
          data.push(project[j]);
          if (project[j].ifChange === 1) {
            count++
          }
        }
      }
      let title =
        item.conditionName +
        "要求: " +
        item.amount +
        "项" +
        " 已完成：" +
        (data.length - count) +
        "/" +
        item.amount;
      templet.push({
        title,
        data,
        childColumns,
      });
    }
    setApply(templet)
  };
  function submitApply() {
    props.history.push('/printApplicationV');
  }
  /*****************useCallBack*********************** */
  //关闭置换
  const handleConcelModal = useCallback(() => {
    setExhangeModal(false);
  }, [exchangeModal]);
  const handleUpdata = useCallback(async () => {

    await post(
      {
        url: "/ec/station/getAllProjectByStudentSid",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: localStorage.studentSid,
          degreeType: JSON.parse(localStorage.userInfo).degreeType,
          entryGrade: JSON.parse(localStorage.userInfo).entryGrade,
        },
      },
      (data) => {
        setTmpConstruct(data)

      },
      props.history
    );

    await post(
      {
        url: "/ec/station/getAllProjectZByStudentSid",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: localStorage.studentSid,
          degreeType: JSON.parse(localStorage.userInfo).degreeType,
          entryGrade: JSON.parse(localStorage.userInfo).entryGrade,
        },
      },
      (data) => {
        // console.log(data.contents)
        setContent(data.contents);
      },
      props.history
    );
  }, [exchangeModal]);
  /*****************************useMemo************************************ */
  // 处理状态表单数据
  // const table = useMemo(() => {
  //   console.log(reviewDataSource)
  //   reviewDataSource.forEach((item) => {
  //     console.log(item)
  //     switch (item.applicationStatus) {
  //       case 1:
  //         item.applicationStatus = "待审核";
  //         break;
  //       case 2:
  //         item.applicationStatus = "拒绝";
  //         break;
  //       case 3:
  //         item.applicationStatus = "同意";
  //         break;
  //       default:
  //         item.applicationStatus = "暂无";
  //     }
  //     switch (item.applyForFirst) {
  //       case '1':
  //         item.applyForFirst = "是";
  //         break;
  //       default:
  //         item.applyForFirst = "否";
  //     }
  //     // 修改状态
  //     setApplicationStatus(item.applicationStatus);
  //     setApplyForFirst(item.applyForFirst);
  //   });
  //   console.log(reviewDataSource)
  //   return reviewDataSource;
  // }, [reviewDataSource]);

  //深拷贝
  // function extend(source) {
  //   var target;
  //   if (typeof source === "object") {
  //     target = Array.isArray(source) ? [] : {};
  //     for (var key in source) {
  //       if (source.hasOwnProperty(key)) {
  //         if (typeof source[key] !== "object") {
  //           target[key] = source[key];
  //         } else {
  //           target[key] = extend(source[key]);
  //         }
  //       }
  //     }
  //   } else {
  //     target = source;
  //   }
  //   return target;
  // }

  // const rowSelection = {
  //   getCheckboxProps: (record) => ({
  //     disabled: record.ifChange === 1, // 配置无法勾选的列
  //   }),
  //   onSelect(record, selected, selectedRows, nativeEvent) {
  //     let selectedPro = extend(selecedData.current);
  //     if (selected) {
  //       if (typeof selectedPro[record.outboundConditionSid] === "undefined") {
  //         selectedPro[record.outboundConditionSid] = [];
  //       }
  //       selectedPro[record.outboundConditionSid].push(record);
  //     } else {
  //       for (
  //         let i = 0;
  //         i < selectedPro[record.outboundConditionSid].length;
  //         i++
  //       ) {
  //         if (
  //           selectedPro[record.outboundConditionSid][i].itemSid ===
  //           record.itemSid
  //         )
  //           selectedPro[record.outboundConditionSid].splice(i, i + 1);
  //       }
  //     }
  //     selecedData.current = selectedPro;
  //     handleUpstateData();
  //   },
  //   onSelectAll(selected, selectedRows, changeRows) {
  //     let selectedPro = extend(selecedData.current);
  //     if (selected) {
  //       if (
  //         typeof selectedPro[changeRows[0].outboundConditionSid] === "undefined"
  //       ) {
  //         selectedPro[changeRows[0].outboundConditionSid] = [];
  //       }
  //       for (let i = 0; i < changeRows.length; i++) {
  //         selectedPro[changeRows[0].outboundConditionSid].push(changeRows[i]);
  //       }
  //       console.log(selectedPro[changeRows[0].outboundConditionSid]);
  //     } else {
  //       selectedPro[changeRows[0].outboundConditionSid] = [];
  //     }
  //     selecedData.current = selectedPro;
  //     handleUpstateData();
  //   },
  // };

  const submit = async () => {
    await post(
      {
        url: "/ec/station/addApplicationZInfo",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: localStorage.studentSid,
        },
      },
      (data) => {
        if (data.code === "N01") {
          notification.success({
            description: "提交申请书成功！",
            message: "通知",
            duration: 1,
            onClose: () => {
              getApplicationInfo().then(() => {
              });
            },
          });
        }
      },
      props.history
    );
    setApplyForFirst(1);
  };

  //置换Modal
  const modalChange = useMemo(() => {
    return (
      <ExchangeDialog
        cRef={exchangeModal}
        concelForModal={handleConcelModal}
        papare={papareShow}
        host={hostShow}
        part={partShow}
        exchange={exchangeData}
        updata={handleUpdata}
      />
    );
  }, [
    exchangeModal,
    papareShow,
    exchangeData,
    handleUpdata,
    handleConcelModal
  ]);

  /*****************************useEffect*************************************** */
  useEffect(() => {
    getApplicationInfo().then(() => {
      getProjectInfo();
      getConstructInfo();
    });
  }, []);
  useEffect(() => {
    if (applyForFirst === 0) { // 未提交
      // 主页面数据处理
      handleUpstateData();
      // 处理置换Modal的专利部分显示数据
      handleShowData();
    } else { // 已提交
      handleSubmitedData()
    }
  }, [content]);

  /* 数据更新 */
  useEffect(() => {
    handleConstData();
  }, [construct])


  //置换数据
  /**********************************useBack*************************************/
  return (
    <>
      <CustomCard options={headerCardOptions} className="outbound">
        <Table
          // rowKey=""
          className="table"
          dataSource={table}
          columns={columns}
          loading={loading}
          pagination={false}
          style={{ margin: "0 auto", width: "55%" }}
        />
      </CustomCard>
      <CustomCard options={tableCardOptions}>
        <div
          style={
            applyForFirst === 0 ? { display: "block" } : { display: "none" }
          }
        >
          <div className="section">
            <h3 className="headTitle">待提交出站申请</h3>
          </div> 
          <div className="btnChange">
            <Button type="primary" onClick={handleExchange}>
              置换
            </Button>
          </div>
          <List
            dataSource={projectData}
            renderItem={(item) => {
              return (
                <List.Item className="listItem" >
                  <Table
                    // key="outboundConditionSid"
                    rowKey="outboundConditionSid"
                    className="contentTable"
                    columns={item.columns}
                    dataSource={item.dataSource}
                    pagination={false}
                    // dangerouslySetInnerHTML={{ __html: projectData }}
                  />
                </List.Item>
              );
            }}
          />
          <div className="btn">
            <Button type="primary" style={{ width: "100px" }} onClick={submit}>
              提交
            </Button>
          </div>
        </div>

        <div
          className="sectionContainer"
          style={
            applyForFirst === 1 ? { display: "block" } : { display: "none" }
          }
        >
          <Button
            type="primary"
            style={{ width: "100px" }}
            onClick={submitApply}
          >
            下载申请书
          </Button>
          <div className="section">
            <h3 className="headTitle">已提交出站申请</h3>
          </div>
          <div className="lookApplyBookModal">
            <div>
              <List
                dataSource={apply}
                renderItem={(item) => {
                  // console.log(item);
                  return (
                    <List.Item>
                      <Card
                        title={
                          item.title
                        }
                      >
                        <Table
                          rowKey="itemSid"
                          className="listData"
                          style={
                            item.data.length != 0
                              ? { display: "block" }
                              : { display: "none" }
                          }
                          columns={item.childColumns}
                          dataSource={item.data}
                          pagination={false}
                        />
                        <div
                          className="noData"
                          style={
                            item.data.length != 0
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          暂无内容
                        </div>
                      </Card>
                    </List.Item>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </CustomCard>
      {modalChange}
    </>
  );
}
