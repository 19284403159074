import React, { useState } from "react";
import {
  Table,
  Button,
  Modal,
  message,
  Card,
} from "antd";
import { post } from "../../../../network/request";
import './exchangeModal.css'
// import moment from "moment";
// import "../studyOutbound.css";
export default function ExchangeDialog(props) {
  /******************useState****************** */
  // console.log(111)
  console.log(props)
  const [exchangePapare, setPapare] = useState([]);
  const [exchangeNet, setNet] = useState([])
  //组件参数
  const columns = [
    {

      title: "项目名称",
      dataIndex: "itemName",
      width: 100,
      align: "center",
    },
    {
      title: "主持者",
      dataIndex: "managerName",
      width: 80,
      align: "center",
    },
    {
      title: "参与者",
      dataIndex: "participants",
      width: 80,
      align: "center",
    },
    {
      title: "开始时间",
      dataIndex: "conditionName",
      width: 80,
      align: "center",
      render: (text, record) => (
        <div>
          {/* {console.log(record)} */}
          {new Date(record.insertTimestamp * 1000).toLocaleDateString()}
        </div>
      ),
    },
    {
      title: "结束时间",
      dataIndex: "conditionName",
      width: 80,
      align: "center",
      render: (text, record) => (
        <div>{new Date(record.endTime * 1000).toLocaleDateString()}</div>
      ),
    },
  ];
  const exchangeCol = [
    {
      title: "置换所得建设方案",
      dataIndex: "constructName",
      width: 80,
      align: "center",
    },
    {
      title: "发明专利",
      dataIndex: "papareName",
      width: 80,
      align: "center",
    },
    {
      title: "操作",
      width: 80,
      align: "center",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            danger
            size="small"
            onClick={() => concelExchange(record.changeSid)}
          >
            取消
          </Button>
        );
      },
    },
  ];
  const rowSelectPapare = {
    selectedRowKeys: exchangePapare,
    getCheckboxProps: (record) => ({
      // disabled: typeof record.changeSid != "undefined", // 配置无法勾选的列
      disabled: record.ifChange == 1 ? true:false, // 配置无法勾选的列
      
    }),
    onChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys)
      console.log(selectedRows)

      setPapare(selectedRowKeys);
    },
  };
  const rowSelectNet = {
    selectedRowKeys: exchangeNet,
    getCheckboxProps: (record) => ({
      // disabled: typeof record.changeSid != "undefined", // 配置无法勾选的列
      disabled: true, // 配置无法勾选的列
    }),
    onChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys);
      setNet(selectedRowKeys)
    },
  };
  /************************接口******************** */
  const concelExchange = async (id) => {
    await post(
      {
        url: "/ec/station/cancelChangeProject",
        data: {
          sessionId: localStorage.sessionId,
          changeSid: id,
        },
      },
      (data) => {
        if (data.code === "N01") {
          console.log(data)          
          props.updata();
        }
      },
      props.history
    );
  };
  const exchange = async (changeType, itemSid) => {
    await post(
      {
        url: "/ec/station/addChangeProject",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: parseInt(localStorage.studentSid),
          itemSid,
          changeType,
          projectType: 2,
        },
      },
      (data) => {
        if (data.code === "N01") {
          props.updata()
        }
      },
      props.history
    );
  };
  /******************function******************* */
  const handleExchange = (type) => {
    console.log(exchangePapare)
    if (exchangePapare.length === 0) {
      message.error("请选择有效的置换项目");
      return;
    }
    exchange(type, exchangePapare[exchangePapare.length - 1].toString());
  };
  return (
    <Modal
      title="置换项目"
      className='titleCenter'
      visible={props.cRef}
      onCancel={props.concelForModal}
      wrapClassName={'myself'}
      footer={[
        <div className="btnCenter">
          <Button  onClick={props.concelForModal}>
            关闭
          </Button>
        </div>
      ]}
    >
      {/* 专利模块 */}
      <div className="lookApplyBook">
        <Card title={props.papare.title}>
          <Table
            rowKey="itemSid"
            columns={columns}
            dataSource={props.papare.papare}
            pagination={false}
            scroll={{ x: 100 }}
            rowSelection={{ ...rowSelectPapare }}
          />
          <div className="btnCenter">
            <Button
              type="primary"
              onClick={() => {
                handleExchange(2);
              }}
            >
              置换至主持
            </Button>
            &ensp; &ensp; &ensp;
            <Button
              type="primary"
              onClick={() => {
                handleExchange(1);
              }}
            >
              置换至参与
            </Button>
          </div>
        </Card>
      </div>
      {/* 主持建设方案模块 */}
      <div className="lookApplyBook">
        <Card title={props.host.title}>
          <Table
            rowKey="itemSid"
            columns={columns}
            dataSource={props.host.hostInfo}
            pagination={false}
            scroll={{ x: 100 }}
            rowSelection={{ ...rowSelectNet }}
          />
        </Card>
      </div>
      {/* 参与建设方案模块 */}
      <div className="lookApplyBook">
        <Card title={props.part.title}>
          <Table
            rowKey="itemSid"
            columns={columns}
            dataSource={props.part.partInfo}
            pagination={false}
            scroll={{ x: 100 }}
            rowSelection={{ ...rowSelectNet }}
          />
        </Card>
      </div>
      <div className="lookApplyBook">
        <Card title="已置换建设方案">
          {/* {console.log(props.exchange)} */}
          <Table
            rowKey="itemSid"
            columns={exchangeCol}
            dataSource={props.exchange}
            pagination={false}
            scroll={{ x: 100 }}
          />
        </Card>
      </div>
    </Modal>
  );
}
