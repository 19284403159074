import React, { useState, useEffect } from "react";
import {
  Popconfirm,
  Button,
  Table,
  Space,
} from "antd";

import "./ShowTable.css";

export default function ShowTable(props) {
  console.log(props)

  const columns = [
    {
      title: "姓名",
      dataIndex: "studentName",
      key: "studentName",
      align: "center",
    },
    {
      title: "年级",
      dataIndex: "entryGrade",
      key: "entryGrade",
      align: "center",
    },
    {
      title: "学硕/专硕",
      dataIndex: "degreeType",
      key: "degreeType",
      align: "center",
    },
    {
      title: "导师",
      dataIndex: "lastMentor",
      key: "lastMentor",
      align: "center",
    },
    {
      title: "EC指导老师",
      dataIndex: "teacherCounselor",
      key: "teacherCounselor",
      align: "center",
    },
    {
      title: "提交申请状态",
      key: "applyForFirst",
      align: "center",
      render: (text) => {
        console.log(text)
        if(text.applyForResearchFirst === 1){
          return <span className="ec-text-color-green">已提交</span>;
        }else if(text.applyForResearchFirst === 0){
          return <span className="ec-text-color-green">未提交</span>;
        }else{
          return <span className="ec-text-color-green">{text.applyForResearchFirst}</span>;
        }
        // if (text.applyForFirst === "已提交") {
        //   return <span className="ec-text-color-green">{text.applyForFirst}</span>;
        // } else if (text.applyForFirst === "未提交") {
        //   return <span className="ec-text-color-red">{text.applyForFirst}</span>;
        // } else {
        //   return <span>{text.applyForFirst}</span>;
        // }
      },
    },
    {
      title: "审核状态",
      key: "applicationStatus",
      align: "center",
      render: (text) => {
        if (text.applicationStatus === "已通过") {
          return (
            <span className="ec-text-color-green">{text.applicationStatus}</span>
          );
        } else if (text.applicationStatus === "已拒绝") {
          return <span className="ec-text-color-red">{text.applicationStatus}</span>;
        } else {
          return <span>{text.applicationStatus}</span>;
        }
      },
    },
    {
      title: "查看信息",
      key: "acceptState",
      align: "center",
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            size="small"
            disabled={text.applyForFirst !== "已提交"}
            onClick={props.handleOutboundApplication.bind(null, text)}
          >
            查看出站申请
          </Button>
          {/* <Button type="primary" size="small" onClick={props.handleCheckHandover.bind(null, text)}>
            查看交接情况
          </Button> */}
        </Space>
      ),
    },
    {
      title: "审核操作",
      key: "userName",
      align: "center",
      render: (text, record) => (
        <Space>
          <Popconfirm
            title="确定同意该生出站吗？"
            okText="Yes"
            cancelText="Cancel"
            onConfirm={props.handleStatueUpdate.bind(null, 'accept', text)}
            disabled={text.applyForFirst !== "已提交"&&text.applyForFirst!=='已拒绝'&&text.applyForFirst!=='已通过'}
          >
            <Button
              type="primary"
              size="small"
              className="ec-button-green"
              disabled={text.applyForFirst !== "已提交"&&text.applyForFirst!=='已拒绝'&&text.applyForFirst!=='已通过'}
            >
              同意出站
            </Button>
          </Popconfirm>
          <Popconfirm
            title="确定拒绝该生出站吗？"
            okText="Yes"
            cancelText="Cancel"
            onConfirm={props.handleStatueUpdate.bind(null, 'reject', text)}
            disabled={text.applyForFirst !== "已提交"&&text.applyForFirst!=='已拒绝'&&text.applyForFirst!=='已通过'}
          >
            <Button
              type="primary"
              size="small"
              danger
              disabled={text.applyForFirst !== "已提交"&&text.applyForFirst!=='已拒绝'&&text.applyForFirst!=='已通过'}
            >
              拒绝出站
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      className="table"
      rowKey="studentNo"
      columns={columns}
      dataSource={props.table}
      loading={props.loading}
      pagination={{
        total: props.total,
        current: props.pageInfo.currIndex,
        pageSize: props.pageInfo.pageSize,
        pageSizeOptions: [10, 20, 30],
        onChange: props.handlePageChange,
        showSizeChanger: true,
      }}
    />
  );
}
