/*
 * @Author: BaiXin 
 * @Date: 2021-10-09 09:32:49 
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-06-10 18:07:16
 */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
    Row,
    Col,
    Form,
    Button,
    Select,
    Table,
    Popconfirm,
    Upload,
    message,
    Modal,
    notification,
    Image,
} from "antd";
import { post, request } from "../../../network/request";
import AddDialog from './addDialog/addDialog'
import CustomCard from "../../../components/common/card/card";
import "./paperManage.css"
import { UploadOutlined } from "@ant-design/icons";
export default function PaperManage(props) {
    //布局参数
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 20 },
    };
    //日期格式
    const dateFormat = "YYYY/MM/DD";
    //header筛选框传值
    const headerCardOptions = { title: null, bordered: false };
    //查找form表单对象
    const [searchForm] = Form.useForm();

    const uploadprop = {
        beforeUpload: file => {
            if (file.type !== 'image/png/jpg') {
                message.error(`${file.name} 请上传图片`);
            }
            return file.type === 'image/png/jpg ' ? true : Upload.LIST_IGNORE
        },
        onChange: info => {
            console.log(info.fileList);
        },
    };
    /*******************useState ********************/
    //搜索信息
    const [searchData, setSearch] = useState({
        itemType: 4,
        roleSid: parseInt(localStorage.roleSid),
        pageSize: 10,
        index: 1,
        studentSid: parseInt(localStorage.studentSid),
        itemStatus: '',
        managerName: ''
    })
    //load
    const [loading, setLoading] = useState(null);
    const [urlLoading, setUrlLoading] = useState(null);
    //全部数目
    const [total, setTotal] = useState();
    //table数据
    const [tableData, setTableData] = useState([])
    //图片table数据
    const [fileTable, setFileTable] = useState([]);
    //dialog显示
    const [addDialogVisible, setAddDialog] = useState(false)
    // const [editDialog, setEditDialog] = useState(false)
    //所有学生信息
    const [allStudents, setStudents] = useState([])
    //add 还是 eitd
    const [type, setType] = useState('添加')
    //设置初始信息
    const [studentInfo, setStuInfo] = useState('')
    //上传图片Dialog
    const [uploadDialog, setUploadDialog] = useState({ visible: false });
    //以装载图片
    const [uploadFileList, setuploadFileList] = useState([]);
    //查看要上传的图片
    const [previewInfo, setPreviewInfo] = useState({
        priviewVisible: false,
        priviewImage: '',
    });
    //选中的论文
    const [selectItemId, setSelectItemId] = useState(1);
    //控制查看图片
    const [fileDialog, setFileDialog] = useState({ visible: false });
    //查看固定图片
    const [pictureDialog, setPictureDialog] = useState({ visible: false });
    const [pictureContent, setPictureContent] = useState({});
    const [fileContent, setFileContent] = useState({});
    //要查看的图片论文ID
    const [itemSid, setItemSid] = useState("");

    /*****************普通方法区********************/
    //换页
    const handlePageChange = (page, pageSize) => {
        console.log(page)
        if (page !== searchData.index) {
            let index = page
            setSearch({ ...searchData, index })
            console.log(searchData.index, "nihao")
        }
        if (pageSize != searchData.pageSize) {
            setSearch({ ...searchData, pageSize })
        }
    }
    //重置搜索表
    const handleReset = () => {
        searchForm.resetFields()

    }
    //搜索
    const handleSearch = (values) => {
        let data = { ...searchData, ...values }
        console.log(data)
        getTableInfo(data)
    }
    //开启addDialog
    const handleClickAddBtn = () => {
        setAddDialog(true)
        setType("添加")
    }
    //关闭添加
    const handleCloseDialog = () => {
        setAddDialog(false)
    }
    //给子组件传递刷新
    const handleGetIndo = () => {
        getTableInfo(searchData)
    }
    //开启上传图片
    function handleUploadFile(data) {
        console.log(data)
        setSelectItemId(data.itemSid)
        setuploadFileList([])
        setUploadDialog({
            visible: true,
        });
    }
    //取消上传图片
    function handleCancelUpload() {
        setUploadDialog({
            visible: false,
        });
    }

    //确定上传图片
    async function handleConfirmUpload() {
        deletePreview();
        let list = [...uploadFileList];
        if (list != []) {
            list.forEach((item) => {
                console.log(item);
                upLoad(item);
            });
        } else {

        }
    }
    function deletePreview() {
        setPreviewInfo({
            priviewVisible: false,
            priviewImage: '',
        });
    }
    function stopUpload(file, filelist) {
        let oldList = [...uploadFileList];
        oldList.push(file);
        setuploadFileList(oldList);
        return new Promise((resolve, reject) => {
            return reject(false);
        });
    }
    //开启修改页面
    const handleDataInit = (info) => {
        setAddDialog(true)
        setStuInfo(info)
        setType('修改')
    }
    //查看固定图片
    function handleClickPicture(data) {
        // setPictureDialog({
        //     visible: true,
        // });
        // setPictureContent(data)
        getPictureData(data.itemSid, data.fileSid);
    }
    //开启图片查看
    function handleClickFile(data) {
        setFileDialog({
            visible: true,
        });
        // console.log(data.itemSid);
        setItemSid(data.itemSid);
        getFileData(data.itemSid);
        setFileContent(data);
    }

    /**********************接口****************************/
    //获取展示信息
    const getTableInfo = async (data) => {
        await post({
            url: "/ec/project/getProjectInfoByWriteItemType",
            data: {
                sessionId: localStorage.sessionId,
                ...data
            },
        }, (data) => {
            if (data.code === "N01") {
                setTableData(data.contents.list)
                setTotal(data.contents.size)
                // console.log(data.contents.list)

            }
            else if (data.code === 'E02') {
                setTableData([])
                setTotal(0)
            }
            setLoading(false)
        })
    }
    //获取全部学生信息 
    const getStudent = async () => {
        await post({
            url: "/ec/project/getAllStudentInfoOfInBound",
            data: {
                sessionId: localStorage.sessionId,
            },
        }, (data) => {
            if (data.code === "N01") {
                setStudents(data.contents)

            }
        })
    }
    //获取固定图片
    async function getPictureData(itemSid, fileSid) {
        await post(
            {
                url: "/ec/outResearchProject/downloadResearchFile",
                data: {
                    sessionId: localStorage.sessionId,
                    flag: 1,
                    itemSid: itemSid,
                    fileSid: fileSid,
                },
            },
            (data) => {
                // setPictureContent(data.contents);
                console.log(data)
                if ((data.code = "N01")) {
                let url = data.contents;
                let a = document.createElement("a");
                let event = new MouseEvent("click");
                // a.download = "file";
                a.target = "_blank";
                a.href = url;
                a.dispatchEvent(event);
                }
            },
            props.history
        );
    }
    //上传图片
    async function upLoad(item) {
        let data = {
            sessionId: localStorage.sessionId,
            itemSid: selectItemId,
            flag: 1,
            file: item,
            updateTime: parseInt(new Date().getTime() / 1000),
        };
        let formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });
        await post(
            {
                url: "/ec/outResearchProject/upLoadResearchFile",
                data: formData,
            },
            () => {
                notification.success({ description: "通知", message: "上传图片成功!" });
                // getFileList();
                handleCancelUpload();
            },
            props.history
        );
    }
    //获取图片info
    async function getFileData(itemSid) {
        setUrlLoading(true);
        await post(
            {
                url: "/ec/outResearchProject/getResearchFileInfo",
                data: {
                    sessionId: localStorage.sessionId,
                    flag: 1,
                    itemSid: itemSid,
                },
            },
            (data) => {
                // console.log(data.code);
                if (data.code == "E02") {
                    setFileTable([]);
                    setUrlLoading(false);
                } else {
                    setUrlLoading(false);
                    setFileTable(data.contents);
                }
            },
            props.history
        );
    }
    //删除固定图片
    async function deleteFile(record) {
        await post(
            {
                url: "/ec/outResearchProject/deleteResearchFile",
                data: {
                    sessionId: localStorage.sessionId,
                    fileSid: record.fileSid,
                    itemSid: record.itemSid,
                    flag: 1,
                },
            },
            () => {
                notification.success({ description: "通知", message: "删除图片成功!" });
                // getFileList();
                // setItemSid(data.itemSid);
                getFileData(record.itemSid);
                setFileContent(record);
            },
            props.history
        );
    }
    // 删除论文
    async function deleteItem(data) {
        await post({
            url: '/ec/project/deleteProjectWriteInfo',
            data: {
                sessionId: localStorage.sessionId,
                itemSid: data.itemSid,
                roleSid: localStorage.roleSid,
            }
        }, () => {
            notification.success({ description: '删除成功!', message: '通知' });
            getTableInfo(searchData)
        }, props.history)
    }
    /********************组件参数**************************/
    const uploadprops = {
        // listType: "picture",
        name: "file",
        action: "/ec/project/upLoadFile",
        headers: {
            authorization: "authorization-text",
        },
        onPreview: (file) => {
            setPreviewInfo({
                priviewImage: file.url,
                priviewVisible: true,
            });
        },
        beforeUpload: stopUpload,
        fileList: uploadFileList,
        onChange(info) {
            // console.log("info", info);
            if (info.file.status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === "done") {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === "error") {
                message.error(`${info.file.name} file upload failed.`);
            }
            setuploadFileList(info.fileList);
        },
    };
    const columns = [
        {
            title: "论文编号",
            dataIndex: "itemNumber",
            key: "itemNumber",
            align: "center",
        },
        {
            title: "论文名称",
            dataIndex: "itemName",
            key: "itemName",
            align: "center",
        },
        {
            title: "作者",
            dataIndex: "managerName",
            key: "managerName",
            align: "center",
        },
        // {
        //     title: "参与者",
        //     dataIndex: "participants",
        //     key: "participants",
        //     align: "center",
        // },
        {
            title: "申请日期",
            dataIndex: "insertTimestamp",
            key: "insertTimestamp",
            align: "center",
            render: (text) => {
                return <span >{new Date(text * 1000).toLocaleDateString()}</span>;
            }
        },
        {
            title: "管理员审核状态",
            key: "itemStatus",
            align: "center",
            render: (text) => {
                // console.log(text)
                if (text.itemStatus == "2") {
                    return <span className="ec-text-color-green">{"已同意"}</span>;
                } else if (text.itemStatus == "3") {
                    return <span className="ec-text-color-red">{"已拒绝"}</span>;
                } else if (text.itemStatus == "1") {
                    return <span className="ec-text-color-orange">{"待审核"}</span>;
                }
            },
        },
        {
            title: "处理理由",
            dataIndex: "note",
            key: "note",
            align: "center",
        },
        {
            title: "论文状态",
            dataIndex: "statusDescription",
            key: "statusDescription",
            align: "center",
        },
        {
            title: "操作",
            key: "operate",
            align: "center",
            render: (text, record) => (
                <>
                    <Button
                        className="operate-button ec-button-lightBlue"
                        // type="primary"
                        size="small"
                        onClick={handleUploadFile.bind(null, text)}
                        style={{ backgroundColor: "#5BC0DE" }}
                    >
                        上传文件
                    </Button>
                    <Button
                        // type="primary"
                        size="small"
                        className="lookPicture ec-button-lightBlue"
                        onClick={handleClickFile.bind(null, text)}
                    >
                        查看文件
                    </Button>
                    <Button
                        // type="primary"
                        size="small"
                        className="operate-button ec-button-green"
                        onClick={handleDataInit.bind(null, text)}
                    >
                        修改信息
                    </Button>
                    <Popconfirm
                        className="delete"
                        placement="bottomRight"
                        title="确认删除吗？"
                        onConfirm={deleteItem.bind(null, text)}
                        okText="确定"
                        cancelText="取消"
                    >
                        <Button
                            className="operate-button"
                            type="danger"
                            size="small"
                        >
                            删除
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];
    //查看图片Table
    const fileColumns = [
        {
            title: "文件名称",
            dataIndex: "filename",
            align: "center",
        },
        {
            title: "操作",
            key: "operate",
            align: "center",
            render: (text, record) => (
                <>
                    <Button
                        type="primary"
                        size="small"
                        onClick={handleClickPicture.bind(null, text)}
                    >
                        获取文件
                    </Button>

                    <Button
                        type="danger"
                        size="small"
                        style={{ marginLeft: '10px' }}
                        onClick={deleteFile.bind(null, text)}
                    >
                        删除
                    </Button>
                </>
            ),
        },
    ];
    /********************useCallBack ********************/
    const addClose = useCallback(
        () => {
            handleCloseDialog();
        },
        [],
    )
    /*******************useEffect************************/
    useEffect(() => {
        getTableInfo(searchData)
    }, [searchData.pageSize, searchData.index])
    useEffect(() => {
        getStudent()
    }, [])
    /****************useMemo******************/
    //添加表单
    const addDialog = useMemo(() => {
        return (<AddDialog visible={addDialogVisible} allStudents={allStudents} closeAddForm={addClose} getInfo={handleGetIndo} type={type} text={studentInfo} />)
    }, [addDialogVisible, addClose])

    return (
        <>
            <CustomCard options={headerCardOptions}>
                <Form
                    form={searchForm}
                    // name="horizontal_login"
                    layout="inline"
                    style={{ flexDirection: "column" }}
                    // {...layout}
                    onFinish={handleSearch.bind(null)}
                >
                    <Row>
                        <Col span={6} offset={1}>
                            <Form.Item label="管理员审核状态" name="itemStatus">
                                <Select
                                    style={{ width: "80%" }}
                                    placeholder="--请选择--"
                                    name="itemStatus"
                                    allowClear
                                >
                                    <Select.Option value={1} key="1">
                                        待审核
                                    </Select.Option>
                                    <Select.Option value={2} key="2">
                                        已通过
                                    </Select.Option>
                                    <Select.Option value={3} key="3">
                                        已拒绝
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col >
                            <Button
                                type="primary"
                                htmlType="submit"
                            >
                                筛选
                            </Button>
                        </Col>
                        <Col offset={1}>
                            <Button type="plain" onClick={handleReset.bind(null)}>重置</Button>
                        </Col>
                    </Row>
                </Form>
            </CustomCard>
            <CustomCard options={headerCardOptions}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
                        <Button type="primary" htmlType="submit" onClick={handleClickAddBtn}>新增论文</Button>
                    </div>
                    <Table
                        className="table"
                        rowKey="itemNumber"
                        columns={columns}
                        dataSource={tableData}
                        loading={loading}
                        pagination={{
                            total: total,
                            current: searchData.index,
                            pageSize: searchData.pageSize,
                            pageSizeOptions: [10, 20, 30],
                            onChange: handlePageChange,
                            showSizeChanger: true,
                        }}
                    />
                </div>
            </CustomCard>
            {/* 添加表单 */}
            {addDialog}
            {/* 上传图片 */}
            <Modal
                title="上传文件"
                visible={uploadDialog.visible}
                onCancel={handleCancelUpload}
                footer={[
                    <div>
                        <Button type="primary" onClick={handleConfirmUpload}>
                            确认上传
                        </Button>
                        <Button onClick={handleCancelUpload}>关闭</Button>
                    </div>,
                ]}
            >
                <div className="clearfix">
                    {/* <Upload {...uploadprops}>
                        <Button icon={<UploadOutlined />}>选择图片</Button>
                    </Upload> */}
                    <Upload {...uploadprops}>
                        <Button type="primary">选择文件</Button>
                    </Upload>
                    <Modal visible={previewInfo.priviewVisible} footer={null} onCancel={() => { setPreviewInfo({ priviewVisible: false }) }}>
                        <img alt="example" src={previewInfo.priviewImage} />
                    </Modal>
                </div>
            </Modal>
            {/* 查看图片 */}
            <Modal
                title="文件列表"
                visible={fileDialog.visible}
                onCancel={() => {
                    setFileDialog({
                        visible: false,
                    });
                }}
                footer={[
                    <Button key="back" onClick={() => {
                        setFileDialog({
                            visible: false,
                        });
                    }}>
                        关闭
                    </Button>,
                ]}
            >
                <Table
                    className="pictureTable"
                    rowKey="fileSid"
                    columns={fileColumns}
                    dataSource={fileTable}
                    loading={urlLoading}
                    pagination={false}
                ></Table>
            </Modal>
            {/* 查看特定图片 */}
            <Modal
                title="查看图片"
                className="pictureList"
                visible={pictureDialog.visible}
                onCancel={() => {
                    setPictureDialog({ visible: false })
                }}
                footer={[
                    <Button key="back" onClick={() => {
                        setPictureDialog({ visible: false })
                    }}>
                        关闭
                    </Button>,
                ]}
            >
                <div style={{ width: "100%", margin: "0 auto", textAlign: "center" }}>
                    <Image
                        width={200}
                        height={200}
                        src={pictureContent}
                    />
                </div>
            </Modal>
        </>
    )
}