/*
 * @Author: ZhouShan
 * @Date: 2020-11-05 20:17:45
 * @Last Modified by: HouQi
 * @Last Modified time: 2021-12-24 10:28:48
 */
import React, { useState, useEffect, useImperativeHandle } from "react";
import {
  Popconfirm,
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Table,
  Space,
  notification,
  Modal,
} from "antd";
import { post } from "../../../../network/request";
import { useHistory } from "react-router-dom";

import CustomCard from "../../../../components/common/card/card";

import "./filter.css";

const { Option } = Select;

export default function Filter(props) {
  const headerCardOptions = { title: null, bordered: false };
  const [filterForm] = Form.useForm();
  const history = useHistory();

  /*************state*************/
  const [entryGrade, setEntryGrade] = useState([]);
  const [mentorList, setmentorList] = useState([]);

  /*************effect*************/
  useEffect(() => {
    //初始化筛选年份
    // function genEntryGrade() {
    //   const date = new Date().getFullYear();
    //   let entryGrade = [];
    //   for (let i = date + 2; i >= 2017; i--) entryGrade.push(i);
    //   setEntryGrade(entryGrade);
    // }
    //初始化筛选年份
    async function genEntryGrade() {
      await post(
        {
          url: "/ec/project/getOutStationGrade",
          data: {
            sessionId: localStorage.sessionId,
            inboundState: 2
          },
        },
        (data) => {
          if (data.code == 'N01') {
            let allGrade = data.contents.allGrade
            console.log(allGrade)
            for (let i = 0; i < allGrade.length; i++) {
              entryGrade.push(parseInt(allGrade[i]))
            }
          } else {
            console.log('error')
          }
        },
        props.history
      );
      console.log(entryGrade)
      setEntryGrade(entryGrade);
    }


    //获得导师信息
    async function getMentorList() {
      await post(
        {
          url: "/ec/student/getMentorNameList",
          data: {
            sessionId: localStorage.sessionId,
          },
        },
        (data) => {
          setmentorList(data.contents);
        },
        history
      );
    }
    genEntryGrade().then(()=>{
      return getMentorList();
    })

    
    
  }, []);
  /*************method*************/

  //handleValueChange
  function handleValueChange(changedValues, allValues) {
    for (let key in changedValues) {
      if (!changedValues[key]) {
        changedValues[key] = "";
      }
    }
    props.setFilterInfo({ ...props.filterInfo, ...changedValues });
  }
  //清除筛选
  function handleClear() {
    filterForm.resetFields();
    const initState = {
      studentName: "",
      lastMentor: "",
      applyForFirst: "",
      entryGrade: "",
      degreeType: "",
      applicationStatus: "",
    };
    props.setFilterInfo({
      ...initState,
    });
    props.setPageInfo({ currIndex: 1, pageSize: 10 });
    props.getTableData({ ...initState, currIndex: 1, pageSize: 10 });
  }
  //筛选
  function handleSubmit() {
    console.log(props.filterInfo)
    console.log(localStorage)

    localStorage.setItem('filterName',props.filterInfo.studentName)
    localStorage.setItem('filterGrade',props.filterInfo.entryGrade)
    localStorage.setItem('filterType',props.filterInfo.degreeType)
    localStorage.setItem('filterMentor',props.filterInfo.lastMentor)
    localStorage.setItem('filterApply',props.filterInfo.applyForFirst)
    localStorage.setItem('filterStatus',props.filterInfo.applicationStatus)

    console.log(localStorage)
    // localStorage.
    props.setPageInfo({ currIndex: 1, pageSize: 10 });
    props.getTableData({ ...props.filterInfo, currIndex: 1, pageSize: 10 });
  }
  /************render*************/
  return (
    <CustomCard options={headerCardOptions}>
      <Form
        layout="inline"
        style={{ flexDirection: "column" }}
        form={filterForm}
        onValuesChange={handleValueChange}
      >
        <Row className="student-resume-row">
          <Col span={6} offset={1}>
            <Form.Item label="姓名" name="studentName">
              <Input placeholder="请输入姓名" style={{ width: "80%" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="学硕/专硕" name="degreeType">
              <Select style={{ width: "80%" }} allowClear placeholder="请选择专业">
                <Option value="1" key="1">
                  学硕
                </Option>
                <Option value="2" key="2">
                  专硕
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="提交申请状态" name="applyForFirst">
              <Select style={{ width: "80%" }} allowClear placeholder="请选择申请状态">
                <Option value="0" key="0">
                  未提交
                </Option>
                <Option value="1" key="1">
                  已提交
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Button type="primary" htmlType="submit" onClick={handleSubmit}>
              筛选
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={1}>
            {/* <Form.Item label="年级" name="entryGrade">
              <Select style={{ width: "80%" }} allowClear placeholder="请选择年级">
                {entryGrade.map((item, index) => (
                  <Option value={item} key={index}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item> */}

            <Form.Item label="年级" name="entryGrade">
              <Select
                style={{ width: "80%" }}
                placeholder="请选择年级"
                allowClear
              >
                {entryGrade.map((item, index) => (
                  <Option value={item} key={index}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="导师" name="lastMentor">
              <Select style={{ width: "80%" }} showSearch allowClear placeholder="请选择导师">
                {mentorList.map((item, index) => (
                  <Option value={item} key={index}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="审核状态" name="applicationStatus">
              <Select style={{ width: "80%" }} showSearch allowClear placeholder="请选择审核状态">
                <Option value="1" key="1">
                  待审核
                </Option>
                <Option value="2" key="2">
                  已拒绝
                </Option>
                <Option value="3" key="3">
                  已通过
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Button type="plain" htmlType="submit" onClick={handleClear}>
              重置
            </Button>
          </Col>
        </Row>
      </Form>
    </CustomCard>
  );
}
