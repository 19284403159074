/*
 * @Author: ZhouShan
 * @Date: 2020-11-05 11:04:20
 * @Last Modified by: HouQi
 * @Last Modified time: 2021-12-08 21:40:26
 */
import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
  Checkbox,
  Tag,
  Form,
  Button,
  Select,
  notification,
  Modal,
  Cascader,
  Divider,
} from "antd";
import { post, request } from "../../../../network/request";
import { useHistory } from "react-router-dom";
//import css
import "./settingDialog.css";

const { Option } = Select;

export default function SettingDialog(props) {
  const history = useHistory();
  const [settingForm] = Form.useForm();
  /************state************/
  //设置窗口的表单汇总对象
  const [settingTotal, setSettingTotal] = useState({
    seniorNo: "",
    lastMentor: "",
    teacherCounselor: "",
    lastResearchDirection: "",
  });
  const [seniorInfo, setSeniorInfo] = useState([]);
  /************memo*************/
  //可指定的学姐学长列表
  const solvedAvailableSenior = useMemo(() => {
    const yearSet = new Set();
    const res = [];
    //算法较慢
    props.availableSenior.forEach((item) => {
      //不存在的年份直接构建
      if (!yearSet.has(item.entryGrade)) {
        res.push({
          value: item.entryGrade,
          label: item.entryGrade,
          children: [
            {
              value: item.studentNo,
              label: item.studentName,
            },
          ],
        });
        yearSet.add(item.entryGrade);
      }
      //存在的年份直接添加进children
      else {
        res.forEach((items) => {
          if (items.value === item.entryGrade) {
            items.children.push({
              value: item.studentNo,
              label: item.studentName,
            });
          }
        });
      }
    });
    return res;
  }, [props.availableSenior]);
  //研究方向
  const ecResearchDirectionOption = useMemo(() => {
    return props.ecResearchDirection.map((item) => {
      return {
        label: item.ecResearchDirectionName,
        value: item.ecResearchDirectionName,
      };
    });
  }, [props.ecResearchDirection]);
  const lastResearchDirectionList = useMemo(() => {
    let res = [];
    if (props.detailContent.lastResearchDirection) {
      res = props.detailContent.lastResearchDirection.split(",");
    }
    return res;
  }, [props.detailContent.lastResearchDirection]);
  const selected = useMemo(() => {
    const list = seniorInfo.map(item => (
      <Tag
        closable
        key={item.seniorNo}
        onClose={handleTagClose}
        data-seniorno={item.seniorNo}
      >
        {item.seniorName}
      </Tag>
    ))
    return list;
  }, [seniorInfo,handleTagClose])
  /************useEffect**********/
  //设置表单的默认值
  useEffect(() => {
    settingForm.setFieldsValue({
      lastMentor: props.detailContent.lastMentor,
      teacherCounselor: props.detailContent.teacherCounselor,
    });
    setSettingTotal({
      seniorNo: "",
      lastMentor: "",
      teacherCounselor: "",
      lastResearchDirection: props.detailContent.lastResearchDirection,
    });
    getSeniorInfoByStudentNo(props.detailContent.studentNo);
  }, [
    props.setting.visible,
    props.detailContent.lastMentor,
    props.detailContent.teacherCounselor,
    props.detailContent.lastResearchDirection,
    // getSeniorInfoByStudentNo,
    props.detailContent.studentNo,
    settingForm
  ]);
  /***********method***********/
  //获得指定学生的传帮带学长学姐信息
   const getSeniorInfoByStudentNo = useCallback(
    async (studentNo) => {
      await post(
        { 
          url: "/ec/student/getSeniorInfoByStudentNo",
          data: {
            sessionId: localStorage.sessionId,
            studentNo,
          },
        },
        (data) => {
          setSeniorInfo(data.contents.list);
        },
        history
      );
    },[]
  )
  //添加传帮带学姐学长
  async function addSeniorInfo() {
    if (!settingTotal.seniorNo) {
      notification.error({ description: "请选择一名学生!", message: "警告" });
      return;
    }
    //获得对应的编号的姓名
    const target = props.availableSenior.find(
      (item) => item.studentNo === settingTotal.seniorNo[1]
    );
    const rawData = await request({
      url: "/ec/student/addSeniorInfo",
      method: "post",
      data: {
        sessionId: localStorage.sessionId,
        seniorNo: settingTotal.seniorNo[1],
        seniorName: target.studentName,
        studentNo: props.detailContent.studentNo,
        studentName: props.detailContent.studentName,
        insertTimestamp: parseInt(new Date().getTime() / 1000),
      },
    });
    const data = rawData.data;
    switch (data.code) {
      case "N01":
        notification.success({ description: "添加成功!", message: "通知" });
        getSeniorInfoByStudentNo(props.detailContent.studentNo);
        props.getTableData();
        break;
      case "E01":
        notification.error({ description: "重复添加！", message: "警告" });
        break;
      default:
        notification.error({
          description: "您的会话已经过期，请重新登录",
          message: "警告",
          duration: 1,
          onClose: () => {
            history.push("/login");
          },
        });
    }
  }
  //关闭Tag函数
  function handleTagClose(event) {
    deleteSeniorInfo(event.currentTarget.parentNode.dataset.seniorno);
  }
  //删除传帮带学姐学长
  async function deleteSeniorInfo(seniorNo) {
    const rawData = await request({
      url: "/ec/student/deleteSeniorInfo",
      method: "post",
      data: {
        sessionId: localStorage.sessionId,
        seniorNo,
        studentNo: props.detailContent.studentNo,
      },
    });
    const data = rawData.data;
    switch (data.code) {
      case "N01":
        notification.success({ description: "删除成功！", message: "通知" });
        props.getTableData();
        getSeniorInfoByStudentNo(props.detailContent.studentNo);
        break;
      default:
        notification.error({
          description: "您的会话已经过期，请重新登录",
          message: "警告",
          duration: 1,
          onClose: () => {
            history.push("/login");
          },
        });
    }
  }
  //设置提交
  async function handleSettingSubmit() {
    const data = {};
    data.lastMentor = settingTotal.lastMentor
      ? settingTotal.lastMentor
      : props.detailContent.lastMentor;
    data.teacherCounselor = settingTotal.teacherCounselor
      ? settingTotal.teacherCounselor
      : props.detailContent.teacherCounselor;
    data.lastResearchDirection = settingTotal.lastResearchDirection;
    await settingForm.validateFields();
    updateStudentInboundInfo(data);
  }
  //更新学生信息
  async function updateStudentInboundInfo(value) {
    const rawData = await request({
      url: "/ec/station/updateStudentInboundInfo",
      method: "post",
      data: {
        ...value,
        sessionId: localStorage.sessionId,
        studentSid: props.detailContent.studentSid,
      },
    });
    const data = rawData.data;
    switch (data.code) {
      case "N01":
        notification.success({ description: "更新成功！", message: "通知" });
        props.getTableData();
        props.setSetting(false);
        break;
      case "E02":
        notification.error({ description: "更新失败！", message: "警告" });
        break;
      case "E03":
        notification.error({
          description: "您的会话已经过期，请重新登录",
          message: "警告",
          duration: 1,
          onClose: () => {
            history.push("/login");
          },
        });
    }
  }
  //Checkbox修改
  function handleCheckbox(event) {
    setSettingTotal({
      ...settingTotal,
      lastResearchDirection: event.join(","),
    });
  }
  //联级选择变化
  function handleCascaderChange(event) {
    setSettingTotal({ ...settingTotal, seniorNo: event });
  }
  //select绑定
  function handleSelectChange(tag, value) {
    setSettingTotal({ ...settingTotal, [tag]: value });
  }
  return (
    <>
      {/* 设置 */}
      <Modal
        title={
          <span>
            &lt;
            <span style={{ color: "#0f5dbb" }}>
              {props.detailContent.studentName}&gt;{" "}
            </span>
            设置
          </span>
        }
        visible={props.setting.visible}
        getContainer={false}
        // forceRender
        onCancel={props.handleCancel.bind(null, "setting")}
        footer={[
          <Button key="submit" type="primary" onClick={handleSettingSubmit}>
            提交
          </Button>,
          <Button key="back" onClick={props.handleCancel.bind(null, "setting")}>
            关闭
          </Button>,
        ]}
      >
        <h3 style={{ fontWeight: 600, color: "#4a4949" }}>传帮带设置</h3>
        <Divider plain style={{ marginTop: 0 }}></Divider>
        <div className="studentResume-setSenior">
          <div>
            <span style={{ marginRight: "40px", fontSize: "1.1em" }}>
              设置传帮带:
            </span>
            <Cascader
              options={solvedAvailableSenior}
              onChange={handleCascaderChange}
              placeholder="请指定学生"
              size="small"
              value={ settingTotal.seniorNo }
            />
            <Button
              type="primary"
              style={{ marginLeft: "34px" }}
              onClick={addSeniorInfo}
              size="small"
            >
              添加
            </Button>
          </div>
          <div style={{ marginTop: "25px" }}>
            <span style={{ marginRight: "10px", fontSize: "1.1em" }}>
              已指定的传帮带人员：
            </span>
            <div style={{ marginTop: "20px" }}>
              {selected}
            </div>
          </div>
        </div>
        <h3 style={{ marginTop: "30px", fontWeight: 600, color: "#4a4949" }}>
          基本信息设置
        </h3>
        <Divider plain style={{ marginTop: 0 }}></Divider>
        <div className="studentResume-setSenior">
          <Form size="small" form={settingForm}>
            <Form.Item
              label="导师"
              name="lastMentor"
              rules={[
                {
                  required: true,
                  message: "请选择导师",
                },
              ]}
            >
              <Select
                style={{ width: "56%" }}
                value={settingTotal.lastMentor}
                showSearch
                onChange={handleSelectChange.bind(null, "lastMentor")}
                allowClear
              >
                {props.mentorList.map((item, index) => (
                  <Option value={item} key={index}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="EC指导老师"
              name="teacherCounselor"
              rules={[
                {
                  required: true,
                  message: "请选择EC指导老师",
                },
              ]}
            >
              <Select
                style={{ width: "56%" }}
                value={settingTotal.teacherCounselor}
                showSearch
                onChange={handleSelectChange.bind(null, "teacherCounselor")}
                allowClear
              >
                {props.teacherCounselorList.map((item, index) => (
                  <Option value={item} key={index}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "请选择方向",
                },
              ]}
            >
              <label style={{ marginRight: "10px" }}>技术栈：</label>
              <Checkbox.Group
                options={ecResearchDirectionOption}
                defaultValue={lastResearchDirectionList}
                onChange={handleCheckbox}
              ></Checkbox.Group>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}
