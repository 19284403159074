/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useMemo } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Table,
  notification,
  DatePicker,
  Popconfirm,
  Space,
} from "antd";
import CustomCard from "../../../components/common/card/card";
import "./projectConstruct.css";
import moment from "moment";
import { post,request } from "../../../network/request";
import AddProject from "./addForm/addProject";
function projectConstruct(props) {
  const [loading, setLoading] = useState(false);
  const [fileForm] = Form.useForm();
  const { Option } = Select;
  const todayDate = new Date().toLocaleDateString();
  const dataRule = "YYYY/MM/DD";
  const headerCardOption = { title: null, bordered: false };
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll", height: "calc(100vh - 78px - 130px)" },
  };
  const [filerListData, setFilerList] = useState({
    // 表单的数据
    itemType: 3,
    roleSid: 1,
    pageSize: 10,
    index: 1,
    itemStatus: "",
    managerName: "",
    participants: "",
    itemName: "",
  });
  //#region   Add表单

  const [rowData, setRowData] = useState([]);

  //#endregion
  const [studentInfo, setStudentInfo] = useState([]);
  const [rowDataList, setRowDataList] = useState([
    //table数据
  ]);
  //页面总数
  const [total, setTotal] = useState(0);
  const [modleState, setMole] = useState(0);

  //方法
  // #region Add方法
  function handleClickAddBtn() {
    // handleClearAddInfo()
    setRowDataList([]);
    setMole(1);
  }
  const closeAddForm = () => {
    setMole(0);
    setRowDataList([]);
  };
  const setFormLoading = (a) => {
    setLoading(a);
  };
  const upDateTable = () => {
    console.log("获取数据");
    getItemList(filerListData);
  };

  //修改页面数据赋值
  const ediDataInit = (value) => {
    setMole(2);
    setRowDataList(value);
  };

  //#endregion
  //拿学生数据
  const getStudentList = async () => {
    setLoading(true);

    // setFilerList({...filerListData,endTimestamp:parseInt(new Date().getTime()/1000)})
    await post(
      {
        url: "/ec/project/getAllStudentInfoOfInBound",
        data: {
          sessionId: localStorage.sessionId,
        },
      },
      (data) => {
        setLoading(false);
        setStudentInfo(data.contents);
      },
      props.history
    );
  };
  useEffect(
    () => {
      getStudentList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //设置显示数据
  const tabel = useMemo(() => {
    rowData.forEach((item) => {
      switch (item.itemStatus) {
        case 1:
          item.itemStatus = "进行中";
          break;
        case 2:
          item.itemStatus = "已完成";
          break;
        case 3:
          item.itemStatus = "已终止";
          break;
        default:
          item.itemStatus = "错误";
      }
      item.updateTimestamp = !item.updateTimestamp
        ? "暂无"
        : new Date(item.updateTimestamp * 1000).toLocaleDateString();

      item.insertTimestamp = !item.insertTimestamp
        ? "暂无"
        : new Date(item.insertTimestamp * 1000).toLocaleDateString();
      item.endTime = !item.endTime
        ? "暂无"
        : new Date(item.endTime * 1000).toLocaleDateString();
    });
    return rowData;
  }, [rowData]);
  //换页
  const handlePageChange = (event, pageSize) => {
    const index = event;
    setFilerList({ ...filerListData, pageSize, index: index });
    //getItemList(filerListData)
  };
  //删除确认函数
  const handleDeleteItem = async (data) => {
    setLoading(true);
    await post(
      {
        url: "ec/project/deleteProjectInfo",
        data: {
          sessionId: localStorage.sessionId,
          itemSid: data.itemSid,
          roleSid: 1,
        },
      },
      (data) => {
        setLoading(false);
        getItemList(filerListData);
      },
      props.history
    );
  };
  useEffect(() => {
    if (props.location.state !== undefined) {
      console.log('有值传过来！')
      console.log(props.location.state.data)
      console.log(props.location.state)
      if (props.location.state.num == 1) {
        getItemList({
          itemType: 3,
          roleSid: 1,
          pageSize: 20,
          index: 1,
          itemStatus: "",
          managerName: props.location.state.data.studentName,
          participants: "",
          itemName: "",
        });
      } else if (props.location.state.num == 2) {
        getItemList({
          itemType: 3,
          roleSid: 1,
          pageSize: 20,
          index: 1,
          itemStatus: "",
          managerName: "",
          participants: props.location.state.data.studentName,
          itemName: "",
        });
      }
    } else {
      getItemList(filerListData);
      console.log('没有值传过来！')
    }
  }, [filerListData.pageSize, filerListData.index]);
  //拿数据
  const getItemList = async (fileData) => {
    console.log(fileData)
    setLoading(true);
    await post(
      {
        url: "/ec/project/getProjectInfoByItemType",
        data: {
          sessionId: localStorage.sessionId,
          ...fileData,
        },
      },
      (data) => {
        if (data.code === "N01") {
          setRowData(data.contents.list);
          setTotal(data.contents.size);
          setLoading(false);
        } else if (data.code === "E02") {
          openNotification();
        }
      },
      props.history
    );
  };
  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          close();
          notification.close(key);
        }}
      >
        Confirm
      </Button>
    );
    notification.open({
      message: "通知",
      description: "未找到符合条件的查询！",
      btn,
      key,
      onClose: close,
    });
  };
  const close = () => {
    fileForm.resetFields();
    //   if(filerListData.startTimestamp){
    //   delete filerListData.startTimestamp
    // }
    //   if(filerListData.endTimestamp){
    //     delete filerListData.endTimestamp
    //   }
    // getItemList(filerListData)
    // setFilerList({...filerListData})
    fileForm.resetFields();
    const initListData = {
      itemType: 3,
      roleSid: 1,
      pageSize: 10,
      index: 1,
      itemStatus: "",
      managerName: "",
      participants: "",
      itemName: "",
    };
    getItemList(initListData);
    setFilerList({ ...initListData });
  };

  const handleChangeStatus = (type, data) => {
    switch (type) {
      case "进行中":
        data.itemStatus = 1;
        break;
      case "已完成":
        data.itemStatus = 2;
        break;
      case "已终止":
        data.itemStatus = 3;
        break;
      default:
        break;
    }
    changeStatus(data);
  };
  const changeStatus = async (statusData) => {
    setLoading(true);
    await post(
      {
        url: "/ec/project/updateProjectStatus",
        data: {
          sessionId: localStorage.sessionId,
          itemSid: statusData.itemSid,
          itemStatus: statusData.itemStatus,
          roleSid: 1,
          isHandover: 1,
          note: "",
        },
      },
      (data) => {
        notification.success({ description: "通知", message: "修改状态成功!" });
        setLoading(false);
        getItemList(filerListData);
      },
      props.history
    );
  };
  //table 模板
  const tableColumns = [
    {
      title: "方案编号",
      dataIndex: "itemNumber",
      key: "itemNumber",
      align: "center",
      width: '100px'
    },
    {
      title: "方案名称",
      dataIndex: "itemName",
      key: "itemName",
      align: "center",
      editable: true,
    },
    {
      title: `主持者`,
      dataIndex: "managerName",
      width: '90px',
      align: "center",
      editable: true,
    },
    {
      title: "参与者",
      dataIndex: "participants",
      key: "participants",
      width: '150px',
      align: "center",
      editable: true,
    },
    {
      title: "负责老师",
      dataIndex: "chargeTeacher",
      key: "chargeTeacher",
      align: "center",
      width: '90px',
      editable: true,
    },

    {
      title: "开始时间",
      dataIndex: "insertTimestamp",
      key: "insertTimestamp",
      align: "center",
      editable: true,
      width: '110px'
    },
    {
      title: "截止时间",
      dataIndex: "endTime",
      key: "endTime",
      align: "center",
      editable: true,
      width: '110px'
    },

    {
      title: "目前状态",
      key: "itemStatus",
      align: "center",
      width: '110px',
      render: (text, record) => {
        switch (text.itemStatus) {
          case "已完成":
            return (
              <span className="ec-text-color-green">{text.itemStatus}</span>
            );
          case "进行中":
            return (
              <span className="ec-text-color-orange">{text.itemStatus}</span>
            );
          case "已终止":
            return (
              <span className="ec-text-color-grey">{text.itemStatus}</span>
            );
          default:
            return <span>{text.itemStatus}</span>;
        }
      },
    },
    {
      title: "更新时间",
      dataIndex: "updateTimestamp",
      key: "updateTimestamp",
      align: "center",
      editable: true,
      width: '110px'
    },
    {
      align: "center",
      title: "操作",
      key: "userName",
      width: '320px',
      ellipsis: true,
      fixed: 'right',
      render: (txt, record, index) => {
        return (
          <div>
            <Button
              size="small"
              disabled={txt.itemStatus === "已完成"}
              onClick={ediDataInit.bind(null, txt)}
              className="operate-button ec-button-lightBlue"
            >
              修改
            </Button>
            <Button
              size="small"
              disabled={txt.itemStatus === "已完成"}
              onClick={handleChangeStatus.bind(null, "进行中", txt)}
              className="operate-button ec-button-orange"
            >
              进行中
            </Button>
            <Popconfirm
              className="operate-button ec-button-green"
              placement="bottomRight"
              title="确认完成吗？注意：目前状态修改为完成后将无法进行其他操作！"
              onConfirm={handleChangeStatus.bind(null, "已完成", txt)}
              okText="确定"
              cancelText="取消"
              disabled={txt.itemStatus === "已完成"}
            >
              <Button
                size="small"
                className="operate-button ec-button-green"
                disabled={txt.itemStatus === "已完成"}
              >
                完成
              </Button>
            </Popconfirm>
            <Button
              type="danger"
              size="small"
              disabled={txt.itemStatus === "已完成"}
              className="operate-button"
              onClick={handleChangeStatus.bind(null, "已终止", txt)}
            >
              终止
            </Button>
            <Popconfirm
              className="operate-button"
              placement="bottomRight"
              title="确认删除吗"
              onConfirm={handleDeleteItem.bind(null, txt)}
              disabled={txt.itemStatus === "已完成"}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="danger"
                size="small"
                disabled={txt.itemStatus === "已完成"}
                className="operate-button"
              >
                删除
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  function handleQuickFilter(type) {
    fileForm.setFieldsValue({ itemStatus: type.toString() });
    setFilerList({ ...filerListData, itemStatus: type, index: 1 });
    getItemList({ ...filerListData, itemStatus: type, index: 1 });
  }

  //提交按钮
  const onFinish = (values) => {
    if (
      typeof values.ItemName !== "undefined" &&
      filerListData.itemName !== values.ItemName
    ) {
      filerListData.itemName = values.ItemName;
      filerListData.index = 1;
    }
    if (
      typeof values.itemStatus !== "undefined" &&
      filerListData.itemStatus !== values.itemStatus
    ) {
      filerListData.itemStatus = values.itemStatus;
      filerListData.index = 1;
    } else if (typeof values.itemStatus === "undefined") {
      filerListData.itemStatus = "";
      filerListData.index = 1;
    }

    if (
      typeof values.managerName !== "undefined" &&
      filerListData.managerName !== values.managerName
    ) {
      filerListData.managerName = values.managerName;
      filerListData.index = 1;
    }
    if (
      typeof values.participants !== "undefined" &&
      filerListData.participants !== values.participants
    ) {
      filerListData.participants = values.participants;
      filerListData.index = 1;
    }
    getItemList(filerListData);
  };

  const handleInputChange = () => {
    fileForm.getFieldValue("ItemName");
  };
  const handleChange = (value) => { };
  //   const startTimeChange=(date,dateString)=>{
  //          if(date===null){
  //            filerListData.startTimestamp=''}
  //            else
  //            filerListData.startTimestamp=moment(dateString).valueOf()/1000

  //   }
  // const endTimeChange=(date,dateString)=>{
  //   if(date===null)
  //   filerListData.endTimestamp=''
  //   else
  //   filerListData.endTimestamp=moment(dateString).valueOf()/1000
  // }
  function handleTimeChange(tag, date, dateString) {
    let value = "";
    if (date) {
      value = parseInt(moment(dateString, dataRule).valueOf() / 1000);
      setFilerList({ ...filerListData, [tag]: value });
    } else {
      if (tag === "startTimestamp") {
        if (filerListData.startTimestamp) {
          delete filerListData.startTimestamp;
        }
      }
      if (tag === "endTimestamp") {
        if (filerListData.endTimestamp) {
          delete filerListData.endTimestamp;
        }
      }
      setFilerList({ ...filerListData });
    }
  }
  const clearItem = () => {
    fileForm.resetFields();
    const initListData = {
      itemType: 3,
      roleSid: 1,
      pageSize: 10,
      index: 1,
      itemStatus: "",
      managerName: "",
      // startTimestamp:1,
      // endTimestamp:'',
      participants: "",
      itemName: "",
    };
    setFilerList({ ...initListData });
    //  getItemList(filerListData)
  };
  function goBack() {
    window.history.go(-1);
  }
  // function msgTest() {
  //   console.log("导出项目明细信息！")
  // }
  async function msgTest() {

    const rawData = await request({
      // url: "/ec/student/getProjectFileUrl",
      url: "/ec/project/exportProjectInfo",
      method: "post",
      data: {
        sessionId: localStorage.sessionId,
        itemType: "3",
        roleSid: "1",
        itemStatus:filerListData.itemStatus
      },
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      responseType: "blob",
    });
    console.log(rawData)
    // console.log(rawData.data);
    console.log(filerListData.itemStatus)
    

    if (!rawData.data?.code) {
      const elink = document.createElement('a');
      let temp = rawData.headers["content-disposition"]
        .split(";")[1]
        .split("=")[1];
      let iconv = require("iconv-lite");


      iconv.skipDecodeWarning = true;
      let fileName = decodeURIComponent(temp)
      console.log(fileName);
      let type = decodeURIComponent(rawData.headers["content-type"]);
      // const xlsx = 'application/vnd.ms-excel'
      // console.log(type);

      elink.download = fileName;
      // elink.download='建设方案管理.xls';
      elink.style.display = 'none';

      const blob = new Blob([rawData.data], {
        type: "application/msexcel,charset=UTF-8"
      });
      elink.href = window.URL.createObjectURL(blob);

      document.body.appendChild(elink);

      elink.click();
      document.body.removeChild(elink)

    }

  }
  //修改
  // const upDateInfo=()=>{
  //     setMole(2)
  // }
  return (
    <div>
      <CustomCard options={headerCardOption}>
        <Form
          layout="inline"
          form={fileForm}
          initialValues={""}
          style={{ flexDirection: "column" }}
          onFinish={onFinish}
        >
          <Row className="project-manage-row">
            <Col span={6} offset={1}>
              <Form.Item label="方案名称" name="ItemName">
                <Input
                  placeholder="请输入方案名称"
                  value={filerListData.itemName}
                  style={{ width: "80%" }}
                  onChange={handleInputChange}
                  name="ItemName"
                ></Input>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="主持者" name="managerName">
                <Input
                  placeholder="请输入主持者"
                  value={filerListData.managerName}
                  style={{ width: "80%" }}
                  //   onChange={handleInputChange}
                  name="managerName"
                ></Input>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="参与者" name="participants">
                <Input
                  placeholder="请输入参与者"
                  value={filerListData.participants}
                  style={{ width: "80%" }}
                  //   onChange={handleInputChange}
                  name="participants"
                ></Input>
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Button type="primary" htmlType="submit">
                筛选
              </Button>
            </Col>
          </Row>
          <Row >
            <Col span={6} offset={1}>
              <Form.Item label="目前状态" name="itemStatus">
                <Select
                  // defaultValue=""
                  style={{ width: "80%" }}
                  value={filerListData.itemStatus}
                  onChange={handleChange.bind(null)}
                  placeholder="请选择"
                  name="itemStatus"
                  allowClear
                >
                  <Option value="1" key="1">
                    进行中
                  </Option>
                  <Option value="2" key="2">
                    已完成
                  </Option>
                  <Option value="3" key="3">
                    {" "}
                    已终止
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="开始时间" name="startTimestamp">
                <Space direction="vertical" size={12} style={{ width: "100%" }}>
                  <DatePicker
                    onChange={handleTimeChange.bind(null, "startTimestamp")}
                    style={{ width: "80%" }}
                    name="startTimestamp"
                    format={dataRule}
                    placeholder="请选择时间"
                  />
                </Space>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="结束时间" name="endTimestamp">
                <Space direction="vertical" size={12} style={{ width: "100%" }}>
                  <DatePicker
                    onChange={handleTimeChange.bind(null, "endTimestamp")}
                    style={{ width: "80%" }}
                    name="endTimestamp"
                    format={dataRule}
                    placeholder="请选择时间"
                  />
                </Space>
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Button htmlType="submit" onClick={clearItem}>
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomCard>
      <CustomCard options={tableCardOptions}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <div>
            <Button
              className="quickFilter ec-button-orange"
              onClick={handleQuickFilter.bind(null, 1)}
            >
              进行中
            </Button>
            <Button
              className="quickFilter ec-button-green"
              onClick={handleQuickFilter.bind(null, 2)}
            >
              已完成
            </Button>
            <Button
              className="quickFilter ec-button-grey"
              onClick={handleQuickFilter.bind(null, 3)}
            >
              已终止
            </Button>
            <Button
              style={{
                  visibility: (props.location.state !== undefined && (props.location.state.num == 1 || 2)) ? 'visible':'hidden'
              }}
              type='primary'
              onClick={ goBack.bind(null) }
            >
              返回统计页
            </Button>
          </div>
          <div>
            {/* 这里是导出方案明细的按钮  */}
            <Button type="primary" htmlType="submit" onClick={msgTest}
              style={{
                color: "white",
                marginRight: '10px'
              }}>
              导出方案明细
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleClickAddBtn}
            >
              新增项目
            </Button>
          </div>
        </div>
        <Table
          columns={tableColumns}
          dataSource={tabel}
          className="table"
          rowKey="itemSid"
          loading={loading}
          scroll={{ x: '1450px' }}
          pagination={{
            total: total,
            current: filerListData.index,
            pageSize: filerListData.pageSize,
            pageSizeOptions: [10, 20, 30],
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        ></Table>
      </CustomCard>

      <AddProject
        allStuList={studentInfo}
        rowstuInfo={rowDataList}
        closeAddForm={closeAddForm}
        setLoading={setFormLoading}
        upDateTable={upDateTable}
        modelState={modleState}
      />
    </div>
  );
}

export default projectConstruct;
