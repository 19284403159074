/*
 * @Author: ZhouShan
 * @Date: 2020-11-05 11:04:39
 * @Last Modified by: HouQi
 * @Last Modified time: 2021-12-03 09:31:31
 */
import React, { useState } from "react";
import { request } from "../../network/request";
import md5 from "js-md5";
import { useDispatch } from "react-redux";

import { Button, Card, Divider, Form, Input, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import "./login.css";

export default function Login(props) {
  // console.log(props)
  const dispatch = useDispatch();
  const [loginForm] = Form.useForm();

  async function handleSubmit() {
    console.log('handleSubmit')
    try {
      await loginForm.validateFields();
    } catch {
      console.log("Form validate filed");
    }
  }

  async function submit(value) {
    const userInfo = value;
    const rawData = await request({
      url: "/ec/accountNumber/login",
      method: "post",
      data: {
        account: userInfo.username,
        password: md5(userInfo.password),
      },
    });
    // console.log(rawData)
    const data = rawData.data;
    console.log('@',data)
    switch (data.code) {
      case "N01":
        dispatch({ type: "updateCommon", options: data.contents });
        localStorage.setItem("sessionId", data.contents[0].sessionId);
        localStorage.setItem("accountSid", data.contents[0].accountSid);
        localStorage.setItem("userInfo", JSON.stringify(data.contents[0]));
        localStorage.setItem(
          "studentSid",
          JSON.stringify(data.contents[0].statusSid)
        );
        localStorage.setItem(
          "roleSid",
          JSON.stringify(data.contents[0].roleSid)
        );
        localStorage.setItem(
          "studentName",
          JSON.stringify(data.contents[0].studentName).match(/"(\S*)"/)[1]
        );
        localStorage.setItem("account", userInfo.username);
        localStorage.setItem('entryGrade',data.contents[0].entryGrade)
        localStorage.setItem('degreeType',data.contents[0].degreeType)
        console.log(localStorage)
        switch (data.contents[0].roleSid) {
          case 1:
            props.history.push("/home/projectManage");
            break;
          case 2:
            props.history.push("/home/TehStudentResume");
            break;
          case 3:
            props.history.push("/home/StuProjectManage");
            break;
          default:
            console.log("Unknow RoleSid.");
        }
        break;
      case "E01":
        notification.error({
          description: "账号密码错误",
          message: "警告",
        });
        break;
      default:
        break;
    }
  }

  return (
    <div className="login">
      <header className="logo-header">
        <img
          src="\assets\images\EClogo.png"
          alt="eclogo"
          className="login-logo"
        />
        <span className="logo-title">工程中心学生管理系统</span>
      </header>
      <main className="login-main">
        <Card
          title="登录"
          bordered="false"
          className="login-card"
          headStyle={{ fontSize: 25, fontWeight: 600, color: "gray" }}
        >
          <Form
            className="login-card-form"
            form={loginForm}
            name="loginForm"
            onFinish={submit}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "请输入用户名",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
                id="username"
                size="large"
                name="username"
              ></Input>
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "请输入密码",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                size="large"
                id="password"
                name="password"
              ></Input>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                onClick={handleSubmit}
              >
                登录
              </Button>
            </Form.Item>
          </Form>
          <Divider />
          <div>
            <p className="login-tag">
              Copyright © 2019-2023{" "}
              {/* <a href="https://www.scuec.edu.cn/s/1/t/560/main.htm">
                中南民族大学
              </a> */}
            </p>
            {/* <p className="login-tag">地址：中国.湖北.武汉.民族大道182号</p> */}
            <p className="login-tag">
              技术支持：
              {/* <a href="https://znglzx.scuec.edu.cn/"> */}
                湖北省制造企业智能管理工程技术研究中心
              {/* </a> */}
            </p>
          </div>
        </Card>
      </main>
    </div>
  );
}
