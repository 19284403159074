/*
 * @Author: BaiXin 
 * @Date: 2021-10-09 09:32:59 
 * @Last Modified by: HouQi
 * @Last Modified time: 2021-12-11 14:19:41
 */

import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";

import { Row, Col, Form, Button, Select, Table, } from "antd";
import Add from './addInfo/index'
import Edit from './editInfo/index'

//请求接口
import { post } from "../../../network/request";
//卡片
import CustomCard from "../../../components/common/card/card";
import { logRoles } from "@testing-library/react";
import EditInfo from "./editInfo";

const { Option } = Select;

export default function StudyRequirementManagement() {

  //普通参数
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 20 },
  };
  //data
  const dateFormat = "YYYY/MM/DD";
  // const todayDate = new Date().toLocaleDateString();
  const todayYear = new Date().getFullYear();
  const dataNum = [2, 1, 0, -1, -2, -3, -4]
  const headerCardOptions = { title: null, bordered: false };
 
  //状态
  const [form] = Form.useForm();
  const [loading,setLoading]=useState(false)
  //当前要修改的对象
  const [gradeAndType,setGradeAndType]=useState({
       grade:null,
       type:null
  })
  //筛选
  const [filterInfo, setFilterInfo] = useState({
    itemStatusStart: null,
    itemStatusEnd: null
  });
  //table数据
  const [tableData, steTableData] = useState([])
  //table模板
  const [columns, setColumns] = useState([])
  //内容
  const [condition,setCondition]=useState([])
  // const conditions=useRef(0)
  //控制dialog的显示
  const [isModalVisible, setIsModalVisible] = useState({
    addModel: false,
    eitdModel: false
  })
  //demands
  const [demands,setDemands]=useState([])
  //年纪数组
  const grade = useRef({})
  const gradeTypes = useRef({})
  //方法
  //选择范围
  let handleSelectChange = (tag, value) => {
    setFilterInfo({ ...filterInfo, [tag]: value });
  }
  //去除重复内容
  let handleDataNum = (array) => {
    // console.log(array)
    var r = [];
    let isHave = false
    for (var i = 0, l = array.length; i < l; i++) {
      isHave = false
      for (var j = i + 1; j < l; j++)
        if (array[i] == array[j]) {
          isHave = true
          break
        }
      if (!isHave)
        r.push(array[i]);
    }
    return r;
  }
  //整合table数据
  //处理table模板和数据
  //处理接口数据,对数组按年级排序，获得现有所有年纪
  let handleData = (data) => {
    let sortList = data.sort((a, b) => {
      return b.grade - a.grade
    })
    let grade = []
    let res = {}
    let gradeType = []
    for (let i = 0; i < sortList.length; i++) {
      grade.push(sortList[i].grade)
      gradeType.push(sortList[i].grade + '.' + sortList[i].studentType)
    }
    //去重
    grade = handleDataNum(grade)
    gradeType = handleDataNum(gradeType)
    
    // console.log(gradeType)
    
    res.grade = grade
    console.log(res.grade);
    res.sortList = sortList
    res.gradeType = gradeType
    return res
  }
  //处理生效时间
  const handleTimeAndButton=(datalists)=>{
        let effectiveTimestamp={ }
        let buttonList={}
        for (let i=0;i<datalists.length;i++){
              let item=datalists[i]
              if(!effectiveTimestamp[item.grade+'temp'+item.studentType]){
                 effectiveTimestamp[item.grade+'temp'+item.studentType]=new Date(item.effectiveTimestamp * 1000).toLocaleDateString()
                 buttonList[item.grade+'temp'+item.studentType]=(<Button type="primary" htmlType="submit" onClick={handleClickSettingStudent.bind(null,item.grade,item.studentType)}>修改</Button>)
              }
        
        }
        let res={
          effectiveTimestamp,
          buttonList
        }
        return res
  }
  //处理table展示数据
  let handleShowData = (sortList, conditions) => {
    let showList = []
    for (var i=0;i< conditions.length;i++) {
      let item=conditions[i]
      let temp = {}
      let outboundConditionSid = item.outboundConditionSid
      temp.conditionName = item.conditionName
      temp.index = item.outboundConditionSid
      for (let i of sortList) {
        if (i.outboundConditionSid == outboundConditionSid) {
          if(!temp[i.grade + 'temp'+i.studentType]){
            temp[i.grade + 'temp'+i.studentType]=i.amount   
          }
        }
      }
      showList.push(temp)
    }
    let res=  handleTimeAndButton(sortList)
    let temp = {
      conditionName : "生效时间",
      index : i+1,
      ...res.effectiveTimestamp
    }
    showList.push(temp)
     temp = {
      conditionName : "",
      index : '',
      ...res.buttonList
    }
    showList.push(temp)
    // console.log(temp)
    // console.log(showList)
    return showList
  }
  //处理表头
  let handleTableHead = (left, right, gradeList) => {
    let resGrades = []
    // console.log(left)
    // console.log(right)
    if (left && right) {
     
      if (left <= parseInt(gradeList[0]) && right >= parseInt(gradeList[gradeList.length - 1])) {
        for (let item of gradeList) {
          if (parseInt(item) <= left && parseInt(item) >= right)
            resGrades.push(item)          
        }
      } else if (left > parseInt(gradeList[0])) {
        for (let item of gradeList) {
          if (parseInt(item) >= right) {
            resGrades.push(item)
          }
        }
      } else if (right < parseInt(gradeList[gradeList.length - 1])) {
        for (let item of gradeList) {
          if (parseInt(item) <= left) {
            resGrades.push(item)
          }
        }
      }
      
    }
    // let handleTableHead = (left, right, gradeList) => {
    //   let resGrades = []
    //   if (left && right) {
    //     if (left <= parseInt(gradeList[0]) && right >= gradeList[gradeList.length - 1]) {
    //       for (let item of gradeList) {
    //         if (item <= left && item >= right)
    //           resGrades.push(item)
    //       }
    //     } else if (left > gradeList[0]) {
    //       for (let item of gradeList) {
    //         if (item >= right) {
    //           resGrades.push(item)
    //         }
    //       }
    //     } else if (right < gradeList[gradeList.length - 1]) {
    //       for (let item of gradeList) {
    //         if (item <= left) {
    //           resGrades.push(item)
    //         }
    //       }
    //     }
    //   }
    // console.log(resGrades)
    //开始组合表头
    // console.log(resGrades)
    let colum = [
      {
        title: "编号",
        dataIndex: "index",
        align: "center",
  
      },
      {
        title: "内容",
        dataIndex: "conditionName",
        align: "center",
     
      },
    ]
    const a=6
    if (resGrades.length >a){
    for (let item = 0; item < a; item++) {
      let temp = {}
      let res = resGrades[item].split('.')
      let title=null
      let type=null
      if (res[1] == '1') {
            title= res[0] + "学硕"
            type="temp1"
      }else{
        title= res[0] + "专硕"
        type="temp2"
      }
      temp = {
        title: title,
        dataIndex: res[0] + type,
        align: "center",
       
      }
      colum.push(temp)
    }
    }
    else if(resGrades.length<=a){
      for (let item = 0; item < resGrades.length; item++) {
        let temp = {}
        let res = resGrades[item].split('.')
        let title = null
        let type = null
        if (res[1] == '1') {
          title = res[0] + "学硕"
          type = "temp1"
        } else {
          title = res[0] + "专硕"
          type = "temp2"
        }
        temp = {
          title: title,
          dataIndex: res[0] + type,
          align: "center",

        }
        colum.push(temp)
      }
    }
    // for (let item = 0; item < resGrades.length; item++) {
    //   let temp = {}
    //   if (handleSeachStudent(resGrades[item]), 1) {
    //     temp = {
    //       title: resGrades[item] + "学硕",
    //       dataIndex: resGrades[item] + "temp1",
    //       align: "center",
    //     }
    //     colum.push(temp)
    //   }
    //   if (handleSeachStudent(resGrades[item]), 2) {
    //     temp = {
    //       title: resGrades[item] + "专硕",
    //       dataIndex: resGrades[item] + "temp2",
    //       align: "center",
    //     }
    //     colum.push(temp)
    //   }
    // }
    // console.log(colum)
    setColumns(colum)
    console.log(colum);
  }
  //修改
  let handleClickSettingStudent=(grade,type)=>{
    setIsModalVisible({ eitdModel: true,addModel:false })
    setGradeAndType({
        grade:grade,
        type:type

    })
    // let res=[]
    // let effectiveTimestamp=null
    // console.log(demands)
    //  for (let i=0;i<condition.length;i++){
    //     for (let j=0;j<demands.length;j++){
          
    //        if(demands[j].outboundConditionSid==condition[i].outboundConditionSid&&demands[j].grade==grade&&demands[j].studentType==type){
    //         effectiveTimestamp=demands[j].effectiveTimestamp
    //          let temp={
    //              conditionName: condition[i].conditionName,
    //              needSid:demands[j].needSid,
    //              amount:demands[j].amount

    //          }
    //          res.push(temp)
    //        }
    //     }
    //  }
    //  res.push(effectiveTimestamp)
    //  console.log(res)
  }
  //筛选
  let handleSearch = () => {
    // console.log(grade.current)
    let leftGrade = null
    let rightGrade = null
    // console.log(filterInfo)
    if (filterInfo.itemStatusStart && filterInfo.itemStatusEnd) {
      leftGrade = filterInfo.itemStatusStart
      rightGrade = filterInfo.itemStatusEnd
      handleTableHead(leftGrade, rightGrade, gradeTypes.current)
    } else {
      // console.log(grade.current)
      if (grade.current.length >= 4) {
        leftGrade = grade.current[0]
        rightGrade = grade.current[3]
      } else if (grade.current.length == 0) {
        leftGrade = null
        rightGrade = null
      } else if (1 < grade.current.length < 3) {
        leftGrade = grade.current[0]
        rightGrade = grade.current[grade.current.length-1]
      }
      handleTableHead(leftGrade, rightGrade, gradeTypes.current)
    }

  }
  // 重置新增
  function handleClear() {
    // filterForm.resetFields();
    filterInfo.itemStatusStart=null
    filterInfo.itemStatusEnd=null
    getTableInfo()
  }
  let handleCancel = () => {
    setIsModalVisible({
      addModel: false ,
      editModel: false
    })
  }
  //请求
  //获取表格数据
  const getTableInfo = async () => {
    setLoading(true)
    await post({
      url: "/ec/station/getOutBoundZConditions",
      data: {
        sessionId: localStorage.sessionId,
      },
    }, (data) => {   
      setCondition(data.contents.conditions)
      setDemands(data.contents.demands)
      let res = handleData(data.contents.demands)
      

      // setGradeList(res.grade)
      grade.current = res.grade
      gradeTypes.current = res.gradeType
      // gradeList=res.grade
      let showList = handleShowData(res.sortList, data.contents.conditions)
      steTableData(showList)
      handleSearch()
      setLoading(false)
      // console.log(res)
      // console.log(showList)
      // let leftGrade = null
      // let rightGrade = null
      // if (filterInfo.itemStatusStart && filterInfo.itemStatusEnd) {
      //   leftGrade=filterInfo.itemStatusStart
      //   rightGrade=filterInfo.itemStatusEnd
      //   handleTableHead(leftGrade,rightGrade,res.grade)
      // } else {
      //   if (res.grade.length >= 3) {
      //     leftGrade = res.grade[0]
      //     rightGrade = res.grade[2]
      //   } else if (res.grade.length == 0) {
      //     leftGrade = null
      //     rightGrade = null
      //   } else if (1 < res.grade.length < 3) {
      //     leftGrade = res.grade[0]
      //     rightGrade = res.grade[res.grade.length]
      //   }
      //   handleTableHead(leftGrade, rightGrade, res.grade)
      // }
    })
  }
  //useEffect
  useEffect(() => {
    getTableInfo()
  }, [])
  //useCallBack
  const cancel = useCallback(
    () => {
      handleCancel();
    },
    [],
  )
  //useMemo
  //添加表单
  const addDialog = useMemo(() => {
    return (
      <Add
        visible={isModalVisible.addModel}
        cancel={cancel}
        // gradeList={grade.current}
        gradeTypes={gradeTypes.current}
        condition={condition}
        getTableInfo={getTableInfo}
      />
    )
  },[isModalVisible.addModel])
  //修改表单
  const editDialog = useMemo(()=>{
      return(
        <Edit 
        visible={isModalVisible.eitdModel}
        cancel={cancel}
        condition={condition}
        demands={demands}
        gradeAndType={gradeAndType}
        getTableInfo={getTableInfo}
        />
      )
  },[isModalVisible.eitdModel])
  return (
    <div>
      {/* 筛选区域 */}
      <CustomCard options={headerCardOptions}>
        <Form
          form={form}
          name="horizontal_login"
          layout="inline"
          style={{ flexDirection: "column" }}
          {...layout}>
          <Row>
            <Col span={10} offset={1}>
              <Form.Item label="查看指定年级区域" name="filterInfo">
                <Select style={{ width: "30%" }} placeholder="请选择"
                  value={filterInfo.itemStatusStart}
                  name="itemStatusStart" allowClear onChange={handleSelectChange.bind(null, "itemStatusStart")} >
                  {
                    dataNum.map(item => {
                      return <Option key={item} value={todayYear + item} >{todayYear + item}</Option>
                    })
                  }
                </Select>
                <span style={{ width: '5%', margin: '0 10px' }}>--</span>
                <Select style={{ width: "30%" }} placeholder="请选择"
                  value={filterInfo.itemStatusEnd}
                  name="itemStatusEnd" allowClear onChange={handleSelectChange.bind(null, "itemStatusEnd")}>
                  {
                    dataNum.map(item => {
                      return <Option key={item} value={todayYear + item} >{todayYear + item}</Option>
                    })
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Button type="primary" htmlType="submit" onClick={handleSearch.bind(null)} disabled={filterInfo.itemStatusStart < filterInfo.itemStatusEnd ? true : false}>
                筛选
              </Button>
            </Col>
            <Col span={2}>
              <Button type="plain" htmlType="submit" onClick={handleClear}>
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomCard>
      {/* 表格区域 */}
      <CustomCard options={headerCardOptions}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 10,
          }}
        >
          <Button type="primary" htmlType="submit" onClick={() => { setIsModalVisible({ ...isModalVisible, addModel: true }) }} >
            新增
          </Button>
        </div>
        <div>
          <Table
            className="addDialogTable"
            rowKey="index"
            columns={columns}
            dataSource={tableData}
            loading={loading}
            pagination={false}
            // scroll={{
            //   x: 1975
            // }}
          />
        </div>
      </CustomCard>
      {/* 添加区域 */}
      {addDialog}
       {/* 修改区域 */}
      {editDialog}
    </div>
  )

}
