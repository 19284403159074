/*
 * @Author: 白鑫
 * @Date: 2020-11-18 19:17:04
 * @Last Modified by: HouQi
 * @Last Modified time: 2021-12-11 14:17:08
 */

/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import {
  Tag,
  Form,
  Input,
  Button,
  Select,
  Space,
  notification,
  DatePicker,
  Modal,
} from "antd";
import { post } from "../../../../network/request";
// import { useParams } from 'react-router-dom';
import moment from "moment";

import "../projectConstruct.css";
const { Option } = Select;

function addProject(props) {
  const formRef = React.createRef();
  const todayDate = new Date().toLocaleDateString();
  const dateFormat = "YYYY/MM/DD";
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  const [addInfo, setAddInfo] = useState({
    itemType: 1,
    roleSid: 1,
    itemName: "",
    itemNumber: "",
    chargeTeacher: "",
    managerSid: 0,
    itemStatus: "",
    insertTimestamp: parseInt(new Date().getTime() / 1000),
    endTime: parseInt(new Date().getTime() / 1000),
    selectParticipantSid: 0,
    participantSid: [],
    statusDescription: "",
  });
  const [entryGrade, setEntryGrade] = useState([]);
  const [selectManageInfo, setManageInfo] = useState([]);
  const [selectPartInfo, setSelectPartInfo] = useState([]);
  const [validateState, setValidateState] = useState({
    managerName: "success",
    managerNamehelp: undefined,
    time: "success",
    timehelp: undefined,
  });
  const validateMessages = {
    required: "请填写${label}!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
      array: "请填写${label}!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const [updataForm] = Form.useForm();
  const [managerState, setMangerState] = useState(true);

  //方法

  const itemNameChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAddInfo({ ...addInfo, [name]: value });
  };
  //管理学生数据映射处理
  const handleSelectGradeChange = (value) => {
    let res = [];
    props.allStuList.forEach((item) => {
      if (item.entryGrade === Number(value)) {
        res.push(item);
      }
    });
    setManageInfo(res);
    let data = { ...addInfo };
    delete data.managerName;
    data.managerSid = 0;
    setAddInfo(data);
  };
  //渲染管理学生数据下拉列表
  const renderMagStuList = () => {
    return selectManageInfo.map((item) => (
      <Option value={item.studentSid} key={item.studentSid}>
        {item.studentName}
      </Option>
    ));
  };
  //参加学生数据映射处理
  const handleSelectPartGradeChange = (value) => {
    let res = [];
    props.allStuList.forEach((item) => {
      if (item.entryGrade === Number(value)) {
        res.push(item);
        //  console.log(item)
      }
    });
    setSelectPartInfo(res);
  };
  //渲染参加学生列表
  const renderPartList = () => {
    return selectPartInfo.map((item) => (
      <Option value={item.studentSid} key={item.studentSid}>
        {item.studentName}
      </Option>
    ));
  };

  //渲染参加学生
  const handleGetPartStudentList = () => {
    let list = [];
    // console.log(addInfo.participantSid)
    addInfo.participantSid.forEach((item) => {
      props.allStuList.forEach((stu) => {
        if (item === stu.studentSid) {
          let student = {
            studentSid: item,
            studentName: stu.studentName,
          };
          list.push(student);
        }
      });
    });

    return list.map((item, index) => (
      <Tag
        closable
        key={item.studentSid}
        onClose={handleClickDeletePartStudent.bind(null, index)}
        style={{ marginTop: "5px" }}
      >
        {item.studentName}{" "}
      </Tag>
    ));
  };
  const handleClickDeletePartStudent = (index) => {
    let data = { ...addInfo };
    data.participantSid.splice(index, 1);
    setAddInfo(data);
  };

  //修改页面数据赋值
  const ediDataInit = () => {
    let data = { ...props.rowstuInfo };
    let allInfo = [...props.allStuList];

    data.participants = data.participants.split(" ");
    let partList = [];
    let partGrade = 2017;
    let managerSid = 0;
    let managerGrade = 2017;
    // console.log(allInfo)

    for (let i = 0; i < data.participants.length; i++) {
      for (let j = 0; j < allInfo.length; j++) {
        if (data.participants[i] === allInfo[j].studentName) {
          partList.push(allInfo[j].studentSid);
          partGrade = allInfo[j].entryGrade;
          console.log(111);
        } else {
          console.log(allInfo[j].studentName);
        }
      }
    }
    console.log(partList);
    for (let i = 0; i < props.allStuList.length; i++) {
      if (data.managerName === allInfo[i].studentName) {
        managerSid = allInfo[i].studentSid;
        managerGrade = allInfo[i].entryGrade;
        break;
      }
    }
    data.participantSid = partList;
    data.partGrade = partGrade;
    data.managerSid = managerSid;
    data.managerGrade = managerGrade;
    handleSelectGradeChange(managerGrade);
    handleSelectPartGradeChange(partGrade);

    data.insertTimestamp = parseInt(
      new Date(data.insertTimestamp).valueOf() / 1000
    );
    data.endTime = parseInt(new Date(data.endTime).valueOf() / 1000);
    data.updateTimestamp = parseInt(
      new Date(data.updateTimestamp).valueOf() / 1000
    );
    setAddInfo({ ...data });
    updataForm.resetFields();
  };
  //修改主持人
  const handleSelectManagerChange = (tag, value) => {
    if (handleIsChangeManager(value)) {
      setMangerState(true);
      setAddInfo({ ...addInfo, [tag]: value });
    } else {
      setMangerState(false)
      setAddInfo({ ...addInfo, [tag]:0 });
      notification.error({
        description: "该学生已经添加到参加者！",
        message: "警告",
      });
    }
  };
  const handleIsChangeManager = (value) => {
    for (var i = 0; i < addInfo.participantSid.length; i++) {
      if (value === addInfo.participantSid[i]) {
        return false;
      }
    }
    return true;
  };
  //规则验证
  const handleMyValidate = (state, info) => {
    myValidate(state, info);
  };

  function myValidate(name, data) {
    if (!data) {
      data = { ...addInfo };
    }
    switch (name) {
      case "managerName": {
        if (managerState === false) {
          setValidateState({
            ...validateState,
            [name]: "error",
            managerNamehelp: "请重新选择主持者!",
          });
        } else if (data.managerSid === 0) {
          setValidateState({
            ...validateState,
            [name]: "error",
            managerNamehelp: "请选择主持者!",
          });
        } else {
          setValidateState({
            ...validateState,
            [name]: "success",
            managerNamehelp: undefined,
          });
        }
        break;
      }
      case "insertTimestamp":
      case "endTime": {
        if (data[name] === undefined || data[name] === "") {
          setValidateState({
            ...validateState,
            time: "error",
            timehelp: "请选择时间范围!",
          });
        } else {
          setValidateState({
            ...validateState,
            time: "success",
            timehelp: undefined,
          });
        }
        break;
      }
      default:
        break;
    }
  }
  //修改参加学生
  function handleSelectPartChange(tag, value, options) {
    let stus = { ...addInfo };
    stus[tag] = value;
    setAddInfo(stus);
  }
  //添加学生
  const handleClickAddPartStudent = () => {
    let list = [...addInfo.participantSid];
    if (addInfo.selectParticipantSid === addInfo.managerSid) {
      notification.error({
        description: "该学生是主持者！",
        message: "警告",
      });
    } else if (list.indexOf(addInfo.selectParticipantSid) === -1) {
      list.push(addInfo.selectParticipantSid);
      setAddInfo({ ...addInfo, participantSid: list });
    } else {
      notification.error({
        description: "该学生已经添加！",
        message: "警告",
      });
    }
  };
  const handleAddTimeChange = (tag, date, dateString) => {
    let value = "";
    let newAdd = { ...addInfo };
    if (date) {
      value = parseInt(moment(dateString, dateFormat).valueOf() / 1000);
    }
    newAdd[tag] = value;
    setAddInfo(newAdd);
    handleMyValidate(tag, newAdd);
  };
  //初始化
  const handleClearAddInfo = () => {
    // updataForm.resetFields()
    const initAdd = {
      itemType: 3,
      roleSid: 1,
      itemName: "",
      chargeTeacher: "",
      managerSid: 0,
      itemStatus: "",
      insertTimestamp: parseInt(new Date().getTime() / 1000),
      endTime: parseInt(new Date().getTime() / 1000),
      selectParticipantSid: 0,
      participantSid: [],
      statusDescription: "",
    };
    const initValidate = {
      managerName: "success",
      managerNamehelp: undefined,
      time: "success",
      timehelp: undefined,
    };
    setAddInfo({ ...initAdd });
    setValidateState({ ...initValidate });
    setMangerState(true);
    updataForm.resetFields();
  };

  const handleAddInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAddInfo({ ...addInfo, [name]: value });
  };
  const addOrUpdata = () => {
    if (props.rowstuInfo.length === 0) handleClickAddItem();
    else handleClickEditItem();
  };
  const handleBeforeAjax = () => {
    if (props.rowstuInfo.length === 0) {
      if (addInfo.itemNumber === "") return false;
    }
    if (
      addInfo.managerSid === 0 ||
      addInfo.insertTimestamp === "" ||
      addInfo.endTime === "" ||
      addInfo.itemName === "" ||
      addInfo.chargeTeacher === ""
    )
      return false;
    // if(validateState.managerName!='success'||validateState.time!='success') return false

    return true;
  };
  //点击添加
  const handleClickAddItem = () => {
    let flag = handleBeforeAjax();
    if (flag) {
      if (addInfo.insertTimestamp <= addInfo.endTime) {
        let formdata = { ...addInfo };
        formdata.participantSid = formdata.participantSid.join(",");
        formdata.sessionId = localStorage.sessionId;
        addProjectInfo(formdata);
      } else {
        notification.error({
          description: "开始时间不能大于结束时间！",
          message: "警告",
        });
      }
    } else {
      notification.error({
        description: "表单信息尚未完善，无法提交！",
        message: "警告",
      });
    }
  };
  //添加数据
  async function addProjectInfo(data) {
    props.setLoading(true);
    await post(
      {
        url: "/ec/project/addProjectInfo",
        data,
      },
      (data) => {
        notification.success({ description: "通知", message: "添加成功!" });

        props.closeAddForm();
        props.setLoading(false);
        props.upDateTable();
      },
      props.history
    );
  }

  //修改数据
  const handleClickEditItem = () => {
    // props.closeAddForm()
    let flag = handleBeforeAjax();
    if (flag) {
      if (addInfo.insertTimestamp <= addInfo.endTime) {
        let formdata = { ...addInfo };
        formdata.participantSid = formdata.participantSid.join(",");
        formdata.sessionId = localStorage.sessionId;
        formdata.roleSid = 1;
        formdata.statusDescription = formdata.itemStatus;
        delete formdata.participants;
        updateProjectInfo(formdata);
      } else {
        notification.error({
          description: "开始时间不能大于结束时间！",
          message: "警告",
        });
      }
    } else {
      // console.log("验证不符合")
      notification.error({
        description: "表单信息尚未完善，无法提交！",
        message: "警告",
      });
    }
  };
  async function updateProjectInfo(data) {
    props.setLoading(true);
    await post(
      {
        url: "/ec/project/updateProjectInfoByItemSid",
        data,
      },
      (data) => {
        props.closeAddForm(0);
        updataForm.resetFields();
        props.setLoading(false);

        props.upDateTable();
      },
      props.history
    );
  }

  //生命周期函数
  useEffect(() => {
    handleClearAddInfo();

    // setModel(props.modelState)
    if (props.rowstuInfo.length === 0) {
      updataForm.resetFields();
    } else ediDataInit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowstuInfo]);
  useEffect(() => {
    async function genEntryGrade() {
      await post(
        {
          url: "/ec/project/getOutStationGrade",
          data: {
            sessionId: localStorage.sessionId,
            inboundState: 2
          },
        },
        (data) => {
          if (data.code == 'N01') {
            let Outing = data.contents.Outing
            console.log(data.contents)
            for(let i = 0;i < Outing.length;i++){
              entryGrade.push(parseInt(Outing[i]))
            }
          }else {
            console.log('error')
          }
        },
        props.history
      );
        console.log(entryGrade)
      setEntryGrade(entryGrade);
    }
    genEntryGrade();

  }, [])

  return (
    <div>
      <Modal
        title={props.modelState == 1 ? "新建项目" : "修改项目"}
        visible={props.modelState !== 0}
        // onOk={upDateInfo}
        onCancel={() => {
          props.closeAddForm(0);
        }}
        destroyOnClose={true}
        footer={[
          <div key='1'>
            <Button key="back" onClick={handleClearAddInfo.bind(null)}>
              清空
            </Button>
            <Button
              key="submitAdd"
              type="primary"
              onClick={addOrUpdata.bind(null)}
            >
              提交
            </Button>
          </div>,
        ]}
      >
        <Form
          form={updataForm}
          validateMessages={validateMessages}
          validateTrigger="onBlur"
          preserve={false}
          {...layout}
          style={{ flexDirection: "column" }}
          ref={formRef}
        >
          <Form.Item
            name="itemName"
            label="方案名称"
            rules={[
              {
                required: true,
                message: "请输入项目名称!",
              },
            ]}
          >
            <Input
              placeholder="请输入方案名称"
              value={addInfo.itemName}
              defaultValue={addInfo.itemName}
              onChange={itemNameChange}
              name="itemName"
            ></Input>
          </Form.Item>
          <Form.Item
            name="itemNumber"
            label="项目编号"
            style={props.rowstuInfo.length === 0 ? { display: "none" } : null}
            rules={[
              {
                required: true,
                message: "请输入项目编号!",
              },
            ]}
          >
            <Input
              placeholder="请输入项目编号"
              value={addInfo.itemNumber}
              defaultValue={addInfo.itemNumber}
              onChange={handleAddInputChange}
              name="itemNumber"
            />
          </Form.Item>

          <Form.Item
            label="主持者"
            rules={[{ required: true }]}
            name="managerGrade"
            validateStatus={validateState.managerName}
            help={validateState.managerNamehelp}
            // ref={formRef}
          >
            {/* <Select
              placeholder="年级"
              onChange={handleSelectGradeChange.bind(null)}
              defaultValue={addInfo.managerGrade}
              allowClear
              style={{ width: "20%", marginRight: "5%" }}
            >
              <Option value="2017" key="2017">
                2017
              </Option>
              <Option value="2018" key="2018">
                2018
              </Option>
              <Option value="2019" key="2019">
                2019
              </Option>
              <Option value="2020" key="2020">
                2020
              </Option>
            </Select> */}

            <Select
              placeholder="年级"
              onChange={handleSelectGradeChange.bind(null)}
              defaultValue={addInfo.managerGrade}
              allowClear
              style={{ width: "20%", marginRight: "5%" }}
            >
              {entryGrade.map((item, index) => (
                <Option value={item} key={index}>
                  {item}
                </Option>
              ))}
            </Select>

            <Select
              // ref={formRef}
              placeholder="姓名"
              name="managerName"
              style={{ width: "72%" }}
              onChange={handleSelectManagerChange.bind(null, "managerSid")}
              onBlur={handleMyValidate.bind(null, "managerName", addInfo)}
              defaultValue={addInfo.managerName}
              allowClear
            >
              {renderMagStuList()}
            </Select>
          </Form.Item>

          <Form.Item
            label="参与者"
            name="participantSid"
            initialValue={addInfo.itemName}
          >
            {/* <Select
              placeholder="年级"
              style={{ width: "20%", marginRight: "5%" }}
              defaultValue={addInfo.partGrade}
              onChange={handleSelectPartGradeChange.bind(null)}
              allowClear
            >
              <Option value="2017" key="2017">
                2017
              </Option>
              <Option value="2018" key="2018">
                2018
              </Option>
              <Option value="2019" key="2019">
                2019
              </Option>
              <Option value="2020" key="2020">
                2020
              </Option>
            </Select> */}
            <Select
              placeholder="年级"
              style={{ width: "20%", marginRight: "5%" }}
              defaultValue={addInfo.partGrade}
              onChange={handleSelectPartGradeChange.bind(null)}
              allowClear
            >
              {entryGrade.map((item, index) => (
                <Option value={item} key={index}>
                  {item}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="姓名"
              style={{ width: "50%", marginRight: "5%" }}
              onChange={handleSelectPartChange.bind(
                null,
                "selectParticipantSid"
              )}
              allowClear
            >
              {renderPartList()}
            </Select>
            <Button type="primary" onClick={handleClickAddPartStudent}>
              {" "}
              添加
            </Button>
            <div className="tagsDiv">{handleGetPartStudentList()}</div>
          </Form.Item>

          <Form.Item
            name="chargeTeacher"
            label="负责老师"
            rules={[
              {
                required: true,
                message: "请输入负责老师名称!",
              },
            ]}
          >
            <Input
              placeholder="请输入负责老师"
              value={addInfo.chargeTeacher}
              defaultValue={addInfo.chargeTeacher}
              onChange={handleAddInputChange}
              name="chargeTeacher"
            />
          </Form.Item>
          <Form.Item
            name="insertTimestamp"
            label="时间范围"
            rules={[{ required: true }]}
            validateStatus={validateState.time}
            help={validateState.timehelp}
          >
            <Space direction="vertical" size={12} style={{ width: "48%" }}>
              <DatePicker
                defaultValue={
                  props.rowstuInfo.length === 0
                    ? moment(todayDate, dateFormat)
                    : moment(
                        new Date(
                          addInfo.insertTimestamp * 1000
                        ).toLocaleDateString(),
                        dateFormat
                      )
                }
                format={dateFormat}
                //   value={ filterInfo.startTimestamp }
                style={{ width: "100%" }}
                onChange={handleAddTimeChange.bind(null, "insertTimestamp")}
                onBlur={handleMyValidate.bind(null, "insertTimestamp", addInfo)}
                name="insertTimestamp"
              />
            </Space>
            <span> - </span>
            <Space direction="vertical" size={12} style={{ width: "48%" }}>
              <DatePicker
                defaultValue={
                  props.rowstuInfo.length === 0
                    ? moment(todayDate, dateFormat)
                    : moment(
                        new Date(addInfo.endTime * 1000).toLocaleDateString(),
                        dateFormat
                      )
                }
                format={dateFormat}
                style={{ width: "100%" }}
                onChange={handleAddTimeChange.bind(null, "endTime")}
                onBlur={handleMyValidate.bind(null, "endTime", addInfo)}
                name="endTime"
              />
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default addProject;
