/*
 * @Author: HouQi 
 * @Date: 2021-11-13 15:46:10 
 * @Last Modified by: HouQi
 * @Last Modified time: 2021-11-19 10:25:11
 */
import React, { useState, useEffect } from "react";
import { Button, Form, Input, DatePicker, Row, Col, notification } from "antd";
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment'
import { post } from "../../../network/request";


export default function AddOrEdit(props) {
  const flag = props.modelState;
  const todayDate = new Date().toLocaleDateString()
  // console.log(todayDate)
  // console.log(props.rowstuInfo.insertTimestamp)
  const dateFormat = 'YYYY/MM/DD'
  const [updataForm] = Form.useForm()

  // onFinish
  const onFinish = (value) => {
    console.log(value)
    value.insertTimestamp = 
    parseInt(moment(value.insertTimestamp,dateFormat).valueOf() / 1000)
    let subInfo = {
      itemType: 3,
      roleSid: parseInt(localStorage.roleSid),
      itemName: value.itemName,
      insertTimestamp: value.insertTimestamp,
      endTime: parseInt(new Date().getTime() / 1000),
      managerSid: parseInt(localStorage.studentSid),
      participantSid: "",
      statusDescription: "",
      pageSize: 10,
      index: 1,
      itemStatus: "",
    }
    console.log(subInfo)
    console.log(flag)
    if (flag == 1) {
      addSub(subInfo);
    } else if (flag == 2) {
      editSub(subInfo);
    } else {
      console.log('error!')
    }
  }

  const addSub = async function (info) {
    console.log(info);
    await post({
      url: '/ec/project/addProjectWriteInfo',
      data: {
        sessionId: localStorage.sessionId,
        ...info
      }
    }, data => {
      if (data.code == "N01") {
        notification.success({
          description: '新增成功!',
          message: '通知'
        });
      }
      console.log(data);
      props.upDateTable()
      props.closeAddForm()
    })
  }

  // const test = () => {
  //   console.log('777')
  //   updataForm.resetFields()
  // }

  const editSub = async function (info) {
    console.log(info);
    console.log(props)
    // info.insertTimestamp
    await post({
      url: '/ec/project/updateProjectWriteInfoByItemSid',
      data: {
        sessionId: localStorage.sessionId,
        roleSid: info.roleSid,
        itemSid: props.rowstuInfo.itemSid,
        itemName: info.itemName,
        managerSid: parseInt(localStorage.studentSid),
        chargeTeacher: "",
        insertTimestamp: info.insertTimestamp,
        endTime: parseInt(new Date().getTime() / 1000),
        participantSid: "",
        statusDescription: "",
        itemNumber: ""
      }
    }, data => {
      if (data.code == "N01") {
        notification.success({
          description: '修改成功!',
          message: '通知'
        });

      }
      console.log(data);
      props.upDateTable()
      props.closeAddForm()
    })
  }

  // 表单样式
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 20 },
  };
  // 时间验证
  const config = {
    rules: [{ type: 'date', required: true, message: '请选择提交时间!' }],
  };

  // 首次渲染后执行
  // useEffect(() => {
  // }, [props.rowstuInfo])

  return (
    <>
      <Form {...formItemLayout}
        // ref={this.formRef}
        onFinish={onFinish}
        form={updataForm}
        validateTrigger="onBlur"
        initialValues={{
          itemName: flag === 1 ? '' : props.rowstuInfo.itemName,
          managerName: localStorage.studentName,
          insertTimestamp: flag === 1 ?
            moment(todayDate, dateFormat) :
            moment(props.rowstuInfo.insertTimestamp, dateFormat)
        }}

      >
        <Form.Item
          label="综述名称"
          name="itemName"
          rules={[{ required: true, message: '请输入综述名称!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="汇报人"
          name="managerName"
          rules={[{ required: true, message: '请输入汇报人!' }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="提交时间"
          name="insertTimestamp"
          {...config}>
          <DatePicker />
        </Form.Item>
        <FormItem labelAlign={"right"} labelCol={{ span: 3, offset: 12 }}>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button
                style={{ margin: '0 8px' }}
                onClick={() => { updataForm.resetFields() }}
              >{flag === 1 ? '清空' : '重置'}</Button>
              <Button
                type="primary"
                htmlType="submit"  >提交</Button>
            </Col>
          </Row>
        </FormItem>
      </Form>
    </>
  )
}