/*
 * @Author: HouQi 
 * @Date: 2021-11-13 15:46:22 
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-06-10 18:01:02
 */

import React, { useState, useMemo, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Button,
  Table,
  Upload,
  Image,
  message,
  Modal,
  Popconfirm,
  notification
} from 'antd'
import CustomCard from "../../../components/common/card/card";
import { UploadOutlined } from "@ant-design/icons";
import AddReview from './addReview';
import { post } from "../../../network/request";

const { Option } = Select;

export default function StuReviewReport(props) {
  // console.log(props)
  const headerCardOptions = { title: null, bordered: false };
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll", height: "calc(100vh - 78px - 88px)" },
  };
  const [loading, setLoading] = useState();
  const [total, setTotal] = useState(0);
  const [rawTable, setRawTable] = useState([]);
  // 新增
  const [studentInfo, setStudentInfo] = useState([])
  const [rowDataList, setRowDataList] = useState([])
  //页面总数
  const [modleState, setMole] = useState(0)
  // load
  const [urlLoading, setUrlLoading] = useState(null);
  //图片table数据
  const [fileTable, setFileTable] = useState([]);
  // 选中的综述
  const [selectItemId, setSelectItemId] = useState(0);
  //已装载图片
  const [uploadFileList, setuploadFileList] = useState([]);
  //上传图片Dialog
  const [uploadDialog, setUploadDialog] = useState({ visible: false });
  //控制查看图片
  const [fileDialog, setFileDialog] = useState({ visible: false });
  //查看要上传的图片
  const [previewInfo, setPreviewInfo] = useState({
    priviewVisible: false,
    priviewImage: '',
  });
  //要查看的图片论文ID
  const [itemSid, setItemSid] = useState("");
  // 查看固定的图片
  const [pictureDialog, setPictureDialog] = useState({ visible: false });
  const [pictureContent, setPictureContent] = useState({});

  const [fileContent, setFileContent] = useState({});

  // 上传文件按钮
  function handleUploadFile(data, record) {
    setSelectItemId(record.itemSid)
    setuploadFileList([])
    setUploadDialog({
      visible: true,
    });
  }
  //取消上传图片
  function handleCancelUpload() {
    setUploadDialog({
      visible: false,
    });
  }
  //确定上传图片
  async function handleConfirmUpload() {
    deletePreview();
    let list = [...uploadFileList];
    if (list != []) {
      list.forEach((item) => {
        console.log(item);
        upLoad(item);
      });
    } else {
      console.log('空');
    }
  }
  // 查看文件（获取行数据、itemSid）
  function handleClickFile(record, data) {
    console.log(data)
    setFileDialog({
      visible: true,
    });
    // setItemSid(data.itemSid);
    getFileData(data.itemSid);
    // setFileContent(data);
  }
  //查看固定图片
  function handleClickPicture(record, data) {
    console.log(data)
    // setPictureDialog({
    //   visible: true,
    // });
    getPictureData(data.itemSid, data.fileSid);
  }
  //上传图片
  async function upLoad(item) {
    let data = {
      sessionId: localStorage.sessionId,
      itemSid: selectItemId,
      flag: 0,
      file: item,
      updateTime: parseInt(new Date().getTime() / 1000),
    };
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    await post(
      {
        url: "/ec/outResearchProject/upLoadResearchFile",
        data: formData,
      },
      () => {
        notification.success({ description: "通知", message: "上传图片成功!" });
        handleCancelUpload();
      },
      props.history
    );
  }
  //获取固定图片
  async function getPictureData(itemSid, fileSid) {
    console.log(itemSid)
    console.log(fileSid)
    await post(
      {
        url: "/ec/outResearchProject/downloadResearchFile",
        data: {
          sessionId: localStorage.sessionId,
          flag: 0,
          itemSid: itemSid,
          fileSid: fileSid,
        },
      },
      (data) => {
        // setPictureContent(data.contents);
        console.log(data)
        if ((data.code = "N01")) {
          let url = data.contents;
          let a = document.createElement("a");
          let event = new MouseEvent("click");
          // a.download = "file";
          a.target = "_blank";
          a.href = url;
          a.dispatchEvent(event);
        }
      },
      props.history
    );
  }
  //获取图片info
  async function getFileData(itemSid) {
    setUrlLoading(true);
    await post(
      {
        url: "/ec/outResearchProject/getResearchFileInfo",
        data: {
          sessionId: localStorage.sessionId,
          flag: 0,
          itemSid: itemSid,
        },
      },
      (data) => {
        // console.log(data.code);
        if (data.code == "E02") {
          setFileTable([]);
          setUrlLoading(false);
        } else {
          setUrlLoading(false);
          setFileTable(data.contents);
        }
      },
      props.history
    );
  }
  // 删除预览？
  function deletePreview() {
    setPreviewInfo({
      priviewVisible: false,
      priviewImage: '',
    });
  }
  // 上传前回调
  function stopUpload(file) {
    let oldList = [...uploadFileList];
    oldList.push(file);
    console.log(oldList)
    setuploadFileList(oldList);
    return new Promise((resolve, reject) => {
      return reject(false);
    });
  }

  // 关闭
  const closeAddForm = () => {
    setMole(0)
  }

  const upDateTable = () => {
    getTableData(filterInfo);
  }
  const uploadprops = {
    // listType: "picture",// 指定则显示照片列表，不指定不影响使用
    beforeUpload: stopUpload,
    fileList: uploadFileList,
  };

  //  定义表头
  const columns = [
    {
      title: "编号",
      dataIndex: "itemNumber",
      align: "center",
    },
    {
      title: "论文综述名称",
      dataIndex: "itemName",
      key: "itemName",
      align: "center",
    },
    {
      title: "汇报人",
      dataIndex: "managerName",
      key: "managerName",
      align: "center",
    },
    {
      title: "提交时间",
      dataIndex: "insertTimestamp",
      key: "insertTimestamp",
      align: "center",
    },
    {
      title: "审核状态",
      key: "itemStatus",
      align: "center",
      render: (text) => {
        if (text.itemStatus == "已同意") {
          return <span
            className="ec-text-color-green"
          >{text.itemStatus}</span>;
        } else if (text.itemStatus == "已拒绝") {
          return <span
            className="ec-text-color-red"
          >{text.itemStatus}</span>;
        } else if (text.itemStatus == "待审核") {
          return <span
            className="ec-text-color-orange"
          >{text.itemStatus}</span>;
        }
      },
    },
    {
      title: "处理事由",
      dataIndex: "note",
      key: "note",
      align: "center"
    },
    {
      title: "操作",
      key: "operate",
      align: "center",
      render: (text, record) => (
        <>
          <Button
            className="operate-button ec-button-lightBlue"
            size="small"
            onClick={handleUploadFile.bind(null, text, record)}
          >上传文件</Button>
          <Button
            className="lookPicture ec-button-lightBlue"
            size="small"
            onClick={handleClickFile.bind(null, text, record)}
          >查看文件</Button>
          <Button
            className="operate-button ec-button-green"
            size="small"
            onClick={editDataInit.bind(null, text, record)}
          >修改信息</Button>
          <Popconfirm
            className="delete"
            placement="bottomRight"
            title="确认删除吗？"
            onConfirm={handleDeleteItem.bind(null, text, record)}
            okText="确定"
            cancelText="取消"
          >
            <Button
              className="operate-button"
              type="danger"
              size="small"
            >
              删除
            </Button>
          </Popconfirm>
        </>
      )
    }
  ]
  //查看图片Table
  const fileColumns = [
    {
      title: "文件名称",
      dataIndex: "filename",
      align: "center",
    },
    {
      title: "操作",
      key: "operate",
      align: "center",
      render: (text, record) => (
        <>
          <Button
            type="primary"
            size="small"
            onClick={handleClickPicture.bind(null, text, record)}
          >
            获取文件
          </Button>

          <Button
            type="danger"
            size="small"
            style={{ marginLeft: '10px' }}
            onClick={deleteFile.bind(null, text)}
          >
            删除
          </Button>
        </>
      ),
    },
  ];

  const table = useMemo(() => {
    rawTable.forEach((item) => {
      switch (item.itemStatus) {
        case 1:
          item.itemStatus = "待审核";
          break;
        case 2:
          item.itemStatus = "已同意";
          break;
        case 3:
          item.itemStatus = "已拒绝";
          break;
        default:
          item.itemStatus = "暂无";
      }
      item.insertTimestamp = !item.insertTimestamp
        ? "暂无"
        : new Date(item.insertTimestamp * 1000).toLocaleDateString();
    });
    return rawTable;
  }, [rawTable])

  // 初始数据
  const [filterForm] = Form.useForm();
  // 筛选原始表单
  const [filterInfo, setFilterInfo] = useState({
    itemType: 3,
    roleSid: parseInt(localStorage.roleSid),
    pageSize: 10,
    index: 1,
    itemStatus: "",
    managerName: 1,
    studentSid: parseInt(localStorage.studentSid)
  });

  // 清除
  function handleClear() {
    filterForm.resetFields();
    const initState = {
      itemType: 3,
      roleSid: parseInt(localStorage.roleSid),
      pageSize: 10,
      index: 1,
      itemStatus: "",
      managerName: 1,
      studentSid: parseInt(localStorage.studentSid)
    };
    setFilterInfo({ ...initState });
    getTableData(initState);
  }

  // 筛选
  function handleFilter() {
    setFilterInfo({ ...filterInfo, index: 1, pageSize: 10 });
    getTableData(filterInfo);
  }
  // 翻页
  function handlePageChange(event, pageSize) {
    setFilterInfo({ ...filterInfo, pageSize, index: event });
  }

  // 设置筛选表单
  function handleSelectChange(tag, value, options) {
    setFilterInfo({ ...filterInfo, [tag]: value });
  }

  // 获取表格数据
  async function getTableData(params) {
    setLoading(true);
    await post(
      {
        url: "/ec/project/getProjectInfoByWriteItemType",
        data: {
          sessionId: localStorage.sessionId,
          ...params
        }
      },
      (data) => {
        setRawTable(data.contents.list)
        setLoading(false);
        setTotal(data.contents.size);
      }
    )
  }
  //删除固定图片
  async function deleteFile(record) {
    await post(
      {
        url: "/ec/outResearchProject/deleteResearchFile",
        data: {
          sessionId: localStorage.sessionId,
          fileSid: record.fileSid,
          itemSid: record.itemSid,
          flag: 0,
        },
      },
      () => {
        notification.success({ description: "删除照片成功", message: '通知' });
        getFileData(record.itemSid);
        // setFileContent(record);
      },
      props.history
    );
  }
  // 删除
  async function handleDeleteItem(text, data) {
    await post({
      url: '/ec/project/deleteProjectWriteInfo',
      data: {
        sessionId: localStorage.sessionId,
        itemSid: data.itemSid,
        roleSid: localStorage.roleSid,
      }
    }, (value) => {
      notification.success({ description: '删除成功!', message: '通知' });
      getTableData(filterInfo);
    }, props.history)
  }

  // 触发首次获取数据
  useEffect(() => {
    getTableData(filterInfo);
  }, [filterInfo.pageSize, filterInfo.index]);

  // 新增综述
  function handleClickAddBtn() {
    setMole(1)
    setRowDataList([])
  }
  // 点击修改按钮触发 修改初始化，将行数据赋值到修改框中
  const editDataInit = (value, record) => {
    setMole(2)
    setRowDataList(record)
  }
  //修改
  const upDateInfo = () => {
    setMole(2)
  }
  return (
    <>
      <CustomCard options={headerCardOptions}>
        <Form
          layout="inline"
          style={{ flexDirection: "column" }}
          form={filterForm}
        >
          <Row>
            <Col span={6} offset={1}>
              <Form.Item label="管理员审核状态" name="itemStatus">
                <Select
                  style={{ width: "80%" }}
                  value={filterInfo.itemStatus}
                  placeholder="--请选择--"
                  name="itemStatus"
                  onChange={handleSelectChange.bind(null, 'itemStatus')}
                  allowClear
                >
                  <Option value="1" key="1">
                    待审核
                  </Option>
                  <Option value="2" key="2">
                    已同意
                  </Option>
                  <Option value="3" key="3">
                    已拒绝
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" onClick={handleFilter}>
                筛选
              </Button>
            </Col>
            <Col offset={1}>
              <Button type="plain" htmlType="submit" onClick={handleClear}>
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomCard>
      <CustomCard options={tableCardOptions}>
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
            <Button type="primary" htmlType="submit" onClick={handleClickAddBtn}>新增综述</Button>
          </div>
          <Table
            className="table"
            rowKey="itemNumber"
            columns={columns}
            dataSource={table}
            loading={loading}
            pagination={{
              total: total,
              current: filterInfo.index,
              pageSize: filterInfo.pageSize,
              pageSizeOptions: [10, 20, 30],
              onChange: handlePageChange,
              showSizeChanger: true,
            }}
          />
        </div>
      </CustomCard>

      <Modal
        title={modleState == 1 ? "新增综述" : "修改综述"}
        visible={modleState !== 0}
        onOk={upDateInfo}
        onCancel={() => { setMole(0) }}
        destroyOnClose={true}
        footer={null}
      >
        <AddReview
          rowstuInfo={rowDataList}
          closeAddForm={closeAddForm}
          upDateTable={upDateTable}
          modelState={modleState} />
      </Modal>
      {/* 上传图片 */}
      <Modal
        title="上传文件"
        visible={uploadDialog.visible}
        onCancel={handleCancelUpload}
        footer={[
          <div key={Math.floor(Math.random() * 100)}>
            <Button type="primary" onClick={handleConfirmUpload}>
              确认上传
            </Button>
            <Button onClick={handleCancelUpload}>关闭</Button>
          </div>,
        ]}
      >
        <div className="clearfix">
          {/* <Upload {...uploadprops}>
            <Button icon={<UploadOutlined />}>选择图片</Button>
          </Upload> */}
          <Upload {...uploadprops}>
            <Button type="primary">选择文件</Button>
          </Upload>
          <Modal
            visible={previewInfo.priviewVisible}
            footer={null}
            onCancel={() => { setPreviewInfo({ priviewVisible: false }) }}
          >
            <img alt="example" src={previewInfo.priviewImage} />
          </Modal>
        </div>
      </Modal>
      {/* 查看图片列表 */}
      <Modal
        title="文件列表"
        visible={fileDialog.visible}
        onCancel={() => {
          setFileDialog({
            visible: false,
          });
        }}
        footer={[
          <Button key="back" onClick={() => {
            setFileDialog({
              visible: false,
            });
          }}>
            关闭
          </Button>,
        ]}
      >
        <Table
          className="pictureTable"
          rowKey="fileSid"
          columns={fileColumns}
          dataSource={fileTable}
          loading={urlLoading}
          pagination={false}
        ></Table>
      </Modal>
      {/* 查看具体图片 */}
      <Modal
        title="查看图片"
        className="pictureList"
        visible={pictureDialog.visible}
        onCancel={() => {
          setPictureDialog({ visible: false })
        }}
        footer={[
          <Button key="back" onClick={() => {
            setPictureDialog({ visible: false })
          }}>
            关闭
          </Button>,
        ]}
      >
        <div style={{ width: "100%", margin: "0 auto", textAlign: "center" }}>
          <Image
            width={200}
            height={200}
            src={pictureContent}
          />
        </div>
      </Modal>
    </>
  )
}