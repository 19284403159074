/*
 * @Author: HouQi
 * @Date: 2021-09-06 08:58:21
 * @Last Modified by: HouQi
 * @Last Modified time: 2021-12-11 14:22:15
 */
import React, { useEffect, useState, useMemo } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Table,
  Space,
  notification,
  Modal,
  Tag,
  DatePicker,
  Popconfirm,
  message,
} from "antd";
import CustomCard from "../../../components/common/card/card";
import "./verticalStatistics.css";
import { post } from "../../../network/request";
import { request } from "../../../network/request";

// 尝试
import { Link } from "react-router-dom";
import { render } from "@testing-library/react";
import { useHistory } from "react-router-dom";

export default function HorizonStatistics(props) {
  let history = useHistory();
  const [filterInfo, setFilterInfo] = useState({
    pageSize: 10,
    index: 1,
    studentName: "",
    entryGrade: "",
    degreeType: "",
  });
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll", height: "calc(100vh - 78px - 130px)" },
  };
  const [rawTable, setRawTable] = useState([]);
  const table = useMemo(() => {
    rawTable.forEach((item) => {
      if (item.degreeType === 1) {
        item.degreeType = "学硕";
      } else if (item.degreeType === 2) {
        item.degreeType = "专硕";
      } else {
        item.degreeType = "未知";
      }
      // item.projAndCompTotal = item.total + item.projectParTotal;
      item.projAndCompTotal = item.total;
      // item.applicationAll = item.applicationTotal + item.applicationParTotal;
      item.applicationAll = item.applicationTotal;
    });
    return rawTable;
  }, [rawTable]);

  const detailColumns = [
    {
      dataIndex: "title",
      key: "title",
      align: "center",
    },
    {
      dataIndex: "content",
      key: "content",
      align: "center",
    },
  ];
  const [entryGrade, setEntryGrade] = useState([]);
  const headerCardOptions = { title: null, bordered: false };
  const [filterForm] = Form.useForm();
  const [detailDialog, setDetailDialog] = useState({ visible: false });
  const { Option } = Select;
  const dateFormat = "YYYY/MM/DD";
  const [detailContent, setDetailContent] = useState({});
  const [loading, setLoading] = useState(null);
  const [total, setTotal] = useState(0);
  const columns = [
    {
      title: "姓名",
      dataIndex: "studentName",
      key: "studentName",
      align: "center",
      width: "150px",
      fixed: true,
    },
    {
      title: "年级",
      dataIndex: "entryGrade",
      key: "entryGrade",
      align: "center",
      width: "150px",
      fixed: true,
    },
    {
      title: "培养类型",
      dataIndex: "degreeType",
      key: "degreeType",
      align: "center",
      width: "150px",
      fixed: true,
    },
    {
      title: "纵向项目与比赛",
      dataIndex: "projAndCompTotal",
      key: "projAndCompTotal",
      align: "center",
      width: "160px",
      render: (projAndCompTotal, text) => (
        <Button
          onClick={toVertProject.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {projAndCompTotal}
        </Button>
      ),
    },
    // {
    //   title: "纵向申请书",
    //   dataIndex: "applicationAll",
    //   key: "applicationAll",
    //   align: "center",
    //   width: '160px',
    //   render: (applicationAll, text) => (
    //     <Button
    //       onClick={toApplicat.bind(null, text, 1)}
    //       style={{ border: 1, borderRadius: 12 }}>
    //       {applicationAll}
    //     </Button>
    //   )
    // },
    {
      title: "论文综述报告",
      dataIndex: "reportTotal",
      key: "reportTotal",
      align: "center",
      width: "160px",
      render: (reportTotal, text) => (
        <Button
          onClick={toReport.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {reportTotal}
        </Button>
      ),
    },
    {
      title: "发表科研论文",
      dataIndex: "paperTotal",
      key: "paperTotal",
      align: "center",
      width: "160px",
      render: (paperTotal, text) => (
        <Button
          onClick={toPaper.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {paperTotal}
        </Button>
      ),
    },
    {
      title: "发明专利",
      dataIndex: "patentTotal",
      key: "patentTotal",
      align: "center",
      width: "160px",
      render: (patentTotal, text) => (
        <Button
          onClick={toPatent.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {patentTotal}
        </Button>
      ),
    },
    {
      title: "技术框架报告",
      dataIndex: "frameworkTotal",
      key: "frameworkTotal",
      align: "center",
      width: "160px",
      render: (frameworkTotal, text) => (
        <Button
          onClick={toFramework.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {frameworkTotal}
        </Button>
      ),
    },
    //新增内容
    //数据接口还没改

    {
      title: "组长-第一等级",
      dataIndex: "applicationTotal",
      key: "applicationTotal",
      align: "center",
      width: "160px",
      render: (applicationTotal, text) => (
        <Button
          onClick={toApplicat.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {applicationTotal[0].grade1}
        </Button>
      ),
    },
    {
      title: "组员-第一等级",
      dataIndex: "applicationParTotal",
      key: "applicationParTotal",
      align: "center",
      width: "160px",
      render: (applicationParTotal, text) => (
        <Button
          onClick={toApplicat.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {applicationParTotal[0].grade1}
        </Button>
      ),
    },
    {
      title: "组长-第二等级",
      dataIndex: "applicationTotal",
      key: "applicationTotal",
      align: "center",
      width: "160px",
      render: (applicationTotal, text) => (
        <Button
          onClick={toApplicat.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {applicationTotal[0].grade2}
        </Button>
      ),
    },
    {
      title: "组员-第二等级",
      dataIndex: "applicationParTotal",
      key: "applicationParTotal",
      align: "center",
      width: "160px",
      render: (applicationParTotal, text) => (
        <Button
          onClick={toApplicat.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {applicationParTotal[0].grade2}
        </Button>
      ),
    },
    {
      title: "组长-第三等级",
      dataIndex: "applicationTotal",
      key: "applicationTotal",
      align: "center",
      width: "160px",
      render: (applicationTotal, text) => (
        <Button
          onClick={toApplicat.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {applicationTotal[0].grade3}
        </Button>
      ),
    },
    {
      title: "组员-第三等级",
      dataIndex: "applicationParTotal",
      key: "applicationParTotal",
      align: "center",
      width: "160px",
      render: (applicationParTotal, text) => (
        <Button
          onClick={toApplicat.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {applicationParTotal[0].grade3}
        </Button>
      ),
    },
    {
      title: "组长-第四等级",
      dataIndex: "applicationTotal",
      key: "applicationTotal",
      align: "center",
      width: "160px",
      render: (applicationTotal, text) => (
        <Button
          onClick={toApplicat.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {applicationTotal[0].grade4}
        </Button>
      ),
    },
    {
      title: "组员-第四等级",
      dataIndex: "applicationParTotal",
      key: "applicationParTotal",
      align: "center",
      width: "160px",
      render: (applicationParTotal, text) => (
        <Button
          onClick={toApplicat.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {applicationParTotal[0].grade4}
        </Button>
      ),
    },
    {
      title: "组长-学习项目",
      dataIndex: "applicationTotal",
      key: "applicationTotal",
      align: "center",
      width: "160px",
      render: (applicationTotal, text) => (
        <Button
          onClick={toApplicat.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {applicationTotal[0].grade5}
        </Button>
      ),
    },
    {
      title: "组员-学习项目",
      dataIndex: "applicationParTotal",
      key: "applicationParTotal",
      align: "center",
      width: "160px",
      render: (applicationParTotal, text) => (
        <Button
          onClick={toApplicat.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {applicationParTotal[0].grade5}
        </Button>
      ),
    },
    //以上内容为新增部分
  ];

  useEffect(() => {
    async function genEntryGrade() {
      await post(
        {
          url: "/ec/project/getOutStationGrade",
          data: {
            sessionId: localStorage.sessionId,
            inboundState: 2,
          },
        },
        (data) => {
          if (data.code == "N01") {
            let allGrade = data.contents.allGrade;
            console.log(allGrade);
            for (let i = 0; i < allGrade.length; i++) {
              entryGrade.push(parseInt(allGrade[i]));
            }
          } else {
            console.log("error");
          }
        },
        props.history
      );
      // console.log(entryGrade)
      setEntryGrade(entryGrade);
    }
    genEntryGrade();
  }, []);
  useEffect(() => {
    getTableData(filterInfo);
  }, [filterInfo.pageSize, filterInfo.currIndex]);

  function handlePageChange(event, pageSize) {
    const index = event;
    setFilterInfo({ ...filterInfo, pageSize, currIndex: index, index: index });
  }
  function toVertProject(text, num) {
    history.push({
      pathname: "/home/studentProjectManage",
      state: { data: text, num: num },
    });
  }
  function toApplicat(text, num) {
    history.push({
      pathname: "/home/erectapplication",
      state: { data: text, num: num },
    });
  }
  function toReport(text, num) {
    history.push({
      pathname: "/home/reviewReport",
      state: { data: text, num: num },
    });
  }
  function toPaper(text, num) {
    history.push({
      pathname: "/home/paperManage",
      state: { data: text, num: num },
    });
  }
  function toPatent(text, num) {
    history.push({
      pathname: "/home/PatentManage",
      state: { data: text, num: num },
    });
  }
  function toFramework(text, num) {
    history.push({
      pathname: "/home/verticalTechnicalFramework",
      state: { data: text, num: num },
    });
  }
  // async function handleClickExport() {
  //   // console.log('$$')
  //   const rawData = await request({
  //     url: "/ec/outResearchProject/downloadResearchSumFile",
  //     method: "post",
  //     data: {
  //       sessionId: localStorage.sessionId,
  //       degreeType: filterInfo.degreeType,
  //       entryGrade: filterInfo.entryGrade,
  //       studentName: filterInfo.studentName
  //     },

  //   });
  //   console.log(rawData)
  //   console.log(rawData.data.content)
  //   if (rawData.data?.code) {
  //     // 加问号为了解决浏览器缓存文件问题
  //     let downloadURL = rawData.data.content.researchFileUrl;
  //     window.location = downloadURL + '?/' + Math.random();
  //     // window.open(downloadURL) /* 可以下载但会屏闪 */
  //     /*
  //       window.open（）方法会直接打开新的窗口显示跳转网页，是window对象的方法。
  //       window.location 会在当前网页更改url的地址进行跳转，不会弹出新的页面，是location对象的属性。
  //     */
  //   }
  // }
  async function handleClickExport() {
    const rawData = await request({
      url: "/ec/outResearchProject/downloadResearchSumFile",
      method: "post",
      data: {
        sessionId: localStorage.sessionId,
        degreeType: filterInfo.degreeType,
        entryGrade: filterInfo.entryGrade,
        studentName: filterInfo.studentName
      },
    });
    console.log(rawData);
    console.log(rawData.data.content);
    if (rawData.data?.code) {
      // 加问号为了解决浏览器缓存文件问题
      let downloadURL = rawData.data.content.researchFileUrl;
      // 创建一个隐藏的iframe
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = downloadURL + '?/' + Math.random();
      document.body.appendChild(iframe);
      // iframe加载完成后移除
      iframe.onload = function() {
        document.body.removeChild(iframe);
      };
    }
}

  async function getTableData(filterData) {
    let studentName = filterData.studentName;
    let degreeType = filterData.degreeType;
    let entryGrade = filterData.entryGrade;
    setLoading(true);
    await post(
      {
        url: "/ec/outResearchProject/getProjectNum",
        data: {
          sessionId: localStorage.sessionId,
          studentName,
          degreeType,
          entryGrade,
        },
      },
      (data) => {
        setLoading(false);
        console.log(data.content.researchProjectData);
        setRawTable(data.content.researchProjectData);
      }
    );
  }
  function handleClear() {
    // 清除页面表单
    filterForm.resetFields();
    const initState = {
      studentName: "",
      degreeType: "",
      entryGrade: "",
    };
    setFilterInfo({
      ...initState,
    });
    getTableData(initState);
  }
  function handleFilter() {
    getTableData(filterInfo);
  }
  function handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    console.log(name, value);
    setFilterInfo({ ...filterInfo, [name]: value });
  }
  function handleCancel() {
    setDetailDialog({
      visible: false,
    });
  }

  function handleValueChange(changedValues, allValues) {
    for (let key in changedValues) {
      if (!changedValues[key]) {
        changedValues[key] = "";
      }
    }
    setFilterInfo({ ...filterInfo, ...changedValues });
  }

  return (
    <>
      <CustomCard options={headerCardOptions}>
        <Form
          layout="inline"
          style={{ flexDirection: "column" }}
          form={filterForm}
          onValuesChange={handleValueChange}
        >
          <Row className="project-manage-row">
            <Col span={6} offset={1}>
              <Form.Item label="学生姓名" name="studentName">
                <Input
                  placeholder="请输入姓名"
                  value={filterInfo.studentName}
                  style={{ width: "80%" }}
                  onChange={handleInputChange}
                  name="studentName"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="年级" name="entryGrade">
                {/* <Select
                  style={{ width: '80%' }}
                  placeholder="请选择年级"
                  allowClear
                >
                  <Option value="2021" key="5">2021</Option>
                  <Option value="2020" key="4">2020</Option>
                  <Option value="2019" key="3">2019</Option>
                  <Option value="2018" key="2">2018</Option>
                  <Option value="2017" key="1">2017</Option>
                </Select> */}

                <Select
                  style={{ width: "80%" }}
                  placeholder="请选择年级"
                  allowClear
                >
                  {entryGrade.map((item, index) => (
                    <Option value={item} key={index}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="培养类型" name="degreeType">
                <Select
                  style={{ width: "80%" }}
                  placeholder="请选择培养类型"
                  allowClear
                >
                  <Option value="2" key="2">
                    专硕
                  </Option>
                  <Option value="1" key="1">
                    学硕
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Button type="primary" htmlType="submit" onClick={handleFilter}>
                筛选
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button type="plain" htmlType="submit" onClick={handleClear}>
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomCard>
      <CustomCard options={tableCardOptions}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 10,
          }}
        >
          <Button type="primary" htmlType="submit" onClick={handleClickExport}>
            导出为Excel
          </Button>
        </div>

        <div style={{ overflowX: "auto" }}>
          <Table
            className="table"
            rowKey="studentNo"
            columns={columns}
            dataSource={table}
            loading={loading}
            scroll={{ x: "1450px" }}
            rowClassName={(record, index) => {
              let className = "light-row";
              if (index % 2 === 1) className = "dark-row";
              return className;
            }}
            pagination={{
              total: total,
              current: filterInfo.index,
              pageSize: filterInfo.pageSize,
              pageSizeOptions: [10, 20, 30],
              onChange: handlePageChange,
              showSizeChanger: true,
            }}
          />
        </div>
      </CustomCard>
    </>
  );
}
