/*
 * @Author: 廖立婷
 * @Date: 2020-11-26 15:45:40
 * @Last Modified by: HouQi
 * @Last Modified time: 2021-11-28 15:39:25
 */
import React, {useState,useMemo,useImperativeHandle,useEffect,} from "react";
import {DatePicker,Form,InputNumber,Button,Select,Modal,Table,Row,Col, notification,} from "antd";
import { post } from "../../../../network/request";
import "./addDialog.css";
const { Option } = Select;
export default function AddDialog(props) {
  const [addForm] = Form.useForm();
  const { cRef } = props;
  //表格列名
  const columns = [
    {
      title: '考察项目',
      dataIndex: 'requestTitle',
      key: 'index',
      align: 'center',

    },
    {
      title: '达标要求',
      dataIndex: 'inputMount',
      key: 'index',
      align: 'center',
    },
  ];
  // const [rawTable, setRawTable] = useState([]);
  const [rowDatas,setRowDatas] = useState([]);
  const dateFormat = "YYYY/MM/DD";
  const [grade, setGrade] = useState('');
  useImperativeHandle(
    cRef,
    () => ({
      addForm,
    }),
    []
  );
  useEffect(() => {
    // console.log("addprops");
    // console.log(props);
    getRowDatas(props.rawTable);
  },[props]);

  // 获取列表
  let getRowDatas = (data) => {
    console.log(data);
    let rowDatasTemp = [];
    let temp ={};
    for(let i = 0 ; i < data.length-1 ; i++){
      if(i < data.length-2){
        temp = {
          index:i,
          requestTitle : data[i].col1,
          inputMount : (<Form.Item center name={i+1} style={{margin:'0' , padding:'0'}}
                        rules={[{required: true,pattern:new RegExp(/^[0-9]\d*$/,"g"), message: "请输入整数",},]}>
                        <InputNumber
                          placeholder="请输入整数"
                          style={{ width: "70%" }}
                          min={0}
                        />
                      </Form.Item>)
        }
      }
      else{
        temp = {
          index:i,
          requestTitle : data[i].col1,
          inputMount : (<Form.Item center name="effectiveTimestamp" style={{margin:'0' , padding:'0'}}
                        rules={[{required: true,message: "请输入日期",}]}
                        >
                        <DatePicker
                          format={dateFormat}
                          placeholder="年/月/日"
                          style={{ width: '70%' }}
                          name="effectiveTimestamp"
                        />
                      </Form.Item>)
        }
      }
      rowDatasTemp.push(temp);
    }
    setRowDatas(rowDatasTemp);
    return rowDatasTemp
  }
  // 年
  let yearFlag =  (years) =>{
    let yearsDemands = [];
    for(let i = 0; i < props.demands.length ; i++){
      if(parseInt(props.demands[i].grade)  == parseInt(years)){
        yearsDemands.push(props.demands[i])
      }
    }
    if(yearsDemands.length > 7){
      return true;
    }
    else{
      return false
    }
  }
  // type
  let yearTypeFlag =  (years,type) =>{
    if(years){
      let yearsDemands = [];
      for(let i = 0; i < props.demands.length ; i++){
        if(parseInt(props.demands[i].grade)  == parseInt(years)){
          yearsDemands.push(props.demands[i])
        }
      }
      console.log(yearsDemands)
      if(yearsDemands.length === 0){
        return false
      }
      else{
        switch(type){
          case 1 :{
            if(parseInt(yearsDemands[0].studentType) === 1){
              return true
            }
            else{
              return false
            }
          }
          case 2 : {
            if(parseInt(yearsDemands[0].studentType) === 2){
              return true
            }
            else{
              return false
            }
          }
        }
      }
    }
    else{
      return false;
    }
  }

  function handleSelectChange(tag, value) {
    setGrade(value)
    
  }

  const years = useMemo(() => {
    const years = [];
    for (let start = new Date().getFullYear() + 2; start > 2017; start--) {
      years.push(start);
    }
    return years;
  }, []);
  //点击提交
  async function submit() {
    console.log("valid");
    try {
      await addForm.validateFields();
    } catch {
      console.log("Form validate filed");
    }
  }

  //调用添加接口
  async function addOutBoundConditions(value) {
    console.log(value);
    console.log(yearFlag(value.grade))
    const addInfo = value;
    addInfo.effectiveTimestamp = addInfo.effectiveTimestamp
      ? parseInt(new Date(addInfo.effectiveTimestamp).getTime() / 1000)
      : null;
    await post(
      {
        url: "/ec/station/addOutBoundConditions",
        data: {
          ...addInfo,
          sessionId: localStorage.sessionId,
        },
      },
      () => {
        notification.success({
          description: "添加成功！",
          message: "通知",
          duration: 1,
          onClose: () => {
            props.handleClear();
            props.handleCancel();
          },
        });
      },
      props.history
    );
  }
  return (
    <>
      {/* 新增学生 */}
      <Modal
        title="出站条件"
        visible={props.addStudent.visible}
        onCancel={props.handleCancel}
        footer={null}
        forceRender
      >
        <Form form={addForm} name="addForm" labelCol={{ span: 5 }} onFinish={addOutBoundConditions}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="grade" rules={[ { required: true, message: "请选择年级",},]}>
                <Select placeholder="请选择年级" onChange={ handleSelectChange.bind(null, 'grade')} allowClear>
                  {years.map((item,index) => (
                    <Option key={item} value={item} disabled = {yearFlag(item)}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="studentType" rules={[{required: true, message: "请选择学硕/专硕",},]}>
                <Select placeholder="请选择学硕/专硕" allowClear>
                  <Option value={1} disabled = {yearTypeFlag(grade,1)}>学硕</Option>
                  <Option value={2} disabled = {yearTypeFlag(grade,2)}>专硕</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* <Form.Item> */}
            <Table
            rowKey="index"
            rowClassName = "addDialogTable"
            pagination={false}
            columns={columns}
            dataSource={rowDatas} />
          {/* </Form.Item> */}
          <Form.Item>
            <Button type="primary" htmlType="submit" block onClick={submit} style = {{margin:"20px 0 0 0"}}>
              添加
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
