import { Redirect, Route, Switch, HashRouter } from 'react-router-dom';
import React from 'react';

//pages
import Login from '../pages/login/login';
import Home from '../pages/home/home';

/* 管理员端 */
import projectConstruction from '../pages/Manager/projectConstruct/projectConstruct';
import StudentResume from '../pages/Manager/studentResume/studentResume';
import ProjectManage from '../pages/Manager/projectManage/projectManage';
import TechnologyManage from '../pages/Manager/technologyManage/technologyManage';
import EnterManagement from '../pages/Manager/enterManagement/enterManagement';
import technologySoftware from '../pages/Manager/technologySoftware/technologySoftware';
import outboundApplication from '../pages/Manager/transveralExitManage/outboundApplication/outboundApplication'
// 新增纵向
import VerticakOutboundApplication from '../pages/Manager/verticalExitManage/outboundApplication/outboundApplication'

// 发明专利
import PatentManage from '../pages/Manager/patentManage/patentManage';
import RequirementManagement from '../pages/Manager/requirementManagement/requirementManagement'
import TransveralExitManage from '../pages/Manager/transveralExitManage/transveralExitManage';
import ProjectHandover from '../pages/Student/projectHandover/projectHandover'


//22版横向出站条件
// import RequirementManagement2022 from '../pages/Manager/requirementManagement2022/requirementManagement2022';



/* 学生端 */
// 纵向出站条件
import StuProComManage from '../pages/Student/stuProComManage/stuProComManage';
import StudyOutbound from '../pages/Student/studyOutbound/studyOutbound';
import ProjectApplication from '../pages/Student/projectApplication/projectApplication';
import VerticalTechnology from '../pages/Student/verticalTechnology/verticalTechnology';
// 横向出站条件
import StuProjectManage from '../pages/Student/projectManage/projectManage';
import StuTechnologyManage from '../pages/Student/technologyManage/technologyManage';
// 一阶段出站申请
// import StuStageOutbound from '../pages/Student/stageOutbound/stageOutbound'
import StuStageOutbound from '../pages/Student/stageOutbound/stageOutbound'
// 打印申请书
import StuPrintApplication from '../pages/Student/printApplication/printApplication'
// 建设方案
import StuProjectContruction from '../pages/Student/projectContruction/projectConstruction';
import StuTechnologySoftware from '../pages/Student/technologySoftware/technologySoftware';
import StuPatentManage from '../pages/Student/stuPatentManage/stuPatentManage';
// 论文综述报告管理
import StuReviewReport from '../pages/Student/stuReviewReport/stuReviewReport';
// 科研论文管理
import PaperManage from '../pages/Manager/paperManage/paperManage'

/* 教师端 */
import TehStudentResume from '../pages/Teacher/TehStudentResume/TehStudentResume';
//教师管理
import Erectapplication from '../pages/Manager/erectapplication/erectapplication';
//学生项目管理
import StudentProjectManage from '../pages/Manager/studentProjectManage/index'
import StudentPaperManage from '../pages/Student/paperManage/paperManage'
// 纵向出站情况统计
import HorizonStatistics from '../pages/Manager/horizonStatistics/horizonStatistics'
import verticalTechnicalFramework from '../pages/Manager/verticalTechnicalFramework/verticalTechnicalFramework'
import VerticalStatistics from '../pages/Manager/verticalStatistics/verticalStatistics'
import ReviewReport from '../pages/Manager/reviewReport/reviewReport'
import StudyRequirementManagement from '../pages/Manager/studyRequirementManagement/studyRequirementManagement'
import PublishedPapers from '../pages/Manager/publishedPapers/publishedPapers'
//纵向出战页面
import VerticalExitManage from '../pages/Manager/verticalExitManage/verticalExitManage'
// 纵向打印申请书
import StuPrintApplicationV from '../pages/Student/printApplicationV/printApplication'
export default class RouteConfig extends React.Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route path="/login" exact component={ Login } />
          <Route path="/home" render={ props => (
            <Home {...props}>
              <Route path="/home/studentResume" component={ StudentResume } />
              <Route path="/home/projectConstruction" component={ projectConstruction } />
              <Route path="/home/projectManage" component={ ProjectManage } />
              <Route path="/home/enterManagement" component={ EnterManagement } />
              <Route path="/home/StuProjectManage" component={ StuProjectManage } />
              <Route path="/home/StuTechnologyManage" component={ StuTechnologyManage } />
              <Route path="/home/technologyManage" component={ TechnologyManage } />
              <Route path="/home/technologySoftware" component={technologySoftware} />
              <Route path="/home/StuProjectContruction" component={ StuProjectContruction } />
              <Route path="/home/PatentManage" component={ PatentManage } />
              <Route path="/home/transveralExitManage" component={ TransveralExitManage } />
              <Route path="/home/outboundApplication" component={ outboundApplication } />
              <Route path="/home/projectHandover" component={ProjectHandover}/>
              <Route path="/home/StuTechnologySoftware" component={StuTechnologySoftware} />
              <Route path="/home/stuPatentManage" component={StuPatentManage} />
              <Route path="/home/TehStudentResume" component={TehStudentResume} />
              <Route path="/home/RequirementManagement" component={RequirementManagement} />
              <Route path="/home/stageOutbound" component={StuStageOutbound} />

              <Route path="/home/studyRequirementManagement" component={StudyRequirementManagement} />
              {/* <Route path='/home/studentsContestManage' component={StudentsContestManage}/> */}
              <Route path="/home/erectapplication" component={ Erectapplication } />
              <Route path='/home/studentProjectManage' component={StudentProjectManage}/>
              <Route path='/home/paperManage' component={PaperManage}/>
              <Route path='/home/horizonStatistics' component={ HorizonStatistics }/>

              <Route path='/home/verticalTechnicalFramework' component={ verticalTechnicalFramework }/>
              <Route path='/home/verticalExitManage' component ={VerticalExitManage }/>
              <Route path='/home/vertoutboundApplication' component={VerticakOutboundApplication}/>
              <Route path='/home/verticalStatistics' component={ VerticalStatistics }/>
              <Route path='/home/reviewReport' component={ ReviewReport }/>
              <Route path='/home/publishedPapers' component={ PublishedPapers }/>
              {/* 学生端纵向 */}
              <Route path='/home/stuProComManage' component={ StuProComManage }/>
              <Route path='/home/studyOutbound' component={ StudyOutbound }/>
              <Route path='/home/projectApplication' component={ ProjectApplication }/>
              <Route path='/home/verticalTechnology' component={ VerticalTechnology }/>
              <Route path='/home/studentPaperManage' component={StudentPaperManage}/>
              <Route path='/home/stuReviewReport' component={StuReviewReport}/>
              {/* <Route path='/home/studentPan'> */}

              {/* <Route path='/home/requirementManagement2022' component={RequirementManagement2022}/> */}

          </Home>
          )} />
          <Route path="/printApplication" component={StuPrintApplication} />
          <Route path="/printApplicationV" component={StuPrintApplicationV} />
          <Redirect to="/login" />
        </Switch>
      </HashRouter>
    )
  }
}
