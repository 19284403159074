/*
 * @Author: 廖立婷
 * @Date: 2020-11-26 15:45:40
 * @Last Modified by: HouQi
 * @Last Modified time: 2022-03-24 13:33:49
 */
import React, {useState,useMemo,useImperativeHandle,useEffect,} from "react";
import {DatePicker,Form,InputNumber,Button,Select,Modal,Table,Row,Col, notification,} from "antd";
import { post } from "../../../../network/request";
import "./settingDialog.css";
import moment from "moment";
const { Option } = Select;
export default function SettingDialog(props) {
  const [settingForm] = Form.useForm();
  const { cRef } = props;
  //表格列名
  const columns = [
    {
      title: '考察项目',
      dataIndex: 'requestTitle',
      key: 'index',
      align: 'center',

    },
    {
      title: '达标要求',
      dataIndex: 'inputMount',
      key: 'index',
      align: 'center',
    },
  ];
  const [rowDatas,setRowDatas] = useState([]);
  const [settingTest, setSettingTest] = useState([]);
  const dateFormat = "YYYY/MM/DD";
  useImperativeHandle(
    cRef,
    () => ({
      settingForm,
    }),
    []
  );
  useEffect(() => {
    settingForm.resetFields();
    setSettingTest([])
    if (props.settingStudent.visible)
      getRowDatas(props.gradeType , props.demands)
  },[props.settingStudent.visible]);

  let getRequestTitle = (outboundConditionSid) => {
    for (let i = 0 ; i < props.conditions.length ; i++){
      if(outboundConditionSid === props.conditions[i].outboundConditionSid){
        return props.conditions[i].conditionName
      }
    }
  };

  // 获取列表
  let getRowDatas = (gradeType,demands) => {
    console.log(demands)
    let grade = parseInt(gradeType.slice(0,4));
    let studentType ;
    switch(gradeType.slice(4,)){
      case "学硕" : studentType = 1 ; break;
      case "专硕" : studentType = 2 ; break;
    }
    let rowDataTemp = [];
    let testarr = [];
    for(let i = 0 ; i < demands.length ; i++){
      if(demands[i].grade === grade && demands[i].studentType === studentType ){
          let test = {
            outboundConditionSid : demands[i].outboundConditionSid,
            name : demands[i].needSid,
            value : demands[i].amount,
            effectiveTimestamp : demands[i].effectiveTimestamp
          }
          testarr.push(test);
      }
    }
    setSettingTest(testarr);
    if(testarr.length){
      for(let i = 0; i < testarr.length;i++){
        let titleTemp = getRequestTitle(testarr[i].outboundConditionSid);
        let rowtemp = {
          index : i,
          requestTitle : titleTemp,
          inputMount : (<Form.Item className="formItem changeamount" center name={testarr[i].name} style={{margin:'0' , padding:'0'}}>
                        <InputNumber
                          min={0}
                          precision="0"
                          value = {testarr[i].value}
                          defaultValue = {testarr[i].value}
                          style={{ width: "70%" }} allowClear/>
                        </Form.Item>)
        }
        console.log('@')
        console.log(rowtemp)
        rowDataTemp.push(rowtemp);
      }
      let rowtempTime = {
        index : testarr.length,
        requestTitle : "生效时间",
        inputMount : (<Form.Item className="formItem" center name="effectiveTimestamp" style={{margin:'0' , padding:'0' ,center: "true"}}>
                        <DatePicker
                          format={dateFormat}
                          placeholder={new Date(testarr[0].effectiveTimestamp * 1000).toLocaleDateString()}
                          style={{ width: '70%' }}
                          name="effectiveTimestamp"
                        />
                      </Form.Item>)
        }
      rowDataTemp.push(rowtempTime);
    }
    setRowDatas(rowDataTemp);
    return {
      rowData : rowDataTemp,
      tArr : testarr
    };
  }

  const years = useMemo(() => {
    const years = [];
    for (let start = new Date().getFullYear() + 2; start > 2017; start--) {
      years.push(start);
    }
    return years;
  }, []);
  //点击提交
  async function submit() {
    await settingForm.validateFields();
  }

  //调用更新接口
  async function updateOutBoundConditions(value) {
    let settingTemp = settingTest;
    for(let i = 0; i < settingTemp.length; i++ ){
      if(typeof(value[settingTemp[i].name]) == 'undefined'||value[settingTemp[i].name]==null){
        value[settingTemp[i].name] = settingTemp[i].value
      }
      else{
        settingTemp[i].value = value[settingTemp[i].name];
      }
    }
    setSettingTest(settingTemp);
    value["effectiveTimestamp"] = value["effectiveTimestamp"]?parseInt(moment(value["effectiveTimestamp"], dateFormat).valueOf() / 1000):settingTest[0].effectiveTimestamp;
    await post(
      {
        url: "/ec/station/updateOutBoundConditions",
        data: {
          ...value,
          sessionId: localStorage.sessionId,
        },
      },
      () => {
        notification.success({
          description: "修改成功！",
          message: "通知",
          duration: 1,
          onClose: () => {
            settingForm.resetFields();
            setSettingTest([]);
            props.handleClear();
            props.handleCancel();
            setRowDatas([])
          },
        });
      },
      props.history
    );
  }
  return (
    <>
      {/* 修改学生 */}
      <Modal
        title={"<"+props.gradeType+">出站条件"}
        visible={props.settingStudent.visible}
        onCancel={props.handleCancel}
        footer={null}
        forceRender
      >
        <Form form={settingForm} name="settingForm" labelCol={{ span: 5 }} onFinish={updateOutBoundConditions}>
          {/* <Form.Item> */}
            <Table
            className="settingDialogTable"
            rowKey="index"
            pagination={false}
            columns={columns}
            dataSource={rowDatas} />
          {/* </Form.Item> */}
          <Form.Item>
            <Button type="primary" htmlType="submit" block onClick={submit} style = {{margin:"20px 0 0 0"}}>
              修改
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
