/*
 * @Author: HouQi 
 * @Date: 2021-11-21 15:36:50 
 * @Last Modified by: HouQi
 * @Last Modified time: 2021-12-11 14:06:59
 */
import React, { useEffect, useState, useMemo } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Table,
  Space,
  notification,
  Modal,
  Tag,
  DatePicker,
  Popconfirm,
  message,
} from "antd";
import { post, request } from "../../../network/request";
import moment from "moment";
//import component
import CustomCard from "../../../components/common/card/card";
//import css
import "./projectManage.css";
import { data } from "browserslist";

const { Option } = Select;

export default function ProjectManage(props) {
  // console.log(props.location.state)

  const headerCardOptions = { title: null, bordered: false };
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll", height: "calc(100vh - 78px - 130px)" },
  };
  const [filterForm] = Form.useForm();
  const [addForm] = Form.useForm();
  //table column
  const columns = [
    {
      title: "项目编号",
      dataIndex: "itemNumber",
      key: "itemNumber",
      align: "center",
      width: '100px'
    },
    {
      title: "项目名称",
      dataIndex: "itemName",
      key: "itemName",
      align: "center",
    },
    {
      title: "主持者",
      dataIndex: "managerName",
      key: "managerName",
      align: "center",
      width: '90px'
    },
    {
      title: "参与者",
      dataIndex: "participants",
      key: "participants",
      align: "center",
    },
    {
      title: "负责老师",
      dataIndex: "chargeTeacher",
      key: "chargeTeacher",
      align: "center",
      width: '90px'
    },
    {
      title: "开始时间",
      dataIndex: "insertTimestamp",
      key: "insertTimestamp",
      align: "center",
      width: '110px'
    },
    {
      title: "截止时间",
      dataIndex: "endTime",
      key: "endTime",
      align: "center",
      width: '110px'
    },
    {
      title: "目前状态",
      // dataIndex: 'itemStatus',
      key: "itemStatus",
      align: "center",
      width: '110px',
      render: (text, record) => {
        switch (text.itemStatus) {
          case "已完成":
            return (
              <span className="ec-text-color-green">{text.itemStatus}</span>
            );
          case "进行中":
            return (
              <span className="ec-text-color-orange">{text.itemStatus}</span>
            );
          case "已终止":
            return (
              <span className="ec-text-color-grey">{text.itemStatus}</span>
            );
          default:
            return <span>{text.itemStatus}</span>;
        }
      },
    },
    {
      title: "更新时间",
      dataIndex: "updateTimestamp",
      key: "updateTimestamp",
      align: "center",
      width: '110px'
    },
    {
      title: "操作",
      key: "userName",
      align: "center",
      width: '320px',
      ellipsis: true,
      fixed: 'right',
      render: (text, record) => (
        <div>
          <Button
            className="operate-button ec-button-lightBlue"
            // type="primary"
            size="small"
            disabled={text.itemStatus === "已完成"}
            // icon={ <CheckOutlined /> }
            onClick={handleClickEditBtn.bind(null, text)}
          // style={{backgroundColor:'#5CB85C',border:'1px solid #4CAE4C'}}
          >
            修改
          </Button>
          <Button
            className="operate-button ec-button-orange"
            // type="primary"
            size="small"
            disabled={text.itemStatus === "已完成"}
            // icon={ <CheckOutlined /> }
            onClick={handleStatusChange.bind(null, "进行中", text)}
          // style={{backgroundColor:'#5BC0DE'}}
          >
            进行中
          </Button>
          <Popconfirm
            className="operate-button ec-button-green"
            placement="bottomRight"
            title="确认完成吗？注意：目前状态修改为完成后将无法进行其他操作！"
            onConfirm={handleStatusChange.bind(null, "已完成", text)}
            okText="确定"
            cancelText="取消"
            disabled={text.itemStatus === "已完成"}
          >
            <Button
              className="operate-button ec-button-green"
              size="small"
              // style={text.itemStatus === '已完成'?{backgroundColor:'#F0AD4E',border:'1px solid #EEA236'}:{backgroundColor:'#F0AD4E',border:'1px solid #EEA236',color:'white'}}
              disabled={text.itemStatus === "已完成"}
            >
              完成
            </Button>
          </Popconfirm>
          <Button
            className="operate-button"
            type="danger"
            size="small"
            disabled={text.itemStatus === "已完成"}
            // icon={ <CheckOutlined /> }
            onClick={handleStatusChange.bind(null, "已终止", text)}
          // style={{backgroundColor:'#D9534F'}}
          >
            终止
          </Button>
          <Popconfirm
            className="operate-button"
            placement="bottomRight"
            title="确认删除吗？"
            onConfirm={handleDeleteItem.bind(null, text)}
            okText="确定"
            cancelText="取消"
            disabled={text.itemStatus === "已完成"}
          >
            <Button
              className="operate-button"
              type="danger"
              size="small"
              disabled={text.itemStatus === "已完成"}
            >
              删除
            </Button>
          </Popconfirm>
          {/* <Button
                        className="operate-button"
                        type="danger"
                        size="small"
                        disabled={ text.itemStatus === '已完成' }
                        // icon={ <CheckOutlined /> }
                        onClick={ handleDeleteItem.bind(null, text) }
                        style={{backgroundColor:'#D9534F'}}
                    >删除</Button> */}
        </div>
      ),
    },
  ];

  //state
  const [itemList, setItemList] = useState([]);
  const [tmp, setTmp] = useState({
    itemType: 1,
    roleSid: 1,
    pageSize: 10,
    index: 1,
    itemStatus: "",
    managerName: "",
    participants: "",
    itemName: "",
  });
  const [filterInfo, setFilterInfo] = useState({
    itemType: 1,
    roleSid: 1,
    pageSize: 10,
    index: 1,
    itemStatus: "",
    managerName: "",
    // startTimestamp:1,
    // endTimestamp:parseInt(new Date().getTime()/1000),
    participants: "",
    itemName: "",
  });
  const [addInfo, setAddInfo] = useState({
    itemType: 1,
    roleSid: 1,
    itemName: "",
    itemNumber: "",
    chargeTeacher: "",
    managerSid: 0,
    itemStatus: "",
    insertTimestamp: parseInt(new Date().getTime() / 1000),
    endTime: parseInt(new Date().getTime() / 1000),
    selectParticipantSid: 0,
    participantSid: [],
    statusDescription: "",
  });
  const [rawTable, setRawTable] = useState([]);
  const [stuInfo, setStuInfo] = useState([]);
  const [selectStuInfo, setSelectStuInfo] = useState([]);
  const [selectPartInfo, setSelectPartInfo] = useState([]);
  const [total, setTotal] = useState(0);
  const [addDialog, setAddDialog] = useState({ visible: false });
  const [title, setTitle] = useState({ type: 0 });
  const [loading, setLoading] = useState(null);
  const [entryGrade, setEntryGrade] = useState([]);
  const [validateState, setValidateState] = useState({
    managerName: "success",
    managerNamehelp: undefined,
    time: "success",
    timehelp: undefined,
  });

  //memo
  const table = useMemo(() => {
    rawTable.forEach((item) => {
      switch (item.itemStatus) {
        case 1:
          item.itemStatus = "进行中";
          break;
        case 2:
          item.itemStatus = "已完成";
          break;
        case 3:
          item.itemStatus = "已终止";
          break;
        default:
          item.itemStatus = "错误";
      }
      item.updateTimestamp = !item.updateTimestamp
        ? "暂无"
        : new Date(item.updateTimestamp * 1000).toLocaleDateString();
      item.insertTimestamp = !item.insertTimestamp
        ? "暂无"
        : new Date(item.insertTimestamp * 1000).toLocaleDateString();
      item.endTime = !item.endTime
        ? "暂无"
        : new Date(item.endTime * 1000).toLocaleDateString();
    });
    return rawTable;
  }, [rawTable]);
  const student = useMemo(() => {
    return stuInfo;
  }, [stuInfo]);

  //date
  const dateFormat = "YYYY/MM/DD";
  const todayDate = new Date().toLocaleDateString();

  //form
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  //validate
  const validateMessages = {
    required: "请填写${label}!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
      array: "请填写${label}!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };


  useEffect(() => {
    async function genEntryGrade() {
      await post(
        {
          url: "/ec/project/getOutStationGrade",
          data: {
            sessionId: localStorage.sessionId,
            inboundState: 2
          },
        },
        (data) => {
          if (data.code == 'N01') {
            let Outing = data.contents.Outing
            console.log(data.contents)
            for(let i = 0;i < Outing.length;i++){
              entryGrade.push(parseInt(Outing[i]))
            }
          }else {
            console.log('error')
          }
        },
        props.history
      );
        console.log(entryGrade)
      setEntryGrade(entryGrade);
    }
    genEntryGrade();
    getStudentList();
  }, [])
  useEffect(() => {
    if (props.location.state !== undefined) {
      console.log(props.location.state.data)
      console.log(props.location.state)
      if (props.location.state.num == 1) {
        getTableData({
          itemType: 1,
          roleSid: 1,
          pageSize: 30,
          index: 1,
          itemStatus: "",
          managerName: props.location.state.data.studentName,
          participants: "",
          itemName: "",
        });
        console.log('有值传过来了！')
      } else if (props.location.state.num == 2) {
        getTableData({
          itemType: 1,
          roleSid: 1,
          pageSize: 30,
          index: 1,
          itemStatus: "",
          managerName: "",
          participants: props.location.state.data.studentName,
          itemName: "",
        });
      }
    } else {
      getTableData(filterInfo);
      console.log('没有值传过来！')
    }
  }, [filterInfo.pageSize, filterInfo.currIndex]);
  useEffect(() => {
    addForm.setFieldsValue({
      itemName: addInfo.itemName,
      itemNumber: addInfo.itemNumber,
      chargeTeacher: addInfo.chargeTeacher,
    });
  }, [addDialog]);

  //operate not auto
  async function getTableData(filterData) {
    console.log(filterData)
    setLoading(true);
    let data = { ...filterData };
    if (
      data.startTimestamp != "" &&
      data.startTimestamp != undefined &&
      data.endTimestamp == undefined
    ) {
      // data.endTimestamp = parseInt(new Date().getTime() / 1000);
      data.endTimestamp=""
    }
    if (
      data.endTimestamp != "" &&
      data.endTimestamp != undefined &&
      data.startTimestamp == undefined
    ) {
      // data.startTimestamp = 1;
    }
    // console.log(data)
    await post(
      {
        url: "/ec/project/getProjectInfoByItemType",
        data: {
          sessionId: localStorage.sessionId,
          ...data,
        },
      },
      (data) => {
        setLoading(false);
        console.log(data)
        setRawTable(data.contents.list);
        // console.log(data.contents.list)
        setTotal(data.contents.size);
      },
      props.history
    );
  }

  async function getStudentList() {
    setLoading(true);
    await post(
      {
        url: "/ec/project/getAllStudentInfoOfInBound",
        data: {
          sessionId: localStorage.sessionId,
        },
      },
      (data) => {
        setLoading(false);
        setStuInfo(data.contents);
        // console.log(data.contents)
      },
      props.history
    );
  }

  async function addProjectInfo(data) {
    setLoading(true);
    await post(
      {
        url: "/ec/project/addProjectInfo",
        data,
      },
      (data) => {
        setLoading(false);
        handleCancel();
        addForm.resetFields();
        getTableData(filterInfo);
      },
      props.history
    );
  }

  async function updateProjectInfo(data) {
    setLoading(true);
    await post(
      {
        url: "/ec/project/updateProjectInfoByItemSid",
        data,
      },
      (data) => {
        setLoading(false);
        handleCancel();
        addForm.resetFields();
        getTableData(filterInfo);
      },
      props.history
    );
  }

  function handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    setFilterInfo({ ...filterInfo, [name]: value });
  }

  function handleAddInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    setAddInfo({ ...addInfo, [name]: value });
  }

  function handleTimeChange(tag, date, dateString) {
    let value = "";
    if (date) {
      value = parseInt(moment(dateString, dateFormat).valueOf() / 1000);
      console.log(value);
    }
    setFilterInfo({ ...filterInfo, [tag]: value });
  }

  function handleAddTimeChange(tag, date, dateString) {
    let value = "";
    let newAdd = { ...addInfo };
    if (date) {
      value = parseInt(moment(dateString, dateFormat).valueOf() / 1000);
    }
    newAdd[tag] = value;
    // setAddInfo({ ...addInfo, [tag]: value });
    setAddInfo(newAdd);
    handleMyValidate(tag, newAdd);
  }

  function handleSelectChange(tag, value, options) {
    if (tag == "itemStatus") value = parseInt(value);
    setFilterInfo({ ...filterInfo, [tag]: value });
  }

  function handleSelectGradeChange(value) {
    console.log(value)
    let res = [];
    stuInfo.forEach((item) => {
      if (item.entryGrade == value) {
        res.push(item);
      }
    });
    setSelectStuInfo(res);
    let data = { ...addInfo };
    delete data.managerName;
    data.managerSid = 0;
    console.log(data)
    setAddInfo(data);
  }

  function handleSelectPartGradeChange(value) {
    let res = [];
    stuInfo.forEach((item) => {
      console.log(item)
      if (item.entryGrade == value) {
        res.push(item);
      }
    });
    setSelectPartInfo(res);
  }

  function handleChangeStudentList() {
    // console.log(selectStuInfo)
    return selectStuInfo.map((item) => (
      <Option value={item.studentSid} key={item.studentSid}>
        {item.studentName}
      </Option>
    ));
  }

  function handleChangePartStudentList() {
    return selectPartInfo.map((item) => (
      <Option value={item.studentSid} key={item.studentSid}>
        {item.studentName}
      </Option>
    )
    );
  }

  function handleClickAddPartStudent() {
    console.log(addInfo)
    let list = [...addInfo.participantSid];
    if (addInfo.selectParticipantSid == addInfo.managerSid) {
      notification.error({
        description: "该学生是主持者！",
        message: "警告",
      });
    } else if (list.indexOf(addInfo.selectParticipantSid) == -1) {
      list.push(addInfo.selectParticipantSid);
      console.log(list)
      setAddInfo({ ...addInfo, participantSid: list });
    } else {
      notification.error({
        description: "该学生已经添加！",
        message: "警告",
      });
    }
  }

  function handleClickDeletePartStudent(index) {
    let data = { ...addInfo };
    data.participantSid.splice(index, 1);
    setAddInfo(data);
  }

  function handleGetPartStudentList() {
    let list = [];
    addInfo.participantSid.forEach((item) => {
      stuInfo.forEach((stu) => {
        if (item == stu.studentSid) {
          let student = {
            studentSid: item,
            studentName: stu.studentName,
          };
          list.push(student);
        }
      });
    });
    return list.map((item, index) => (
      <Tag
        closable
        key={item.studentSid}
        onClose={handleClickDeletePartStudent.bind(null, index)}
        style={{ marginTop: "5px" }}
      >
        {item.studentName}{" "}
      </Tag>
    ));
  }

  function handleSelectManagerChange(tag, value, options) {
    setAddInfo({ ...addInfo, [tag]: value });
    for (let i = 0; i < addInfo.participantSid.length; i++) {
      if (addInfo.participantSid[i] == value) {
        notification.error({
          description: "该学生已经是参与者！",
          message: "警告",
        });
        break;
      }
    }
    // myValidate('managerName')
  }

  function handleSelectPartChange(tag, value, options) {
    let stus = { ...addInfo };
    stus[tag] = value;
    setAddInfo(stus);
  }

  function handleFilter() {
    // setFilterInfo({...filterInfo,index:1});
    filterInfo.index = 1;
    getTableData(filterInfo);
  }

  function handleClear() {
    filterForm.resetFields();
    const initState = {
      itemType: 1,
      roleSid: 1,
      pageSize: 10,
      index: 1,
      itemStatus: "",
      itemNumber: "",
      managerName: "",
      startTimestamp: 1,
      endTimestamp: parseInt(new Date().getTime() / 1000),
      participants: "",
      itemName: "",
    };
    setFilterInfo({
      itemType: 1,
      roleSid: 1,
      pageSize: 10,
      index: 1,
      itemStatus: "",
      managerName: "",
      participants: "",
      itemName: "",
    });
    console.log(filterInfo)
    getTableData({
      itemType: 1,
      roleSid: 1,
      pageSize: 10,
      index: 1,
      itemStatus: "",
      managerName: "",
      participants: "",
      itemName: "",
    });
  }

  function handlePageChange(event, pageSize) {
    const index = event;
    setFilterInfo({ ...filterInfo, pageSize, currIndex: index, index: index });
  }

  async function handleStatusChange(type, data) {
    switch (type) {
      case "进行中":
        data.itemStatus = 1;
        break;
      case "已完成":
        data.itemStatus = 2;
        break;
      case "已终止":
        data.itemStatus = 3;
        break;
    }
    await post(
      {
        url: "/ec/project/updateProjectStatus",
        data: {
          sessionId: localStorage.sessionId,
          itemSid: data.itemSid,
          itemStatus: data.itemStatus,
          roleSid: 1,
          isHandover: 1,
          note: "",
        },
      },
      () => {
        notification.success({ description: "通知", message: "修改状态成功!" });
        getTableData(filterInfo);
      },
      props.history
    );
  }

  async function handleDeleteItem(data) {
    await post(
      {
        url: "/ec/project/deleteProjectInfo",
        data: {
          sessionId: localStorage.sessionId,
          itemSid: data.itemSid,
          roleSid: 1,
        },
      },
      () => {
        notification.success({ description: "通知", message: "删除成功!" });
        getTableData(filterInfo);
      },
      props.history
    );
  }

  function handleClickAddBtn() {
    handleClearAddInfo();
    setAddDialog({
      visible: true,
    });
    setTitle({
      type: 0,
    });
  }

  function handleClickEditBtn(text) {
    handleClearAddInfo();
    // addForm.resetFields();

    let data = { ...text };
    setTitle({
      type: 1,
    });
    data.participants = data.participants.split(" ");
    let partList = [];
    let partGrade = 2017;
    let managerSid = 0;
    let managerGrade = 2017;
    for (let i = 0; i < data.participants.length; i++) {
      for (let j = 0; j < stuInfo.length; j++) {
        if (data.participants[i] == stuInfo[j].studentName) {
          partList.push(stuInfo[j].studentSid);
          partGrade = stuInfo[j].entryGrade;
          break;
        }
      }
    }
    for (let i = 0; i < stuInfo.length; i++) {
      if (data.managerName == stuInfo[i].studentName) {
        managerSid = stuInfo[i].studentSid;
        managerGrade = stuInfo[i].entryGrade;
        break;
      }
    }
    data.participantSid = partList;
    data.partGrade = partGrade;
    data.managerSid = managerSid;
    data.managerGrade = managerGrade;
    handleSelectGradeChange(managerGrade);
    handleSelectPartGradeChange(partGrade);
    data.insertTimestamp = parseInt(
      new Date(data.insertTimestamp).valueOf() / 1000
    );
    data.endTime = parseInt(new Date(data.endTime).valueOf() / 1000);
    data.updateTimestamp = parseInt(
      new Date(data.updateTimestamp).valueOf() / 1000
    );
    setAddInfo({ ...data });
    setAddDialog({
      visible: true,
    });
  }

  function handleCancel() {
    addForm.resetFields();
    setAddDialog({
      visible: false,
    });
  }

  function handleClearAddInfo() {
    addForm.resetFields();
    const initAdd = {
      itemType: 1,
      roleSid: 1,
      itemName: "",
      chargeTeacher: "",
      managerSid: 0,
      itemStatus: "",
      insertTimestamp: parseInt(new Date().getTime() / 1000),
      endTime: parseInt(new Date().getTime() / 1000),
      selectParticipantSid: 0,
      participantSid: [],
      statusDescription: "",
    };
    const initValidate = {
      managerName: "success",
      managerNamehelp: undefined,
      time: "success",
      timehelp: undefined,
    };
    setAddInfo({ ...initAdd });
    setValidateState({ ...initValidate });
  }

  function handleBeforeAjax() {
    if (title.type != 0) {
      if (addInfo.itemNumber == "") return false;
    }
    if (
      addInfo.managerSid == 0 ||
      addInfo.insertTimestamp == "" ||
      addInfo.insertTimestamp == "" ||
      addInfo.itemName == "" ||
      addInfo.chargeTeacher == ""
    )
      return false;
    // if(validateState.managerName!='success'||validateState.time!='success') return false
    return true;
  }

  function handleClickAddItem() {
    let flag = handleBeforeAjax();
    if (flag) {
      let formdata = { ...addInfo };
      formdata.participantSid = formdata.participantSid.join(",");
      formdata.sessionId = localStorage.sessionId;
      addProjectInfo(formdata);
    } else {
      notification.error({
        description: "表单信息尚未完善，无法提交！",
        message: "警告",
      });
    }
  }

  function handleClickEditItem() {
    let flag = handleBeforeAjax();
    if (flag) {
      let formdata = { ...addInfo };
      formdata.participantSid = formdata.participantSid.join(",");
      formdata.sessionId = localStorage.sessionId;
      formdata.roleSid = 1;
      formdata.statusDescription = formdata.itemStatus;
      delete formdata.participants;
      updateProjectInfo(formdata);
    } else {
      console.log("验证不符合");
      notification.error({
        description: "表单信息尚未完善，无法提交！",
        message: "警告",
      });
    }
  }

  function handleQuickFilter(type) {
    filterForm.setFieldsValue({ itemStatus: type.toString() });
    setFilterInfo({ ...filterInfo, itemStatus: type, index: 1 });
    getTableData({ ...filterInfo, itemStatus: type, index: 1 });
  }

  function myValidate(name, data) {
    if (!data) {
      data = { ...addInfo };
    }
    switch (name) {
      case "managerName": {
        // if(data.managerName==undefined||data.managerName==''){
        if (data.managerSid == 0) {
          setValidateState({
            ...validateState,
            [name]: "error",
            managerNamehelp: "请选择主持者!",
          });
        } else {
          setValidateState({
            ...validateState,
            [name]: "success",
            managerNamehelp: undefined,
          });
        }
        break;
      }
      case "insertTimestamp":
      case "endTime": {
        if (data[name] == undefined || data[name] == "") {
          setValidateState({
            ...validateState,
            time: "error",
            timehelp: "请选择时间范围!",
          });
        } else {
          setValidateState({
            ...validateState,
            time: "success",
            timehelp: undefined,
          });
        }
        break;
      }
      default:
        break;
    }
  }

  function handleMyValidate(name, data) {
    // console.log("name",name)
    // console.log("data",data)
    myValidate(name, data);
  }
  function goBack() {
    window.history.go(-1);
  }

  // function msgTest(){
  //   console.log("导出项目明细信息！")
  // }
  async function handleClickExportProjectMsg() {
    console.log(filterInfo);
    const rawData = await request({
      // url: "/ec/student/getProjectFileUrl",
      url: "/ec/project/exportProjectInfo",
      method: "post",
      data: {
        sessionId: localStorage.sessionId,
        itemType: "1",
        roleSid: "1",
        index:"1",
        itemStatus: filterInfo.itemStatus,
        managerName:filterInfo.managerName,
        participants:filterInfo.participants,
        itemName:filterInfo.itemName,
        startTimestamp:filterInfo.startTimestamp,
        endTimestamp:filterInfo.endTimestamp
      },
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      responseType: "blob",
    });
    console.log(rawData)
    // console.log(rawData.data);

    if (!rawData.data?.code) {
      const elink = document.createElement('a');
      let temp = rawData.headers["content-disposition"]
        .split(";")[1]
        .split("=")[1];
      let iconv = require("iconv-lite");


      iconv.skipDecodeWarning = true;
      let fileName = decodeURIComponent(temp)
      console.log(fileName);
      let type = decodeURIComponent(rawData.headers["content-type"]);
      // const xlsx = 'application/vnd.ms-excel'
      // console.log(type);

      elink.download = fileName;
      // elink.download = '应用项目管理.xls';
      elink.style.display = 'none';

      const blob = new Blob([rawData.data], {
        type: "application/msexcel,charset=UTF-8"
      });
      elink.href = window.URL.createObjectURL(blob);

      document.body.appendChild(elink);

      elink.click();
      document.body.removeChild(elink)

    }

  }

  // async function handleClickExportProjectMsg() {
  //   const rawData = await request({
  //     url: "/ec/project/exportProjectInfo",
  //     method: "post",
  //     data: {
  //       sessionId: localStorage.sessionId,
  //       itemType:"1",
  //       itemStatus: filterInfo.itemStatus,
  //       // entryGrade: filterInfo.entryGrade
  //     },

  //   });
  
  //   if (rawData.data?.code) {
  //     // 加问号为了解决浏览器缓存文件问题
  //     let downloadURL = rawData.data.contents;
  //     window.location = downloadURL + '?/' + Math.random();
  //     // window.open(downloadURL) /* 可以下载但会屏闪 */
  //     /* 
  //       window.open（）方法会直接打开新的窗口显示跳转网页，是window对象的方法。
  //       window.location 会在当前网页更改url的地址进行跳转，不会弹出新的页面，是location对象的属性。
  //     */
  //   }
  // }




  return (
    <>
      <CustomCard options={headerCardOptions}>
        <Form
          layout="inline"
          style={{ flexDirection: "column" }}
          form={filterForm}
        >
          <Row className="project-manage-row">
            <Col span={6} offset={1}>
              <Form.Item label="项目名称" name="itemName">
                <Input
                  placeholder="请输入项目名"
                  value={filterInfo.itemName}
                  style={{ width: "80%" }}
                  onChange={handleInputChange}
                  name="itemName"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="主持者" name="managerName">
                <Input
                  placeholder="请输入主持者"
                  value={filterInfo.managerName}
                  style={{ width: "80%" }}
                  onChange={handleInputChange}
                  name="managerName"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="参与者" name="participants">
                <Input
                  placeholder="请输入参与者"
                  value={filterInfo.participants}
                  style={{ width: "80%" }}
                  onChange={handleInputChange}
                  name="participants"
                />
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Button type="primary" htmlType="submit" onClick={handleFilter}>
                筛选
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={6} offset={1}>
              <Form.Item label="目前状态" name="itemStatus">
                <Select
                  style={{ width: "80%" }}
                  placeholder="请选择"
                  value={filterInfo.itemStatus}
                  name="itemStatus"
                  onChange={handleSelectChange.bind(null, "itemStatus")}
                  allowClear
                >
                  <Option value="1" key="1">
                    进行中
                  </Option>
                  <Option value="2" key="2">
                    已完成
                  </Option>
                  <Option value="3" key="3">
                    已终止
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="开始时间" name="startTimestamp">
                <Space direction="vertical" size={12} style={{ width: "100%" }}>
                  <DatePicker
                    // defaultValue={moment('2015/01/01', dateFormat)}
                    format={dateFormat}
                    // value={ filterInfo.startTimestamp }
                    style={{ width: "80%" }}
                    onChange={handleTimeChange.bind(null, "startTimestamp")}
                    name="startTimestamp"
                    placeholder="请选择时间"
                  />
                </Space>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="结束时间" name="endTimestamp">
                <Space direction="vertical" size={12} style={{ width: "100%" }}>
                  <DatePicker
                    // defaultValue={moment(todayDate, dateFormat)}
                    format={dateFormat}
                    // value={ filterInfo.endTimestamp }
                    style={{ width: "80%" }}
                    onChange={handleTimeChange.bind(null, "endTimestamp")}
                    name="endTimestamp"
                    placeholder="请选择时间"
                  />
                </Space>
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Button type="plain" htmlType="submit" onClick={handleClear}>
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomCard>
      <CustomCard options={tableCardOptions}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10
          }}
        >
          <div>
            <Button
              className="quickFilter ec-button-orange"
              onClick={handleQuickFilter.bind(null, 1)}
            >
              进行中
            </Button>
            <Button
              className="quickFilter ec-button-green"
              onClick={handleQuickFilter.bind(null, 2)}
            >
              已完成
            </Button>
            <Button
              className="quickFilter ec-button-grey"
              onClick={handleQuickFilter.bind(null, 3)}

            >
              已终止
            </Button>
            <Button
              style={{
                visibility: (props.location.state !== undefined && (props.location.state.num == 1 || 2)) ? 'visible' : 'hidden'
              }}
              type='primary'
              onClick={goBack.bind(null)}
            >
              返回统计页
            </Button>
          </div>
          <div>
          <Button type="primary" htmlType="submit" onClick={handleClickExportProjectMsg} 
          style={{
            color:"white",
            marginRight:'10px'
            }}>
            导出项目明细
          </Button>
          <Button type="primary" onClick={handleClickAddBtn.bind(null)}>
            新增项目
          </Button>
          </div>
        </div>
        <div style={{ overflowX: 'auto' }}>
          <Table
            className="table"
            rowKey="itemSid"
            columns={columns}
            dataSource={table}
            loading={loading}
            scroll={{ x: '1450px' }}
            pagination={{
              total: total,
              current: filterInfo.index,
              pageSize: filterInfo.pageSize,
              pageSizeOptions: [10, 20, 30],
              onChange: handlePageChange,
              showSizeChanger: true,
            }}
          />
        </div>
      </CustomCard>
      <Modal
        title={title.type == 0 ? "新建项目" : "修改项目"}
        visible={addDialog.visible}
        onCancel={handleCancel}
        footer={[
          <div>
            <Button key="back" onClick={handleClearAddInfo}>
              清空
            </Button>
            <Button
              key="submitAdd"
              type="primary"
              onClick={
                title.type == 0 ? handleClickAddItem : handleClickEditItem
              }
            >
              提交
            </Button>
          </div>,
        ]}
      >
        <Form
          {...layout}
          name="control-ref"
          form={addForm}
          validateMessages={validateMessages}
          validateTrigger="onBlur"
        >
          <Form.Item
            name="itemName"
            label="项目名称"
            rules={[
              {
                required: true,
                message: "请输入项目名称!",
              },
            ]}
          >
            <Input
              placeholder="请输入项目名称"
              value={addInfo.itemName}
              onChange={handleAddInputChange}
              name="itemName"
            />
          </Form.Item>
          <Form.Item
            name="itemNumber"
            label="项目编号"
            style={title.type == 0 ? { display: "none" } : null}
            rules={[
              {
                required: true,
                message: "请输入项目编号!",
              },
            ]}
          >
            <Input
              disabled
              placeholder="请输入项目编号"
              value={addInfo.itemNumber}
              onChange={handleAddInputChange}
              name="itemNumber"
            />
          </Form.Item>
          <Form.Item
            name="managerName"
            label="主持者"
            validateStatus={validateState.managerName}
            help={validateState.managerNamehelp}
            rules={[{ required: true }]}
          >
            {/* <Select
              placeholder="年级"
              style={{ width: "20%", marginRight: "5%" }}
              onChange={handleSelectGradeChange.bind(null)}
              // onBlur ={handleMyValidate.bind(null,'managerName')}
              defaultValue={addInfo.managerGrade}
              allowClear
            >
              <Option value="2017" key="2017">
                2017
              </Option>
              <Option value="2018" key="2018">
                2018
              </Option>
              <Option value="2019" key="2019">
                2019
              </Option>
              <Option value="2020" key="2020">
                2020
              </Option>
            </Select> */}

            <Select
              placeholder="年级"
              style={{ width: "20%", marginRight: "5%" }}
              onChange={handleSelectGradeChange.bind(null)}
              // onBlur ={handleMyValidate.bind(null,'managerName')}
              defaultValue={addInfo.managerGrade}
              allowClear
            >
              {entryGrade.map((item, index) => (
                <Option value={item} key={item}>
                  {item}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="姓名"
              style={{ width: "72%" }}
              onChange={handleSelectManagerChange.bind(null, "managerSid")}
              onBlur={handleMyValidate.bind(null, "managerName", addInfo)}
              defaultValue={addInfo.managerName}
              allowClear
            >
              {handleChangeStudentList()}
            </Select>
          </Form.Item>
          <Form.Item
            name="participantSid"
            label="参与者"
            initialValue={addInfo.itemName}
          >
            {/* <Select
              placeholder="年级"
              style={{ width: "20%", marginRight: "5%" }}
              onChange={handleSelectPartGradeChange.bind(null)}
              defaultValue={addInfo.partGrade}
              allowClear
            >
              <Option value="2017" key="2017">
                2017
              </Option>
              <Option value="2018" key="2018">
                2018
              </Option>
              <Option value="2019" key="2019">
                2019
              </Option>
              <Option value="2020" key="2020">
                2020
              </Option>
            </Select> */}
            <Select
              placeholder="年级"
              style={{ width: "20%", marginRight: "5%" }}
              onChange={handleSelectPartGradeChange.bind(null)}
              defaultValue={addInfo.partGrade}
              allowClear
            >
              {entryGrade.map((item, index) => (
                <Option value={item} key={item}>
                  {item}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="姓名"
              style={{ width: "50%", marginRight: "5%" }}
              onChange={handleSelectPartChange.bind(
                null,
                "selectParticipantSid"
              )}
              allowClear
            >
              {handleChangePartStudentList()}
            </Select>
            <Button
              type="primary"
              onClick={handleClickAddPartStudent.bind(null)}
            >
              添加
            </Button>
            <div className="tagsDiv">{handleGetPartStudentList()}</div>
          </Form.Item>
          <Form.Item
            name="chargeTeacher"
            label="负责老师"
            rules={[
              {
                required: true,
                message: "请输入负责老师名称!",
              },
            ]}
          >
            <Input
              placeholder="请输入负责老师"
              value={addInfo.chargeTeacher}
              defaultValue={addInfo.chargeTeacher}
              onChange={handleAddInputChange}
              name="chargeTeacher"
            />
          </Form.Item>
          <Form.Item
            name="insertTimestamp"
            label="时间范围"
            rules={[{ required: true }]}
            validateStatus={validateState.time}
            help={validateState.timehelp}
          >
            <Space direction="vertical" size={12} style={{ width: "48%" }}>
              <DatePicker
                defaultValue={
                  title.type == 0
                    ? moment(todayDate, dateFormat)
                    : moment(
                      new Date(
                        addInfo.insertTimestamp * 1000
                      ).toLocaleDateString(),
                      dateFormat
                    )
                }
                format={dateFormat}
                // value={ filterInfo.startTimestamp }
                style={{ width: "100%" }}
                onChange={handleAddTimeChange.bind(null, "insertTimestamp")}
                onBlur={handleMyValidate.bind(null, "insertTimestamp", addInfo)}
                name="insertTimestamp"
              />
            </Space>
            <span> - </span>
            <Space direction="vertical" size={12} style={{ width: "48%" }}>
              <DatePicker
                defaultValue={
                  title.type == 0
                    ? moment(todayDate, dateFormat)
                    : moment(
                      new Date(addInfo.endTime * 1000).toLocaleDateString(),
                      dateFormat
                    )
                }
                format={dateFormat}
                style={{ width: "100%" }}
                onChange={handleAddTimeChange.bind(null, "endTime")}
                onBlur={handleMyValidate.bind(null, "endTime", addInfo)}
                name="endTime"
              />
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
