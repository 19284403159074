/*
 * @Author: HouQi
 * @Date: 2021-09-06 08:58:21
 * @Last Modified by: HouQi
 * @Last Modified time: 2021-12-11 14:00:07
 */
import React, { useEffect, useState, useMemo } from "react";
import { request } from "../../../network/request";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Table,
  Space,
  notification,
  Modal,
  Tag,
  DatePicker,
  Popconfirm,
  message,
} from "antd";
import CustomCard from "../../../components/common/card/card";
import "./horizonStatistics.css";
import { post } from "../../../network/request";

// 尝试
import { useHistory } from "react-router-dom";
// export const context = useContext(0)

export default function HorizonStatistics(props) {
  // 尝试
  let history = useHistory();
  const [filterInfo, setFilterInfo] = useState({
    pageSize: 10,
    index: 1,
    studentName: "",
    entryGrade: "",
    degreeType: "",
  });
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll", height: "calc(100vh - 78px - 130px)" },
  };
  const [rawTable, setRawTable] = useState([]);
  const table = useMemo(() => {
    rawTable.forEach((item) => {
      // 主持项目
      if (item.projectManager.length === 2) {
        item.projectManagerNum =
          item.projectManager[0].projectManagerNum +
          item.projectManager[1].projectManagerNum;
        // 判断已完成和进行中的数量
        for (let index = 0; index < 2; index++) {
          if (item.projectManager[index].itemStatus === 1) {
            item.projectManagerNumIng =
              item.projectManager[index].projectManagerNum;
          } else if (item.projectManager[index].itemStatus === 2) {
            item.projectManagerNumEd =
              item.projectManager[index].projectManagerNum;
          }
        }
      } else if (item.projectManager.length === 1) {
        item.projectManagerNum = item.projectManager[0].projectManagerNum;
        // 判断已完成和进行中的数量
        if (item.projectManager[0].itemStatus === 1) {
          item.projectManagerNumIng = item.projectManager[0].projectManagerNum;
          item.projectManagerNumEd = 0;
        } else if (item.projectManager[0].itemStatus === 2) {
          item.projectManagerNumEd = item.projectManager[0].projectManagerNum;
          item.projectManagerNumIng = 0;
        }
      } else {
        item.projectManagerNum = 0;
        item.projectManagerNumEd = 0;
        item.projectManagerNumIng = 0;
      }
      // 参与项目
      if (item.projectParticipant.length === 2) {
        item.projectParticipantNum =
          item.projectParticipant[0].projectParticipantNum +
          item.projectParticipant[1].projectParticipantNum;
        // 判断已完成和进行中的数量
        for (let index = 0; index < 2; index++) {
          if (item.projectParticipant[index].itemStatus === 1) {
            item.projectParticipantNumIng =
              item.projectParticipant[index].projectParticipantNum;
          } else if (item.projectParticipant[index].itemStatus === 2) {
            item.projectParticipantNumEd =
              item.projectParticipant[index].projectParticipantNum;
          }
        }
      } else if (item.projectParticipant.length === 1) {
        item.projectParticipantNum =
          item.projectParticipant[0].projectParticipantNum;
        if (item.projectParticipant[0].itemStatus === 1) {
          item.projectParticipantNumIng =
            item.projectParticipant[0].projectParticipantNum;
          item.projectParticipantNumEd = 0;
        } else if (item.projectParticipant[0].itemStatus === 2) {
          item.projectParticipantNumEd =
            item.projectParticipant[0].projectParticipantNum;
          item.projectParticipantNumIng = 0;
        }
      } else {
        item.projectParticipantNum = 0;
        item.projectParticipantNumEd = 0;
        item.projectParticipantNumIng = 0;
      }
      // 主持建设方案
      if (item.projectManagerBuild.length === 2) {
        item.projectBuildManagerNum =
          item.projectManagerBuild[0].projectBuildManagerNum +
          item.projectManagerBuild[1].projectBuildManagerNum;
        for (let index = 0; index < 2; index++) {
          if (item.projectManagerBuild[index].itemStatus === 1) {
            item.projectBuildManagerNumIng =
              item.projectManagerBuild[index].projectBuildManagerNum;
          } else if (item.projectManagerBuild[index].itemStatus === 2) {
            item.projectBuildManagerNumEd =
              item.projectManagerBuild[index].projectBuildManagerNum;
          }
        }
      } else if (item.projectManagerBuild.length === 1) {
        item.projectBuildManagerNum =
          item.projectManagerBuild[0].projectBuildManagerNum;
        if (item.projectManagerBuild[0].itemStatus === 1) {
          item.projectBuildManagerNumIng =
            item.projectManagerBuild[0].projectBuildManagerNum;
          item.projectBuildManagerNumEd = 0;
        } else if (item.projectManagerBuild[0].itemStatus === 2) {
          item.projectBuildManagerNumEd =
            item.projectManagerBuild[0].projectBuildManagerNum;
          item.projectBuildManagerNumIng = 0;
        }
      } else {
        item.projectBuildManagerNum = 0;
        item.projectBuildManagerNumEd = 0;
        item.projectBuildManagerNumIng = 0;
      }
      // 参与建设方案
      if (item.projectParticipantBuild.length === 2) {
        item.projectBuildParticipantNum =
          item.projectParticipantBuild[0].projectBuildParticipantNum +
          item.projectParticipantBuild[1].projectBuildParticipantNum;

        for (let index = 0; index < 2; index++) {
          if (item.projectParticipantBuild[index].itemStatus === 1) {
            item.projectBuildParticipantNumIng =
              item.projectParticipantBuild[index].projectBuildParticipantNum;
          } else if (item.projectParticipantBuild[index].itemStatus === 2) {
            item.projectBuildParticipantNumEd =
              item.projectParticipantBuild[index].projectBuildParticipantNum;
          }
        }
      } else if (item.projectParticipantBuild.length === 1) {
        item.projectBuildParticipantNum =
          item.projectParticipantBuild[0].projectBuildParticipantNum;
        if (item.projectParticipantBuild[0].itemStatus === 1) {
          item.projectBuildParticipantNumIng =
            item.projectParticipantBuild[0].projectBuildParticipantNum;
          item.projectBuildParticipantNumEd = 0;
        } else if (item.projectParticipantBuild[0].itemStatus === 2) {
          item.projectBuildParticipantNumEd =
            item.projectParticipantBuild[0].projectBuildParticipantNum;
          item.projectBuildParticipantNumIng = 0;
        }
      } else {
        item.projectBuildParticipantNum = 0;
        item.projectBuildParticipantNumEd = 0;
        item.projectBuildParticipantNumIng = 0;
      }
      // 新技术框架
      if (item.newTechNums.length != 0) {
        item.NewTechNum = item.newTechNums[0].NewTechNum;
      } else {
        item.NewTechNum = 0;
      }
      // 软件著作权
      if (item.SoftWareNums.length != 0) {
        item.SoftWareNum = item.SoftWareNums[0].SoftWareNum;
      } else {
        item.SoftWareNum = 0;
      }
    });
    return rawTable;
  }, [rawTable]);
  const detailColumns = [
    {
      dataIndex: "title",
      key: "title",
      align: "center",
    },
    {
      dataIndex: "content",
      key: "content",
      align: "center",
    },
  ];
  const headerCardOptions = { title: null, bordered: false };
  const [filterForm] = Form.useForm();
  const [detailDialog, setDetailDialog] = useState({ visible: false });
  const { Option } = Select;
  const dateFormat = "YYYY/MM/DD";
  const [detailContent, setDetailContent] = useState({});
  const [loading, setLoading] = useState(null);
  const [total, setTotal] = useState(0);
  const [entryGrade, setEntryGrade] = useState([]);
  const columns = [
    {
      title: "姓名",
      dataIndex: "studentName",
      key: "studentName",
      align: "center",
      width: "150px",
    },
    {
      title: "年级",
      dataIndex: "grade",
      key: "grade",
      align: "center",
      width: "150px",
    },
    {
      title: "培养类型",
      dataIndex: "studentType",
      key: "studentType",
      align: "center",
      width: "150px",
    },
    {
      title: "主持项目",
      dataIndex: "projectManagerNum",
      key: "projectManagerNum",
      align: "center",
      width: "160px",
      render: (projectManagerNum, text) => (
        <Button
          onClick={toProject.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {projectManagerNum}
        </Button>
      ),
    },
    {
      title: "参与项目",
      dataIndex: "projectParticipantNum",
      key: "projectParticipantNum",
      align: "center",
      width: "160px",
      render: (projectParticipantNum, text) => (
        <Button
          onClick={toProject.bind(null, text, 2)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {projectParticipantNum}
        </Button>
      ),
    },
    {
      title: "主持建设方案",
      dataIndex: "projectBuildManagerNum",
      key: "projectBuildManagerNum",
      align: "center",
      width: "160px",
      render: (projectBuildManagerNum, text) => (
        <Button
          onClick={toConstruct.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {projectBuildManagerNum}
        </Button>
      ),
    },
    {
      title: "参与建设方案",
      dataIndex: "projectBuildParticipantNum",
      key: "projectBuildParticipantNum",
      align: "center",
      width: "160px",
      render: (projectBuildManagerNum, text) => (
        <Button
          onClick={toConstruct.bind(null, text, 2)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {projectBuildManagerNum}
        </Button>
      ),
    },
    {
      title: "新技术框架",
      dataIndex: "NewTechNum",
      key: "NewTechNum",
      align: "center",
      width: "160px",
      render: (NewTechNum, text) => (
        <Button
          onClick={toTechnology.bind(null, text, 1)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {NewTechNum}
        </Button>
      ),
    },
    {
      title: "软件著作权",
      dataIndex: "SoftWareNum",
      key: "SoftWareNum",
      align: "center",
      width: "160px",
      render: (SoftWareNum, text) => (
        <Button
          onClick={toSoftware.bind(null, text, 2)}
          style={{ border: 1, borderRadius: 12 }}
        >
          {SoftWareNum}
        </Button>
      ),
    },
    {
      title: "查看",
      key: "userName",
      align: "center",
      width: "140px",
      ellipsis: true,
      fixed: "right",
      render: (text, record) => (
        <Button
          type="primary"
          size="small"
          onClick={handleClickDetail.bind(null, text)}
        >
          查看详情
        </Button>
      ),
    },
  ];

  const detailTable = useMemo(() => {
    if (detailContent == {}) return;
    // const table = [];
    const table = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const detail = detailContent;
    console.log(detail);
    // console.log('!',detail);
    for (let item in detail) {
      switch (item) {
        case "studentName":
          table[0] = { title: "姓名", content: detail[item], key: 1 };
          // table.push();
          break;
        case "grade":
          table[1] = { title: "年级", content: detail[item], key: 2 };
          // table.push({ title: '年级', content: detail[item], key: 2 });
          break;
        case "studentType":
          table[2] = { title: "培养类型", content: detail[item], key: 3 };
          // table.push({ title: '培养类型', content: detail[item], key: 3 });
          break;
        case "projectManagerNumEd":
          // console.log('1')
          table[3] = {
            title: "主持项目（已完成）",
            content: detail[item],
            key: 4,
          };
          // table.push({ title: '主持项目（已完成）', content: detail[item], key: 4 });
          break;
        case "projectManagerNumIng":
          // console.log('2')
          table[4] = {
            title: "主持项目（进行中）",
            content: detail[item],
            key: 5,
          };
          // table.push({ title: '主持项目（进行中）', content: detail[item], key: 5 });
          break;
        case "projectParticipantNumEd":
          // console.log('3')
          table[5] = {
            title: "参与项目（已完成）",
            content: detail[item],
            key: 6,
          };
          // table.push({ title: '参与项目（已完成）', content: detail[item], key: 6 });
          break;
        case "projectParticipantNumIng":
          // console.log('4')
          table[6] = {
            title: "参与项目（进行中）",
            content: detail[item],
            key: 7,
          };
          // table.push({ title: '参与项目（进行中）', content: detail[item], key: 7 });
          break;
        case "projectBuildManagerNumEd":
          // console.log('5')
          table[7] = {
            title: "主持建设方案（已完成）",
            content: detail[item],
            key: 8,
          };
          // table.push({ title: '主持建设方案（已完成）', content: detail[item], key: 8 });
          break;
        case "projectBuildManagerNumIng":
          // console.log('6')
          table[8] = {
            title: "主持建设方案（进行中）",
            content: detail[item],
            key: 9,
          };
          // table.push({ title: '主持建设方案（进行中）', content: detail[item], key: 9 });
          break;
        case "projectBuildParticipantNumEd":
          // console.log('7')
          table[9] = {
            title: "参与建设方案（已完成）",
            content: detail[item],
            key: 10,
          };
          // table.push({ title: '参与建设方案（已完成）', content: detail[item], key: 10 });
          break;
        case "projectBuildParticipantNumIng":
          // console.log('8')
          table[10] = {
            title: "参与建设方案（进行中）",
            content: detail[item],
            key: 11,
          };
          // table.push({ title: '参与建设方案（进行中）', content: detail[item], key: 11 });
          break;
        case "NewTechNum":
          table[11] = { title: "新技术框架", content: detail[item], key: 12 };
          // table.push({ title: '新技术框架', content: detail[item], key: 12 });
          break;
        case "SoftWareNum":
          table[12] = { title: "软件著作权", content: detail[item], key: 13 };
          // table.push({ title: '软件著作权', content: detail[item], key: 13 });
          break;
        default:
          break;
      }
    }
    console.log(table);
    return table;
  }, [detailContent]);

  useEffect(() => {
    async function genEntryGrade() {
      await post(
        {
          url: "/ec/project/getOutStationGrade",
          data: {
            sessionId: localStorage.sessionId,
            inboundState: 2,
          },
        },
        (data) => {
          if (data.code == "N01") {
            let allGrade = data.contents.allGrade;
            console.log(allGrade);
            for (let i = 0; i < allGrade.length; i++) {
              entryGrade.push(parseInt(allGrade[i]));
            }
          } else {
            console.log("error");
          }
        },
        props.history
      );
      console.log(entryGrade);
      setEntryGrade(entryGrade);
    }
    genEntryGrade();
    getTableData(filterInfo);
  }, [filterInfo.pageSize, filterInfo.currIndex]);

  function handleClickDetail(data) {
    console.log(data);
    setDetailDialog({
      visible: true,
    });
    setDetailContent(data);
  }
  function handlePageChange(event, pageSize) {
    // console.log(event, pageSize)
    const index = event;
    setFilterInfo({ ...filterInfo, pageSize, currIndex: index, index: index });
  }
  function toProject(text, num) {
    history.push({
      pathname: "/home/projectManage",
      state: { data: text, num: num },
    });
  }
  function toConstruct(text, num) {
    console.log(text, num);
    history.push({
      pathname: "/home/projectConstruction",
      state: { data: text, num: num },
    });
  }
  function toTechnology(text, num) {
    console.log(text, num);
    history.push({
      pathname: "/home/technologyManage",
      state: { data: text, num: num },
    });
  }
  function toSoftware(text, num) {
    console.log(text, num);
    history.push({
      pathname: "/home/technologySoftware",
      state: { data: text, num: num },
    });
  }
  // async function handleClickExport() {
  //   const rawData = await request({
  //     url: "/ec/student/getProjectFileUrl",
  //     method: "post",
  //     data: {
  //       sessionId: localStorage.sessionId,
  //       degreeType: filterInfo.degreeType,
  //       entryGrade: filterInfo.entryGrade,
  //       studentName: filterInfo.studentName,
  //     },
  //   });
  //   // console.log("导出测试", rawData.data?.code);
  //   // console.log("导出测试", rawData.data);
  //   if (rawData.data?.code) {
  //     // 加问号为了解决浏览器缓存文件问题
  //     let downloadURL = rawData.data.contents;
  //     window.location = downloadURL + "?/" + Math.random();
  //     // window.open(downloadURL) /* 可以下载但会屏闪 */
  //     /*
  //       window.open（）方法会直接打开新的窗口显示跳转网页，是window对象的方法。
  //       window.location 会在当前网页更改url的地址进行跳转，不会弹出新的页面，是location对象的属性。
  //     */
  //   }
  // }
  async function handleClickExport() {
    const rawData = await request({
      url: "/ec/student/getProjectFileUrl",
      method: "post",
      data: {
        sessionId: localStorage.sessionId,
        degreeType: filterInfo.degreeType,
        entryGrade: filterInfo.entryGrade,
        studentName: filterInfo.studentName,
      },
    });
    if (rawData.data?.code) {
      // 加问号为了解决浏览器缓存文件问题
      let downloadURL = rawData.data.contents;
      // 创建一个隐藏的iframe
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = downloadURL + "?/" + Math.random();
      document.body.appendChild(iframe);
      // iframe加载完成后移除
      iframe.onload = function() {
        document.body.removeChild(iframe);
      };
    }
}


  async function getTableData(filterData) {
    // console.log('test!!!',filterData)
    let studentName = filterData.studentName;
    let degreeType = filterData.degreeType;
    let entryGrade = filterData.entryGrade;
    setLoading(true);
    await post(
      {
        url: "/ec/student/getStudentProjectNum",
        data: {
          sessionId: localStorage.sessionId,
          studentName,
          degreeType,
          entryGrade,
        },
      },
      (data) => {
        console.log(data);
        setLoading(false);
        setRawTable(data.contents);
      }
    );
  }
  function handleClear() {
    // 清除页面表单
    filterForm.resetFields();
    const initState = {
      studentName: "",
      degreeType: "",
      entryGrade: "",
    };
    setFilterInfo({
      ...initState,
    });
    getTableData(initState);
  }
  function handleFilter() {
    getTableData(filterInfo);
  }
  function handleInputChange(event) {
    // console.log('handleInputChange', event)
    const value = event.target.value;
    const name = event.target.name;
    console.log(name, value);
    setFilterInfo({ ...filterInfo, [name]: value });
  }
  function handleCancel() {
    setDetailDialog({
      visible: false,
    });
  }

  function handleValueChange(changedValues, allValues) {
    for (let key in changedValues) {
      if (!changedValues[key]) {
        changedValues[key] = "";
      }
    }
    setFilterInfo({ ...filterInfo, ...changedValues });
  }

  return (
    <>
      <CustomCard options={headerCardOptions}>
        <Form
          layout="inline"
          style={{ flexDirection: "column" }}
          form={filterForm}
          onValuesChange={handleValueChange}
        >
          <Row className="project-manage-row">
            <Col span={6} offset={1}>
              <Form.Item label="学生姓名" name="studentName">
                <Input
                  placeholder="请输入姓名"
                  value={filterInfo.studentName}
                  style={{ width: "80%" }}
                  onChange={handleInputChange}
                  name="studentName"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="年级" name="entryGrade">
                <Select
                  style={{ width: "80%" }}
                  placeholder="请选择年级"
                  allowClear
                >
                  {entryGrade.map((item, index) => (
                    <Option value={item} key={index}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="培养类型" name="degreeType">
                <Select
                  style={{ width: "80%" }}
                  placeholder="请选择培养类型"
                  allowClear
                >
                  <Option value="2" key="2">
                    专硕
                  </Option>
                  <Option value="1" key="1">
                    学硕
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Button type="primary" htmlType="submit" onClick={handleFilter}>
                筛选
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button type="plain" htmlType="submit" onClick={handleClear}>
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomCard>
      <CustomCard options={tableCardOptions}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 10,
          }}
        >
          <Button type="primary" htmlType="submit" onClick={handleClickExport}>
            导出为Excel
          </Button>
        </div>

        <div style={{ overflowX: "auto" }}>
          <Table
            className="table"
            rowKey="studentNo"
            columns={columns}
            dataSource={table}
            loading={loading}
            scroll={{ x: "1450px" }}
            rowClassName={(record, index) => {
              let className = "light-row";
              if (index % 2 === 1) className = "dark-row";
              return className;
            }}
            pagination={{
              total: total,
              current: filterInfo.index,
              pageSize: filterInfo.pageSize,
              pageSizeOptions: [10, 20, 30],
              onChange: handlePageChange,
              showSizeChanger: true,
            }}
          />
        </div>
      </CustomCard>

      <Modal
        title="详细信息"
        visible={detailDialog.visible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            关闭
          </Button>,
        ]}
      >
        <Table
          className="detailTable"
          showHeader={false}
          columns={detailColumns}
          dataSource={detailTable}
          pagination={false}
          rowClassName={(record, index) => {
            let className = "light-row";
            if (index % 2 === 1) className = "dark-row";
            return className;
          }}
        ></Table>
      </Modal>
    </>
  );
}
