/*
 * @Author: 张玥
 * @Date: 2020-11-10 15:53:15
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-06-07 22:28:33
 */
import React, { useEffect, useState, useMemo } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Table,
  Space,
  notification,
  Modal,
  Upload,
  DatePicker,
  Popconfirm,
  message,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { post, request } from "../../../network/request";
import moment from "moment";
//import component
import CustomCard from "../../../components/common/card/card";
//import css
import "./technologyManage.css";

const { Option } = Select;
const { TextArea } = Input;

export default function TechnologyManage(props) {
  const headerCardOptions = { title: null, bordered: false };
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll", height: "calc(100vh - 78px - 98px)" },
  };
  const [filterForm] = Form.useForm();
  const [addForm] = Form.useForm();
  const columns = [
    {
      title: "编号",
      dataIndex: "itemNumber",
      key: "itemNumber",
      align: "center",
    },
    {
      title: "新技术框架名称",
      dataIndex: "itemName",
      key: "itemName",
      align: "center",
    },
    {
      title: "申请时间",
      dataIndex: "insertTimestamp",
      key: "insertTimestamp",
      align: "center",
    },
    {
      title: "管理员审核状态",
      key: "itemStatus",
      align: "center",
      render: (text, record) => {
        switch (text.itemStatus) {
          case "已通过":
            return <span className="ec-text-color-green">{text.itemStatus}</span>;
          case "待审核":
            return <span className="ec-text-color-orange">{text.itemStatus}</span>;
          case "已拒绝":
            return <span className="ec-text-color-red">{text.itemStatus}</span>;
          default:
            return <span>{text.itemStatus}</span>;
        }
      },
    },
    {
      title: "处理理由",
      dataIndex: "note",
      key: "note",
      align: "center",
    },
    {
      title: "操作",
      key: "userName",
      align: "center",
      render: (text, record) => (
        <div>
          <Button
            className="operate-button ec-button-lightBlue"
            // type="primary"
            size="small"
            onClick={clickUploadFile.bind(null, text)}
            // style={{ backgroundColor: "#5BC0DE" }}
          >
            上传文件
          </Button>
          <Button
            className="operate-button ec-button-lightBlue"
            // type="primary"
            size="small"
            onClick={handleClickDownloadBtn.bind(null, "下载文件", text)}
            // style={{ backgroundColor: "#5BC0DE" }}
          >
            查看文件
          </Button>
          <Button
            className="operate-button ec-button-green"
            // type="primary"
            size="small"
            onClick={handleClickEditBtn.bind(null, text)}
            // style={{ backgroundColor: "#5CB85C", border: "1px solid #4CAE4C" }}
          >
            修改信息
          </Button>
          <Popconfirm
            className="operate-button"
            type="danger"
            placement="bottomRight"
            title="确认删除吗？"
            onConfirm={handleDeleteItem.bind(null, text)}
            okText="确定"
            cancelText="取消"
          >
            <Button className="operate-button" type="danger" size="small">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  const detailColumns = [
    {
      title: "文件名称",
      dataIndex: "filename",
      key: "title",
      align: "center",
    },
    {
      title: "操作",
      key: "content",
      align: "center",
      render: (text, record) => (
        <div>
          <Button
            type="primary"
            style={{ marginRight: "5%" }}
            onClick={downloadfile.bind(null, record)}
          >
            下载文件
          </Button>
        </div>
      ),
    },
  ];
  const singleDetailColumns = [
    {
      title: "文件名称",
      dataIndex: "filename",
      key: "title",
      align: "center",
    },
    {
      title: "操作",
      key: "content",
      align: "center",
      render: (text, record) => (
        <div>
          <Button
            type="primary"
            style={{ marginRight: "5%" }}
            onClick={downloadfile.bind(null, record)}
          >
            下载文件
          </Button>
          <Button type="danger" onClick={deleteSingleFile.bind(null, record)}>
            删除文件
          </Button>
        </div>
      ),
    },
  ];

  //state
  const [filterInfo, setFilterInfo] = useState({
    itemType: 5,
    roleSid: 3,
    pageSize: 10,
    index: 1,
    managerName: "",
    itemStatus: "",
  });
  const [addInfo, setAddInfo] = useState({
    itemType: 5,
    roleSid: 3,
    itemName: "",
    itemNumber: "",
    itemStatus: "",
    insertTimestamp: parseInt(new Date().getTime() / 1000),
    endTime: parseInt(new Date().getTime() / 1000),
    statusDescription: "",
  });
  const [rawTable, setRawTable] = useState([]);
  const [addDialog, setAddDialog] = useState({ visible: false });
  const [fileInfo, setFileInfo] = useState([]);
  const [detailTable, setDetailTable] = useState([]);
  const [singleDetailTable, setSingleDetailTable] = useState([]);
  const [total, setTotal] = useState(0);
  const [templateDialog, setTemplateDialog] = useState({ visible: false });
  const [refuseDialog, setRefuseDialog] = useState({ visible: false });
  const [dowmloadDialog, setDownloadDialog] = useState({ visible: false });
  const [uploadDialog, setUploadDialog] = useState({ visible: false });
  const [uploadFileList, setuploadFileList] = useState([]);
  const [title, setTitle] = useState({ type: 0 });
  const [selectItemId, setSelectItemId] = useState(1);
  const [validateState, setValidateState] = useState({
    time: "success",
    timehelp: undefined,
  });
  const [loading, setLoading] = useState(null);

  //memo
  const table = useMemo(() => {
    rawTable.forEach((item) => {
      switch (item.itemStatus) {
        case 1:
          item.itemStatus = "待审核";
          break;
        case 2:
          item.itemStatus = "已通过";
          break;
        case 3:
          item.itemStatus = "已拒绝";
          break;
        default:
          item.itemStatus = "错误";
      }
      item.updateTimestamp = !item.updateTimestamp
        ? "暂无"
        : new Date(item.updateTimestamp * 1000).toLocaleDateString();
      item.insertTimestamp = !item.insertTimestamp
        ? "暂无"
        : new Date(item.insertTimestamp * 1000).toLocaleDateString();
      item.endTime = !item.endTime
        ? "暂无"
        : new Date(item.endTime * 1000).toLocaleDateString();
    });
    return rawTable;
  }, [rawTable]);

  //date
  const dateFormat = "YYYY/MM/DD";
  const todayDate = new Date().toLocaleDateString();

  //form
  const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 20 },
  };
  const layout2 = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const uploadprops = {
    name: "file",
    action: "/ec/project/upLoadFile",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: stopUpload,
    fileList: uploadFileList,
    onChange(info) {
      console.log("info", info);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      setuploadFileList(info.fileList);
    },
  };

  //validate
  const validateMessages = {
    required: "",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
      array: "请填写${label}!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  useEffect(() => {
    getFileList();
  }, []);
  useEffect(() => {
    getTableData(filterInfo);
  }, [filterInfo.pageSize, filterInfo.currIndex]);
  useEffect(() => {
    addForm.setFieldsValue({
      itemName: addInfo.itemName,
      itemNumber: addInfo.itemNumber,
    });
  }, [addDialog]);

  async function getTableData(filterData) {
    setLoading(true);
    await post(
      {
        url: "/ec/project/getProjectInfoByItemType",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: localStorage.studentSid,
          ...filterData,
        },
      },
      (data) => {
        setLoading(false);
        setRawTable(data.contents.list);
        setTotal(data.contents.size);
      },
      props.history
    );
  }

  function handleFilter() {
    filterInfo.index = 1
    getTableData(filterInfo);
  }

  async function getFileList() {
    setLoading(true);
    await post(
      {
        url: "/ec/project/getFileInfo",
        data: {
          sessionId: localStorage.sessionId,
          flag: 0,
          itemSid: 1,
        },
      },
      (data) => {
        setLoading(false);
        setFileInfo(data.contents);
        if (data.code == "N01") setDetailTable(data.contents);
        else setDetailTable(data.contents.list);
      },
      props.history
    );
  }

  function clickUploadFile(data) {
    setSelectItemId(data.itemSid);
    setUploadDialog({
      visible: true,
    });
  }

  async function downloadfile(record) {
    console.log(record)
    await post(
      {
        url: "/ec/project/downloadFile",
        data: {
          sessionId: localStorage.sessionId,
          fileSid: record.fileSid,
          itemSid: record.itemSid,
          flag: 0,
        },
      },
      (data) => {
        if ((data.code = "N01")) {
          let url = data.contents;
          let a = document.createElement("a");
          let event = new MouseEvent("click");
          // a.download = "file";
          a.target = "_blank";
          a.href = url;
          a.dispatchEvent(event);
        }
      },
      props.history
    );
  }

  async function deleteFile(record) {
    await post(
      {
        url: "/ec/project/deleteFile",
        data: {
          sessionId: localStorage.sessionId,
          fileSid: record.fileSid,
          itemSid: record.itemSid,
          flag: 0,
        },
      },
      () => {
        notification.success({ description: "通知", message: "删除文件成功!" });
        getFileList();
      },
      props.history
    );
  }

  function stopUpload(file, filelist) {
    let oldList = [...uploadFileList];
    oldList.push(file);
    setuploadFileList(oldList);
    return new Promise((resolve, reject) => {
      return reject(false);
    });
  }

  function handleSelectChange(tag, value, options) {
    if (tag == "itemStatus") value = parseInt(value);
    setFilterInfo({ ...filterInfo, [tag]: value });
  }

  function handleRefuseInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    setAddInfo({ ...addInfo, [name]: value });
  }

  function handleClear() {
    filterForm.resetFields();
    const initState = {
      itemType: 5,
      roleSid: 3,
      pageSize: 10,
      index: 1,
      itemStatus: "",
      itemNumber: "",
      managerName: "",
    };
    setFilterInfo({
      ...initState,
    });
    getTableData(initState);
  }

  async function handleClickTemplateBtn() {
    await post(
      {
        url: "/ec/project/getFileInfo",
        data: {
          sessionId: localStorage.sessionId,
          flag: 0,
          itemSid: 1,
        },
      },
      (data) => {
        if (data.code == "N01") setDetailTable(data.contents);
        else setDetailTable(data.contents.list);
      }
    );
    setTemplateDialog({
      visible: true,
    });
  }

  function handlePageChange(event, pageSize) {
    const index = event;
    setFilterInfo({ ...filterInfo, pageSize, currIndex: index, index: index });
  }

  function handleClickAddBtn() {
    handleClearAddInfo();
    setAddDialog({
      visible: true,
    });
    setTitle({
      type: 0,
    });
  }

  function handleClearAddInfo() {
    addForm.resetFields();
    const initAdd = {
      itemType: 5,
      roleSid: 3,
      itemName: "",
      itemNumber: "",
      itemStatus: "",
      insertTimestamp: parseInt(new Date().getTime() / 1000),
      endTime: parseInt(new Date().getTime() / 1000),
      statusDescription: "",
    };
    const initValidate = {
      time: "success",
      timehelp: undefined,
    };
    setAddInfo({ ...initAdd });
    setValidateState({ ...initValidate });
  }

  function handleCancel() {
    setTemplateDialog({
      visible: false,
    });
  }

  function handleCancelAdd() {
    addForm.resetFields();
    setAddDialog({
      visible: false,
    });
  }

  function handleClearAddInfo() {
    addForm.resetFields();
    const initAdd = {
      itemType: 5,
      roleSid: 3,
      itemName: "",
      itemNumber: "",
      itemStatus: "",
      insertTimestamp: parseInt(new Date().getTime() / 1000),
      endTime: parseInt(new Date().getTime() / 1000),
      statusDescription: "",
    };
    const initValidate = {
      time: "success",
      timehelp: undefined,
    };
    setAddInfo({ ...initAdd });
    setValidateState({ ...initValidate });
  }

  function handleTimeChange(tag, date, dateString) {
    let value = "";
    let newAdd = { ...addInfo };
    if (date) {
      value = parseInt(moment(dateString, dateFormat).valueOf() / 1000);
    }
    newAdd[tag] = value;
    newAdd["endTime"] = value;
    setAddInfo(newAdd);
    handleMyValidate(tag, newAdd);
  }

  function myValidate(name, data) {
    console.log(name);
    console.log(data);
    if (!data) {
      data = { ...addInfo };
    }
    switch (name) {
      case "insertTimestamp":
      case "endTime": {
        if (data[name] == undefined || data[name] == "") {
          console.log("here end");
          setValidateState({
            ...validateState,
            time: "error",
            timehelp: "请填写申请时间!",
          });
        } else {
          console.log("here success");
          setValidateState({
            ...validateState,
            time: "success",
            timehelp: undefined,
          });
        }
        break;
      }
      default:
        break;
    }
  }

  function handleMyValidate(name, data) {
    myValidate(name, data);
  }

  function handleBeforeAjax() {
    if (title.type != 0) {
      if (addInfo.itemNumber == "") return false;
    }
    if (addInfo.insertTimestamp == "") return false;
    return true;
  }

  function handleClickAddItem() {
    let flag = handleBeforeAjax();
    if (flag) {
      let formdata = { ...addInfo };
      formdata.sessionId = localStorage.sessionId;
      formdata.managerSid = localStorage.studentSid;
      formdata.participantSid = "";
      addProjectInfo(formdata);
    } else {
      notification.error({
        description: "表单信息尚未完善，无法提交！",
        message: "警告",
      });
    }
  }

  function handleAddInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    setAddInfo({ ...addInfo, [name]: value });
  }

  async function addProjectInfo(data) {
    setLoading(true);
    await post(
      {
        url: "/ec/project/addProjectInfo",
        data,
      },
      (data) => {
        setLoading(false);
        handleCancelAdd();
        addForm.resetFields();
        getTableData(filterInfo);
      },
      props.history
    );
  }

  function handleClickEditBtn(text) {
    handleClearAddInfo();
    let data = { ...text };
    setTitle({
      type: 1,
    });
    data.insertTimestamp = parseInt(
      new Date(data.insertTimestamp).valueOf() / 1000
    );
    data.endTime = parseInt(new Date(data.endTime).valueOf() / 1000);
    setAddInfo({ ...data });
    setAddDialog({
      visible: true,
    });
  }

  function handleClickEditItem() {
    let flag = handleBeforeAjax();
    if (flag) {
      let formdata = { ...addInfo };
      formdata.sessionId = localStorage.sessionId;
      formdata.roleSid = 3;
      formdata.statusDescription = formdata.itemStatus;
      delete formdata.participants;
      updateProjectInfo(formdata);
    } else {
      console.log("验证不符合");
      notification.error({
        description: "表单信息尚未完善，无法提交！",
        message: "警告",
      });
    }
  }

  async function updateProjectInfo(data) {
    setLoading(true);
    await post(
      {
        url: "/ec/project/updateProjectInfoByItemSid",
        data,
      },
      (data) => {
        setLoading(false);
        handleCancelAdd();
        addForm.resetFields();
        getTableData(filterInfo);
      },
      props.history
    );
  }

  async function handleDeleteItem(data) {
    await post(
      {
        url: "/ec/project/deleteProjectInfo",
        data: {
          sessionId: localStorage.sessionId,
          itemSid: data.itemSid,
          roleSid: 3,
        },
      },
      () => {
        notification.success({ description: "通知", message: "删除成功!" });
        getTableData(filterInfo);
      },
      props.history
    );
  }

  function handleCancelUpload() {
    setUploadDialog({
      visible: false,
    });
  }

  async function handleConfirmUpload() {
    let list = [...uploadFileList];
    if (list != []) {
      list.forEach((item) => {
        console.log(item);
        upLoad(item);
      });
    }
  }

  async function upLoad(item) {
    let data = {
      sessionId: localStorage.sessionId,
      itemSid: selectItemId,
      flag: 0,
      file: item,
      updateTime: parseInt(new Date().getTime() / 1000),
    };
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    await post(
      {
        url: "/ec/project/upLoadFile",
        data: formData,
      },
      () => {
        notification.success({ description: "通知", message: "上传文件成功!" });
        getFileList();
        setuploadFileList([])
        handleCancelUpload();
      },
      props.history
    );
  }

  function handleCancelDownload() {
    setDownloadDialog({
      visible: false,
    });
  }

  async function deleteSingleFile(record) {
    await post(
      {
        url: "/ec/project/deleteFile",
        data: {
          sessionId: localStorage.sessionId,
          fileSid: record.fileSid,
          itemSid: record.itemSid,
          flag: 0,
        },
      },
      () => {
        notification.success({ description: "通知", message: "删除文件成功!" });
        getSingleFileList(record.itemSid);
      },
      props.history
    );
  }

  async function getSingleFileList(id) {
    setLoading(true);
    await post(
      {
        url: "/ec/project/getFileInfo",
        data: {
          sessionId: localStorage.sessionId,
          flag: 0,
          itemSid: id,
        },
      },
      (data) => {
        setLoading(false);
        if (data.code == "N01") setSingleDetailTable(data.contents);
        else setSingleDetailTable(data.contents.list);
      },
      props.history
    );
  }

  async function handleClickDownloadBtn(type, data) {
    await post(
      {
        url: "/ec/project/getFileInfo",
        data: {
          sessionId: localStorage.sessionId,
          flag: 0,
          itemSid: data.itemSid,
        },
      },
      (data) => {
        if (data.code == "N01") setSingleDetailTable(data.contents);
        else setSingleDetailTable(data.contents.list);
      }
    );
    setDownloadDialog({
      visible: true,
    });
  }

  return (
    <>
      <CustomCard options={headerCardOptions}>
        <Form
          layout="inline"
          // {...layout}
          style={{ flexDirection: "column" }}
          form={filterForm}
        >
          <Row>
            <Col span={6} offset={1}>
              <Form.Item label="管理员审核状态" name="itemStatus">
                <Select
                  style={{ width: "80%" }}
                  value={filterInfo.itemStatus}
                  placeholder="--请选择--"
                  name="itemStatus"
                  onChange={handleSelectChange.bind(null, 'itemStatus')}
                  allowClear
                >
                  <Option value="1" key="1">
                    待审核
                  </Option>
                  <Option value="2" key="2">
                    已同意
                  </Option>
                  <Option value="3" key="3">
                    已拒绝
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" onClick={handleFilter}>
                筛选
              </Button>
            </Col>
            <Col offset={1}>
              <Button type="plain" htmlType="submit" onClick={handleClear}>
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomCard>
      <CustomCard options={tableCardOptions}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 10,
          }}
        >
          <Button type="primary" onClick={handleClickTemplateBtn.bind(null)}>
            查看模板
          </Button>
          <Button
            type="primary"
            style={{ marginLeft: "30px" }}
            onClick={handleClickAddBtn.bind(null)}
          >
            新增技术框架
          </Button>
        </div>
        <div>
          <Table
            className="table"
            rowKey="itemSid"
            columns={columns}
            dataSource={table}
            loading={loading}
            pagination={{
              total: total,
              current: filterInfo.currIndex,
              pageSize: filterInfo.pageSize,
              pageSizeOptions: [10, 20, 30],
              onChange: handlePageChange,
              showSizeChanger: true,
            }}
          />
        </div>
      </CustomCard>
      <Modal
        title="查看模板"
        visible={templateDialog.visible}
        onCancel={handleCancel}
        footer={[
          <div>
            <Button key="backupload" onClick={handleCancel}>
              关闭
            </Button>
          </div>,
        ]}
        width={800}
      >
        <Table
          className="table"
          // showHeader={ false }
          columns={detailColumns}
          dataSource={detailTable}
          pagination={false}
        ></Table>
      </Modal>
      <Modal
        title={title.type == 0 ? "新增技术框架" : "修改技术框架"}
        visible={addDialog.visible}
        onCancel={handleCancelAdd}
        style={{width:'800px'}}
        footer={[
          <div>
            <Button key="back" onClick={handleClearAddInfo}>
              清空
            </Button>
            <Button
              key="submitAdd"
              type="primary"
              onClick={
                title.type == 0 ? handleClickAddItem : handleClickEditItem
              }
            >
              提交
            </Button>
          </div>,
        ]}
      >
        <Form
          {...layout2}
          name="control-ref"
          form={addForm}
          validateMessages={validateMessages}
          validateTrigger="onBlur"
        >
          <Form.Item
            name="itemName"
            label="项目名称"
            rules={[
              {
                required: true,
                message: "请输入项目名称!",
              },
            ]}
          >
            <Input
              placeholder="请输入项目名称"
              value={addInfo.itemName}
              onChange={handleAddInputChange}
              name="itemName"
            />
          </Form.Item>
          <Form.Item
            name="itemNumber"
            label="项目编号"
    
            style={title.type == 0 ? { display: "none" } : null}
            rules={[
              {
                required: true,
                message: "请输入项目编号!",
              },
            ]}
          >
            <Input
              placeholder="请输入项目编号"
              value={addInfo.itemNumber}
              onChange={handleAddInputChange}
              name="itemNumber"
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            name="insertTimestamp"
            label="申请时间"
            rules={[{ required: true }]}
            validateStatus={validateState.time}
            help={validateState.timehelp}
          >
            <Space direction="vertical" size={12} style={{ width: "100%" }}>
              <DatePicker
                defaultValue={
                  title.type == 0
                    ? moment(todayDate, dateFormat)
                    : moment(
                        new Date(
                          addInfo.insertTimestamp * 1000
                        ).toLocaleDateString(),
                        dateFormat
                      )
                }
                format={dateFormat}
                style={{ width: "100%" }}
                onChange={handleTimeChange.bind(null, "insertTimestamp")}
                onBlur={handleMyValidate.bind(null, "insertTimestamp", addInfo)}
                name="insertTimestamp"
              />
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="上传文件"
        visible={uploadDialog.visible}
        onCancel={handleCancelUpload}
        footer={[
          <div>
            <Button type="primary" onClick={handleConfirmUpload}>
              确认上传
            </Button>
            <Button onClick={handleCancelUpload}>关闭</Button>
          </div>,
        ]}
      >
        <div>
          <Upload {...uploadprops}>
            <Button type="primary">选择文件</Button>
          </Upload>
        </div>
      </Modal>
      <Modal
        title="文件列表"
        visible={dowmloadDialog.visible}
        onCancel={handleCancelDownload}
        footer={[
          <Button key="back" onClick={handleCancelDownload}>
            关闭
          </Button>,
        ]}
        width={800}
      >
        <Table
          className="table"
          // showHeader={ false }
          columns={singleDetailColumns}
          dataSource={singleDetailTable}
          pagination={false}
        ></Table>
      </Modal>
    </>
  );
}
