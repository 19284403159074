/*
 * @Author: ZhouShan
 * @Date: 2020-11-06 14:42:15
 * @Last Modified by: HouQi
 * @Last Modified time: 2022-02-24 19:18:21
 */
import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Select,
  Table,
  Space,
  notification,
  Modal,
} from "antd";
import { post, request } from "../../../../network/request";
import { useHistory } from "react-router-dom";

import "./checkHandover.css";

export default function CheckHandover(props) {
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: this.onSelectChange,
  // };
  const history = useHistory();
  const columns = [
    {
      title: "交接内容",
      dataIndex: "itemName",
      key: "itemName",
      align: "center",
    },
    {
      title: "类型",
      dataIndex: "conditionName",
      key: "conditionName",
      align: "center",
    },
    {
      title: "来源",
      dataIndex: "roleName",
      key: "roleName",
      align: "center",
    },
    {
      title: "是否需要交接",
      key: "ifNeedHandover",
      align: "center",
      render: (text) => {
        if (text.ifNeedHandover === "否")
          return <span style={{ color: "red" }}>{text.ifNeedHandover}</span>;
        else
          return <span style={{ color: "green" }}>{text.ifNeedHandover}</span>;
      },
    },
    {
      title: "交接人",
      dataIndex: "heir",
      key: "heir",
      align: "center",
    },
    {
      title: "交接人是否确认",
      key: "isConfirm",
      align: "center",
      render: text => {
        if (text.isConfirm === '确认') {
          return <span style={{ color: "green" }}>{text.isConfirm}</span>;
        }
        else {
          return text.isConfirm;
        }
      }
    },
  ];
  /*************state**************/
  const [loading, setLoading] = useState(null);
  const [rawTable, setRawTable] = useState([]);
  const [total, setTotal] = useState(0);
  const [filterInfo, setFilterInfo] = useState({
    pageSize: 10,
    currIndex: 1,
  });
  const [selectedRowKeys, setSelectedKeys] = useState([]);
  /**************memo*************/
  const table = useMemo(() => {
    const tableData = [].concat(rawTable);
    tableData.forEach((item) => {
      switch (item.ifConfirm) {
        case 0:
          item.isConfirm = "未指定";
          item.ifNeedHandover = '是';
          break;
        case 1:
          item.isConfirm = "确认";
          item.ifNeedHandover = '是';
          break;
        case 2:
          item.isConfirm = "未确认";
          item.ifNeedHandover = '是';
          break;
        case 3:
          item.isConfirm = "不需要交接";
          item.ifNeedHandover = '否';
          break;
      }
      if (!item.heir || item.ifConfirm === 0) item.heir = "未指定";
      else {
        item.heir = item.heir ? item.heir : "不需指定";
      }
      if (item.heir === props.detail.studentName) {
        item.heir = '不需指定';
        item.isConfirm = '不需交接';
      }
    });
    return tableData;
  }, [rawTable]);
  /*************effect*************/
  useEffect(() => {
    // 接口错误原因：props.detail是空的
    getTableData(props.detail.studentSid);
    // console.log(props)
  }, [props.detail, filterInfo.pageSize, filterInfo.currIndex]);
  useEffect(() => {
    setSelectedKeys([]);
    setFilterInfo({
      pageSize: 10,
      currIndex: 1
    })
  }, [props.handoverDialog])
  /**************method*************/
  //分页改变
  function handlePageChange(event, pageSize) {
    setFilterInfo({ ...filterInfo, pageSize, currIndex: event });
  }
  // studentSid=116解决第一次调用时studentSid为undefined，接口报错500问题
  async function getTableData(studentSid=116) {
    setLoading(true);
    // console.log(localStorage.sessionId,studentSid,filterInfo)
    await post(
      {
        url: "/ec/station/getStudentHandoverProject",
        data: {
          sessionId: localStorage.sessionId,
          studentSid,
          ...filterInfo,
        },
      },
      (data) => {
        setLoading(false);
        setRawTable(data.contents.list);
        setTotal(data.contents.size);
      },
      history
    );
  }
  function handleSelectChange(selectedRowKeys, selectedRows) {
    setSelectedKeys(selectedRowKeys);
  }
  //更新项目交接状态，仅改变项目是否需要交接
  async function updateIfConfirm(ifConfirm) {
    await post(
      {
        url: "/ec/station/updateIfConfirm",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: props.detail.studentSid,
          ifConfirm,
          itemSid: "",
          handoverSid: "",
          roleSid: JSON.parse(localStorage.userInfo).roleSid,
          maintainSid: selectedRowKeys.join(","),
        },
      },
      () => {
        notification.success({
          message: '通知',
          description: '修改状态成功'
        })
        getTableData(props.detail.studentSid);
        setSelectedKeys([]);
      },
      history
    );
  }

  function handleSetNeedHandover() {
    if (selectedRowKeys.length <= 0) {
      notification.error({
        message: '警告',
        description: '您未选择任何项目'
      });
      return;
    }
    updateIfConfirm(0);
  }

  function handleSetNotNeedHandover() {
    if (selectedRowKeys.length <= 0) {
      notification.error({
        message: '警告',
        description: '您未选择任何项目'
      });
      return;
    }
    updateIfConfirm(3);
  }

  return (
    <Modal
      title="横向出站交接情况"
      visible={props.handoverDialog}
      onCancel={props.handleDialogClose.bind(null, "checkHandoverDialog")}
      width={1000}
      footer={[
        <Button
          key="back"
          onClick={props.handleDialogClose.bind(null, "checkHandoverDialog")}
        >
          关闭
        </Button>,
      ]}
    >
      <div style={{ marginBottom: "10px" }}>
        <Button
          type="primary"
          style={{ marginRight: "10px" }}
          onClick={handleSetNeedHandover}
        >
          设置为需要交接
        </Button>
        <Button type="primary" onClick={handleSetNotNeedHandover}>
          设置为无需交接
        </Button>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={table}
          loading={loading}
          rowKey="maintainSid"
          rowSelection={{
            selectedRowKeys,
            onChange: handleSelectChange,
          }}
          pagination={{
            total,
            current: filterInfo.currIndex,
            pageSize: filterInfo.pageSize,
            pageSizeOptions: [10, 20, 30],
            showSizeChanger: true,
            onChange: handlePageChange,
          }}
        />
      </div>
    </Modal>
  );
}
