import React, { useEffect, useState, useMemo ,useRef} from "react";

import { DatePicker, Form, InputNumber, Button, Select, Modal, Table, Row, Col, notification, } from "antd";

import { post } from "../../../../network/request";
import moment from "moment";
export default function EditInfo(props) {
  //  console.log('props')
    console.log(props)
    //常量
    const dateFormat = "YYYY/MM/DD";
    const columns = [
        {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            align: 'center',

        },
        {
            title: '考察项目',
            dataIndex: 'requestTitle',
            key: 'index',
            align: 'center',

        },
        {
            title: '达标要求',
            dataIndex: 'inputMount',
            key: 'index',
            align: 'center',
        },
    ];
    
    //方法
    //初始化
    const handleInit = () => {
        let { demands, condition } = props
        let { grade, type } = props.gradeAndType
        if (condition.length == 0) {
            return
        }
        if(!demands)
         return
        let res = []
        let effectiveTimestamp = null
        // console.log(condition.length)
        for (let i = 0; i < condition.length; i++) {
            for (let j = 0; j < demands.length; j++) {

                if (demands[j].outboundConditionSid == condition[i].outboundConditionSid && demands[j].grade == grade && demands[j].studentType == type) {
                    effectiveTimestamp = demands[j].effectiveTimestamp
                    let temp = {
                        conditionName: condition[i].conditionName,
                        needSid: demands[j].needSid,
                        amount: demands[j].amount,
                        //  outboundConditionSid:demands[j].outboundConditionSid
                    }
                    res.push(temp)
                }
            }
        }
        res.push(effectiveTimestamp)
         setOldDataTime(effectiveTimestamp)
        //   handleFormData(res)
         handleTabelContent(res)
    }
    //处理数据
    const handleFormData=(data)=>{
        let res={}
        for(let i=0 ;i<data.length-1;i++){
             res[data[i].needSid]=data[i].amount
            //  console.log(data[i])
        }
        // console.log({...res})
        // initDatas.current=res0
        settingForm.resetFields()
        settingForm.setFieldsValue(res)
        // setInitData(res)
    }
    //处理table内容
    const handleTabelContent=(tableDatas)=>{
        //  console.log(tableDatas)
         let res=[]
         for(var i=0;i<tableDatas.length-1;i++){
            //  console.log(tableDatas[i])
            let  amount=tableDatas[i].amount
             let temp={
                 index:i+1,
                 requestTitle:tableDatas[i].conditionName,
                //  needSid:tableDatas[i].needSid,
                 inputMount:(<Form.Item name={tableDatas[i].needSid} style={{margin:'0' , padding:'0'}}
                >
                 <InputNumber
                //    placeholder="请输入整数"
                  precision="0"
                   style={{ width: "70%" }}
                   min={0}
                   value={parseInt(amount)}
                   allowClear
                //   defaultValue = {parseInt(amount)}
                 />
               </Form.Item>),
             }
            res.push(temp)
         }
         let temp={
            index:i+1,
            requestTitle : "生效时间",
            inputMount : (<Form.Item  name="effectiveTimestamp" style={{margin:'0' , padding:'0'}}
                        //  rules={[{required: true,message: "请输入日期",}]}
                         >
                          <DatePicker
                            format={dateFormat}
                            placeholder={new Date(tableDatas[tableDatas.length-1] * 1000).toLocaleDateString()}
                            style={{ width: '70%' }}
                            name="effectiveTimestamp"
                          />
                        </Form.Item>)
            }
          res.push(temp)
          setRowData(res)
        //   console.log(rowDatas)
          handleFormData(tableDatas)
        //   settingForm.resetFields()
    }
    //提交修改
    const handleUpdateData=(value)=>{
    //    props.cancel('edit')   
       
    //    for(let i=0;i<oldData.length;i++){
    //        console.log(oldData[i].name)
    //        if(typeof(oldData[i])==undefined){
                
    //        }
    //    }
    // let i=0
    // // for(let i =0;i++;i<value.length){
    // //     console.log(value[i])
    // // }
    // for(let item in value){

    //     if( typeof(value[item]) == 'undefined'||value[item]==null){
    //         console.log(oldData.length)
    //         console.log(i)
    //         if(i<oldData.length-1){
    //             //   console.log(oldData[i])
    //             //   console.log("item"+item)
    //               value[item]=oldData[i].amount
    //         }
                
    //     }  
    //     i++
    // }
    // console.log( value["effectiveTimestamp"])
    if(typeof(  value["effectiveTimestamp"])=='undefined'){
        value["effectiveTimestamp"] = oldDataTime
    }
    else{
       let time=  parseInt(new Date(value["effectiveTimestamp"]).getTime() / 1000)
       value.effectiveTimestamp=time 
    }
       
    //    console.log(value)
       updateData(value)
    }
    //取消
    const handleCancel=()=>{
      props.cancel()   
    //   console.log(11)
      settingForm.resetFields()
    }
    //接口
    const updateData=(editDatas)=>{
        post({
         url: "/ec/station/updateOutBoundZConditions",
         data: {
           ...editDatas,
           sessionId: localStorage.sessionId,
         },
        },(data)=>{
           if(data.code=="N01"){
             notification.success({
               description: "添加成功！",
               message: "通知",
               duration: 1,
               onClose: () => {
                handleCancel()
                props.getTableInfo()
               },
             });
           }else{
             notification.error({
               description: "添加失败！",
               message: "通知",
               duration: 1,
               onClose: () => {
                handleCancel()
               },
             });
           }
        })
      }
    //useState
    const [settingForm] = Form.useForm();
    const [rowDatas,setRowData]=useState([])
    const [oldDataTime,setOldDataTime]=useState([])
    const [initData,setInitData]=useState({})
    //useEffect
    useEffect(() => {
        setRowData([])
        handleInit()
    }, [props.visible])
  //useRef
    const initDatas=useRef({})
    return (
        <>
            <Modal title={'<' + props.gradeAndType.grade + (props.type == 1 ? "学硕" : '专硕') + '>' + "出站条件"} visible={props.visible}       onCancel={handleCancel}
                footer={null}>
                <Form form={settingForm} name="settingForm" labelCol={{ span: 5 }} onFinish={handleUpdateData.bind(null)}
                // initialValues={initDatas.current}
                >
                    {/* <Form.Item> */}
                    <Table
                        className="settingDialogTable"
                        rowKey="index"
                        pagination={false}
                        columns={columns}
                        dataSource={rowDatas} 
                        // loading={loading}
                        />
                    {/* </Form.Item> */}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block  style={{ margin: "20px 0 0 0" }}>
                            修改
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}