import React, { useEffect, useState, useMemo } from "react";
import {
  Image,
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Table,
  Popconfirm,
  notification,
  Modal,
  message,
  Upload,
} from "antd";
// import { Icon } from '@ant-design/compatible';
import { UploadOutlined } from "@ant-design/icons";
import { post, request } from "../../../network/request";
import moment from "moment";
//import component
import CustomCard from "../../../components/common/card/card";
import AddProject from './addProject';
//import css
import "./stuPatentManage.css";

const { Option } = Select;

export default function PatentManage(props) {
  const headerCardOptions = { title: null, bordered: false };
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll", height: "calc(100vh - 78px - 88px)" },
  };
  const columns = [
    {
      title: "专利编号",
      dataIndex: "itemNumber",
      key: "itemNumber",
      align: "center",
    },
    {
      title: "专利名称",
      dataIndex: "itemName",
      key: "itemName",
      align: "center",
    },
    {
      title: "主持者",
      dataIndex: "managerName",
      key: "managerName",
      align: "center",
    },
    // {
    //   title: "参与者",
    //   dataIndex: "participants",
    //   key: "participants",
    //   align: "center",
    // },
    {
      title: "申请日期",
      dataIndex: "insertTimestamp",
      key: "insertTimestamp",
      align: "center",
    },
    {
      title: "管理员审核状态",
      key: "itemStatus",
      align: "center",
      render: (text) => {
        if (text.itemStatus == "已同意") {
          return <span className="ec-text-color-green">{text.itemStatus}</span>;
        } else if (text.itemStatus == "已拒绝") {
          return <span className="ec-text-color-red">{text.itemStatus}</span>;
        } else if (text.itemStatus == "待审核") {
          return <span className="ec-text-color-orange">{text.itemStatus}</span>;
        }
      },
    },
    {
      title: "处理理由",
      dataIndex: "note",
      key: "note",
      align: "center",
    },
    {
      title: "专利局状态",
      dataIndex: "statusDescription",
      key: "statusDescription",
      align: "center",
    },
    {
      title: "操作",
      key: "operate",
      align: "center",
      render: (text, record) => (
        <>
          <Button
            className="operate-button ec-button-lightBlue"
            // type="primary"
            size="small"
            onClick={clickUploadFile.bind(null, text)}
            style={{ backgroundColor: "#5BC0DE" }}
          >
            上传文件
          </Button>
          <Button
            // type="primary"
            size="small"
            className="lookPicture ec-button-lightBlue"
            onClick={handleClickFile.bind(null, text)}
          >
            查看文件
          </Button>
          <Button
            // type="primary"
            size="small"
            className="operate-button ec-button-green"
            onClick={ediDataInit.bind(null, text)}
          >
            修改信息
          </Button>
          <Popconfirm
            className="delete"
            placement="bottomRight"
            title="确认删除吗？"
            onConfirm={handleDeleteItem.bind(null, text)}
            okText="确定"
            cancelText="取消"
          >
            <Button
              className="operate-button"
              type="danger"
              size="small"
            >
              删除
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];
  const fileColumns = [
    {
      title: "文件名称",
      dataIndex: "filename",
      align: "center",
    },
    {
      title: "操作",
      key: "operate",
      align: "center",
      render: (text, record) => (
        <>
          <Button
            type="primary"
            size="small"
            onClick={handleClickPicture.bind(null, text)}
          >
            获取文件
          </Button>

          <Button
            type="danger"
            size="small"
            style={{ marginLeft: '10px' }}
            onClick={deleteFile.bind(null, text)}
          >
            删除
          </Button>
        </>
      ),
    },
  ];
  const [filterInfo, setFilterInfo] = useState({
    itemType: 5,
    roleSid: parseInt(localStorage.roleSid),
    pageSize: 10,
    index: 1,
    itemStatus: "",
    managerName: "",
    startTimestamp: 1,
    endTimestamp: parseInt(new Date().getTime() / 1000),
    participants: "",
    itemName: "",
  });
  const [addInfo, setAddInfo] = useState({
    itemType: 5,
    roleSid: parseInt(localStorage.roleSid),
    itemName: "",
    itemNumber: "",
    managerSid: 0,
    itemStatus: "",
    insertTimestamp: "",
    endTimestamp: "",
    statusDescription: "",
  });
  const refuseLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  //date
  const dateFormat = "YYYY/MM/DD";
  const todayDate = new Date().toLocaleDateString();
  const [filterForm] = Form.useForm();
  const [refuseForm] = Form.useForm();
  const [total, setTotal] = useState(0);
  const [itemSid, setItemSid] = useState("");
  const [loading, setLoading] = useState(null);
  const [urlLoading, setUrlLoading] = useState(null);
  const [rawTable, setRawTable] = useState([]);
  const [fileTable, setFileTable] = useState([]);
  const [fileDialog, setFileDialog] = useState({ visible: false });
  const [pictureDialog, setPictureDialog] = useState({ visible: false });
  const [refuseDialog, setRefuseDialog] = useState({ visible: false });
  const [fileContent, setFileContent] = useState({});
  const [pictureContent, setPictureContent] = useState({});
  function handleInputChange(event) {
    const value = event.target.value;
    setFilterInfo({ ...filterInfo, [event.target.name]: value });
  }
  function handlePageChange(event, pageSize) {
    setFilterInfo({ ...filterInfo, pageSize, index: event });
  }
  function handleSelectChange(tag, value, options) {
    setFilterInfo({ ...filterInfo, [tag]: value });
  }
  function handleTimeChange(tag, date, dateString) {
    let value = "";
    if (date) {
      value = parseInt(moment(dateString, dateFormat).valueOf() / 1000);
      // console.log(value);
    }
    setFilterInfo({ ...filterInfo, [tag]: value });
  }
  async function getTableData(filterData) {
    setLoading(true);
    await post(
      {
        url: "/ec/project/getProjectInfoByWriteItemType",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: parseInt(localStorage.studentSid),
          ...filterData,
        },
      },
      (data) => {
        setRawTable([]);
        setLoading(false);
        setRawTable(data.contents.list);
        setTotal(data.contents.size);
      },
      props.history
    );
  }
  async function getFileData(itemSid) {
    setUrlLoading(true);
    await post(
      {
        url: "/ec/outResearchProject/getResearchFileInfo",
        data: {
          sessionId: localStorage.sessionId,
          flag: 0,
          itemSid: itemSid,
        },
      },
      (data) => {
        // console.log(data.code);
        if (data.code == "E02") {
          setFileTable([]);
          setUrlLoading(false);
        } else {
          setUrlLoading(false);
          setFileTable(data.contents);
        }
      },
      props.history
    );
  }
  async function getPictureData(itemSid, fileSid) {
    await post(
      {
        url: "/ec/outResearchProject/downloadResearchFile",
        data: {
          sessionId: localStorage.sessionId,
          flag: 0,
          itemSid: itemSid,
          fileSid: fileSid,
        },
      },
      (data) => {
        // setPictureContent(data.contents);
        console.log(data)
        if ((data.code = "N01")) {
          let url = data.contents;
          let a = document.createElement("a");
          let event = new MouseEvent("click");
          // a.download = "file";
          a.target = "_blank";
          a.href = url;
          a.dispatchEvent(event);
        }
      },
      props.history
    );
  }
  function handleClickRefuseBtn(type, data) {
    refuseForm.resetFields();
    setRefuseDialog({
      visible: true,
    });
    setAddInfo({ ...data });
  }
  function handleCancelRefuse() {
    setRefuseDialog({
      visible: false,
    });
  }
  async function handleStatusChange(type, data) {
    // console.log("data", data);
    switch (type) {
      case "待审核":
        data.itemStatus = 1;
        break;
      case "已同意":
        data.itemStatus = 2;
        break;
      case "已拒绝":
        data.itemStatus = 3;
        setRefuseDialog({
          visible: false,
        });
        break;
    }
    await post(
      {
        url: "/ec/project/updateProjectWriteInfoByItemSid",
        data: {
          sessionId: localStorage.sessionId,
          itemSid: data.itemSid,
          itemStatus: data.itemStatus,
          roleSid: parseInt(localStorage.roleSid),
          isHandover: 0,
          note: data.note,
        },
      },
      () => {
        notification.success({
          description: "修改审核状态成功！",
          message: "通知",
        });
        getTableData(filterInfo);
      },
      props.history
    );
  }
  function handleFilter() {
    // setFilterInfo({...filterInfo});
    // setFilterInfo({ ...filterInfo, index:'1'});
    setFilterInfo({ ...filterInfo, index: 1, pageSize: 10 });
    getTableData(filterInfo);
  }
  function handleClear() {
    filterForm.resetFields();
    const initState = {
      itemType: 5,
      roleSid: parseInt(localStorage.roleSid),
      pageSize: 10,
      index: 1,
      itemStatus: "",
      managerName: "",
      startTimestamp: 1,
      endTimestamp: parseInt(new Date().getTime() / 1000),
      participants: "",
      itemName: "",
    };
    setFilterInfo({
      ...initState,
    });
    getTableData(initState);
  }
  // function handleRefuseInputChange(event) {
  //   const value = event.target.value;
  //   const name = event.target.name;
  //   setAddInfo({ ...addInfo, [name]: value });
  // }
  useEffect(() => {
    getTableData(filterInfo);
  }, [filterInfo.pageSize, filterInfo.index]);
  useEffect(() => {
    refuseForm.setFieldsValue({
      note: addInfo.note,
    });
  }, [refuseDialog]);
  const table = useMemo(() => {
    rawTable.forEach((item) => {
      switch (item.itemStatus) {
        case 1:
          item.itemStatus = "待审核";
          break;
        case 2:
          item.itemStatus = "已同意";
          break;
        case 3:
          item.itemStatus = "已拒绝";
          break;
        default:
          item.itemStatus = "暂无";
      }
      item.insertTimestamp = !item.insertTimestamp
        ? "暂无"
        : new Date(item.insertTimestamp * 1000).toLocaleDateString();
    });
    return rawTable;
  }, [rawTable]);
  function handleClickFile(data) {
    setFileDialog({
      visible: true,
    });
    // console.log(data.itemSid);
    setItemSid(data.itemSid);
    getFileData(data.itemSid);
    setFileContent(data);
  }
  function handleClickPicture(data) {
    // setPictureDialog({
    //   visible: true,
    // });
    // setPictureContent(data)
    getPictureData(data.itemSid, data.fileSid);
  }
  function handleCancel() {
    setFileDialog({
      visible: false,
    });
  }
  function handlePictureCancel() {
    setPictureDialog({
      visible: false,
    });
  }
  // 删除专利
  async function handleDeleteItem(data) {
    await post({
      url: '/ec/project/deleteProjectWriteInfo',
      data: {
        sessionId: localStorage.sessionId,
        itemSid: data.itemSid,
        roleSid: localStorage.roleSid,
      }
    }, () => {
      notification.success({ description: '删除成功!', message: '通知' });
      getTableData(filterInfo);
    }, props.history)
  }

  // 新增专利
  const [studentInfo, setStudentInfo] = useState([])
  const [rowDataList, setRowDataList] = useState([
    //table数据
  ])
  //修改或新增或关闭页面
  const [modleState, setMole] = useState(0)

  function handleClickAddBtn() {
    // handleClearAddInfo()
    setRowDataList([])
    setMole(1)
  }

  //修改
  const upDateInfo = () => {
    setMole(2)
  }

  const closeAddForm = () => {
    setMole(0)
  }
  const setFormLoading = (a) => {
    setLoading(a);
  }
  const upDateTable = () => {
    getTableData(filterInfo);
  }

  //修改页面数据赋值
  const ediDataInit = (value) => {
    console.log(value)
    setMole(2)
    setRowDataList(value)
  }

  //拿学生数据
  const getStudentList = async () => {
    setLoading(true)
    await post({
      url: '/ec/project/getAllStudentInfoOfInBound',
      data: {
        sessionId: localStorage.sessionId,
      }
    }, data => {
      setLoading(false);
      let content = data.contents
      let stuInfo = [];
      for (let i = 0; i < content.length; i++) {
        if (content[i].studentSid !== parseInt(localStorage.studentSid)) {
          stuInfo.push(content[i])
        }
      }
      setStudentInfo(stuInfo)
    }, props.history)
  }
  useEffect(() => {
    getStudentList()
  }, [])

  // 上传图片
  const [selectItemId, setSelectItemId] = useState(1);
  const [uploadDialog, setUploadDialog] = useState({ visible: false });
  const [fileInfo, setFileInfo] = useState([]);
  const [detailTable, setDetailTable] = useState([]);
  const [uploadFileList, setuploadFileList] = useState([]);
  const [templateDialog, setTemplateDialog] = useState({ visible: false });
  const [previewInfo, setPreviewInfo] = useState({
    priviewVisible: false,
    priviewImage: '',
  });
  useEffect(() => {
    getFileList();
  }, []);
  function clickUploadFile(data) {
    setSelectItemId(data.itemSid)
    setuploadFileList([])
    setUploadDialog({
      visible: true,
    });
  }
  function handleCancelUpload() {
    setUploadDialog({
      visible: false,
    });
  }
  async function handleConfirmUpload() {
    deletePreview();
    let list = [...uploadFileList];
    if (list != []) {
      list.forEach((item) => {
        console.log(item);
        upLoad(item);
      });
    }
  }
  function deletePreview() {
    setPreviewInfo({
      priviewVisible: false,
      priviewImage: '',
    });
  }
  const uploadprops = {
    // listType: "picture",
    name: "file",
    action: "/ec/project/upLoadFile",
    headers: {
      authorization: "authorization-text",
    },
    onPreview: (file) => {
      setPreviewInfo({
        priviewImage: file.url,
        priviewVisible: true,
      });
    },
    beforeUpload: stopUpload,
    fileList: uploadFileList,
    onChange(info) {
      // console.log("info", info);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      setuploadFileList(info.fileList);
    },
  };
  async function upLoad(item) {
    let data = {
      sessionId: localStorage.sessionId,
      itemSid: selectItemId,
      flag: 0,
      file: item,
      updateTime: parseInt(new Date().getTime() / 1000),
    };
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    await post(
      {
        url: "/ec/outResearchProject/upLoadResearchFile",
        data: formData,
      },
      () => {
        notification.success({ description: "通知", message: "上传文件成功!" });
        getFileList();
        handleCancelUpload();
      },
      props.history
    );
  }

  function stopUpload(file, filelist) {
    let oldList = [...uploadFileList];
    oldList.push(file);
    setuploadFileList(oldList);
    return new Promise((resolve, reject) => {
      return reject(false);
    });
  }

  async function getFileList() {
    setLoading(true);
    await post(
      {
        url: "/ec/project/getFileInfo",
        data: {
          sessionId: localStorage.sessionId,
          flag: 0,
          itemSid: 1,
        },
      },
      (data) => {
        setLoading(false);
        setFileInfo(data.contents);
        if (data.code == "N01") setDetailTable(data.contents);
        else setDetailTable(data.contents.list);
      },
      props.history
    );
  }

  async function deleteFile(record) {
    await post(
      {
        url: "/ec/outResearchProject/deleteResearchFile",
        data: {
          sessionId: localStorage.sessionId,
          fileSid: record.fileSid,
          itemSid: record.itemSid,
          flag: 0,
        },
      },
      () => {
        notification.success({ description: "通知", message: "删除文件成功!" });
        // getFileList();
        // setItemSid(data.itemSid);
        getFileData(record.itemSid);
        setFileContent(record);
      },
      props.history
    );
  }

  function handleCancel() {
    setTemplateDialog({
      visible: false,
    });
  }

  function handleCancel() {
    setFileDialog({
      visible: false,
    });
  }

  return (
    <>
      <CustomCard options={headerCardOptions}>
        <Form
          layout="inline"
          style={{ flexDirection: "column" }}
          form={filterForm}
        >
          <Row>
            <Col span={6} offset={1}>
              <Form.Item label="管理员审核状态" name="itemStatus">
                <Select
                  style={{ width: "80%" }}
                  value={filterInfo.itemStatus}
                  placeholder="--请选择--"
                  name="itemStatus"
                  onChange={handleSelectChange.bind(null, "itemStatus")}
                  allowClear
                >
                  <Option value="1" key="1">
                    待审核
                  </Option>
                  <Option value="2" key="2">
                    已同意
                  </Option>
                  <Option value="3" key="3">
                    已拒绝
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" onClick={handleFilter}>
                筛选
              </Button>
            </Col>
            <Col offset={1}>
              <Button type="plain" htmlType="submit" onClick={handleClear}>
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomCard>
      <CustomCard options={tableCardOptions}>
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
            <Button type="primary" htmlType="submit" onClick={handleClickAddBtn}>新增专利</Button>
          </div>
          <Table
            className="table"
            rowKey="itemNumber"
            columns={columns}
            dataSource={table}
            loading={loading}
            pagination={{
              total: total,
              current: filterInfo.index,
              pageSize: filterInfo.pageSize,
              pageSizeOptions: [10, 20, 30],
              onChange: handlePageChange,
              showSizeChanger: true,
            }}
          />
        </div>
      </CustomCard>
      <Modal
        title="上传文件"
        visible={uploadDialog.visible}
        onCancel={handleCancelUpload}
        footer={[
          <div>
            <Button type="primary" onClick={handleConfirmUpload}>
              确认上传
            </Button>
            <Button onClick={handleCancelUpload}>关闭</Button>
          </div>,
        ]}
      >
        <div className="clearfix">
          {/* <Upload {...uploadprops}>
            <Button icon={<UploadOutlined />}>选择文件</Button>
          </Upload> */}
          <Upload {...uploadprops}>
            <Button type="primary">选择文件</Button>
          </Upload>
          <Modal visible={previewInfo.priviewVisible} footer={null} onCancel={handleCancelUpload}>
            <img alt="example" src={previewInfo.priviewImage} />
          </Modal>
        </div>
      </Modal>
      <Modal
        title="文件列表"
        visible={fileDialog.visible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            关闭
          </Button>,
        ]}
      >
        <Table
          className="pictureTable"
          rowKey="fileSid"
          columns={fileColumns}
          dataSource={fileTable}
          loading={urlLoading}
          pagination={false}
        ></Table>
      </Modal>
      <Modal
        title="查看文件"
        className="pictureList"
        visible={pictureDialog.visible}
        onCancel={handlePictureCancel}
        footer={[
          <Button key="back" onClick={handlePictureCancel}>
            关闭
          </Button>,
        ]}
      >
        <div style={{ width: "100%", margin: "0 auto", textAlign: "center" }}>
          <Image
            width={200}
            height={200}
            src={pictureContent}
          />
        </div>
      </Modal>
      <Modal
        title={modleState == 1 ? "新建专利" : "修改专利"}
        visible={modleState !== 0}
        onOk={upDateInfo}
        onCancel={() => { setMole(0) }}
        destroyOnClose={true}
        footer={[
        ]}
      >
        <AddProject allStuList={studentInfo} rowstuInfo={rowDataList} closeAddForm={closeAddForm} setLoading={setFormLoading} upDateTable={upDateTable} modelState={modleState} />
      </Modal>
    </>
  );
}
