/*
 * @Author: niuyue 
 * @Date: 2021-09-29 19:46:34 
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-06-10 19:20:16
 */
import React, { useState,useMemo,useEffect } from "react";
import { post, request } from "../../../network/request";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";
import CustomCard from "../../../components/common/card/card";
import {
    Tag,
    Row,
    Col,
    Form,
    Input,
    Button,
    Select,
    Table,
    Space,
    notification,
    Modal,
    Upload,
    DatePicker,
    Popconfirm,
    message,
    Image,
  } from "antd";
const { Option } = Select;
const { RangePicker } = DatePicker;
// const [urlLoading, setUrlLoading] = useState(null);
export default function VerticalTechnology(props){
const [selectItemId, setSelectItemId] = useState(1);
//以装载图片
const [uploadFileList, setuploadFileList] = useState([]);
//上传图片Dialog
const [uploadDialog, setUploadDialog] = useState({ visible: false });
//查看固定图片
const [pictureDialog, setPictureDialog] = useState({ visible: false });
//控制查看图片
const [fileDialog, setFileDialog] = useState({ visible: false });
const [loading, setLoading] = useState(null);
const [previewInfo, setPreviewInfo] = useState({
  priviewVisible: false,
  priviewImage: '',
});
const [fileContent, setFileContent] = useState({});
//要查看的图片论文ID
const [itemSid, setItemSid] = useState("");
const [filterForm] = Form.useForm();
const [pictureContent, setPictureContent] = useState({});
const [addForm] = Form.useForm();
const [stuInfo, setStuInfo] = useState([]);
const [selectPartInfo, setSelectPartInfo] = useState([]);
const [title, setTitle] = useState({ type: 0 });
const [isModalVisible1, setIsModalVisible1] = useState({visible:false});
const headerCardOptions = { title: null, bordered: false };
const [selectStuInfo, setSelectStuInfo] = useState([]);
const [validateState, setValidateState] = useState({
  managerName: "success",
  managerNamehelp: undefined,
  time: "success",
  timehelp: undefined,
});
const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll", height: "calc(100vh - 78px - 98px)" },
  };
//date
const dateFormat = "YYYY/MM/DD";
//图片table数据
const [fileTable, setFileTable] = useState([]);
//分页器初始状态设置
const [total, setTotal] = useState(0);
//定义主表格数据
const [MainTable, setMainTable] = useState([]);
const [filterInfo, setFilterInfo] = useState({
    roleSid: 3,
    pageSize: 10,
    index: 1,
    itemStatus: "",
    managerName: "",
    startTimestamp: "",
    endTimestamp: "",
    participants: "",
    itemName: "",
  });
//
const [addInfo, setAddInfo] = useState({
    itemType: 6,
    roleSid: 3,
    itemName: "",
    chargeTeacher: "",
    itemNumber: "",
    managerSid: localStorage.studentSid,
    managerName:localStorage.studentName,
    itemStatus: "",
    insertTimestamp:"",
    endTime:"",
    selectParticipantSid: 0,
    participantSid:[],
    statusDescription: "",
    itemSid:'',
    partSid:"",
  });
//表格标题
const columns = [
    {
      title: '技术编号',
      dataIndex: 'itemNumber',
      key: 'itemNumber',
      align: "center",
    },
    {
      title: '技术名称',
      dataIndex: 'itemName',
      key: 'itemName',
      align: "center",
    },
    {
      title: "主持者",
      dataIndex: "managerName",
      key: "managerName",
      align: "center",
    },
    // {
    //   title: "参与者",
    //   dataIndex: "participants",
    //   key: "participants",
    //   align: "center",
    // },
    {
      title: "申请日期",
      dataIndex: "insertTimestamp",
      key: "insertTimestamp",
      align: "center",
    },
    // {
    //     title: "负责老师",
    //     dataIndex: "chargeTeacher",
    //     key: "chargeTeacher",
    //     align: "center",
    // },
    {
      title: '管理员审核状态',
      key: 'itemStatus',
      align: "center",
      render: (text) => {
        if (text.itemStatus == "已通过") {
          return <span className="ec-text-color-green">{text.itemStatus}</span>;
        } else if (text.itemStatus == "已拒绝") {
          return <span className="ec-text-color-red">{text.itemStatus}</span>;
        } else if (text.itemStatus == "待审核") {
          return <span className="ec-text-color-orange">{text.itemStatus}</span>;
        }
      },
    },
    {
        title: "处理理由",
        dataIndex: "note",
        key: "note",
        align: "center",
      },
      {
        title: "操作",
        key: "operate",
        align: "center",
        render: (text, record) => (
          <>
            <Button
              type="primary"
              size="small"
              className="ec-button-lightBlue"
              style={{marginLeft:"3px",marginRight:"3px"}}
              onClick={handleUploadFile.bind(null, text)}
            >
              上传文件
            </Button>
            <Button
              type="primary"
              size="small"
              className="ec-button-lightBlue"
              style={{marginLeft:"3px",marginRight:"3px"}}
              onClick={handleClickFile.bind(null, text)}
            >
              查看文件
            </Button>
            <Button
              type="primary"
              size="small"
              className="ec-button-green"
              onClick={handleClickEditBtn.bind(null, text)}
              style={{ marginLeft:"3px",marginRight:"3px" }}
            >
              修改信息
            </Button>
            <Popconfirm
              className="delete"
              placement="bottomRight"
              title="确认删除吗？"
              onConfirm={handleDeleteItem.bind(null, text)}
              okText="确定"
              cancelText="取消"
            >
            <Button
                className="operate-button"
                type="danger"
                size="small"
            >
                删除
            </Button>
            </Popconfirm>
          </>
        ),
      },
  ];
  function stopUpload(file, filelist) {
    let oldList = [...uploadFileList];
    oldList.push(file);
    setuploadFileList(oldList);
    return new Promise((resolve, reject) => {
        return reject(false);
    });
}
  function handleClickEditBtn(text) {
  //  handleClearAddInfo();
  console.log(text)
  // text.insertTimestamp= parseInt(moment(text.insertTimestamp, dateFormat).valueOf() / 1000);
  var time= parseInt(moment(text.insertTimestamp, dateFormat).valueOf() / 1000);
  console.log(text.insertTimestamp)
  setTitle({
      type: 1,
    });
  setAddInfo({...addInfo, itemName:text.itemName,itemNumber:text.itemNumber,endTime:time,itemSid:text.itemSid});
  console.log(addInfo.participantSid);
  setIsModalVisible1({
    visible: true,
  });
  }
  const uploadprops = {
    // listType: "picture",
    name: "file",
    action: "/ec/project/upLoadFile",
    headers: {
        authorization: "authorization-text",
    },
    onPreview: (file) => {
        setPreviewInfo({
            priviewImage: file.url,
            priviewVisible: true,
        });
    },
    beforeUpload: stopUpload,
    fileList: uploadFileList,
    onChange(info) {
        // console.log("info", info);
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
        setuploadFileList(info.fileList);
    },
};
  //开启上传图片
  function handleUploadFile(data) {
    console.log(data)
    setSelectItemId(data.itemSid)//拿到这一行项目的id，知道是给谁上传图片
    setuploadFileList([])
    setUploadDialog({
        visible: true,
    });
}
  //新增Form
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  const validateMessages = {
    required: "'${label}' 是必选字段",
    // ...
  };
  async function updateProjectInfo(brray) {
    setLoading(true);
    await post(
      {
        url: "/ec/project/updateProjectWriteInfoByItemSid",
        data:{
          sessionId: localStorage.sessionId,
          roleSid:3,
          itemSid:addInfo.itemSid,
          itemName:addInfo.itemName,
          managerSid:localStorage.studentSid,
          chargeTeacher:"",
          insertTimestamp:addInfo.insertTimestamp,
          endTime:addInfo.insertTimestamp,
          participantSid:brray,
          statusDescription:"",
          itemNumber:addInfo.itemNumber
        },
      },
      (data) => {
       setLoading(false);
       addForm.resetFields();
        setIsModalVisible1({
          visible: false,
        });
        notification.success({ description: "修改技术框架成功！", message: "通知" });
        getTableData(filterInfo);
      },
      // props.history
    );
  }
  function handleClickEditItem() {
  let flag = handleBeforeAjax();
  if (flag) {
    let formdata = { ...addInfo };
    console.log(formdata.participantSid)
    var array=[]
    for(var i=0;i<formdata.participantSid.length;i++){
      var a=formdata.participantSid[i].selectParticipantSid
      array.push(a)
    }
    
    var brray = array.join(",");
    console.log(brray)
    formdata.sessionId = localStorage.sessionId;
    // setAddInfo({ ...addInfo, partSid: formdata.participantSid });
    updateProjectInfo(brray);
  } else {
    notification.error({
      description: "表单信息尚未完善，无法提交！",
      message: "警告",
    });
  }
  }
  //取消上传图片
  function handleCancelUpload() {
    setUploadDialog({
        visible: false,
    });
}
//确定上传图片
async function handleConfirmUpload() {
  deletePreview();
  let list = [...uploadFileList];
  if (list != []) {
      list.forEach((item) => {
          console.log(item);
          upLoad(item);
      });
  } else {

  }
}
//获取图片info
async function getFileData(itemSid) {
  // setUrlLoading(true);
  await post(
      {
          url: "/ec/outResearchProject/getResearchFileInfo",
          data: {
              sessionId: localStorage.sessionId,
              flag: 0,
              itemSid: itemSid,
          },
      },
      (data) => {
          // console.log(data.code);
          if (data.code == "E02") {
              setFileTable([]);
              // setUrlLoading(false);
          } else {
              // setUrlLoading(false);
              setFileTable(data.contents);
          }
      },
      props.history
  );
}
//上传图片
async function upLoad(item) {
  let data = {
      sessionId: localStorage.sessionId,
      itemSid: selectItemId,
      flag: 0,
      file: item,
      updateTime: parseInt(new Date().getTime() / 1000),
  };
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
  });
  await post(
      {
          url: "/ec/outResearchProject/upLoadResearchFile",
          data: formData,
      },
      () => {
          notification.success({ description: "通知", message: "上传文件成功!" });
          // getFileList();
          handleCancelUpload();
      },
      props.history
  );
}
function deletePreview() {
  setPreviewInfo({
      priviewVisible: false,
      priviewImage: '',
  });
}
//获取固定图片
async function getPictureData(itemSid, fileSid) {
  await post(
      {
          url: "/ec/outResearchProject/downloadResearchFile",
          data: {
              sessionId: localStorage.sessionId,
              flag: 0,
              itemSid: itemSid,
              fileSid: fileSid,
          },
      },
      (data) => {
          // setPictureContent(data.contents);
          console.log(data)
        if ((data.code = "N01")) {
          let url = data.contents;
          let a = document.createElement("a");
          let event = new MouseEvent("click");
          // a.download = "file";
          a.target = "_blank";
          a.href = url;
          a.dispatchEvent(event);
        }
      },
      props.history
  );
}
//开启图片查看
function handleClickFile(data) {
  setFileDialog({
      visible: true,
  });
  // console.log(data.itemSid);
  setItemSid(data.itemSid);
  getFileData(data.itemSid);
  setFileContent(data);
}
  // 清空表单数据
  function handleClearAddInfo() {
    addForm.resetFields();
    const initAdd = {
      itemType: 6,
      roleSid: 3,
      itemName: "",
      itemNumber:"",
      chargeTeacher: "",
      managerSid: localStorage.studentSid,
      managerName:localStorage.studentName,
      itemStatus: "",
      insertTimestamp: parseInt(new Date().getTime() / 1000),
      endTime: parseInt(new Date().getTime() / 1000),
      selectParticipantSid: 0,
      participantSid: [],
      statusDescription: "",
    };
    const initValidate = {
      managerName: "success",
      managerNamehelp: undefined,
      time: "success",
      timehelp: undefined,
    };
    setAddInfo({ ...initAdd });
    setValidateState({ ...initValidate });
  }
  //删除固定图片
  async function deleteFile(record) {
    await post(
        {
            url: "/ec/outResearchProject/deleteResearchFile",
            data: {
                sessionId: localStorage.sessionId,
                fileSid: record.fileSid,
                itemSid: record.itemSid,
                flag: 0,
            },
        },
        () => {
            notification.success({ description: "通知", message: "删除图片成功!" });
            // getFileList();
            // setItemSid(data.itemSid);
            getFileData(record.itemSid);
            setFileContent(record);
        },
        props.history
    );
}
  //查看固定图片
  function handleClickPicture(data) {
    // setPictureDialog({
    //     visible: true,
    // });
    // setPictureContent(data)
    getPictureData(data.itemSid, data.fileSid);
}
  // 新增参与者
  function handleClickAddPartStudent() {
    // console.log(addInfo.selectParticipantSid)
    let list = [...addInfo.participantSid];
    console.log(list)
    if (addInfo.selectParticipantSid == addInfo.managerSid) {
      notification.error({
        description: "该学生是主持者！",
        message: "警告",
      });
    } else if (list.indexOf(addInfo.selectParticipantSid) == -1) {
      // 在小标签列表中新增数据
      let smalllist=[...addInfo.participantSid]
      smalllist.push({
        selectParticipantSid:addInfo.selectParticipantSid,
        selectname:addInfo.selectname});
      console.log(smalllist)
      setAddInfo({ ...addInfo, participantSid: smalllist });
    } else {
      notification.error({
        description: "该学生已经添加！",
        message: "警告",
      });
    }
    // handleGetPartStudentList()
  }
  //查看图片Table
  const fileColumns = [
    {
        title: "文件名称",
        dataIndex: "filename",
        align: "center",
    },
    {
        title: "操作",
        key: "operate",
        align: "center",
        render: (text, record) => (
            <>
        <Button
            type="primary"
            size="small"
            onClick={handleClickPicture.bind(null, text)}
        >
            获取文件
        </Button>

        <Button
            type="danger"
            size="small"
            style={{ marginLeft: '10px' }}
            onClick={deleteFile.bind(null, text)}
        >
            删除
        </Button>
            </>
        ),
    },
];
//多次循环处理表格当中的数据
const mainTable=useMemo(()=>{
       MainTable.forEach((item)=>{
       switch(item.itemStatus){
        case 1:
            item.itemStatus="待审核";
            break;
        case 2:
            item.itemStatus="已通过";
            break;
        case 3:
            item.itemStatus="已拒绝";
            break;    
        default:
            item.itemStatus = "暂无";
       } 
       //时间戳转换
       item.insertTimestamp = !item.insertTimestamp
       ? "暂无"
       : new Date(item.insertTimestamp * 1000).toLocaleDateString();

    })   
    return MainTable;  
},[MainTable])
function handleClickAddItem() {
  let flag = handleBeforeAjax();
  // console.log(addInfo.participantSid)
  if (flag) {
    let formdata = { ...addInfo };
    console.log(formdata.participantSid)
    var array=[]
    for(var i=0;i<formdata.participantSid.length;i++){
      var a=formdata.participantSid[i].selectParticipantSid
      array.push(a)
    }
    
    var brray = array.join(",");
    console.log(brray)
    formdata.sessionId = localStorage.sessionId;
    // setAddInfo({ ...addInfo, partSid: formdata.participantSid });
    addProjectInfo(brray);
  } else {
    notification.error({
      description: "表单信息尚未完善，无法提交！",
      message: "警告",
    });
  }
}
async function addProjectInfo(brray) {
  setLoading(true);
  await post(
    {
      url: "/ec/project/addProjectWriteInfo",
      data:{
        sessionId: localStorage.sessionId,
        itemType:6,
        roleSid:3,
        itemName:addInfo.itemName,
        chargeTeacher:"",
        insertTimestamp:addInfo.insertTimestamp,
        endTime:addInfo.insertTimestamp,
        managerSid:localStorage.studentSid,
        participantSid:brray,
        statusDescription:""
      },
    },
    (data) => {
    //  console.log(data)
      // setLoading(false);
      console.log(data)
      notification.success({ description: "新增项目成功！", message: "通知" });
      addForm.resetFields();
      getTableData(filterInfo);
      setIsModalVisible1({
        visible: false,
      });
      
    },
    // props.history
  );
}
function handleBeforeAjax() {
  // console.log(addInfo.managerSid);
  if (title.type != 0) {
    if (addInfo.itemNumber == "") return false;
  }
  if (
    addInfo.managerSid == 0 ||
    addInfo.insertTimestamp == "" ||
    addInfo.itemName == ""
  )
    return false;
  return true;
}
//useEffect初始化并监听表格数据的变化
useEffect(() => {
    getTableData(filterInfo);
    getStudentList();
  }, [filterInfo.index,filterInfo.pageSize]);
  useEffect(() => {
    
    if(isModalVisible1.visible==true){
      let time = moment(new Date(addInfo.endTime * 1000).toLocaleDateString(), dateFormat)
      addForm.setFieldsValue({
      itemName: addInfo.itemName,
      itemNumber: addInfo.itemNumber,
      insertTimestamp:time,
    });
    var time1= parseInt(moment(time, dateFormat).valueOf() / 1000);
    setAddInfo({...addInfo, insertTimestamp:time1});
    }

  }, [isModalVisible1.visible]);
function handleSelectPartChange(tag, value,options) {
    let stus = { ...addInfo };
    stus[tag] = options.key;
    stus["selectname"] = value;
    console.log(stus)
    // console.log(value)
    // console.log(options.key)
    setAddInfo(stus);
  }
function handleGetPartStudentList() {
   let list = [];
   addInfo.participantSid.forEach((item)=>{
   let student = {
      studentSid:item.selectParticipantSid,
      studentName:item.selectname
    }
    list.push(student)
    console.log(list)
    
  })
  
   return list.map((item, index) => (
      <Tag
        closable
        key={item.studentSid}
        onClose={handleClickDeletePartStudent.bind(null, index)}
        style={{ marginTop: "5px" }}
      >
        {item.studentName}{" "}
      </Tag>
    ));
  }
  function handleSelectGradeChange(value) {
    let res = [];
    stuInfo.forEach((item) => {
      if (item.entryGrade == value) {
        res.push(item);
      }
    });
    setSelectStuInfo(res);
    let data = { ...addInfo };
    delete data.managerName;
    data.managerSid = localStorage.studentSid;
    setAddInfo(data);
  }
function handleSelectPartGradeChange(value) {
    let res = [];
    stuInfo.forEach((item) => {
      if (item.entryGrade == value) {
        res.push(item);
      }
    });
    setSelectPartInfo(res);
  } 
  
//获取表格数据
async function getTableData(filterData) {
    setLoading(true);
    await post(
      {
        url: "/ec/project/getProjectInfoByWriteItemType",
        data: {
          sessionId: localStorage.sessionId,
          itemType:6,
          studentSid: parseInt(localStorage.studentSid),
          ...filterData,
        },
      },
      (data) => {
        // console.log(data.contents.list)
        setMainTable(data.contents.list);
        setTotal(data.contents.size);
        setLoading(false);
      },
      props.history
    );
  }
  function handleClickDeletePartStudent(index) {
    let data = { ...addInfo };
    data.participantSid.splice(index, 1);
    setAddInfo(data);
  }
//打开新增项目弹框
async function handleClickAddBtn(){
  handleClearAddInfo()
    setTitle({
      type: 0,
    });
    setIsModalVisible1({
      visible: true,
    });
    // console.log(stuInfo);
  }
 // 删除项目申请书
 async function handleDeleteItem(data){
  await post({
      url: '/ec/project/deleteProjectWriteInfo',
      data: {
          sessionId: localStorage.sessionId,
          itemSid: data.itemSid,
          roleSid:localStorage.roleSid,
      }
  }, () => {
      notification.success({ description: '删除成功!', message: '通知' });
      getTableData(filterInfo);
  }, props.history)
} 
// 获取全部学生接口
async function getStudentList() {
  setLoading(true);
  await post(
    {
      url: "/ec/project/getAllStudentInfoOfInBound",
      data: {
        sessionId: localStorage.sessionId,
      },
    },
    (data) => {
      setLoading(false);
      setStuInfo(data.contents);
    },
    props.history
  );
}
// 获取参与者学生列表
function handleChangePartStudentList() {
  // 给姓名列表赋值的
  return selectPartInfo.map((item) => (
    <Option value={item.studentName} key={item.studentSid}>
      {item.studentName}
    </Option>
  ));
}

//日期改变
function handleAddTimeChange(tag, date, dateString) {
    let value = "";
    if (date) {
        value = parseInt(moment(dateString, dateFormat).valueOf() / 1000);
      }
    // console.log('tag',tag);
    // console.log('value',value)
    setAddInfo({ ...addInfo, insertTimestamp: value });
    console.log(addInfo)
    // console.log('dateFormat',dateFormat)
    
  }
const handleCancel = () => {
    setIsModalVisible1(false);
};
// 监听输入变化
function handleAddInputChange(event) {
  const value = event.target.value;
  const name = event.target.name;
  setAddInfo({ ...addInfo, [name]: value });
}
function handleSelectChange(tag,value) {
    setFilterInfo({ ...filterInfo, [tag]: value });
}
function handlePageChange(current, pageSize) {
  console.log(current)
  console.log(filterInfo.index)
  setFilterInfo({...filterInfo, index:current,pageSize })
 }
function handleFilter() {
   getTableData(filterInfo);
}
function handleClear() {
    filterForm.resetFields();
    const initState = {
    roleSid: 3,
    pageSize: 10,
    index: 1,
    itemStatus: "",
    managerName: "",
    startTimestamp: "",
    endTimestamp: "",
    participants: "",
    itemName: "",
    };
    setFilterInfo({
      ...initState,
    });
    getTableData(initState);
  }
return (
<>
 <CustomCard options={headerCardOptions}>
    <Form
    layout="inline"
    style={{ flexDirection: "column" }}
    form={filterForm}
    >
    <Row>
            <Col span={6} offset={1}>
              <Form.Item label="管理员审核状态" name="itemStatus">
                <Select
                  style={{ width: "80%" }}
                  value={filterInfo.itemStatus}
                  name="itemStatus"
                  placeholder="--请选择--"
                  onChange={handleSelectChange.bind(null, "itemStatus")}
                  allowClear
                >
                  <Option value="1" key="1">
                    待审核
                  </Option>
                  <Option value="2" key="2">
                    已通过
                  </Option>
                  <Option value="3" key="3">
                    已拒绝
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" onClick={handleFilter}>
                筛选
              </Button>
            </Col>
            <Col offset={1}>
              <Button type="plain" htmlType="submit" onClick={handleClear}>
                清除
              </Button>
            </Col>
          </Row>
    </Form>  
 </CustomCard>
 <CustomCard options={tableCardOptions}>
    <div
        style={{
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 15,
        }}
        >
        <Button type="primary"
        onClick={handleClickAddBtn.bind(null,"0")}
        >
        新增技术框架
        </Button>
    </div>
    <div>
      <Table 
        rowKey="itemNumber" 
        columns={columns}
        dataSource={mainTable} 
        loading={loading}
        pagination={{
            total: total,
            current: filterInfo.index,
            pageSize: filterInfo.pageSize,
            pageSizeOptions: [10, 20, 30],
            onChange: handlePageChange,
            showSizeChanger: true,
        }}
      />
    </div>
    <Modal title={title.type==0?"新增技术框架(带*必填)":"修改技术框架(带*必填)"} 
    visible={isModalVisible1.visible} 
    footer={[
    <div>
      <Button key="back"
      onClick={handleClearAddInfo}
      >
        清空
      </Button>
      <Button
        key="submitAdd"
        type="primary"
        onClick={
          title.type == 0 ? handleClickAddItem : handleClickEditItem
        }
      >
        提交
      </Button>
    </div>,
      ]}
      onCancel={handleCancel}>
    <Form
    {...layout}
    name="control-ref"
    form={addForm}
    // validateTrigger="onBlur"
    // validateMessages={validateMessages}
    >
    <div>
    <Form.Item
      label="项目名称"
      name="itemName"
      rules={[{ required: true, message: '请输入项目名称!' }]}
      >
      <Input 
      placeholder="请输入项目名称"
      value={addInfo.itemName}
      name="itemName"
      onChange={handleAddInputChange}
      style={{width:"70%"}}
       />
    </Form.Item>
    <Form.Item
      name="itemNumber"
      label="项目编号"
      style={title.type == 0 ? { display: "none" } : null}
      rules={[
        {
          required: true,
          message: "请输入项目编号",
        },
      ]}
    >
      <Input
        placeholder="请输入项目编号"
        value={addInfo.itemNumber}
        onChange={handleAddInputChange}
        name="itemNumber"
        disabled
      />
    </Form.Item>      
    <Form.Item
      label="主持者"
      name="主持者"
      rules={[
        {
          required: true,
          message: "请输入主持者",
        },
      ]}
    >
      <Input 
      placeholder=""
      value={addInfo.managerName}
      defaultValue={addInfo.managerName}
      name="managerName"
      disabled
       />
    </Form.Item>
    {/* <Form.Item
      name="participantSid"
      label="参与者"
      value={addInfo.participantSid}
    >
      <Select
        placeholder="年级"
        style={{ width: "20%", marginRight: "5%" }}
        onChange={handleSelectPartGradeChange.bind(null)}
        allowClear
      >
        <Option value="2017" key="2017">
          2017
        </Option>
        <Option value="2018" key="2018">
          2018
        </Option>
        <Option value="2019" key="2019">
          2019
        </Option>
        <Option value="2020" key="2020">
          2020
        </Option>
      </Select>
      <Select
        placeholder="姓名"
        style={{ width: "50%", marginRight: "5%" }}
        onChange={handleSelectPartChange.bind(
          null,
          "selectParticipantSid",
          
         )}
        allowClear
      >
        {handleChangePartStudentList()}
      </Select>
      <Button
        type="primary"
        onClick={handleClickAddPartStudent.bind(null)}
      >
        添加
      </Button>
      <div className="tagsDiv">
      {handleGetPartStudentList()}
      </div>
    </Form.Item>       */}
    <Form.Item
      label="申请时间"
      name="insertTimestamp"
      rules={[{ required: true}]}
      
      >
     {/* <Space direction="vertical" size={12}> */}
     <DatePicker
      placeholder="申请时间"
      format={dateFormat}
      style={{ width: "100%" }}
      onChange={handleAddTimeChange.bind(null, "insertTimestamp")}
      // onBlur={handleMyValidate.bind(null, "insertTimestamp", addInfo)}
      name="insertTimestamp"
     />
    
     {/* </Space> */}
    
    </Form.Item>
    </div>
    </Form>
    </Modal>
     {/* 上传图片 */}
     <Modal
     title="上传文件"
    visible={uploadDialog.visible}
     onCancel={handleCancelUpload}
     footer={[
     <div>
       <Button type="primary" onClick={handleConfirmUpload}>
         确认上传
       </Button>
       <Button onClick={handleCancelUpload}>关闭</Button>
     </div>,
     ]}
    >
    <div className="clearfix">
    {/* <Upload {...uploadprops}>
      <Button icon={<UploadOutlined />}>选择图片</Button>
    </Upload> */}
    <Upload {...uploadprops}>
      <Button type="primary">选择文件</Button>
    </Upload>
    <Modal visible={previewInfo.priviewVisible} footer={null} onCancel={() => { setPreviewInfo({ priviewVisible: false }) }}>
      <img alt="example" src={previewInfo.priviewImage} />
    </Modal>
      </div>
    </Modal>   
     {/* 查看图片 */}
     <Modal
      title="文件列表"
      visible={fileDialog.visible}
       onCancel={() => {
        setFileDialog({
        visible: false,
         });
      }}
       footer={[
        <Button key="back" onClick={() => {
         setFileDialog({
           visible: false,
            });
          }}>
            关闭
       </Button>,
       ]}
        >
        <Table
       className="pictureTable"
        rowKey="fileSid"
        columns={fileColumns}
        dataSource={fileTable}
      //  loading={urlLoading}
        pagination={false}
      ></Table>
      </Modal>
      {/* 查看特定图片 */}
      <Modal
          title="查看文件"
          className="pictureList"
          visible={pictureDialog.visible}
          onCancel={() => {
              setPictureDialog({ visible: false })
          }}
          footer={[
              <Button key="back" onClick={() => {
                  setPictureDialog({ visible: false })
              }}>
                  关闭
              </Button>,
          ]}
      >
      <div style={{ width: "100%", margin: "0 auto", textAlign: "center" }}>
          <Image
            width={200}
            height={200}
            src={pictureContent}
          />
        </div>
  </Modal>
 </CustomCard>
</>
)
}