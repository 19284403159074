import React, { useState } from "react";
import {
  Table,
  Button,
  Modal,
  message,
  Card,
} from "antd";
import { post } from "../../../../network/request";
import './exchange.css'
export default function ExchangeDialog(props) {
  /******************useState****************** */
  // console.log(111)
  console.log(props.fatherChange)
  const [exchangePapare, setPapare] = useState([]);
  const [exchangeNet, setNet] = useState([])

  const [manPro, setManPro] = useState([])
  const [parPro, setParPro] = useState([])
  const [manCon, setManCon] = useState([])
  const [parCon, setParCon] = useState([])


  //组件参数
  const columns = [
    {
      title: "项目名称",
      dataIndex: "itemName",
      width: 100,
      align: "center",
    },
    {
      title: "主持者",
      dataIndex: "managerName",
      width: 80,
      align: "center",
    },
    {
      title: "参与者",
      dataIndex: "participants",
      width: 80,
      align: "center",
    },
    {
      title: "开始时间",
      dataIndex: "conditionName",
      width: 80,
      align: "center",
      render: (text, record) => (
        <div>
          {new Date(record.insertTimestamp * 1000).toLocaleDateString()}
        </div>
      ),
    },
    {
      title: "结束时间",
      dataIndex: "conditionName",
      width: 80,
      align: "center",
      render: (text, record) => (
        <div>{new Date(record.endTime * 1000).toLocaleDateString()}</div>
      ),
    },
  ];
  const exchangePro = [
    {
      title: "主持项目",
      dataIndex: "first",
      width: 80,
      align: "center",
    },
    {
      title: "参与项目",
      dataIndex: "second",
      width: 80,
      align: "center",
    },
    {
      title: "操作",
      width: 80,
      align: "center",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            danger
            size="small"
            onClick={() => concelExchange(record.changeSid)}
          >
            取消
          </Button>
        );
      },
    },
  ];
  const exchangeCon = [
    {
      title: "主持建设方案",
      dataIndex: "first",
      width: 80,
      align: "center",
    },
    {
      title: "参与建设方案",
      dataIndex: "second",
      width: 80,
      align: "center",
    },
    {
      title: "操作",
      width: 80,
      align: "center",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            danger
            size="small"
            onClick={() => concelExchange(record.changeSid)}
          >
            取消
          </Button>
        );
      },
    },
  ];
  // 主持项目勾选
  const rowSelManPro = {
    selectedRowKeys: manPro,
    getCheckboxProps: (record) => ({
      disabled: record.changeSid
    }),
    onChange(selectedRowKeys, selectedRows) {
      setManPro(selectedRowKeys);
    }
  }
  // 参与项目勾选
  const rowSelParPro = {
    selectedRowKeys: parPro,
    getCheckboxProps: (record) => ({
      disabled: record.changeSid, // 配置无法勾选的列
    }),
    onChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys);
      setParPro(selectedRowKeys)
    },
  };
  // 主持建设方案勾选
  const rowSelManCon = {
    selectedRowKeys: manCon,
    getCheckboxProps: (record) => ({
      disabled: record.changeSid
    }),
    onChange(selectedRowKeys, selectedRows) {
      setManCon(selectedRowKeys);
    }
  }
  // 参与建设方案勾选
  const rowSelParCon = {
    selectedRowKeys: parCon,
    getCheckboxProps: (record) => ({
      disabled: record.changeSid, // 配置无法勾选的列
    }),
    onChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys);
      setParCon(selectedRowKeys)
    },
  };

  // const rowSelectPapare = {
  //   selectedRowKeys: exchangePapare,
  //   getCheckboxProps: (record) => ({
  //     disabled: typeof record.changeSid != "undefined", // 配置无法勾选的列
  //   }),
  //   onChange(selectedRowKeys, selectedRows) {
  //     setPapare(selectedRowKeys);
  //   },
  // };
  // const rowSelectNet = {
  //   selectedRowKeys: exchangeNet,
  //   getCheckboxProps: (record) => ({
  //     disabled: typeof record.changeSid != "undefined", // 配置无法勾选的列
  //   }),
  //   onChange(selectedRowKeys, selectedRows) {
  //     console.log(selectedRowKeys);
  //     setNet(selectedRowKeys)
  //   },
  // };
  /************************接口******************** */
  const concelExchange = async (id) => {
    await post(
      {
        url: "/ec/station/cancelChangeProject",
        data: {
          sessionId: localStorage.sessionId,
          changeSid: id,
        },
      },
      (data) => {
        console.log(data)
        if (data.code === "N01") {
          props.updata();
        }
      },
      props.history
    );
  };
  const exchange = async (changeType, itemSid) => {
    console.log(changeType)
    console.log(itemSid)
    await post(
      {
        url: "/ec/station/addChangeProject",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: parseInt(localStorage.studentSid),
          itemSid,
          changeType,
          projectType: 1,
        },
      },
      (data) => {
        console.log(data)
        if (data.code === "N01") {
          if (changeType === 1)
            setPapare([])
          else
            setNet([])
          props.updata();
        }
      },
      props.history
    );
  };
  /******************function******************* */
  const handleExchange = (type) => {
    console.log(type)
    if (type === 1) {
      if (manPro.length === 0) {
        message.error("请选择有效的置换项目");
        return;
      }
      exchange(1, manPro.toString());
    } else if (type === 2) {
      if (parPro.length <= 1) {
        message.error("请选择有效的置换项目数");
        return;
      } else if (parPro.length % 2 !== 0) {
        message.error("置换项目数为2的倍数");
        return;
      }
      exchange(2, parPro.toString());
    } else if (type === 3) {
      if (manCon.length === 0) {
        message.error("请选择有效的置换项目");
        return;
      }
      exchange(1, manCon.toString());
    } else if (type === 4) {
      if (parCon.length <= 1) {
        message.error("请选择有效的置换方案数");
        return;
      } else if (parPro.length % 2 !== 0) {
        message.error("置换方案数为2的倍数");
        return;
      }
      exchange(2, parCon.toString());
    }
  };
  return (
    <Modal
      title="置换项目"
      visible={props.cRef}
      onCancel={props.concelForModal}
      wrapClassName={'myself'}
      footer={[
        <div key='1' className="btnCenter">
          <Button key="back" onClick={props.concelForModal}>
            关闭
          </Button>
        </div>
      ]}
    >
      {/* 主持项目 */}
      <div className="lookApplyBook">
        <Card title={props.mproject.title}>
          <Table
            rowKey="itemSid"
            columns={columns}
            dataSource={props.mproject.mproject}
            pagination={false}
            scroll={{ x: 100 }}
            rowSelection={{ ...rowSelManPro }}
          />
          <div className="btnCenter">
            <Button
              type="primary"
              onClick={() => {
                handleExchange(1);
              }}
            >
              置换
            </Button>
          </div>
        </Card>
      </div>
      {/* 参与项目 */}
      <div className="lookApplyBook">
        <Card title={props.pproject.title}>
          <Table
            rowKey="itemSid"
            columns={columns}
            dataSource={props.pproject.pproject}
            pagination={false}
            scroll={{ x: 100 }}
            rowSelection={{ ...rowSelParPro }}
          />
          <div className="btnCenter">
            <Button
              type="primary"
              onClick={() => {
                handleExchange(2)
              }}
            >
              置换
            </Button>
          </div>
        </Card>
      </div>
      {/* 主持建设方案 */}
      <div className="lookApplyBook">
        <Card title={props.mconstruct.title}>
          <Table
            rowKey="itemSid"
            columns={columns}
            dataSource={props.mconstruct.mconstruct}
            pagination={false}
            scroll={{ x: 100 }}
            rowSelection={{ ...rowSelManCon }}
          />
          <div className="btnCenter">
            <Button
              type="primary"
              onClick={() => {
                handleExchange(3);
              }}
            >
              置换
            </Button>
          </div>
        </Card>
      </div>
      {/* 参与建设方案 */}
      <div className="lookApplyBook">
        <Card title={props.pconstruct.title}>
          <Table
            rowKey="itemSid"
            columns={columns}
            dataSource={props.pconstruct.pconstruct}
            pagination={false}
            scroll={{ x: 100 }}
            rowSelection={{ ...rowSelParCon }}
          />
          <div className="btnCenter">
            <Button
              type="primary"
              onClick={() => {
                handleExchange(4)
              }}
            >
              置换
            </Button>
          </div>
        </Card>
      </div>
      {/* 已置换 */}
      <div className="lookApplyBook">
        <Card title="已置换项目">
          <Table
            rowKey="changeSid"
            columns={exchangePro}
            dataSource={props.exchangePro}
            pagination={false}
            scroll={{ x: 100 }}
          />
          {console.log(props.exchangePro)}
          <Table
            rowKey="changeSid"
            columns={exchangeCon}
            dataSource={props.exchangeCon}
            pagination={false}
            scroll={{ x: 100 }}
          />
        </Card>
      </div>
    </Modal>
  );
}
