/*
 * @Author: ZhouShan
 * @Date: 2020-11-05 11:04:29
 * @Last Modified by: 白鑫
 * @Last Modified time: 2020-11-30 09:03:17
 */
import React, {
  useState,
  useMemo,
  useImperativeHandle,
  useEffect,
} from "react";
import {
  DatePicker,
  Form,
  Input,
  Button,
  Select,
  notification,
  Modal,
} from "antd";
import { post } from "../../../../network/request";
import { useHistory } from 'react-router-dom'

const { Option } = Select;

export default function AddDialog(props) {
  const [addForm] = Form.useForm();
  const history = useHistory();

  const { cRef } = props;

  useImperativeHandle(
    cRef,
    () => ({
      addForm,
    }),
    []
  );

  useEffect(() => {
    console.log("add");
  });

  const years = useMemo(() => {
    const years = [];
    for (let start = new Date().getFullYear() + 2; start >= 2017; start--) {
      years.push(start);
    }
    return years;
  }, []);

  //点击提交
  async function submit() {
    console.log("valid");
    try {
      console.log(111);
      await addForm.validateFields();
    } catch {
      console.log("Form validate filed");
    }
  }

  //调用添加接口
  async function addInboundStudentInfo(value) {
    const addInfo = value;
    addInfo.studentBirthDate = addInfo.studentBirthDate
      ? parseInt(new Date(addInfo.studentBirthDate).getTime() / 1000)
      : null;
    await post(
      {
        url: "/ec/station/addInboundStudentInfo",
        data: {
          ...addInfo,
          mentor: "", //意向导师
          lastMentor: "",
          teacherCounselor: "",
          lastResearchDirection: "",
          studentPhone: "",
          studentEmail: "",
          acceptState: "",
          ecResearchDirectionSid: "",
          ecResearchDirectionName: "",
          ecResearchDirectionNo: 2,
          ecResearchDirectionSid2: "",
          ecResearchDirectionName2: "",
          ecResearchDirectionNo2: 1,
          insertTimestamp: parseInt(new Date().getTime() / 1000),
          senior: "",
          ecResearchDirectionNo3: 3,
          lastResearchDirectionSid: "",
          lastResearchDirection: "",
        },
      },
      () => {
        notification.success({
          description: "添加成功！",
          message: "通知",
          duration: 1,
          onClose: () => {
            props.getTableData();
            props.handleCancel();
          },
        });
      },
      history
    );
  }
  return (
    <>
      {/* 新增学生 */}
      <Modal
        title="新增学生"
        visible={props.addStudent.visible}
        onCancel={props.handleCancel}
        footer={null}
        forceRender
      >
        <Form
          form={addForm}
          name="addForm"
          labelCol={{ span: 5 }}
          onFinish={addInboundStudentInfo}
        >
          <Form.Item
            name="studentName"
            label="姓名"
            rules={[
              {
                required: true,
                message: "请输入姓名",
              },
            ]}
          >
            <Input placeholder="请输入姓名"></Input>
          </Form.Item>
          <Form.Item
            name="entryGrade"
            label="年级"
            rules={[
              {
                required: true,
                message: "请选择年级",
              },
            ]}
          >
            <Select placeholder="请选择年级">
              {years.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="studentSex"
            label="性别"
            rules={[
              {
                required: true,
                message: "请选择性别",
              },
            ]}
          >
            <Select placeholder="请选择性别">
              <Option value="男">男</Option>
              <Option value="女">女</Option>
              <Option value="跨性别">跨性别</Option>
            </Select>
          </Form.Item>
          <Form.Item name="studentBirthDate" label="出生年月">
            <DatePicker placeholder="请选择日期" />
          </Form.Item>
          <Form.Item name="natives" label="籍贯">
            <Input placeholder="请输入籍贯"></Input>
          </Form.Item>
          <Form.Item name="studentPhone" label="联系电话">
            <Input placeholder="请输入电话号码"></Input>
          </Form.Item>
          <Form.Item name="undergraduateSchool" label="本科院校">
            <Input placeholder="请输入本科院校"></Input>
          </Form.Item>
          <Form.Item name="undergraduateMajor" label="本科专业">
            <Input placeholder="请输入本科专业"></Input>
          </Form.Item>
          <Form.Item
            name="degreeType"
            label="学硕/专硕"
            rules={[
              {
                required: true,
                message: "请选择学硕/专硕",
              },
            ]}
          >
            <Select placeholder="请选择学硕/专硕">
              <Option value="1">学硕</Option>
              <Option value="2">专硕</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="graduate"
            label="应届/往届"
            rules={[
              {
                required: true,
                message: "请选择应届/往届",
              },
            ]}
          >
            <Select placeholder="请选择应届/往届">
              <Option value="1">应届</Option>
              <Option value="2">往届</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block onClick={submit}>
              添加
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
