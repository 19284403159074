/*
 * @Author: ZhouShan
 * @Date: 2020-11-05 11:04:10
 * @Last Modified by: HouQi
 * @Last Modified time: 2021-12-11 13:41:58
 */
import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  Popconfirm,
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Table,
  Space,
  notification,
  Modal,
} from "antd";
import { post, request } from "../../../network/request";
//import component
import CustomCard from "../../../components/common/card/card";
import AddDialog from "./addDialog/addDialog";
import SettingDialog from "./settingDialog/settingDialog";
//import css
import "./enterManagement.css";

const { Option } = Select;

export default function EnterManagement(props) {
  const headerCardOptions = { title: null, bordered: false };
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll", height: "calc(100vh - 78px - 130px)" },
  };
  const [filterForm] = Form.useForm();
  //table column
  const columns = [
    {
      title: "姓名",
      dataIndex: "studentName",
      key: "studentName",
      align: "center",
    },
    {
      title: "学硕/专硕",
      dataIndex: "degreeType",
      key: "degreeType",
      align: "center",
    },
    {
      title: "本科院校",
      dataIndex: "undergraduateSchool",
      key: "undergraduateSchool",
      align: "center",
    },
    {
      title: "应届/往届",
      dataIndex: "graduate",
      key: "graduate",
      align: "center",
    },
    {
      title: "联系邮箱",
      dataIndex: "studentEmail",
      key: "studentEmail",
      align: "center",
    },
    {
      title: "联系电话",
      dataIndex: "studentPhone",
      key: "studentPhone",
      align: "center",
    },
    {
      title: "导师",
      dataIndex: "lastMentor",
      key: "lastMentor",
      align: "center",
    },
    {
      title: "EC指导老师",
      dataIndex: "teacherCounselor",
      key: "teacherCounselor",
      align: "center",
    },
    {
      title: "传帮带",
      dataIndex: "senior",
      key: "senior",
      align: "center",
    },
    {
      title: "技术栈",
      dataIndex: "lastResearchDirection",
      key: "lastResearchDirection",
      align: "center",
    },
    {
      title: "状态",
      key: "inboundState",
      align: "center",
      render: (text) => {
        if (text.inboundState === "在站") {
          // console.log(text)
          // return <span style={{ color: "green" }}>{text.inboundState}</span>;
          return <span className="ec-text-color-green">{text.inboundState}</span>;
        } else if (text.inboundState === "退站") {
          return <span className="ec-text-color-red">{text.inboundState}</span>;
        } else if (text.inboundState === "Error") {
          return <span className="ec-text-color-red">{"未审核"}</span>;
        }
        else {
          return <span>{text.inboundState}</span>;
        }
      },
    },
    {
      title: "入站管理",
      key: "acceptState",
      align: "center",
      fixed: 'right',
      width: '120px',
      render: (text, record) => (
        <Space>
          <Popconfirm
            title="确定该生的入站考核通过?"
            okText="确定"
            cancelText="取消"
            disabled={text.inboundState !== "待考核"}
            onConfirm={handleStatusChange.bind(null, "accept", text)}
          >
            <Button
              type="primary"
              size="small"
              disabled={text.inboundState !== "待考核"}
            >
              入站考核通过
            </Button>
          </Popconfirm>
          <Popconfirm
            title="确定将该生退站?"
            okText="确定"
            cancelText="取消"
            disabled={
              text.inboundState === "退站" || text.inboundState === "出站"
            }
            onConfirm={handleStatusChange.bind(null, "reject", text)}
          >
            <Button
              type="primary"
              danger
              size="small"
              disabled={
                text.inboundState === "退站" || text.inboundState === "出站"
              }
            >
              退站
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "操作",
      key: "userName",
      align: "center",
      fixed: 'right',
      width: '180px',
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={handleClickDetail.bind(null, text)}
          >
            查看详情
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={handleClickSetting.bind(null, text)}
            disabled={text.inboundState === "退站"}
          >
            设置
          </Button>
        </Space>
      ),
    }, 
  ];
  const detailColumns = [
    {
      title: "title",
      dataIndex: "title",
      key: "title",
      align: "center",
    },
    {
      title: "content",
      dataIndex: "content",
      key: "content",
      align: "center",
    },
  ];
  /**************ref*************/
  const addRef = useRef();
  /*************state*************/
  const [mentorList, setmentorList] = useState([]);
  const [teacherCounselorList, setTeacherCounselorList] = useState([]);
  const [ecResearchDirection, setEcResearchDirection] = useState([]);
  const [filterInfo, setFilterInfo] = useState({
    inbountState: "",
    acceptState: "",
    senior: "",
    studentName: "",
    undergraduateSchool: "",
    graduate: "",
    degreeType: "",
    mentor: "",
    lastMentor: "",
    entryGrade: "",
    currIndex: 1,
    pageSize: 10,
  });
  const [rawTable, setRawTable] = useState([]);
  const [total, setTotal] = useState(0);
  const [detailDialog, setDetailDialog] = useState({ visible: false });
  const [setting, setSetting] = useState({ visible: false });
  const [addStudent, setAddStudent] = useState({ visible: false });
  const [detailContent, setDetailContent] = useState({});
  const [seniorNameList, setSeniorNameList] = useState([]);
  const [entryGrade, setEntryGrade] = useState([]);
  const [loading, setLoading] = useState(null);
  const [availableSenior, setAvailableSenior] = useState([]);
  /**************callback*************/
  //新增学生模态框关闭的回调函数记忆
  const cancelForAdd = useCallback((type) => {
    handleCancel("addStudent", type);
  }, []);
  /*************memo*************/
  const table = useMemo(() => {
    rawTable.forEach((item) => {
      item.degreeType = item.degreeType === 1 ? "学硕" : "专硕";
      item.graduate = item.graduate === 1 ? "应届" : "往届";
      switch (item.acceptState) {
        case 1:
          item.acceptState = "已接受";
          break;
        case 0:
          item.acceptState = "未处理";
          break;
        case 2:
          item.acceptState = "已拒绝";
          break;
        default:
          item.acceptState = "错误";
      }
      switch (item.inboundState) {
        case 0:
          item.inboundState = "待考核";
          break;
        case 1:
          item.inboundState = "在站";
          break;
        case 2:
          item.inboundState = "第一阶段出站";
          break;
        case 3:
          item.inboundState = "出站";
          break;
        case 4:
          item.inboundState = "退站";
          break;
        default:
          item.inboundState = "待考核";
      }
      item.updateTimestamp = !item.updateTimestamp
        ? "暂无"
        : new Date(item.updateTimestamp * 1000).toLocaleDateString();
    });
    return rawTable;
  }, [rawTable]);
  const detailTable = useMemo(() => {
    if (detailContent == {}) return;
    const table = [];
    let scores = "";
    const detail = detailContent;
    for (let item in detail) {
      switch (item) {
        case "studentName":
          table.push({ title: "姓名", content: detail[item] });
          break;
        case "studentSex":
          table.push({ title: "性别", content: detail[item] });
          break;
        case "studentBirthDate":
          table.push({
            title: "出生年月",
            content: new Date(detail[item] * 1000).toLocaleDateString(),
          });
          break;
        case "natives":
          table.push({ title: "籍贯", content: detail[item] });
          break;
        case "studentEmail":
          table.push({ title: "常用邮箱", content: detail[item] });
          break;
        case "studentPhone":
          table.push({ title: "联系电话", content: detail[item] });
          break;
        case "undergraduateSchool":
          table.push({ title: "本科院校", content: detail[item] });
          break;
        case "undergraduateMajor":
          table.push({ title: "本科专业", content: detail[item] });
          break;
        case "firstChoice":
          table.push({ title: "第一志愿院校", content: detail[item] });
          break;
        case "direction":
          table.push({ title: "报考专业", content: detail[item] });
          break;
        case "degreeType":
          table.push({ title: "学硕/专硕", content: detail[item] });
          break;
        case "graduate":
          table.push({ title: "应届/往届", content: detail[item] });
          break;
        case "preliminaryGrade":
          table.push({ title: "初始总分", content: detail[item] });
          break;
        case "math":
          scores += detail[item] + "(数学)";
          break;
        case "english":
          scores += detail[item] + "(英语)";
          break;
        case "politics":
          scores += detail[item] + "(政治)";
          break;
        case "professionalCourse":
          scores += detail[item] + "(专业课)";
          break;
        case "ecResearchDirectionName2":
          table.push({ title: "意向应用方向", content: detail[item] });
          break;
        case "ecResearchDirectionName":
          table.push({ title: "意向科研方向", content: detail[item] });
          break;
        default:
          break;
      }
    }
    table.push({ title: "初始各科成绩", content: scores });
    return table;
  }, [detailContent]);
  //addDialog组件记忆
  const addDialogMemo = useMemo(() => {
    return (
      <AddDialog
        cRef={addRef}
        addStudent={addStudent}
        handleCancel={cancelForAdd}
        getTableData={getTableDataWithoutParams}
      />
    );
  }, [addRef, addStudent, cancelForAdd]);
  //settingDialog组件记忆
  const settingDialogMemo = useMemo(() => {
    return <SettingDialog
    detailContent={detailContent}
    availableSenior={availableSenior}
    ecResearchDirection={ecResearchDirection}
    getTableData={getTableDataWithoutParams}
    setting={setting}
    setSetting={setSetting}
    teacherCounselorList={teacherCounselorList}
    handleCancel={handleCancel}
    mentorList={mentorList}
  />
  }, [setting, setSetting])
  /***********lifecycle************/
  useEffect(() => {
    //获得传帮带信息
    async function getSeniorNameListByTStudent() {
      await post(
        {
          url: "/ec/student/getSeniorNameListByTStudent",
          data: {
            sessionId: localStorage.sessionId,
          },
        },
        (data) => {
          if (!data?.contents || data.contents.length <= 0) {
            notification.error({
              description: "传帮带信息初始化失败!",
              message: "警告",
            });
            return;
          }
          setSeniorNameList(data.contents);
        },
        props.history
      );
    }
    //获得EC指导老师信息
    async function getTeacherCounselorOrMentorName() {
      await post(
        {
          url: "/ec/station/getTeacherCounselorOrMentorName",
          data: {
            sessionId: localStorage.sessionId,
            roleSid: "2",
          },
        },
        (data) => {
          setTeacherCounselorList(data.contents.list);
        },
        props.history
      );
    }
    //获得导师信息
    async function getMentorList() {
      await post(
        {
          url: "/ec/student/getMentorNameList",
          data: {
            sessionId: localStorage.sessionId,
          },
        },
        (data) => {
          setmentorList(data.contents);
        },
        props.history
      );
    }
    //初始化筛选年份
    async function genEntryGrade() {
      await post(
        {
          url: "/ec/project/getOutStationGrade",
          data: {
            sessionId: localStorage.sessionId,
            inboundState: 2
          },
        },
        (data) => {
          if (data.code == 'N01') {
            let allGrade = data.contents.allGrade
            console.log(allGrade)
            for(let i = 0;i < allGrade.length;i++){
              entryGrade.push(parseInt(allGrade[i]))
            }
          }else {
            console.log('error')
          }
        },
        props.history
      );
        // console.log(entryGrade)
      setEntryGrade(entryGrade);
    }
    //获得所有可指定学姐学长信息
    async function getAllStudentInfoOfInBound() {
      await post(
        {
          url: "/ec/project/getAllStudentInfoOfInBound",
          data: {
            sessionId: localStorage.sessionId,
          },
        },
        (data) => {
          setAvailableSenior(data.contents);
        },
        props.history
      );
    }
    //获得实验室所有横向方向
    async function getEcResearchDirectionByNo() {
      await post(
        {
          url: "/ec/student/getEcResearchDirectionByNo",
          data: {
            ecResearchDirectionNo: 2,
          },
        },
        (data) => {
          setEcResearchDirection(data.contents.list);
        },
        props.history
      );
    }
    //调用初始函数
    getMentorList()
      .then(() => {
        return getSeniorNameListByTStudent();
      })
      .then(() => {
        return getAllStudentInfoOfInBound();
      })
      .then(() => {
        return getTeacherCounselorOrMentorName();
      })
      .then(() => {
        return getEcResearchDirectionByNo();
      });
    genEntryGrade();
  }, []);
  useEffect(() => {
    getTableData(filterInfo);
  }, [filterInfo.pageSize, filterInfo.currIndex]);
  /************method*************/
  async function getTableData(filterData) {
    setLoading(true);
    await post(
      {
        url: "/ec/student/getStudentAndGradeByMultipleConditions",
        data: {
          sessionId: localStorage.sessionId,
          ...filterData,
        },
      },
      (data) => {
        setLoading(false);
        setRawTable(data.contents.list);
        setTotal(data.contents.size);
      },
      props.history
    );
  }
  //无入参表格筛选
  async function getTableDataWithoutParams() {
    setLoading(true);
    await post(
      {
        url: "/ec/student/getStudentAndGradeByMultipleConditions",
        data: {
          sessionId: localStorage.sessionId,
          ...filterInfo,
        },
      },
      (data) => {
        setLoading(false);
        setRawTable(data.contents.list);
        setTotal(data.contents.size);
      },
      props.history
    );
  }
  //筛选
  function handleFilter() {
    setFilterInfo({...filterInfo, currIndex: 1, pageSize: 10});
    getTableData(filterInfo);
  }
  //清除筛选
  function handleClear() {
    filterForm.resetFields();
    const initState = {
      inbountState: "",
      acceptState: "",
      senior: "",
      studentName: "",
      undergraduateSchool: "",
      graduate: "",
      degreeType: "",
      mentor: "",
      lastMentor: "",
      entryGrade: "",
      currIndex: 1,
      pageSize: 10,
    };
    setFilterInfo({
      ...initState,
    });
    getTableData(initState);
  }
  //分页改变
  function handlePageChange(event, pageSize) {
    console.log(event, pageSize)
    setFilterInfo({ ...filterInfo, pageSize, currIndex: event });
  }
  //学生状态变化
  async function handleStatusChange(type, data) {
    await post(
      {
        url: "/ec/student/getInboundState",
        data: {
          studentSid: data.studentSid,
          inboundState: type === "accept" ? 1 : 4,
        },
      },
      () => {
        notification.success({ description: "修改状态成功!", message: "通知" });
        getTableData(filterInfo);
      },
      props.history
    );
  }
  //打开详情模态框
  function handleClickDetail(data) {
    setDetailDialog({
      visible: true,
    });
    setDetailContent(data);
  }
  //打开设置模态框
  function handleClickSetting(data) {
    setDetailContent(data);
    setSetting({
      visible: true,
    });
  }
  //打开新增学生模态框
  function handleClickAddStudent() {
    addRef.current.addForm.resetFields();
    setAddStudent({
      visible: true,
    });
  }
  //关闭详情模态框
  function handleCancel(target, type) {
    console.log(target);
    if (!target) {
      setDetailDialog({
        visible: false,
      });
    } else if (target === "addStudent") {
      setAddStudent({
        visible: false,
      });
    } else {
      setSetting({
        visible: false,
      });
    }
  }
  //导出Excel
  async function handleClickExport() {
    const filter = Object.assign({}, filterInfo);
    delete filter.currIndex;
    delete filter.pageSize;
    filter.number = 1;
    console.log(filter)
    const rawData = await request({
      url: "/ec/station/exportStudentInfoStation",
      method: "post",
      data: {
        sessionId: localStorage.sessionId,
        ...filter,
      },
      responseType: "arraybuffer",
    });
    console.log('1',rawData);
    if (!rawData.data?.code) {
      let blob = new Blob([rawData.data], { type: "application/msexcel" });
      let objectUrl = URL.createObjectURL(blob);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display:none");
      a.setAttribute("href", objectUrl);
      //下载的文件名称
      let filename = "入站管理.xls";
      a.setAttribute("download", filename);
      a.click();
      URL.revokeObjectURL(objectUrl);
      if (window.navigator.msSaveBlob)
        window.navigator.msSaveBlob(blob, "入站管理.xls");
    }
  }
  //handleValueChange
  function handleValueChange(changedValues, allValues) {
    for (let key in changedValues) {
      if (!changedValues[key]) {
        changedValues[key] = '';
      }
    }
    setFilterInfo({...filterInfo, ...changedValues});
  }
  return (
    <>
      <CustomCard options={headerCardOptions}>
        <Form
          layout="inline"
          style={{ flexDirection: "column" }}
          form={filterForm}
          onValuesChange={ handleValueChange }
        >
          <Row className="student-resume-row">
            <Col span={6} offset={1}>
              <Form.Item label="姓名" name="studentName">
                <Input
                  placeholder="请输入姓名"
                  style={{ width: "80%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="传帮带" name="senior">
                <Select
                  style={{ width: "80%" }}
                  placeholder="请选择传帮带"
                  showSearch
                  allowClear
                >
                  {seniorNameList.map((item, index) => (
                    <Option value={item} key={index}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="学生状态" name="inboundState">
                <Select
                  style={{ width: "80%" }}
                  placeholder="请选择在站状态"
                  allowClear
                >
                  <Option value="0" key="0">
                    待考核
                  </Option>
                  <Option value="1" key="1">
                    在站
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Button type="primary" htmlType="submit" onClick={handleFilter}>
                筛选
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={6} offset={1}>
              <Form.Item label="年级" name="entryGrade">
                <Select
                  style={{ width: "80%" }}
                  placeholder="请选择年级"
                  allowClear
                >
                  {entryGrade.map((item, index) => (
                    <Option value={item} key={index}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="学硕/专硕" name="degreeType">
                <Select
                  style={{ width: "80%" }}
                  placeholder="请选择专业"
                  allowClear
                >
                  <Option value="1" key="1">
                    学硕
                  </Option>
                  <Option value="2" key="2">
                    专硕
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="导师" name="mentor">
                <Select
                  style={{ width: "80%" }}
                  placeholder="请选择导师"
                  showSearch
                  allowClear
                >
                  {mentorList.map((item, index) => (
                    <Option value={item} key={index}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Button type="plain" htmlType="submit" onClick={handleClear}>
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomCard>
      <CustomCard options={tableCardOptions}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <Button type="primary" onClick={handleClickAddStudent}>
            新增学生
          </Button>
          <Button type="primary" htmlType="submit" onClick={handleClickExport}>
            导出为Excel
          </Button>
        </div>
        <div>
          <Table
            className="table"
            rowKey="studentNo"
            columns={columns}
            dataSource={table}
            loading={loading}
            scroll={{ x: '1450px' }}
            pagination={{
              total: total,
              current: filterInfo.currIndex,
              pageSize: filterInfo.pageSize,
              pageSizeOptions: [10, 20, 30],
              onChange: handlePageChange,
              showSizeChanger: true,
            }}
          />
        </div>
      </CustomCard>
      {/* 新增学生 */}
      {addDialogMemo}
      {/* 查看详情 */}
      <Modal
        title="详细信息"
        visible={detailDialog.visible}
        onCancel={handleCancel.bind(null, null)}
        footer={[
          <Button key="back" onClick={handleCancel.bind(null, null)}>
            关闭
          </Button>,
        ]}
      >
        <Table
          className="detailTable"
          showHeader={false}
          columns={detailColumns}
          dataSource={detailTable}
          pagination={false}
        ></Table>
        <div className="studentResume-award">
          <p
            style={{
              marginLeft: "15px",
              fontSize: "15px",
              fontWeight: 600,
              marginBottom: 0,
            }}
          >
            所获奖项：
          </p>
          <div dangerouslySetInnerHTML={{ __html: detailContent.awards }}></div>
          <p
            style={{
              marginLeft: "15px",
              fontSize: "15px",
              fontWeight: 600,
              marginBottom: 0,
            }}
          >
            个人简介
          </p>
          <div
            dangerouslySetInnerHTML={{ __html: detailContent.selfIntroduction }}
          ></div>
        </div>
      </Modal>
      {/* 设置 */}
      {settingDialogMemo}
    </>
  );
}
