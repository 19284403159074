import React from 'react'
import { Card } from 'antd'

import "./card.css"

export default function CustomCard(props) {
  return (
    <Card
    title={ props.options.title }
    bordered={ props.options.bordered }
    className="custom-card"
    style={ props.options.style }
    >
      {props.children}
    </Card>
  )
}
