/*
 * @Author: ZhouShan
 * @Date: 2020-11-05 20:02:48
 * @Last Modified by: HouQi
 * @Last Modified time: 2022-02-24 22:00:37
 */
import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { post, request } from "../../../network/request";
import {
  Button,
  notification
} from "antd";

//子组件
import Filter from "./filter/filter";
import CustomCard from "../../../components/common/card/card";
import CheckHandover from "./checkHandover/checkHandover";
import ShowTable from "./table/ShowTable";

import "./transveralExitManage.css";

export default function TransveralExitManage(props) {
  console.log(props)
  // 筛选框状态保留
  if (props.location.state) {
    console.log('返回上一页页面跳转而来')
    console.log(props.location.state.hhhhhhh)
    // setFilterInfo({
    //   studentName: localStorage.getItem('filterName'),
    //   lastMentor: localStorage.getItem('filterMentor'),
    //   applyForFirst: localStorage.getItem('filterApply'),
    //   entryGrade: localStorage.getItem('filterGrade'),
    //   degreeType: localStorage.getItem('filterType'),
    //   applicationStatus: localStorage.getItem('filterStatus')
    // })
    console.log('this???')
    // console.log(filterInfo)
  } else {
    console.log('不是返回上一页页面跳转而来')
    localStorage.removeItem('filterName')
    localStorage.removeItem('filterGrade')
    localStorage.removeItem('filterType')
    localStorage.removeItem('filterMentor')
    localStorage.removeItem('filterApply')
    localStorage.removeItem('filterStatus')
  }
  
  console.log(localStorage)
  if (localStorage.filterStatus == '') {
    console.log('空')
    
  } else {
    console.log('非空')
  }
  const tableCardOptions = {
    title: null,
    bordered: false,
    style: { overflowY: "scroll", height: "calc(100vh - 78px - 130px)" },
  };

  /***************ref***************/
  // const filterRef = useRef();
  /*************state***************/
  const [loading, setLoading] = useState(null);
  const [rawTable, setRawTable] = useState([]);
  const [total, setTotal] = useState(0);
  const [beginValue, setBeginValue] = useState('')
  const [filterInfo, setFilterInfo] = useState({
    studentName: "",
    lastMentor: "",
    applyForFirst: "",
    entryGrade: "",
    degreeType: "",
    applicationStatus: ""
  });
  
  const [pageInfo, setPageInfo] = useState({
    currIndex: 1,
    pageSize: 10
  })
  //交接情况模态框
  const [handoverDialog, setHandoverDialog] = useState(false);
  const [detail, setDetail] = useState({});
  /************callback************/
  const handleCheckHandoverCallback = useCallback(
    (value) => {
      handleCheckHandover(value);
    },
    [],
  )
  const handlePageChangeCallback = useCallback(
    (event, pageSize) => {
      handlePageChange(event, pageSize);
    },
    [],
  )
  const handleOutboundApplicationCallback = useCallback(
    (value) => {
      handleOutboundApplication(value);
    },
    [],
  )
  const handleStatueUpdateCallback = useCallback(
    (type, target) => {
      handleStatueUpdate(type, target);
    },
    [],
  )
  /**************memo**************/
  const FilterMemo = useMemo(() => {
    return (
      <Filter
        getTableData={getTableData}
        setFilterInfo={setFilterInfo}
        filterInfo={filterInfo}
        setPageInfo={setPageInfo}
      />
    );
  }, [filterInfo, setPageInfo]);

  const CheckHandoverMemo = useMemo(() => {
    return (
      <CheckHandover
        handoverDialog={handoverDialog}
        handleDialogClose={handleDialogClose}
        detail={detail}
      />
    );
  }, [handoverDialog, detail]);

  const table = useMemo(() => {
    const tableData = [].concat(rawTable);
    tableData.forEach((item) => {
      item.applyForFirst = item.applyForFirst === 1 ? "已提交" : "未提交";
      item.degreeType = item.degreeType === 1 ? "学硕" : "专硕";
      switch (item.applicationStatus) {
        case 1:
          item.applicationStatus = "待审核";
          break;
        case 2:
          item.applicationStatus = "已拒绝";
          break;
        case 3:
          item.applicationStatus = "已通过";
          break;
        default:
          item.applicationStatus = "暂无";
      }
    });
    return tableData;
  }, [rawTable]);

  const CustomTable = useMemo(() => {
    return (
      <ShowTable
        total={total}
        pageInfo={pageInfo}
        handlePageChange={handlePageChangeCallback}
        loading={loading}
        table={table}
        handleOutboundApplication={handleOutboundApplicationCallback}
        handleStatueUpdate={handleStatueUpdateCallback}
        handleCheckHandover={handleCheckHandoverCallback}
      />
    );
  }, [
    handleCheckHandoverCallback,
    total,
    pageInfo,
    handlePageChangeCallback,
    loading,
    table,
    handleOutboundApplicationCallback,
    handleStatueUpdateCallback
  ]);

  

  /**************effect*************/
  useEffect(() => {
    getTableData(filterInfo);
  }, [pageInfo.pageSize, pageInfo.currIndex]);
  /*************method**************/
  async function getTableData(filterData) {
    setLoading(true);
    await post(
      {
        url: "/ec/station/getStudentInfoOfStation",
        data: {
          sessionId: localStorage.sessionId,
          roleId: JSON.parse(localStorage.userInfo).roleSid,
          ...pageInfo,
          ...filterData,
        },
      },
      (data) => {
        setLoading(false);
        setRawTable(data.contents.list);
        setTotal(data.contents.size);
      },
      props.history
    );
  }
  //分页改变
  function handlePageChange(event, pageSize) {
    setPageInfo({pageSize, currIndex: event });
  }
  //导出
  async function handleClickExport() {
    const filter = Object.assign({}, filterInfo);
    filter.number = 1;
    const rawData = await request({
      url: "/ec/station/exportStudentInfoForFirst",
      method: "post",
      data: {
        sessionId: localStorage.sessionId,
        ...filter,
      },
      responseType: "arraybuffer",
    });
    if (!rawData.data?.code) {
      let blob = new Blob([rawData.data], { type: "application/msexcel" });
      let objectUrl = URL.createObjectURL(blob);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display:none");
      a.setAttribute("href", objectUrl);
      //下载的文件名称
      let filename = "横向出站管理.xls";
      a.setAttribute("download", filename);
      a.click();
      URL.revokeObjectURL(objectUrl);
      if (window.navigator.msSaveBlob)
        window.navigator.msSaveBlob(blob, "学生简历管理.xls");
    }
  }
  //打开交接情况模态框
  function handleCheckHandover(value) {
    setDetail(value);
    setHandoverDialog(true);
  }
  //关闭模态框
  function handleDialogClose(type) {
    switch (type) {
      case "checkHandoverDialog":
        setHandoverDialog(false);
        break;
    }
  }
  /** 查看出站申请 **/
  function handleOutboundApplication(value) {
    console.log(value);
    let type;
    if (value.degreeType === "学硕") {
      type = 1;
    } else if (value.degreeType === "专硕") {
      type = 2;
    }
    props.history.push({
      pathname: "/home/outboundApplication",
      // state: {
      //   studentName: value.studentName,
      //   studentSid: value.studentSid,
      //   degreeType: type,
      //   entryGrade: value.entryGrade,
      // },
    });

    localStorage.setItem("studentName",value.studentName)
    localStorage.setItem("studentSid",value.studentSid)
    localStorage.setItem("degreeType",type)
    localStorage.setItem("entryGrade",value.entryGrade)
  }
  function handleStatueUpdate(type, target) {
    updateStationStatusByStudentSid(type, target);
  }
  async function updateStationStatusByStudentSid(type, target) {
    await post(
      {
        url: "/ec/station/updateStationStatusByStudentSid",
        data: {
          sessionId: localStorage.sessionId,
          studentSid: target.studentSid,
          ifAgree: type === "accept" ? 1 : 0,
          updateTime: parseInt(new Date().getTime() / 1000),
        },
      },
      () => {
        if (type === "accept") {
          notification.success({
            description: "通过成功",
            message: "通知",
          });
        } else {
          notification.success({
            description: "拒绝成功",
            message: "通知",
          });
        }
        getTableData(filterInfo);
      },
      props.history
    );
  }
  return (
    <>
      {FilterMemo}
      <CustomCard options={tableCardOptions}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 10,
          }}
        >
          <Button type="primary" htmlType="submit" onClick={handleClickExport}>
            导出为Excel
          </Button>
        </div>
        <div>{CustomTable}</div>
      </CustomCard>
      {CheckHandoverMemo}
    </>
  );
}
